import React from "react";
import { FormLabel, Slider, TextField } from "@mui/material";
import ExpandableContainer from "components/ExpandableContainer";
import { Settings } from "@mui/icons-material";
import { AnalyticType } from "interfaces/analytics";

interface AdvancedConfigsProps {
  similarity: number;
  type: AnalyticType;
  handleChangeSimilarity: (value: number) => void;
  movementThreshold?: number;
  handleChangeMovementThreshold: (value: number) => void;
}

const similarityMarks = [
  {
    value: 30,
    label: "30%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const thresholdMarks = [
  {
    value: 0,
    label: "Curto",
  },
  {
    value: 100,
    label: "Longo",
  },
];

export default function AdvancedConfigs({
  type,
  similarity,
  movementThreshold,
  handleChangeMovementThreshold,
  handleChangeSimilarity,
}: AdvancedConfigsProps) {
  function valuetext(value: number) {
    return `${value}%`;
  }

  function handleSimilarity(event: Event, newValue: number | number[]) {
    if (typeof newValue === "number") {
      handleChangeSimilarity(newValue);
    }
  }

  function handleMovementThreshold(event: Event, newValue: number | number[]) {
    if (typeof newValue === "number") {
      handleChangeMovementThreshold(newValue);
    }
  }

  return (
    <ExpandableContainer title="Configurações avançadas" icon={<Settings />}>
      <FormLabel>ID Gear</FormLabel>
      <TextField label="ID Gear" margin="dense" size="small" />
      <FormLabel>Similaridade</FormLabel>
      <div style={{ paddingInline: "20px" }}>
        <Slider
          min={30}
          value={similarity}
          marks={similarityMarks}
          valueLabelDisplay="auto"
          aria-label="Similaridade"
          onChange={handleSimilarity}
          getAriaValueText={valuetext}
        />
      </div>
      {type === "Inatividade" && (
        <>
          <FormLabel>Limiar de movimento</FormLabel>
          <div style={{ paddingInline: "20px" }}>
            <Slider
              aria-label="Movimento"
              value={movementThreshold}
              onChange={handleMovementThreshold}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              marks={thresholdMarks}
            />
          </div>
        </>
      )}
    </ExpandableContainer>
  );
}
