import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import MainContainer from "components/MainContainer/MainContainer";
import React, { useState } from "react";
import { LprForm } from "./LprForm";
import { LprList } from "./LprList";
import { useNavigate } from "react-router-dom";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getLprLogs } from "api/logs";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";
import { Security } from "@mui/icons-material";
import { CamerasAndGroups } from "interfaces/occurrences";

const permissions = ["authorized", "unauthorized", "all"] as const;
const zodDay = z.custom<Dayjs>((val) => val instanceof dayjs, "Data inválida");

const lprFormSchema = z.object({
  plate: z.string(),
  colors: z.array(z.string()),
  platePermissions: z.enum(permissions),
  cameras: z.custom<CamerasAndGroups>().nullable(),
  timestampAfter: zodDay.nullable(),
  timestampBefore: zodDay.nullable(),
});

export type LprFormSchema = z.infer<typeof lprFormSchema>;

export default function Lpr() {
  const navigate = useNavigate();
  const form = useForm<LprFormSchema>({
    defaultValues: {
      plate: "",
      colors: ["all"],
      platePermissions: "all",
      cameras: null,
      timestampAfter: null,
      timestampBefore: null,
    },
    resolver: zodResolver(lprFormSchema),
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { data: lprLogs, refetch } = useQuery({
    queryKey: ["lpr-log", { page, pageSize }],
    placeholderData: keepPreviousData,
    staleTime: 50 * 1000, // 50 seconds
    queryFn: () =>
      getLprLogs({
        page,
        pageSize,
        licensePlate: form.watch("plate"),
        timestampAfter: form
          .watch("timestampAfter")
          ?.format("YYYY-MM-DD HH:mm"),
        timestampBefore: form
          .watch("timestampBefore")
          ?.format("YYYY-MM-DD HH:mm"),
        colors: form.watch("colors"),
        camerasIds: getCamerasIds(form.watch("cameras")),
        platesPermission: form.watch("platePermissions"),
      }),
  });
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  function getCamerasIds(_entity: CamerasAndGroups | null) {
    if (!_entity) return;
    if (_entity.category === "Grupos") {
      let cameraIds = "";
      (_entity as HeimdallCameraGroup).cameras_in_group.forEach(
        (_camera, idx, arr) => {
          if (idx < arr.length - 1) {
            cameraIds += `${_camera.id},`;
          }
          if (idx === arr.length - 1) cameraIds += `${_camera.id}`;
        }
      );
      return cameraIds;
    }
    // entity is a single camera
    return _entity.id.toString();
  }

  async function handleSearch(data: LprFormSchema) {
    refetch();
  }

  async function handleClear() {
    form.reset();
    refetch();
  }

  return (
    <MainContainer
      title="LPR"
      responsiveActions={[
        {
          title: "Gerenciar permissões",
          icon: <Security />,
          action: () => navigate("/lpr/blacklist"),
        },
      ]}
    >
      <LprForm form={form} onSearch={handleSearch} onClear={handleClear} />
      <LprList
        page={page}
        pageSize={pageSize}
        lprLogs={lprLogs}
        setSelectedImage={setSelectedImage}
        onPageChange={(_page) => setPage(_page)}
        onPageSizeChange={(_pageSize) => {
          setPage(1);
          setPageSize(_pageSize);
        }}
      />
      {selectedImage && (
        <Dialog
          open={selectedImage !== null}
          fullWidth
          onClose={() => setSelectedImage(null)}
          maxWidth="md"
        >
          <DialogContent>
            <img src={selectedImage} alt="veiculo" width="100%" />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedImage(null)}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}
    </MainContainer>
  );
}
