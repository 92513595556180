import { Dayjs } from "dayjs";
import { DispatcherTransiton } from "./alertSystem";
import { AnalyticType } from "./analytics";

export interface NewDispatcher {
  name: string;
  duration: Dayjs | null;
  analyticType: AnalyticType | null;
  triggers: NewDispatcherTrigger[];
}

export interface NewDispatcherTrigger {
  analyticTriggerTime: Dayjs | null;
  transition: DispatcherTransiton;
  receivers: DispatcherReceiver[];
}

export type WhatsAppGroupReceiver = {
  groupId: string;
  isVideo: boolean;
};

export function isWhatsAppGroupReceiver(
  myObj: any
): myObj is WhatsAppGroupReceiver {
  return (
    typeof myObj.groupId === "string" && typeof myObj.isVideo === "boolean"
  );
}

export type WhatsAppReceiver = {
  number: string;
  isVideo: boolean;
};

export function isWhatsAppReceiver(myObj: any): myObj is WhatsAppReceiver {
  return typeof myObj.number === "string" && typeof myObj.isVideo === "boolean";
}

export type TelegramReceiver = {
  id: string | number;
  isVideo: boolean;
};

export function isTelegramReceiver(myObj: any): myObj is TelegramReceiver {
  return (
    (typeof myObj.id === "string" || typeof myObj.id === "number") &&
    typeof myObj.isVideo === "boolean"
  );
}

export type EmailReceiver = {
  address: string;
  id: -1; // yes
  name: string;
};

export function isEmailReceiver(myObj: any): myObj is EmailReceiver {
  return (
    typeof myObj.address === "string" &&
    typeof myObj.id === "number" &&
    typeof myObj.name === "string"
  );
}

export type GearReceiver = null;

export type MoniReceiver = {
  moniIntegrationId: number;
  clientId: string;
  companyId: string;
  partitionId: string;
};

export function isMoniReceiver(myObj: any): myObj is MoniReceiver {
  return (
    typeof myObj.moniIntegrationId === "number" &&
    typeof myObj.clientId === "string" &&
    typeof myObj.companyId === "string" &&
    typeof myObj.partitionId === "string"
  );
}

export type UserReceiver = {
  id: string | number;
  name: string;
};

export function isUserReceiver(myObj: any): myObj is UserReceiver {
  return (
    (typeof myObj.id === "string" || typeof myObj.id === "number") &&
    typeof myObj.name === "string"
  );
}

export type WebhookReceiver = {
  ip: string;
  protocol: "https" | "http";
  port: string | number;
  endpoint?: string;
  headers?: {
    [key: string]: string;
  };
  body?: {
    [key: string]: string;
  };
};

export function isWebhookReceiver(myObj: any): myObj is WebhookReceiver {
  return (
    typeof myObj.ip === "string" &&
    (myObj.protocol === "http" || myObj.procotol === "https") &&
    (typeof myObj.port === "string" || typeof myObj.port === "number") &&
    (myObj.endpoint ? typeof myObj.endpoint === "string" : true) &&
    (myObj.headers ? typeof myObj.headers === "object" : true) &&
    (myObj.body ? typeof myObj.body === "object" : true)
  );
}

export type DispatcherReceiver =
  | WhatsAppGroupReceiver
  | WhatsAppReceiver
  | TelegramReceiver
  | EmailReceiver
  | GearReceiver
  | MoniReceiver
  | UserReceiver
  | WebhookReceiver;
