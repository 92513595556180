import React, { useContext, useEffect } from "react";
import GlobalContext from "context/GlobalContext";
import { Navigate, Outlet, useLoaderData } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { authMuiTheme } from "styles/muiTheme";
import { isPlatform } from "@ionic/react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { HeimdallUser } from "interfaces/generic";

const excludedRedirectRoutes = [
  "/auth/confirm-email",
  "/auth/confirm-update-email",
  "/auth/reset",
];

export default function AuthLayout() {
  const { user, setUser } = useContext(GlobalContext);
  // Check redirectToAppLoader.ts file
  const storedUser = useLoaderData() as HeimdallUser | null;
  const currentUrl = new URL(window.location.href);

  // Mobile status bar color
  useEffect(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      StatusBar.setBackgroundColor({
        color: "#fafafa",
      });
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }, []);

  useEffect(() => {
    if (storedUser) setUser(storedUser);
  }, [setUser, storedUser]);

  // Redirects user to the application's main routes
  if (user && !excludedRedirectRoutes.includes(currentUrl.pathname)) {
    if (isPlatform("mobile") || isPlatform("mobileweb")) {
      return <Navigate to="/cameras" />;
    }
    return <Navigate to="/" />;
  }

  return (
    <ThemeProvider theme={authMuiTheme}>
      <Outlet />
    </ThemeProvider>
  );
}
