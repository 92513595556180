import React from "react";
import styles from "./AnalyticControls.module.css";
import { AccessTime } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

export default function AlertInterval({
  value,
  error,
  onChange,
}: {
  value: Dayjs | null;
  error: boolean;
  onChange: (value: Dayjs | null) => void;
}) {
  return (
    <section className={styles.configSection}>
      <header className={styles.sectionHeader}>
        <AccessTime />
        <h3 style={{ marginLeft: "5px" }}>Intervalo entre alertas</h3>
      </header>
      <TimePicker
        label="mm:ss"
        value={value}
        views={["minutes", "seconds"]}
        format="mm:ss"
        onChange={onChange}
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
            error: error,
            helperText: error
              ? "Você deve fornecer um intervalo entre alertas maior que 0 minutos e 0 segundos"
              : "",
          },
        }}
      />
    </section>
  );
}
