import { WhatsApp, Telegram, Person, Send } from "@mui/icons-material";
import React from "react";
import { ReceiverType } from "./ConfigureAnalytic";

export function ReceiverIcon({ type }: { type: ReceiverType | null }) {
  switch (type) {
    case ReceiverType.whatsapp:
      return <WhatsApp color="success" />;
    case ReceiverType.telegram:
      return <Telegram color="info" />;
    case ReceiverType.user:
      return <Person />;

    default:
      return <Send />;
  }
}
