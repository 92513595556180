import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./NotFound.module.css";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className={`${styles.container} surface`}>
      <h1>404 - Não encontrado</h1>
      <h2>Whoops, essa página não existe</h2>
      <Button
        variant="contained"
        startIcon={<ArrowBackIcon />}
        style={{ marginTop: "15px" }}
        onClick={() => navigate("/")}
      >
        Ir para o início
      </Button>
    </div>
  );
}
