import { NotificationsNone } from "@mui/icons-material";
import { Button, Switch } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
  muteAnalyticPushNotifications,
  unmuteAnalyticPushNotifications,
} from "api/analytics";
import { useToast } from "components/ui/use-toast";
import { CAMERA_BY_ID_QUERY_KEY } from "constants/apiQueryKeys";
import GlobalContext from "context/GlobalContext";
import { Analytic, AnalyticType } from "interfaces/analytics";
import { HeimdallCamera } from "interfaces/heimdallCamera";
import { convertAnalyticToAnalyticForm } from "lib/addCamera";
import { getErrorMessage } from "lib/apiErrorHandler";
import { cn } from "lib/classNames";
import {
  canUpdateAnalytic,
  updateAnalyticMiddleware,
} from "lib/configureAnalytic";
import { LogIcon } from "lib/LogIcon";
import React, { useContext, useEffect, useMemo, useState } from "react";

export default function AnalyticsList({
  camera,
  onConfigureAnalytic,
}: {
  camera: HeimdallCamera;
  onConfigureAnalytic: (analyticType: AnalyticType) => void;
}) {
  const { user } = useContext(GlobalContext);
  const [peopleAnalytics, setPeopleAnalytics] = useState<Analytic[]>([]);
  const [vehicleAnalytics, setVehicleAnalytics] = useState<Analytic[]>([]);
  const [animalAnalytic, setAnimalAnalytic] = useState<Analytic>();
  const queryClient = useQueryClient();

  function handleActivateAnalytic() {
    queryClient.invalidateQueries({
      queryKey: [CAMERA_BY_ID_QUERY_KEY(camera.id)],
    });
  }

  useEffect(() => {
    const newPeopleAnalytics: Analytic[] = [];
    const newVehicleAnalytics: Analytic[] = [];
    if (user?.company.plan.permissions.analytic.intrusion.active)
      newPeopleAnalytics.push(camera.analytics[0]); // intrusion
    if (user?.company.plan.permissions.analytic.agglomeration.active)
      newPeopleAnalytics.push(camera.analytics[1]); // agglomeration
    if (user?.company.plan.permissions.analytic.evasion.active)
      newPeopleAnalytics.push(camera.analytics[2]); // evasion
    if (user?.company.plan.permissions.analytic.inactivity.active)
      newPeopleAnalytics.push(camera.analytics[5]); // inactivity
    if (user?.company.plan.permissions.analytic.permanence.active)
      newPeopleAnalytics.push(camera.analytics[10]); // permanence
    if (user?.company.plan.permissions.analytic.vehicle_intrusion.active)
      newVehicleAnalytics.push(camera.analytics[7]); // vehicle intrusion
    if (user?.company.plan.permissions.analytic.vehicle_agglomeration.active)
      newVehicleAnalytics.push(camera.analytics[8]); // vehicle agglomeration
    if (user?.company.plan.permissions.analytic.vehicle_evasion.active)
      newVehicleAnalytics.push(camera.analytics[9]); // vehicle evasion
    if (user?.company.plan.permissions.analytic.lpr.active)
      newVehicleAnalytics.push(camera.analytics[4]); // lpr
    if (user?.company.plan.permissions.analytic.radar.active)
      newVehicleAnalytics.push(camera.analytics[3]); // radar
    setPeopleAnalytics(newPeopleAnalytics);
    setVehicleAnalytics(newVehicleAnalytics);
    if (user?.company.plan.permissions.analytic.animal_intrusion.active)
      setAnimalAnalytic(camera.analytics[6]); // animal
  }, [camera, user]);

  return (
    <div className="flex flex-col space-y-3">
      <h4>Pessoas</h4>
      {peopleAnalytics.map((analytic) => (
        <AnalyticButton
          key={analytic.id}
          analytic={analytic}
          onActivateAnalytic={handleActivateAnalytic}
          onConfigureAnalytic={() =>
            onConfigureAnalytic(analytic.analytic_type)
          }
        />
      ))}
      <h4>Veículos</h4>
      {vehicleAnalytics.map((analytic) => (
        <AnalyticButton
          key={analytic.id}
          analytic={analytic}
          onActivateAnalytic={handleActivateAnalytic}
          onConfigureAnalytic={() =>
            onConfigureAnalytic(analytic.analytic_type)
          }
        />
      ))}
      {!!animalAnalytic && (
        <>
          <h4>Animais</h4>
          <AnalyticButton
            analytic={animalAnalytic}
            onActivateAnalytic={handleActivateAnalytic}
            onConfigureAnalytic={() =>
              onConfigureAnalytic(animalAnalytic.analytic_type)
            }
          />
        </>
      )}
    </div>
  );
}

function AnalyticButton({
  analytic,
  onConfigureAnalytic,
  onActivateAnalytic,
}: {
  analytic: Analytic;
  onConfigureAnalytic?: React.MouseEventHandler<HTMLButtonElement>;
  onActivateAnalytic: () => void;
}) {
  const { user } = useContext(GlobalContext);
  const { toast } = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingMute, setIsUpdatingMute] = useState(false);

  const mutedNotificationsObject = useMemo(
    () =>
      analytic.userMutedAnalytic.find((value) => value.user_id === user?.id),
    [analytic, user]
  );

  const analyticForm = useMemo(
    () => convertAnalyticToAnalyticForm(analytic),
    [analytic]
  );
  const canActivateAnalytic = useMemo(
    () =>
      canUpdateAnalytic(analyticForm, {
        checked: [true, true, true, true, true, true, true],
      }),
    [analyticForm]
  );

  async function handleActivateAnalytic() {
    setIsUpdating(true);
    await updateAnalyticMiddleware({
      analytic: analyticForm,
      checkedDaysOfWeek: [true, true, true, true, true, true, true],
    })
      .then(async () => {
        toast({
          description: analytic.activated
            ? "Analítico desativado com sucesso"
            : "Analítico ativado com sucesso",
        });
        await onActivateAnalytic();
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: `Falha ao ${analytic.activated ? "desativar" : "ativar"} analítico`,
          description: getErrorMessage(err),
        });
      });
    setIsUpdating(false);
  }

  async function handleMuteAnalyticPushNotifications(shouldMute: boolean) {
    setIsUpdatingMute(true);
    if (shouldMute) {
      await muteAnalyticPushNotifications({
        analyticId: analytic.id,
      })
        .then(async () => {
          toast({
            description: "Notificações de celular silenciadas com sucesso",
          });
          onActivateAnalytic();
          setIsUpdatingMute(false);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao silenciar notificações de celular",
            description: getErrorMessage(err),
          });
          setIsUpdatingMute(false);
        });
    } else {
      await unmuteAnalyticPushNotifications(mutedNotificationsObject!.id)
        .then(async () => {
          toast({
            description: "Notificações de celular habilitadas com sucesso",
          });
          onActivateAnalytic();
          setIsUpdatingMute(false);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao habilitar notificações de celular",
            description: getErrorMessage(err),
          });
          setIsUpdatingMute(false);
        });
    }
  }

  return (
    <div
      className={cn(
        "flex flex-col border px-3 py-4 justify-between w-full",
        analytic.activated ? "primary-container" : ""
      )}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center space-x-3">
          <LogIcon
            color="var(--root-color-primary)"
            logType={analytic.analytic_type}
          />
          <span className="font-bold primary-text">
            {analytic.analytic_type}
          </span>
        </div>
        <Switch
          color="primary"
          checked={analytic.activated}
          disabled={!canActivateAnalytic || isUpdating}
          onChange={handleActivateAnalytic}
        />
      </div>
      <div className="my-3 flex justify-between">
        <div className="flex space-x-3 items-center">
          <NotificationsNone fontSize="small" color="secondary" />
          <span className="text-sm font-bold secondary-text">Notificações</span>
        </div>
        <Switch
          size="small"
          color="secondary"
          disabled={isUpdatingMute}
          checked={!mutedNotificationsObject}
          onChange={(ev, checked) =>
            handleMuteAnalyticPushNotifications(!checked)
          }
        />
      </div>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={onConfigureAnalytic}
      >
        Configurar
      </Button>
    </div>
  );
}
