import { IconButton, useMediaQuery } from "@mui/material";
import React, { useMemo, useState } from "react";
import BorderedDataGrid, {
  BorderedDataGridParams,
} from "components/BorderedDataGrid/BorderedDataGrid";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { LogsCardViewer } from "./LogsCardViewer";
import { GridView, ViewListOutlined } from "@mui/icons-material";
import { BackendResponsePagination } from "interfaces/generic";
import MobilePagination from "components/MobilePagination/MobilePagination";

interface LogsMultiViewerParams extends BorderedDataGridParams {
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
  setOpenEdit: (value: boolean) => void;
  data?: BackendResponsePagination<CombinedLog>;
}

const defaultPageSize = 15;

export default function LogsMultiViewer({
  data,
  setOpenEdit,
  setOpenExpanded,
  setSelectedOccurrence,
  ...borderedGridParams
}: LogsMultiViewerParams) {
  const isSm = useMediaQuery("(max-width: 600px)");
  const [viewType, setViewType] = useState<"list" | "grid">(
    isSm ? "grid" : "list"
  );
  const paginationModel = useMemo(
    () => borderedGridParams.paginationModel!,
    [borderedGridParams]
  );

  function toggleViewType() {
    if (viewType === "list") setViewType("grid");
    else setViewType("list");
  }

  if (isSm) {
    return (
      <div>
        <header className="flex items-center justify-between">
          <IconButton
            color="secondary"
            onClick={toggleViewType}
            sx={{ marginBlock: "10px" }}
          >
            {viewType === "list" ? <GridView /> : <ViewListOutlined />}
          </IconButton>
          <MobilePagination
            data={data}
            defaultPageSize={15}
            page={paginationModel.page}
            pageSize={paginationModel.pageSize || defaultPageSize}
            onPageChange={(page) => {
              borderedGridParams.onPaginationModelChange?.(
                {
                  page,
                  pageSize: paginationModel.pageSize,
                },
                {
                  api: borderedGridParams.apiRef!.current,
                }
              );
            }}
            onPageSizeChange={(pageSize) => {
              borderedGridParams.onPaginationModelChange?.(
                {
                  page: 0,
                  pageSize,
                },
                {
                  api: borderedGridParams.apiRef!.current,
                }
              );
            }}
          />
        </header>
        {viewType === "grid" ? (
          <LogsCardViewer
            isLoading={borderedGridParams.loading}
            rows={borderedGridParams.rows as CombinedLog[]}
            setSelectedOccurrence={setSelectedOccurrence}
            setOpenExpanded={setOpenExpanded}
            setOpenEdit={setOpenEdit}
          />
        ) : (
          <BorderedDataGrid {...borderedGridParams} />
        )}
      </div>
    );
  }

  return <BorderedDataGrid {...borderedGridParams} />;
}
