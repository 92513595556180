import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";

/**
 * Creates a file inside android Documents folder
 * @param filename the filename or path to the file to be written (e.g myFilename.txt)
 * @param recursive if filename contains a parent folder, use recurise=true to potentially create the parent directory
 * @param data the data contained in the file
 */
export async function writeFileAndroid(
  filename: string,
  data: string | Blob,
  recursive?: boolean
) {
  await Filesystem.writeFile({
    path: filename,
    data,
    directory: Directory.Documents,
    encoding: Encoding.UTF8,
    recursive,
  });
}
