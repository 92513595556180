import React from "react";
import { Add, Dashboard, Public } from "@mui/icons-material";
import { Button, Popover } from "@mui/material";
import { Link } from "react-router-dom";
import { Cftv } from "components/icons/thirdPartyIcons";
import { baseUrlNoPort } from "api/rootConfig";
import styles from "./MainActionButton.module.css";
import { isPlatform } from "@ionic/react";

const containerStyles = {
  marginLeft: "10px",
  marginRight: "5px",
  marginBlock: "10px",
  height: "52px",
  borderRadius: "20px",
  maxWidth: "100px",
};

export default function MainActionButton({ inDrawer }: { inDrawer?: boolean }) {
  // Popover
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  if (isPlatform("mobile") && !isPlatform("mobileweb")) {
    return null;
  }

  return (
    <>
      <Button
        className={inDrawer ? "" : styles.container}
        variant="contained"
        color="onPrimary"
        startIcon={
          <Add className={inDrawer ? "" : styles.icon} fontSize="large" />
        }
        onClick={handleClick}
        sx={
          inDrawer
            ? {
                "&.MuiButton-startIcon": {
                  fontSize: "20px",
                  marginLeft: "10px",
                },
                ...containerStyles,
              }
            : {
                "&.MuiButton-startIcon": {
                  fontSize: "20px",
                  marginLeft: "10px",
                },
              }
        }
      >
        <span className={inDrawer ? "" : styles.title}>Iniciar</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ul style={{ paddingBlock: "10px" }}>
          <li>
            <Link
              to="/monitor"
              target="_blank"
              style={{
                all: "revert",
                maxWidth: "100px",
                color: "var(--root-color-on-surface)",
              }}
              onClick={handleClose}
            >
              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="text"
                style={{
                  justifyContent: "flex-start",
                  paddingInline: "25px",
                  borderRadius: "0px",
                }}
                startIcon={<Dashboard />}
              >
                Monitoramento
              </Button>
            </Link>
          </li>
          <li>
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="text"
              style={{
                justifyContent: "flex-start",
                paddingInline: "25px",
                borderRadius: "0px",
              }}
              startIcon={
                <Cftv
                  width={24}
                  height={24}
                  style={{ fill: "var(--root-color-on-surface)" }}
                />
              }
              onClick={() => {
                handleClose();
                window.open(
                  `${baseUrlNoPort}:8083/pages/multiview/full`,
                  "_blank"
                );
              }}
            >
              CFTV
            </Button>
          </li>
          <li>
            <Link
              to="/cameras/map"
              style={{
                all: "revert",
                maxWidth: "100px",
                color: "var(--root-color-on-surface)",
              }}
              onClick={handleClose}
            >
              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="text"
                style={{
                  justifyContent: "flex-start",
                  paddingInline: "25px",
                  borderRadius: "0px",
                }}
                startIcon={<Public />}
              >
                Mapa de câmeras
              </Button>
            </Link>
          </li>
        </ul>
      </Popover>
    </>
  );
}
