import { OccurenceMarkColor } from "styles/colors";
import { OccurrenceMark } from "../interfaces/occurrences";

export function getTranslation(level: OccurrenceMark) {
  switch (level) {
    case "accidents":
      return "Acidente";
    case "effective":
      return "Efetivo";
    case "false_positive":
      return "Falso positivo";
    case "resident":
      return "Morador";
    case "vandalism":
      return "Vandalismo";
    case "visualized":
      return "Visto";
    case "whatsapp":
      return "WhatsApp";
    case "headless":
      return "Sem destinatário";
    default:
      return "Não tratado";
  }
}

export function getOccurrenceMarkColor(level: OccurrenceMark) {
  switch (level) {
    case "accidents":
      return OccurenceMarkColor.accidents;
    case "effective":
      return OccurenceMarkColor.effective;
    case "false_positive":
      return OccurenceMarkColor.false_positive;
    case "resident":
      return OccurenceMarkColor.resident;
    case "vandalism":
      return OccurenceMarkColor.vandalism;
    case "visualized":
      return OccurenceMarkColor.visualized;
    default:
      break;
  }
}
