import { BackendResponsePagination } from "interfaces/generic";
import { HeimdallOperator, HeimdallOperatorRoles } from "interfaces/operator";
import axiosRoot from "./rootConfig";

interface GetOperatorsParams {
  page?: number;
  pageSize?: number;
  name?: string;
  role?: HeimdallOperatorRoles | "";
  status?: "activated" | "deactivated";
  dateAfter?: string;
  dateBefore?: string;
}

export async function getOperators(
  {
    page = 1,
    pageSize = 100000,
    name,
    role,
    status,
    dateAfter,
    dateBefore,
  }: GetOperatorsParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  let requestUrl = "/api/user_company?page=" + page;

  if (name) requestUrl += "&filter%5Bname%5D=" + name;
  if (role) requestUrl += "&filter%5BofRole%5D=" + role;
  if (status) requestUrl += "&filter%5Bstatus%5D=" + status;
  if (dateAfter) requestUrl += "&filter%5Btimestamp_after%5D=" + dateAfter;
  if (dateBefore) requestUrl += "&filter%5Btimestamp_before%5D=" + dateBefore;

  return await axiosRoot
    .get<BackendResponsePagination<HeimdallOperator>>(requestUrl, {
      headers: {
        paginate: pageSize,
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
}
export async function createOperator(
  data: Partial<{
    company_id: number;
    name: string;
    cpf: string;
    phone: string;
    username: string;
    email: string;
    group_ids: number[];
    group_update_permission: boolean[];
    camera_ids: number[];
    update_permission: boolean[];
    password: string;
    password_confirmation: string;
    role: string;
  }>
) {
  return await axiosRoot.post("/api/user_company", data);
}

export async function getOperator(id: number | string) {
  return await axiosRoot
    .get<{ data: HeimdallOperator }>("/api/user_company/" + id)
    .then((response) => response.data.data);
}

export async function editOperator(id: number | string, data: any) {
  return await axiosRoot.put("/api/user_company/" + id, data);
}

export async function getSpecificOperators(
  { page = 1, pageSize = 100000, name, role }: GetOperatorsParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  return await axiosRoot
    .get<BackendResponsePagination<HeimdallOperator>>(
      "/api/list_operator?page=" + page,
      {
        headers: { paginate: pageSize },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteOperator(id: number | string) {
  return await axiosRoot.delete("/api/user_company/" + id);
}

/*----------------------------------------------------------
 * Operator Permissions
 * ------------------------------------------------------------- */
export async function updatePermissions(
  id: number,
  type: "group" | "camera",
  data: {
    group_permission?: [number, boolean][];
    camera_permission?: [number, boolean][];
  }
) {
  let requestURL = "/api/user_company/";
  if (type === "camera") requestURL += "camera_rules/" + id;
  else if (type === "group") requestURL += "camera_groups/" + id;

  return await axiosRoot
    .put(requestURL, data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
