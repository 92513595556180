import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "context/GlobalContext";
// Components
import { TextField, IconButton, MenuItem, Autocomplete } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// Styles
import styles from "./NewOperator.module.css";
import { createOperator } from "api/operators";
import { isValidOperator, isValidEmail } from "lib/operators";
import MainContainer from "components/MainContainer/MainContainer";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { useQuery } from "@tanstack/react-query";
import { COMPANIES_QUERY_KEY } from "constants/apiQueryKeys";
import { listCompanies } from "api/company";
import { HeimdallCompany } from "interfaces/company";

export default function NewOperator() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [company, setCompany] = useState<HeimdallCompany | null>(null);
  const [companyError, setCompanyError] = useState(false);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { data: companies, isLoading: isLoadingCompanies } = useQuery({
    queryKey: [COMPANIES_QUERY_KEY],
    queryFn: () => listCompanies(),
    enabled: user?.role === "super-admin",
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<Element, Event>) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  function handleFormErrors() {
    if (user?.role === "super-admin" && !company) setCompanyError(true);
    if (!role) setRoleError(true);
    if (!name) setNameError(true);
    if (!username || username.length < 4 || username.length > 15)
      setUsernameError(true);
    if (!email || !isValidEmail(email)) setEmailError(true);
    if (!password || password.length < 6) setPasswordError(true);
    if ((password && !confirm) || (password && confirm !== password))
      setConfirmError(true);
  }

  const handleCreateOperator = async () => {
    handleFormErrors(); // Displaying errors in the form
    if (
      !user ||
      (user.role === "super-admin" && !company) ||
      !isValidOperator(name, username, email, password, confirm)
    )
      return;
    // Can create operator
    setLoading(true);

    const creationData: any = {
      name,
      username,
      email,
      password,
      password_confirmation: confirm,
      role,
    };

    if (user.role === "super-admin") {
      creationData.company_id = company!.id;
    }

    await createOperator(creationData)
      .then(() => navigate("/operators"))
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao criar usuário",
          description: getErrorMessage(err),
        });
      });

    setLoading(false);
  };

  const handleForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleCreateOperator();
  };

  return (
    <MainContainer title="Novo usuário" returnAction>
      <p className={styles.subtitle}>
        Crie um novo usuário para ter acesso a plataforma heimdall. O
        colaborador terá permissões pré-definidas, tais como: criação de câmera,
        visualização, atualização e remoção. Além disso, o usuário pode
        configurar os analíticos de cada câmera.
      </p>
      <form noValidate className={styles.formContainer} onSubmit={handleForm}>
        {user?.role === "super-admin" && (
          <Autocomplete
            disablePortal
            id="companies-autocomplete"
            options={
              companies?.data.sort((a, b) => b.name.localeCompare(a.name)) || []
            }
            size="small"
            value={company}
            loading={isLoadingCompanies}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => setCompany(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Companhia"
                error={companyError}
                helperText={companyError ? "Campo obrigatório" : ""}
              />
            )}
          />
        )}
        <TextField
          select
          size="small"
          label="Cargo"
          value={role}
          error={roleError}
          onChange={(ev) => setRole(ev.target.value)}
          helperText={roleError ? "Você deve selecionar um cargo" : ""}
        >
          <MenuItem value="">Selecionar</MenuItem>
          <MenuItem value="manager">Administrador</MenuItem>
          <MenuItem value="collaborator">Operador</MenuItem>
        </TextField>
        <TextField
          required
          id="name"
          label="Nome"
          size="small"
          value={name}
          error={nameError}
          helperText={nameError ? "Você deve fornecer um nome" : ""}
          onChange={(ev) => {
            setNameError(false);
            setName(ev.target.value);
          }}
        />
        <TextField
          required
          id="email"
          size="small"
          value={email}
          label="E-mail"
          error={emailError}
          helperText={
            emailError ? "Você deve fornecer um endereço de email válido" : ""
          }
          onChange={(ev) => {
            setEmailError(false);
            setEmail(ev.target.value);
          }}
        />
        <TextField
          required
          size="small"
          id="username"
          value={username}
          label="Usuário"
          error={usernameError}
          helperText={
            usernameError
              ? "Nome de usuário deve ter entre 4 e 15 caracteres"
              : ""
          }
          onChange={(ev) => {
            setUsernameError(false);
            setUsername(ev.target.value);
          }}
        />
        <TextField
          required
          size="small"
          label="Senha"
          id="password"
          value={password}
          error={passwordError}
          helperText={
            passwordError ? "A senha deve ter no mínimo 6 caracteres" : ""
          }
          onChange={(event) => {
            setPasswordError(false);
            setPassword(event.target.value);
          }}
          type={!showPassword ? "password" : "text"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          size="small"
          id="confirm"
          value={confirm}
          error={confirmError}
          label="Confirmar senha"
          helperText={
            confirmError
              ? "O campo de confirmação deve ser igual ao campo Senha"
              : ""
          }
          onChange={(event) => {
            setConfirm(event.target.value);
            setConfirmError(false);
          }}
          type={!showPasswordConfirm ? "password" : "text"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          onSubmit={handleForm}
          loading={loading}
        >
          {password || confirm ? "Criar usuário" : "Enviar convite"}
        </LoadingButton>
      </form>
    </MainContainer>
  );
}
