import { PushNotifications } from "@capacitor/push-notifications";

export const addListeners = async () => {
  await PushNotifications.addListener(
    "pushNotificationReceived",
    (notification) => {
      console.log("Push notification received: ", notification);
    }
  );

  await PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log(
        "Push notification action performed",
        notification.actionId,
        notification.inputValue
      );
    }
  );
};

export const registerNotifications = async (forcePrompt?: boolean) => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt" || forcePrompt) {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  await PushNotifications.register();
};

export const unregisterNotifications = async () => {
  await PushNotifications.unregister();
};

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log("delivered notifications", notificationList);
};
