import React from "react";

export function InfoTile({ label, value }: { label: string; value?: string; }) {
  return (
    <div className="border rounded-lg p-2">
      <h2>{label}</h2>
      <p>{value}</p>
    </div>
  );
}
