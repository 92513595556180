import React from "react";
import { downloadCSV } from "lib/downloadCsv";
import { MenuItem } from "@mui/material";
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridExportMenuItemProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarExportProps,
  GridToolbarFilterButton,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { exportLogs, getLogs, GetLogsParams } from "api/logs";
import { convertLogsToCsv, getLogNameFromLogType } from "lib/logs";
import { OccurrenceMark, SpecificLogUrlPath } from "interfaces/occurrences";
import { toast } from "components/ui/use-toast";
import { isPlatform } from "@ionic/react";
import { writeFileAndroid } from "mobileBridge/androidWriteFile";

export type ExportOptions = {
  cameras?: string;
  before?: string;
  after?: string;
  status?: OccurrenceMark | "";
  type: SpecificLogUrlPath;
  order?: GetLogsParams["order"];
};

const oneBillion = Math.pow(10, 9);

const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

/**
 * @deprecated this method causes a server crash when exporting too many pages, because of memory overuse
 * @param options the filters that should be applied to the cameras request, before exporting
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function exportAllRows(options?: ExportOptions) {
  let logName = "";
  const timestampBefore = options?.before;
  const timestampAfter = options?.after;
  const allRows = await getLogs({
    page: 1,
    pageSize: oneBillion,
    camerasIds: options?.cameras,
    timestampBefore,
    timestampAfter,
    status: options?.status,
    type: options?.type,
  });
  if (options) logName = getLogNameFromLogType(options.type);
  const fileName = `Logs_${
    logName ? `${logName}_` : ""
  }Heimdall_${dayjs().format("DD-MM-YYYY_HH:mm:ss")}.csv`;
  if (allRows) {
    const csvData = convertLogsToCsv(allRows.data);
    downloadCSV(csvData, fileName);
  }
}

function CustomExportMenuItems({
  csvOptions,
  options,
  ...props
}: {
  csvOptions?: GridCsvExportOptions;
  options: ExportOptions;
  props?: GridExportMenuItemProps<{}>;
}) {
  const apiRef = useGridApiContext();
  const { hideMenu }: any = props;
  const handleExport = (_options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(_options);
  const isBrowser = !(isPlatform("mobile") && !isPlatform("mobileweb"));

  return (
    <>
      {isBrowser ? (
        <>
          <MenuItem
            onClick={() => {
              handleExport({
                getRowsToExport: getRowsFromCurrentPage,
                ...csvOptions,
              });
              hideMenu?.();
            }}
          >
            Exportar c/ colunas visíveis
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleExport({
                getRowsToExport: getRowsFromCurrentPage,
                ...csvOptions,
                allColumns: true,
              });
              hideMenu?.();
            }}
          >
            Exportar c/ todas as colunas
          </MenuItem>
          <MenuItem
            onClick={() => {
              // If the user is in a browser, then the export should open a new window that
              // makes the request to download the resource, which gives a good default UI
              // for the download.
              exportLogs(options, isBrowser);
              hideMenu?.();
            }}
          >
            Exportar todas as páginas
          </MenuItem>
        </>
      ) : (
        <MenuItem
          onClick={() => {
            const logName = getLogNameFromLogType(options.type);
            const fileName = `Logs_${
              logName ? `${logName}_` : ""
            }Heimdall_${dayjs().format("DD-MM-YYYY_HH:mm:ss")}.csv`;

            // If the user is inside the android app, it should download in background
            exportLogs(options, isBrowser)
              .then(async (res) => {
                const text = await (res as Blob).text();
                await writeFileAndroid(fileName, text);
              })
              .catch(() => {
                toast({
                  variant: "destructive",
                  description: "Um erro inesperado ocorreu.",
                });
              });

            hideMenu?.();
          }}
        >
          Exportar todas as páginas
        </MenuItem>
      )}
    </>
  );
}

const GridToolbarExport = ({
  csvOptions,
  printOptions,
  ...other
}: GridToolbarExportProps) => (
  <GridToolbarExportContainer {...other}>
    <CustomExportMenuItems csvOptions={csvOptions} options={other.options} />
  </GridToolbarExportContainer>
);

export default function LogsGridToolbar(options?: ExportOptions) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `Logs_Heimdall_${dayjs().format(
            "DD-MM-YYYY_HH[h]mm[m]ss[s]"
          )}`,
        }}
        options={options}
      />
    </GridToolbarContainer>
  );
}
