import { WhatsApp, Telegram, Person, AddCircle } from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getOperators } from "api/operators";
import { OPERATORS_QUERY_KEY } from "constants/apiQueryKeys";
import { cn } from "lib/classNames";
import React, { useEffect, useRef, useState } from "react";
import { ReceiverType } from "./ConfigureAnalytic";
import { ReceiverIcon } from "./ReceiverIcon";

export function SimpleReceiverField({
  onAddReceiver,
}: {
  onAddReceiver: (type: ReceiverType, receiverInfo: string) => void;
}) {
  const { data: operators } = useQuery({
    queryKey: [OPERATORS_QUERY_KEY],
    queryFn: () => getOperators(),
  });
  const [selectedReceiverType, setSelectedReceiverType] =
    useState<ReceiverType | null>(null);
  const [hideFirstClickContainer, setHideFirstClickContainer] =
    useState(!!selectedReceiverType);
  const [receiverInfo, setReceiverInfo] = useState("");
  const [receiverError, setReceiverError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Menu state
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setHideFirstClickContainer(!!selectedReceiverType);
  }, [selectedReceiverType]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(buttonRef.current);
  };

  function handleReceiverType(type: ReceiverType) {
    setSelectedReceiverType(type);
    setAnchorEl(null);
  }

  function handleAddReceiver() {
    if (!selectedReceiverType) {
      setReceiverError(true);
      setErrorMessage("Você deve selecionar o tipo de remetente");
      return;
    }
    if (!receiverInfo || receiverInfo === "") {
      setReceiverError(true);
      setErrorMessage("Você deve preencher o campo");
      return;
    }

    onAddReceiver(selectedReceiverType, receiverInfo);
    setReceiverInfo("");
    setSelectedReceiverType(null);
  }

  return (
    <>
      <TextField
        fullWidth
        size="small"
        className="relative"
        value={receiverInfo}
        error={receiverError}
        helperText={receiverError ? errorMessage : ""}
        select={selectedReceiverType === ReceiverType.user}
        onChange={(ev) => setReceiverInfo(ev.target.value)}
        placeholder={
          hideFirstClickContainer ? "ID" : "Selecione o tipo de destinatário"
        }
        InputProps={{
          style: { borderRadius: "20px" },
          startAdornment: (
            <>
              <div
                className={cn(
                  "absolute top-0 left-0 w-full h-10 rounded-2xl",
                  hideFirstClickContainer ? "hidden" : ""
                )}
                onClick={handleClick}
              ></div>
              <IconButton
                ref={buttonRef}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <ReceiverIcon type={selectedReceiverType} />
              </IconButton>
              <Menu
                open={open}
                id="basic-menu"
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => handleReceiverType(ReceiverType.whatsapp)}
                >
                  <ListItemIcon>
                    <WhatsApp color="success" />
                  </ListItemIcon>
                  <ListItemText>WhatsApp</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleReceiverType(ReceiverType.telegram)}
                >
                  <ListItemIcon>
                    <Telegram color="info" />
                  </ListItemIcon>
                  <ListItemText>Telegram</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleReceiverType(ReceiverType.user)}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText>Usuário</ListItemText>
                </MenuItem>
              </Menu>
            </>
          ),
        }}
      >
        <MenuItem value="-1/Todos">Todos usuários</MenuItem>
        {selectedReceiverType === ReceiverType.user &&
          operators?.data.map((operator) => (
            <MenuItem
              key={operator.id}
              value={`${operator.id}/${operator.name}`}
            >
              {operator.name}
            </MenuItem>
          ))}
      </TextField>
      <IconButton color="primary" onClick={handleAddReceiver}>
        <AddCircle fontSize="large" />
      </IconButton>
    </>
  );
}
