import {
  HeimdallDeviceTypes,
  HeimdallDeviceTypesPretty,
} from "interfaces/devices";

export function getDeviceTypeFromDevicePrettyType(
  prettyType: HeimdallDeviceTypesPretty
): HeimdallDeviceTypes {
  switch (prettyType) {
    case "Alto Falante":
      return "horn_speaker";
    case "Controladora":
      return "commbox";
    default:
      throw new Error("Tipo de dispositivo nao existe");
  }
}
