import React, { useReducer, useRef, useState } from "react";
// Helpers
import { Dayjs } from "dayjs";
import { TimeRange } from "interfaces/heimdallCamera";
import { ConfigureAnalyticProps } from "interfaces/configureAnalytic";
import useCanvasDimensions from "./useCanvasDimensions";
// Components
import AdvancedConfigs from "./Controls/AdvancedConfigs";
import AssociateDevices from "./IO_Devices/AssociateDevices";
import { Alert, Button } from "@mui/material";
import {
  configureAnalyticReducer,
  updateAnalyticMiddleware,
  canUpdateAnalytic,
} from "lib/configureAnalytic";
import { RegionMonitor } from "interfaces/analytics";
import { useToast } from "components/ui/use-toast";
import AnalyticControls from "./Controls/AnalyticControls";
import MultipleRegionCanvas from "./DrawCanvas/MultipleRegionCanvas";
import { LoadingButton } from "@mui/lab";
// Styles
import styles from "./ConfigureAnalytic.module.css";
import { isPlatform } from "@ionic/react";
import ExpandableContainer from "components/ExpandableContainer";
import { Notifications } from "@mui/icons-material";
import DispatcherSelector from "./Controls/DispatcherSelector";
import CreateSimpleDispatcherDialog from "./CreateSimpleDispatcherDialog";

export default function ConfigureAnalytic({
  cameraFrame,
  updateCamera,
  cameraAnalytic,
}: ConfigureAnalyticProps) {
  const { toast } = useToast();
  // -----------DrawCanvas (child)component state-----------
  const canvasDimensions = useCanvasDimensions();
  const canvasDimensionsRef = useRef(useCanvasDimensions());
  const [regionError, setRegionError] = useState(false);
  // Analytic
  const [hasChanged, setHasChanged] = useState(false);
  const [analytic, dispatch] = useReducer(
    configureAnalyticReducer,
    cameraAnalytic
  );
  const [initialRegions] = useState(analytic.region_monitor);
  const [allTimeSchedule, setAllTimeSchedule] = useState<
    { initTime: Dayjs | null; stopTime: Dayjs | null }[]
  >([{ initTime: null, stopTime: null }]);
  // -----------AnalyticControls (child)component state-----------
  // Checkbox state
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(new Array(8).fill(true));
  // RangePicker state
  const [timeScheduleError, setTimeScheduleError] = useState(false);
  const [timeScheduleAll, setTimeScheduleAll] = useState<TimeRange[]>([
    { init_time: null, stop_time: null },
  ]);
  const [alertInterval, setAlertInterval] = useState<Dayjs | null>(null);
  const [alertIntervalError, setAlertIntervalError] = useState(false);
  // Dispatchers
  const currentDispatcher = analytic.alerts_analytics[0];
  const [openNewDispatcher, setOpenNewDispatcher] = useState(false);
  // other
  const [isLoading, setIsLoading] = useState(false);
  canvasDimensionsRef.current = useCanvasDimensions();

  // Destructuring state
  const analyticControlsState = {
    checked,
    analytic,
    setChecked,
    indeterminate,
    alertInterval,
    allTimeSchedule,
    timeScheduleAll,
    setAlertInterval,
    setIndeterminate,
    timeScheduleError,
    alertIntervalError,
    setTimeScheduleAll,
    setAlertIntervalError,
    handleAddTimeSchedule,
    handleChangeDispatcher,
    handleChangeAlertInterval,
    handleChangeAllTimeSchedule,
    handleDeleteTimeSchedule,
    handleChangeTimeSchedule,
    setTimeScheduleError,
    handleChangeTargetDetectionNumber,
  };

  function handleActiveStatus(value: boolean) {
    dispatch({
      type: "activeStatus",
      payload: value,
    });
  }

  function handlePauseStatus(value: boolean) {
    dispatch({
      type: "pauseStatus",
      payload: value,
    });
  }

  function handleAddRegion() {
    dispatch({
      type: "addedRegion",
    });
    setHasChanged(true);
  }

  function handleDrawPolygon(polygon: [number, number][], index: number) {
    const normalizedPolygon = polygon.map((vertex) => [
      vertex[0] / canvasDimensionsRef.current.width,
      vertex[1] / canvasDimensionsRef.current.height,
    ]);
    dispatch({
      type: "drawedRegion",
      index,
      polygon: normalizedPolygon,
    });

    setRegionError(false);
    setHasChanged(true);
  }

  function handleDeleteRegion(index: number) {
    dispatch({
      type: "deletedRegion",
      index,
    });
    setHasChanged(true);
  }

  function handleResetRegions(newRegions: RegionMonitor) {
    dispatch({
      type: "resetRegions",
      payload: newRegions,
    });
  }

  function handleAddTimeSchedule(dayOfWeek: string, allFields?: boolean) {
    dispatch({
      type: allFields ? "addedAllTimeSchedule" : "addedTimeSchedule",
      dayOfWeek,
    });
    if (allFields) {
      const newAllTimeSchedule = [...allTimeSchedule];
      newAllTimeSchedule.push({ initTime: null, stopTime: null });
      setAllTimeSchedule(newAllTimeSchedule);
    }
    setHasChanged(true);
  }

  function handleChangeAllTimeSchedule(
    index: number,
    timeRange: [Dayjs | null, Dayjs | null]
  ) {
    const newAllTimeSchedule = [...allTimeSchedule];
    newAllTimeSchedule[index] = {
      initTime: timeRange[0],
      stopTime: timeRange[1],
    };
    setAllTimeSchedule(newAllTimeSchedule);
    setTimeScheduleError(false);

    dispatch({
      type: "changedAllTimeSchedule",
      index,
      timeRange,
    });
    setHasChanged(true);
  }

  function handleChangeTimeSchedule(
    dayOfWeek: string,
    index: number,
    timeRange: [Dayjs | null, Dayjs | null]
  ) {
    setTimeScheduleError(false);
    dispatch({
      type: "changedTimeSchedule",
      dayOfWeek,
      index,
      timeRange,
    });
    setHasChanged(true);
  }

  function handleDeleteTimeSchedule(
    index: number,
    dayOfWeek: string,
    allFields?: boolean
  ) {
    dispatch({
      type: allFields ? "deletedAllTimeSchedule" : "deletedTimeSchedule",
      index,
      dayOfWeek,
    });
    if (allFields) {
      const newAllTimeSchedule = [...allTimeSchedule];
      newAllTimeSchedule.splice(index, 1);
      setAllTimeSchedule(newAllTimeSchedule);
    }
    setHasChanged(true);
  }

  function handleChangeTargetDetectionNumber(targetDetectionNumber: string) {
    dispatch({
      type: "changedTargetDetectionNumber",
      payload: targetDetectionNumber,
    });
    setHasChanged(true);
  }

  function handleChangeAlertInterval(value: Dayjs | null) {
    dispatch({
      type: "changedAlertInterval",
      payload: value,
    });
    setHasChanged(true);
  }

  function handleChangeSimilarity(value: number) {
    dispatch({
      type: "changedSimilarity",
      payload: value,
    });
    setHasChanged(true);
  }

  function handleChangeMovementThreshold(value: number) {
    dispatch({
      type: "changedMovementThreshold",
      payload: value,
    });
    setHasChanged(true);
  }

  function handleChangeDispatcher(dispatcherId: number) {
    dispatch({
      type: "changedDispatcher",
      payload: dispatcherId,
    });
    setHasChanged(true);
  }

  // @depracated method
  // function handleChangeMoniSector(payload: {
  //   moniIntegrationId: number;
  //   sectorId: string;
  // }) {
  //   dispatch({
  //     type: "changedMoniSector",
  //     payload,
  //   });
  //   setHasChanged(true);
  // }

  // TODO: needs to reset the analytic object after success
  async function handleUpdateAnalytic() {
    if (
      !canUpdateAnalytic(analytic, {
        checked,
        setRegionError,
        handleDeleteRegion,
        setTimeScheduleError,
        setAlertIntervalError,
      })
    ) {
      return toast({
        variant: "destructive",
        title: "Falha ao ativar analítico",
        description: "Preencha os campos corretamente",
      });
    }
    setIsLoading(true);

    const changedAnalytic = { ...analytic };
    changedAnalytic.region_monitor = changedAnalytic.region_monitor.filter(
      (value) => value.length > 0
    );

    if (hasChanged && analytic.activated) {
      changedAnalytic.activated = false;
    } else if (hasChanged && !analytic.activated) {
      changedAnalytic.activated = true;
    }

    const isActivating = !changedAnalytic.activated && !hasChanged;
    const isDeactivating = changedAnalytic.activated && !hasChanged;

    const response = await updateAnalyticMiddleware({
      analytic: changedAnalytic,
      checkedDaysOfWeek: checked,
    });
    setIsLoading(false);

    if (response) {
      handlePauseStatus(
        !hasChanged && !changedAnalytic.activated
          ? false
          : changedAnalytic.pause
      );
      handleActiveStatus(changedAnalytic.activated ? false : true);
      updateCamera();
      if (isDeactivating) {
        toast({
          description: "Analítico desativado com sucesso!",
        });
      } else if (isActivating) {
        // Analytic now active
        toast({
          description: "Analítico ativado com sucesso!",
        });
      } else {
        toast({
          description: "Alterações salvas com sucesso!",
        });
      }
      setHasChanged(false);
    } else {
      // Communication with backend error
      toast({
        variant: "destructive",
        description: "Um erro inesperado ocorreu",
      });
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <MultipleRegionCanvas
          cameraFrame={cameraFrame}
          regionError={regionError}
          initialValue={initialRegions}
          onAddRegion={handleAddRegion}
          dimensions={canvasDimensions}
          regions={analytic.region_monitor}
          onPolygonDraw={handleDrawPolygon}
          onResetRegions={handleResetRegions}
          onDeleteRegion={handleDeleteRegion}
        />
        <section className={styles.controlsContainer}>
          {analytic.pause && (
            <Alert severity="warning" style={{ marginBottom: "10px" }}>
              Analítico pausado. Resume em: {analytic.expiration_time}
            </Alert>
          )}
          {hasChanged ? (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="secondary"
              style={{ marginBottom: "15px" }}
              onClick={handleUpdateAnalytic}
            >
              Salvar mudanças
            </LoadingButton>
          ) : analytic.activated ? (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="error"
              style={{ marginBottom: "15px" }}
              onClick={handleUpdateAnalytic}
            >
              Desativar
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              style={{ marginBottom: "15px" }}
              onClick={handleUpdateAnalytic}
            >
              Ativar
            </LoadingButton>
          )}

          <AnalyticControls {...analyticControlsState} />
          <AdvancedConfigs
            type={analytic.analytic_type}
            similarity={analytic.similarity}
            handleChangeSimilarity={handleChangeSimilarity}
            movementThreshold={analytic.inactivity?.movement_threshold}
            handleChangeMovementThreshold={handleChangeMovementThreshold}
          />
          {analytic.analytic_type !== "Radar" && (
            <>
              <ExpandableContainer
                title="Notificações"
                icon={<Notifications />}
              >
                {currentDispatcher && (
                  <DispatcherSelector
                    initialValue={currentDispatcher}
                    analyticType={analytic.analytic_type}
                    onSelectDispatcher={handleChangeDispatcher}
                  />
                )}
                <Button
                  variant="outlined"
                  onClick={() => setOpenNewDispatcher(true)}
                >
                  Novo sistema de alerta
                </Button>
              </ExpandableContainer>
              <CreateSimpleDispatcherDialog
                openNewDispatcher={openNewDispatcher}
                analyticType={cameraAnalytic.analytic_type}
                onClose={() => setOpenNewDispatcher(false)}
              />
            </>
          )}
        </section>
      </div>
      {!(isPlatform("mobile") && !isPlatform("mobileweb")) && (
        <AssociateDevices analyticID={analytic.id} />
      )}
    </div>
  );
}

export enum ReceiverType {
  whatsapp = "whatsappGroup",
  telegram = "telegram",
  user = "user",
}
