import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "context/GlobalContext";
import { useNavigate, useLocation } from "react-router-dom";
import { editOperator, getOperator } from "api/operators";
// Components
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// Styles
import styles from "./EditOperator.module.css";
import { isValidEmail, isValidOperator } from "lib/operators";
import MainContainer from "components/MainContainer/MainContainer";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import { useQuery } from "@tanstack/react-query";
import {
  COMPANIES_QUERY_KEY,
  OPERATOR_BY_ID_QUERY_KEY,
} from "constants/apiQueryKeys";
import { listCompanies } from "api/company";
import { HeimdallCompany } from "interfaces/company";

export default function EditOperator() {
  const { toast } = useToast();
  const router = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(GlobalContext);
  const [company, setCompany] = useState<Pick<
    HeimdallCompany,
    "id" | "name"
  > | null>(null);
  const [companyError, setCompanyError] = useState(false);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: companies, isLoading: isLoadingCompanies } = useQuery({
    queryKey: [COMPANIES_QUERY_KEY],
    queryFn: () => listCompanies(),
    enabled: user?.role === "super-admin",
  });
  const { data: operator } = useQuery({
    queryKey: [OPERATOR_BY_ID_QUERY_KEY(router.state.id)],
    queryFn: () => getOperator(router.state.id),
  });

  // Sets the fields with the operator data
  useEffect(() => {
    if (!!operator) {
      if (!!companies) {
        setCompany({ id: operator.company.id, name: operator.company.name });
      }
      setRole(operator.role);
      setName(operator.name);
      setUsername(operator.username);
      setEmail(operator.email);
    }
  }, [companies, operator]);

  function handleFormErrors() {
    if (user?.role === "super-admin" && !company) setCompanyError(true);
    if (!role) setRoleError(true);
    if (!name) setNameError(true);
    if (!username || username.length < 4 || username.length > 15)
      setUsernameError(true);
    if (!email || !isValidEmail(email)) setEmailError(true);
  }

  async function handleEditOperator() {
    handleFormErrors(); // Displaying errors in the form
    if (
      !user ||
      (user.role === "super-admin" && !company) ||
      !isValidOperator(name, username, email, "", "", true)
    )
      return;

    const requestData: any = {
      name,
      username,
      email,
      role,
    };

    if (user.role === "super-admin") {
      requestData.company_id = company!.id;
    }

    setLoading(true);

    await editOperator(router.state.id, requestData)
      .then(() => navigate("/operators"))
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar usuário",
          description: getErrorMessage(err),
        });
      });

    setLoading(false);
  }

  const handleForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleEditOperator();
  };

  return (
    <MainContainer title="Editar usuários" returnAction>
      <p className={styles.subtitle}>
        Edite um usuário. O usuário terá permissões pré-definidas, tais como:
        criação de câmera, visualização, atualização e remoção. Além disso, o
        usuário pode configurar os analíticos de cada câmera.
      </p>
      <form noValidate className={styles.formContainer} onSubmit={handleForm}>
        {user?.role === "super-admin" && (
          <Autocomplete
            disablePortal
            id="companies-autocomplete"
            options={
              companies?.data.sort((a, b) => b.name.localeCompare(a.name)) || []
            }
            size="small"
            value={company}
            loading={isLoadingCompanies}
            getOptionLabel={(option) => option.name}
            onChange={(ev, newValue) => setCompany(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Companhia"
                error={companyError}
                helperText={companyError ? "Campo obrigatório" : ""}
              />
            )}
          />
        )}
        <TextField
          select
          size="small"
          label="Cargo"
          value={role}
          error={roleError}
          onChange={(ev) => setRole(ev.target.value)}
          helperText={roleError ? "Você deve selecionar um cargo" : ""}
        >
          <MenuItem value="">Selecionar</MenuItem>
          <MenuItem value="manager">Administrador</MenuItem>
          <MenuItem value="collaborator">Operador</MenuItem>
        </TextField>
        <TextField
          required
          id="name"
          label="Nome"
          size="small"
          value={name}
          error={nameError}
          helperText={nameError ? "Você deve fornecer um nome" : ""}
          onChange={(ev) => {
            setNameError(false);
            setName(ev.target.value);
          }}
        />
        <TextField
          required
          id="email"
          size="small"
          value={email}
          label="E-mail"
          error={emailError}
          helperText={
            emailError ? "Você deve fornecer um endereço de email válido" : ""
          }
          onChange={(ev) => {
            setEmailError(false);
            setEmail(ev.target.value);
          }}
        />
        <TextField
          required
          size="small"
          id="username"
          value={username}
          label="Usuário"
          error={usernameError}
          helperText={
            usernameError
              ? "Nome de usuário deve ter entre 4 e 15 caracteres"
              : ""
          }
          onChange={(ev) => {
            setUsernameError(false);
            setUsername(ev.target.value);
          }}
        />
        <LoadingButton
          variant="contained"
          type="submit"
          onSubmit={handleForm}
          loading={loading}
        >
          Salvar
        </LoadingButton>
      </form>
    </MainContainer>
  );
}
