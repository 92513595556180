export const API_ROOT = "/api"; // Optional if your API endpoints have a common base URL

export const AVAILABLE_ANALYTICS_QUERY_KEY = `${API_ROOT}/alert_system/analytic_types`;
export const CAMERA_GROUPS_QUERY_KEY = `${API_ROOT}/camera-groups`;
export const CAMERAS_QUERY_KEY = `${API_ROOT}/cameras`;
export const CAMERA_BY_ID_QUERY_KEY = (cameraId: string | number) =>
  `${API_ROOT}/camera/${cameraId}`;
export const CAMERAS_SIMPLIFIED_QUERY_KEY = `${API_ROOT}/dashboard_cameras`;
export const COMPANIES_QUERY_KEY = `${API_ROOT}/company`;
export const COMPANY_BY_ID_QUERY_KEY = (companyId: string) =>
  `${API_ROOT}/companies/${companyId}`;
export const OPERATORS_QUERY_KEY = `${API_ROOT}/user_company`;
export const OPERATOR_BY_ID_QUERY_KEY = (operatorId: string) =>
  `${API_ROOT}/user_company/${operatorId}`;
export const USER_PROFILE_QUERY_KEY = `${API_ROOT}/user_profile`;
export const DISPATCHERS_QUERY_KEY = `${API_ROOT}/api/alert_system`;
