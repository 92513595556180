import { IconButton } from "@mui/material";
import React from "react";
import { LogoutOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import { ConnectedDevice } from "interfaces/generic";
import { PlatformIcon } from "./PlatformIcon";

export function ConnectedDeviceCard({
  device, isCurrentDevice, onRemoveDevice,
}: {
  isCurrentDevice?: boolean;
  device: ConnectedDevice;
  onRemoveDevice: (device: ConnectedDevice) => void;
}) {
  return (
    <div className="p-2 flex items-center justify-between space-x-2 border max-w-md">
      <PlatformIcon devicePlatform={device.device_info.platform} />
      <div className="flex flex-col my-1">
        <h5>
          {device.device_info.model} - {device.device_info.name}{" "}
          {isCurrentDevice ? "(este dispositivo)" : ""}
        </h5>
        <p className="text-sm sm:text-base font-light">
          Última utilização:{" "}
          {dayjs(device.last_used).format("DD-MM-YYYY HH:mm")}
        </p>
      </div>
      <IconButton
        disabled={isCurrentDevice}
        onClick={() => onRemoveDevice(device)}
      >
        <LogoutOutlined />
      </IconButton>
    </div>
  );
}
