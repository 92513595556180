import React from "react";
// import heimdall from 'assets/logo.png'
import { useState } from "react";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { sendNewPasswordRequest } from "api/user";
import { useToast } from "components/ui/use-toast";
import styles from "./LostPassword.module.css";

export default function LostPassword() {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { toast } = useToast();

  const handleSendForm = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const response = await sendNewPasswordRequest(email);

    if (response.status === 200) {
      setSuccess(true);
    } else if (response.response.status === 429) {
      toast({
        variant: "destructive",
        description:
          "Número de requisições excedido, tente novamente mais tarde.",
      });
    } else if (response.response.status === 422) {
      toast({
        variant: "destructive",
        description: "Email não cadastrado no sistema.",
      });
    }

    setIsLoading(false);
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: "url(/images/authBackgroundLight.jpg)",
      }}
    >
      <div className={styles.card}>
        {success ? (
          <>
            <h1 style={{ marginBottom: "15px" }}>Sucesso!</h1>
            <p style={{ marginBottom: "15px" }}>
              Um email de redefinição de senha foi enviado para {email}. Siga as
              instruções presentes no email para redefinir sua senha.
            </p>
            <p style={{ marginBottom: "15px" }}>
              Essa página já pode ser fechada
            </p>
          </>
        ) : (
          <>
            <h1 style={{ marginBottom: "15px" }}>Recuperar senha</h1>
            <p style={{ marginBottom: "15px" }}>
              Informe um usuário ou e-mail válido para receber um link e
              cadastrar uma nova senha
            </p>
            <form className={styles.formContainer} id="lostPasswordForm">
              <TextField
                fullWidth
                id="email"
                name="email"
                value={email}
                label="Usuário ou email"
                onChange={(ev) => setEmail(ev.target.value)}
              />
              <LoadingButton
                fullWidth
                type="submit"
                loading={isLoading}
                variant="contained"
                onClick={handleSendForm}
              >
                Obter nova senha
              </LoadingButton>
              <Link to="/auth">
                <Button fullWidth variant="outlined">
                  Voltar
                </Button>
              </Link>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
