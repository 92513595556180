import React, { useEffect, useState } from "react";
import { getDashboardData } from "api/home";
// Components
import DashboardCard from "../../components/Dashboard/DashboardCard/DashboardCard";
// import GraphControls from "components/Dashboard/GraphControls/GraphControls";
// import StyledPieChart from "../components/StyledPieChart/StyledPieChart";
// Dayjs
// import dayjs from "dayjs";

// import FluxGraph from "components/Dashboard/FluxGraph";
import { DashboardMetrics } from "interfaces/dashboard";
import MainContainer from "components/MainContainer/MainContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useQuery } from "@tanstack/react-query";
import { AVAILABLE_ANALYTICS_QUERY_KEY } from "constants/apiQueryKeys";
import { getAvailableAnalytics } from "api/analytics";
import { App } from "@capacitor/app";
import { useNavigate } from "react-router-dom";
// import { addMobileStateListeners } from "lib/mobileStateListeners";
// import styles from "./Home.module.css";

// interface GraphData {
//   name: string;
//   data: any;
// }

// interface PieChartData {
//   name: string;
//   value: number | string;
// }

export default function Home() {
  // Dashboard info state
  const [radar, setRadar] = useState(0);
  const [panics, setPanics] = useState(0);
  const [animal, setAnimal] = useState(0);
  const [evasion, setEvasion] = useState(0);
  const [cameras, setCameras] = useState(0);
  const [ocurrence, setOcurrence] = useState(0);
  const [intrusion, setIntrusion] = useState(0);
  const [permanence, setPermanence] = useState(0);
  const [inactivity, setInactivity] = useState(0);
  const [offCameras, setOffCameras] = useState(0);
  const [agglomeration, setAgglomeration] = useState(0);
  const [licensePlates, setLicensePlates] = useState(0);
  const [vehicleEvasions, setVehicleEvasions] = useState(0);
  const [vehicleIntrusions, setVehicleIntrusions] = useState(0);
  const [vehicleAgglomerations, setVehicleAgglomerations] = useState(0);
  const { data: availableAnalytics } = useQuery({
    queryKey: [AVAILABLE_ANALYTICS_QUERY_KEY],
    queryFn: () => getAvailableAnalytics(),
  });
  const navigate = useNavigate();
  // STATES FOR AREA CHART DATA
  // const [fInterval, setFInterval] = useState(5);
  // const [room, setRoom] = useState(1);
  // const [data, setData] = useState<any[]>([]);
  // const [series, setSeries] = useState<GraphData[]>([]);
  // STATES FOR PIE CHART DATA
  // const [pieChartData, setPieChartData] = useState<
  //   PieChartData[] | undefined
  // >();
  // const [initDate, setInitDate] = useState(dayjs().subtract(3, "days"));
  // const [endDate, setEndDate] = useState(dayjs());
  // Heat Map state
  // const [open, setOpen] = useState(false);
  // const [urlToModal, setUrlToModal] = useState("");
  // User

  // const graphControlsProps = {
  //   initDate,
  //   endDate,
  //   fInterval,
  //   filter,
  //   room,
  //   generateHeatMap,
  //   setInitDate,
  //   setEndDate,
  //   setFInterval,
  //   setRoom,
  // };

  // Getting initial data for dashboard info and line chart

  useEffect(() => {
    async function getData() {
      const dashboardData = await getDashboardData();

      if (dashboardData && dashboardData.data.length > 0) {
        let permanenceCountSum = 0;
        let radarCountSum = 0;
        let paniCountSum = 0;
        let animalCountSum = 0;
        let evasionCountSum = 0;
        let licensePlatesCountSum = 0;
        let intrusionCountSum = 0;
        let camerasCountSum = 0;
        let inactivityCountSum = 0;
        let offCamerasCountSum = 0;
        let agglomerationCountSum = 0;
        let vehicleEvasionsCountSum = 0;
        let vehicleIntrusionsCountSum = 0;
        let vehicleAgglomerationsCountSum = 0;
        dashboardData.data.forEach((info: DashboardMetrics) => {
          radarCountSum += info.radars_count;
          paniCountSum += info.panics_count;
          animalCountSum += info.animals_count;
          evasionCountSum += info.evasions_count;
          licensePlatesCountSum += info.lprs_count;
          intrusionCountSum += info.intrusions_count;
          permanenceCountSum += info.permanences_count;
          camerasCountSum += info.cameras_active_count;
          inactivityCountSum += info.inactivities_count;
          offCamerasCountSum += info.cameras_inactive_count;
          agglomerationCountSum += info.agglomerations_count;
          vehicleEvasionsCountSum += info.vehicle_evasions_count;
          vehicleIntrusionsCountSum += info.vehicle_intrusions_count;
          vehicleAgglomerationsCountSum += info.vehicle_agglomerations_count;
        });
        setRadar(radarCountSum);
        setPanics(paniCountSum);
        setAnimal(animalCountSum);
        setEvasion(evasionCountSum);
        setCameras(camerasCountSum);
        setPermanence(permanenceCountSum);
        setLicensePlates(licensePlatesCountSum);
        setIntrusion(intrusionCountSum);
        setInactivity(inactivityCountSum);
        setOffCameras(offCamerasCountSum);
        setAgglomeration(agglomerationCountSum);
        setVehicleEvasions(vehicleEvasionsCountSum);
        setVehicleIntrusions(vehicleIntrusionsCountSum);
        setVehicleAgglomerations(vehicleAgglomerationsCountSum);
      }

      // const graphData = await getGraphData(
      //   fInterval,
      //   initDate.format("YYYY-MM-DD+HH:mm"),
      //   endDate.format("YYYY-MM-DD+HH:mm"),
      // );

      // if (graphData) setData(graphData);
    }
    // addMobileStateListeners();

    getData();
  }, []);

  useEffect(() => {
    console.log("adding listener for appUrlOpen");
    // Listen for deep link events
    App.addListener("appUrlOpen", (data) => {
      console.log("App opened with URL:", data);
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = data.url.split("//").pop();
      if (slug) {
        console.log("redirectig to " + slug);
        navigate("/" + slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    // return () => {
    //   App.removeAllListeners();
    // };
  }, []);

  // Setting data for graph
  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     const newState: GraphData[] = [];
  //     const keys = Object.keys(data[0]);

  //     keys.forEach((key) => {
  //       if (key === "datetime") return;
  //       const transformedData = data.map((info) => {
  //         return [dayjs(info.datetime).unix() * 1000, info[key]];
  //       });
  //       newState.push({ name: key, data: transformedData });
  //     });

  //     setSeries(newState);
  //   }
  // }, [data]);

  // Setting occurrence metric
  useEffect(() => {
    setOcurrence(
      radar +
        animal +
        panics +
        evasion +
        intrusion +
        agglomeration +
        vehicleEvasions +
        vehicleIntrusions +
        vehicleAgglomerations
    );
  }, [
    intrusion,
    evasion,
    agglomeration,
    animal,
    vehicleIntrusions,
    vehicleAgglomerations,
    vehicleEvasions,
    panics,
    radar,
  ]);

  // SETTING DATA FOR PIE CHART
  // useEffect(() => {
  //   setPieChartData([
  //     { name: "Intrusao", value: intrusion },
  //     { name: "Aglomeracao", value: agglomeration },
  //     { name: "Evasao", value: evasion },
  //   ]);
  // }, [intrusion, agglomeration, evasion]);

  // --------DO NOT DELETE--------
  // function generateHeatMap() {
  //   const heatMap = `http://192.168.88.201:5150/api/heatmap?cam_id=${room}&start=${initDate.format(
  //     "YYYY-MM-DD+HH:mm"
  //   )}&end=${endDate.format("YYYY-MM-DD+HH:mm")}`;

  //   setUrlToModal(heatMap);
  //   setOpen(true);
  // }

  // --------DO NOT DELETE--------
  // async function filter() {
  //   if (initDate.isValid() && endDate.isValid()) {
  //     const graphData = await getGraphData(
  //       fInterval,
  //       initDate.format("YYYY-MM-DD+HH:mm"),
  //       endDate.format("YYYY-MM-DD+HH:mm")
  //     );
  //     setData(graphData);
  //   }
  // }

  return (
    <MainContainer title="Métricas">
      <Grid2
        container
        rowSpacing={{ xs: 1, md: 2 }}
        columnSpacing={{ xs: 1, md: 2 }}
      >
        <Grid2 xs={12}>
          <DashboardCard title="Total" value={ocurrence} />
        </Grid2>
        {availableAnalytics?.includes("Aglomeração") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Aglomeração" value={agglomeration} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Evasão") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Evasão" value={evasion} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Intrusão") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Intrusão" value={intrusion} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Permanência") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Permanência" value={permanence} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Inatividade") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Inatividade" value={inactivity} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Congestionamento") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard
              title="Congestionamento"
              value={vehicleAgglomerations}
            />
          </Grid2>
        )}
        {availableAnalytics?.includes("Detecção de Veículo") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard
              title="Intrusão de veículos"
              value={vehicleIntrusions}
            />
          </Grid2>
        )}
        {availableAnalytics?.includes("Ausência de Veículo") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard
              title="Ausência de veículos"
              value={vehicleEvasions}
            />
          </Grid2>
        )}
        {availableAnalytics?.includes("Lpr") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Placas reconhecidas" value={licensePlates} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Radar") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Infrações de radar" value={radar} />
          </Grid2>
        )}
        {availableAnalytics?.includes("Detecção de Animal") && (
          <Grid2 xs={12} sm={4} md={3}>
            <DashboardCard title="Detecção de animais" value={animal} />
          </Grid2>
        )}
      </Grid2>
      <h2 className="my-3 text-xl">Câmeras</h2>
      <Grid2
        container
        spacing={2}
        rowSpacing={{ xs: 1, md: 2 }}
        columnSpacing={{ xs: 1, md: 2 }}
      >
        <Grid2 xs={12} sm={6}>
          <DashboardCard title="Câmeras ativas" value={cameras} />
        </Grid2>
        <Grid2 xs={12} sm={6}>
          <DashboardCard title="Câmeras inativas" value={offCameras} />
        </Grid2>
      </Grid2>
      {/* <div className={styles.graphsContainer}>
        // <GraphControls {...graphControlsProps} /> 
        <div
          className={`${styles.graphCard} primary-container on-primary-container-text`}
        >
          <h2 className={styles.graphTitle} style={{ textAlign: "center" }}>
            Fluxo de pessoas
          </h2>
          <FluxGraph series={series} theme={theme.palette.mode} />
        </div>
        // {pieChartData ? <StyledPieChart pieChartData={pieChartData} /> : null} 
      </div> */}
    </MainContainer>
  );
}
