import { Skeleton } from "@mui/material";
import React from "react";

export default function CanvasSkeleton({
  show,
  dimensions,
}: {
  show: boolean;
  dimensions: { width: number; height: number };
}) {
  return (
    <div
      style={{
        display: show ? "flex" : "none",
        flexDirection: "column",
      }}
    >
      <Skeleton
        variant="rectangular"
        width={dimensions.width}
        height={dimensions.height}
      />
      <div style={{ display: "flex" }}>
        <Skeleton width={dimensions.width / 3 - 8} height={75} />
        <Skeleton
          width={dimensions.width / 3 - 8}
          height={75}
          style={{ marginInline: "12px" }}
        />
        <Skeleton width={dimensions.width / 3 - 8} height={75} />
      </div>
    </div>
  );
}
