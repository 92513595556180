import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  getOccurrenceMarkColor,
  getTranslation,
} from "lib/occurrenceMarksTranslation";
import { OccurrenceMark } from "interfaces/occurrences";
import { updateAnalyticLog } from "api/logs";
import { AnalyticLog } from "interfaces/analytics";
import { MonitoringMessage } from "interfaces/monitoring";
import AllRegionCanvas from "components/AddCamera/ConfigureAnalytic/DrawCanvas/AllRegionCanvas";
import styles from "./ExpandImageModal.module.css";
import { baseUrl } from "api/rootConfig";
import { useToast } from "components/ui/use-toast";
import { LoadingButton } from "@mui/lab";

interface EditGenericLogModalProps {
  title?: string;
  location?: string;
  log: AnalyticLog | MonitoringMessage;
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const levels: OccurrenceMark[] = [
  "visualized",
  "false_positive",
  "resident",
  "effective",
  "accidents",
  "vandalism",
];

export default function EditGenericLogModal({
  title,
  location,
  open,
  onClose,
  log,
  onSubmit,
}: EditGenericLogModalProps) {
  const { toast } = useToast();
  // Canvas state
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 640,
    height: 360,
  });
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  // Modal data state
  const [descriptionAnalytics, setDescriptionAnalytics] = useState("");
  const [level, setLevel] = useState<OccurrenceMark>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onRefChange = useCallback((node: HTMLCanvasElement) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
      setCtx(null);
    } else {
      // DOM node referenced by ref has changed and exists
      setCtx(node.getContext("2d"));
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      const newSize = getWindowSize();
      if (newSize.innerWidth < 800) {
        setCanvasDimensions({
          width: newSize.innerWidth - 40,
          height: (newSize.innerWidth - 40) * 0.6,
        });
      }
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (log.check_description) setDescriptionAnalytics(log.check_description);
    else setDescriptionAnalytics("");

    if (log.check_status) setLevel(log.check_status);
    else setLevel(undefined);

    function handleBoundingBox() {
      if (!log.bbox_lst || typeof log.bbox_lst === "string" || !ctx) return;
      // ctx.strokeStyle = "#ba1a1a";
      ctx.strokeStyle = "#fcba03";
      ctx.lineWidth = 2;
      log.bbox_lst.forEach((bbox: number[]) => {
        const initialPointNormalized = [bbox[0], bbox[1]];
        const [widthNormalized, heightNormalized] = [bbox[2], bbox[3]];
        const initialPoint = [
          initialPointNormalized[0] * canvasDimensions.width,
          initialPointNormalized[1] * canvasDimensions.height,
        ];
        const [width, height] = [
          widthNormalized * canvasDimensions.width,
          heightNormalized * canvasDimensions.height,
        ];

        // Starting point
        ctx.moveTo(initialPoint[0], initialPoint[1]);
        // Coordinates for the lines
        ctx.lineTo(initialPoint[0] + width, initialPoint[1]);
        ctx.lineTo(initialPoint[0] + width, initialPoint[1] + height);
        ctx.lineTo(initialPoint[0], initialPoint[1] + height);
        ctx.lineTo(initialPoint[0], initialPoint[1]);
      });

      // Drawing line
      ctx?.stroke();
    }

    if (open) handleBoundingBox();
  }, [open, log, ctx, canvasDimensions]);

  async function handleAnalyticLog() {
    if (!level || !log.id) return;

    const requestData: {
      check_status: OccurrenceMark;
      check_description: string;
    } = {
      check_status: level,
      check_description: descriptionAnalytics,
    };

    const response = await updateAnalyticLog(
      log.occurrence,
      requestData,
      log.id
    );
    if (response) {
      setIsSubmitting(true);
      await onSubmit()
        .then(() => {
          toast({
            description: "Ocorrência tratada com sucesso",
          });
        })
        .catch(() => {
          toast({
            variant: "destructive",
            description: "Erro ao tratar ocorrência",
          });
        });
      setIsSubmitting(false);
    } else {
      toast({
        variant: "destructive",
        description: "Erro ao tratar ocorrência",
      });
      onClose();
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className={styles.modal}>
        <h3 style={{ marginBottom: "2px" }}>{title}</h3>
        <h3 style={{ marginBottom: "10px" }}>{location}</h3>
        <div
          className={styles.imageContainerOccurence}
          style={{ position: "relative" }}
        >
          <AllRegionCanvas
            show
            regions={log.region_dic}
            dimensions={canvasDimensions}
            isInactivityTEMP={log.occurrence === "Inatividade"}
          />
          <canvas
            id="bboxCanvas"
            ref={onRefChange}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{
              position: "absolute",
              zIndex: "2",
            }}
          ></canvas>
          <img
            src={baseUrl + log.url}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{
              width: `${canvasDimensions.width}px`,
              height: `${canvasDimensions.height}px`,
            }}
            alt="Ocorrência"
          />
        </div>
        <Typography sx={{ marginTop: "10px" }}>
          Selecione o nível da ocorrência
        </Typography>
        <form className={styles.form}>
          <div className="flex flex-wrap justify-around mt-3 space-x-3">
            {levels.map((currentLevel, index) => (
              <div key={index} className="flex flex-col items-center">
                <IconButton
                  key={currentLevel}
                  onClick={() => setLevel(currentLevel)}
                >
                  <WarningRoundedIcon
                    fontSize="large"
                    sx={{
                      fill:
                        currentLevel === level
                          ? getOccurrenceMarkColor(currentLevel)
                          : "grey",
                    }}
                  />
                </IconButton>
                {getTranslation(currentLevel)}
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              label="Descrição"
              fullWidth
              multiline
              rows={log.occurrence === "Radar" ? 2 : 4}
              style={{ marginTop: "10px" }}
              id="outlined-multiline-static"
              value={descriptionAnalytics}
              onChange={(text) => {
                setDescriptionAnalytics(text.target.value);
              }}
            />
          </div>
        </form>
        <div className={styles.modalActions}>
          <Button variant="text" color="error" onClick={onClose}>
            Cancelar
          </Button>
          <LoadingButton
            variant="text"
            loading={isSubmitting}
            onClick={handleAnalyticLog}
          >
            Salvar
          </LoadingButton>
        </div>
      </Paper>
    </Modal>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
