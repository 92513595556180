import { PaletteOptions, ThemeOptions, createTheme } from "@mui/material";
import { ptBR as pickersPtBR } from "@mui/x-date-pickers/locales";
import { ptBR as gridPtBR } from "@mui/x-data-grid/locales";
import { ptBR as corePtBR } from "@mui/material/locale";
import { defaultHeimdallMuiOverrides } from "lib/themeDefaults";

declare module "@mui/material/styles" {
  interface Palette {
    onPrimary: Palette["primary"];
  }

  interface PaletteOptions {
    onPrimary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    onPrimary: true;
  }
}

/**
 * Auxiliary method that already uses default mui overrides for heimdall. You only need to provide the palette.
 *
 */
export function createHeimdallMuiTheme(
  palette: ThemeOptions["palette"],
  rootContainer?: HTMLDivElement
) {
  return createTheme(
    {
      ...defaultHeimdallMuiOverrides(rootContainer),
      palette,
    },
    corePtBR, // core translations
    pickersPtBR, // x-date-pickers translations
    gridPtBR // x-data-grid translations
  );
}

const heimdallAuthMuiPalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#161616",
  },
  secondary: {
    main: "#c7c7c7",
  },
};

export const authMuiTheme = createHeimdallMuiTheme(heimdallAuthMuiPalette);
