import dayjs from "dayjs";
import { Analytic, AnalyticForm } from "interfaces/analytics";
import { AnalyticType } from "interfaces/analytics";
import {
  HeimdallCamera,
  ScheduleTime,
  ScheduleTimeForm,
} from "interfaces/heimdallCamera";

function getEmptyTimeSchedule(): ScheduleTimeForm {
  return {
    monday: [{ initTime: null, stopTime: null }],
    tuesday: [{ initTime: null, stopTime: null }],
    wednesday: [{ initTime: null, stopTime: null }],
    thursday: [{ initTime: null, stopTime: null }],
    friday: [{ initTime: null, stopTime: null }],
    saturday: [{ initTime: null, stopTime: null }],
    sunday: [{ initTime: null, stopTime: null }],
  };
}

export function getMatchingAnalytic(
  camera: HeimdallCamera,
  analyticType: AnalyticType
) {
  for (let index = 0; index < camera.analytics.length; index++) {
    const analytic = camera.analytics[index];
    if (analytic.analytic_type === analyticType) {
      return analytic;
    }
  }
  throw new Error("Erro na obtenção de analítico");
}

export function convertToCamelCase(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertToCamelCase(item));
  }

  const camelCaseObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
        letter.toUpperCase()
      );
      camelCaseObj[camelCaseKey] = convertToCamelCase(obj[key]);
    }
  }

  return camelCaseObj;
}

export function camelToSnake(obj: any): any {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelToSnake);
  }

  const snakeObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeKey = key.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`
      );
      snakeObj[snakeKey] = camelToSnake(obj[key]);
    }
  }

  return snakeObj;
}

export function convertAnalyticToAnalyticForm(
  analytic: Analytic,
  integrationParams?: HeimdallCamera["integration_params"]
): AnalyticForm {
  // console.log("Converting analytic");
  const analyticForm: any = { ...analytic };

  // ALERTS INTERVAL
  const newTimeInterval = dayjs(analyticForm.service_sleep_duration, "mm:ss");
  if (newTimeInterval.isValid()) {
    analyticForm.service_sleep_duration = newTimeInterval;
  } else throw new Error("Intervalo entre alertas invalido");

  // SCHEDULE TIME
  if (
    analyticForm.schedule_time &&
    typeof analyticForm.schedule_time === "string"
  ) {
    const scheduleTime: ScheduleTime = JSON.parse(analyticForm.schedule_time);
    const scheduleTimeForm: any = {};
    Object.entries(scheduleTime).forEach(([dayOfWeek, timeRanges]) => {
      const dayjsTimeRanges = timeRanges.map((timeRange) => ({
        initTime: timeRange.init_time
          ? dayjs(timeRange.init_time, "HH:mm")
          : null,
        stopTime: timeRange.stop_time
          ? dayjs(timeRange.stop_time, "HH:mm")
          : null,
      }));
      scheduleTimeForm[dayOfWeek] = dayjsTimeRanges;
    });
    analyticForm.schedule_time = scheduleTimeForm;
  } else if (analyticForm.schedule_time === null) {
    analyticForm.schedule_time = getEmptyTimeSchedule();
  }

  // Transforming analytic's initial field values to default values
  if (analyticForm.evasion?.target_detection_number === -1) {
    analyticForm.evasion.target_detection_number = 1;
  }
  if (analyticForm.agglomeration?.target_detection_number === -1) {
    analyticForm.agglomeration.target_detection_number = 2;
  }
  if (analyticForm.vehicle_agglomeration?.target_detection_number === -1) {
    analyticForm.vehicle_agglomeration.target_detection_number = 2;
  }
  if (analyticForm.inactivity) {
    analyticForm.inactivity.movement_threshold *= 10;
  }

  // Removing empty regions
  for (let index = 1; index < analyticForm.region_monitor.length; index++) {
    const region = analyticForm.region_monitor[index];
    if (region.length === 0) {
      analyticForm.region_monitor.splice(index, 1);
    }
  }
  analyticForm.integration_params = integrationParams
    ? integrationParams
    : null;

  // Starting with one empty region, if there is none
  if (analyticForm.region_monitor.length === 0) {
    analyticForm.region_monitor.push([]);
  }

  return analyticForm as AnalyticForm;
}
