import React, { ReactNode, useMemo, useState } from "react";
import GlobalContext from "./GlobalContext";
import { logout as signOut } from "api/auth";
import { cleanUp } from "lib/logoutUtils";
import { HeimdallUser } from "interfaces/generic";
import { isPlatform } from "@ionic/react";
import { unregisterNotifications } from "lib/pushNotifications";

export let externalSetUser: (user: null) => void;

function GlobalContextProvider({ children }: { children?: ReactNode }) {
  const [user, setUser] = useState<HeimdallUser | null>(null);
  const [baseURL] = useState(localStorage.getItem("baseURL") || "");
  const logout = useMemo(() => {
    externalSetUser = setUser;
    return async () => {
      await signOut();
      if (isPlatform("mobile") && !isPlatform("mobileweb")) {
        await unregisterNotifications();
      }
      cleanUp();
      setUser(null);
    };
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        user,
        setUser,
        baseURL,
        logout,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
