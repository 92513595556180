import { NewDispatcher } from "interfaces/newAlertSystem";

const DISPATCHER_REDUCER_ACTION_TYPE = {
  SET_NAME: "SET_NAME",
  ADD_RECEIVER: "ADD_RECEIVER",
  REMOVE_RECEIVER: "REMOVE_RECEIVER",
  SET_ANALYTIC_TYPE: "SET_ANALYTIC_TYPE",
  SET_ANALYTIC_TRIGGER_TIME: "SET_ANALYTIC_TRIGGER_TIME",
} as const;

export type DispatcherReducerActionType =
  keyof typeof DISPATCHER_REDUCER_ACTION_TYPE;

export function dispatcherReducer(
  dispatcher: NewDispatcher,
  action: {
    type: DispatcherReducerActionType;
    payload: any;
  }
): NewDispatcher {
  switch (action.type) {
    case "SET_NAME": {
      return {
        ...dispatcher,
        name: action.payload,
      };
    }
    case "ADD_RECEIVER": {
      const triggers = [...dispatcher.triggers];
      const receivers = [...triggers[action.payload.triggerIndex].receivers];
      receivers.push(action.payload.receiver);
      triggers[action.payload.triggerIndex].receivers = receivers;
      return {
        ...dispatcher,
        triggers,
      };
    }
    case "REMOVE_RECEIVER": {
      const triggers = [...dispatcher.triggers];
      const receivers = [...triggers[action.payload.triggerIndex].receivers];
      receivers.splice(action.payload.receiverIndex);
      triggers[action.payload.triggerIndex].receivers = receivers;
      return {
        ...dispatcher,
        triggers,
      };
    }
    case "SET_ANALYTIC_TYPE": {
      return { ...dispatcher, analyticType: action.payload };
    }
    case "SET_ANALYTIC_TRIGGER_TIME": {
      const triggers = [...dispatcher.triggers];
      triggers[action.payload.triggerIndex].analyticTriggerTime =
        action.payload.triggerTime;
      return { ...dispatcher, triggers };
    }

    default:
      throw new Error("Dispatcher action type not defined");
  }
}
