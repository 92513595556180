import { AnalyticType } from "interfaces/analytics";
// Card images
import agglomeration from "assets/images/analyticAgglomeration2.webp";
import intrusion from "assets/images/analyticIntrusion2.webp";
import animals from "assets/images/analyticAnimal2.webp";
import evasion from "assets/images/analyticEvasion2.webp";
import inactivity from "assets/images/analyticInactivity2.webp";
import permanence from "assets/images/analyticPermanence2.webp";
import radar from "assets/images/analyticRadar2.webp";
import vehicleDetection from "assets/images/analyticVehicleDetection2.webp";
import vehicleCongestion from "assets/images/analyticCongestion2.webp";
import vehicleEvasion from "assets/images/analyticVehicleEvasion2.webp";
import lpr from "assets/images/analiticoLpr2.webp";

export function getAnalyticCardTitle(analyticType: AnalyticType) {
  switch (analyticType) {
    case "Aglomeração":
    case "Inatividade":
    case "Detecção de Veículo":
    case "Ausência de Veículo":
    case "Congestionamento":
    case "Evasão":
    case "Intrusão":
    case "Radar":
    case "Pânico":
    case "Permanência":
      return analyticType;
    case "Lpr":
      return "LPR";
    case "Detecção de Animal":
      return "Animais";
    default:
      return "";
  }
}

export function getAnalyticCardDescription(analyticType: AnalyticType) {
  switch (analyticType) {
    case "Aglomeração":
      return "Analítico que permite detectar a aglomeração de pessoa(s) em zona restrita, conforme a configuração realizada no sistema.";
    case "Detecção de Animal":
      return "Analítico que permite detectar animais em zona restrita, conforme a configuração realizada no sistema.";
    case "Detecção de Veículo":
      return "Analítico que permite detectar veículos em zona restrita, conforme a configuração realizada no sistema.";
    case "Evasão":
      return "Analítico que permite detectar a evasão de pessoa(s) em zona restrita, conforme a configuração realizada no sistema.";
    case "Intrusão":
      return "Analítico que permite detectar a intrusão de pessoa(s) em zona restrita, conforme a configuração realizada no sistema.";
    case "Radar":
      return "Analítico que permite detectar a passagem de veículos em alta velocidade, conforme a configuração realizada no sistema.";
    case "Congestionamento":
      return "Analítico que permite detectar o congestionamento de veículos, conforme a configuração realizada no sistema.";
    case "Ausência de Veículo":
      return "Analítico que permite monitorar se o veículo está no local adequado, conforme configuração do sistema.";
    case "Inatividade":
      return "Analítico que permite detectar a ausência de movimento em determinada região ao longo de um período.";
    case "Lpr":
      return "Analítico que permite detectar a placa de um veículo em determinada região.";
    case "Permanência":
      return "Analítico que permite detectar a permanência de pessoa(s) em zona restrita, conforme a configuração realizada no sistema.";
    default:
      return;
  }
}

export function getAnalyticCardImage(analyticType: AnalyticType) {
  switch (analyticType) {
    case "Aglomeração":
      return agglomeration;
    case "Detecção de Animal":
      return animals;
    case "Detecção de Veículo":
      return vehicleDetection;
    case "Evasão":
      return evasion;
    case "Intrusão":
      return intrusion;
    case "Inatividade":
      return inactivity;
    case "Radar":
      return radar;
    case "Congestionamento":
      return vehicleCongestion;
    case "Ausência de Veículo":
      return vehicleEvasion;
    case "Lpr":
      return lpr;
    case "Permanência":
      return permanence;
    default:
      return;
  }
}
