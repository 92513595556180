import React, { useMemo } from "react";
import { baseUrl } from "api/rootConfig";
import { IconButton } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { getOccurrenceMarkColor } from "lib/occurrenceMarksTranslation";
import { getResizedImagePath } from "lib/resizedImage";

export function LogCard({
  row,
  breakpoints,
  setOpenEdit,
  setOpenExpanded,
  setSelectedOccurrence,
}: LogCardProps) {
  const resizedImagePath = useMemo(() => {
    if (breakpoints?.isSmallest) {
      return baseUrl + getResizedImagePath(row.url, 350);
    } else if (breakpoints?.isXxs) {
      return baseUrl + getResizedImagePath(row.url, 425);
    }
    return baseUrl + getResizedImagePath(row.url, 600);
  }, [row.url, breakpoints]);

  function handleEditLog() {
    // @ts-expect-error
    setSelectedOccurrence(row);
    setOpenEdit(true);
  }
  function handleExpandImage() {
    // @ts-expect-error
    setSelectedOccurrence(row);
    setOpenExpanded(true);
  }

  return (
    <div className="flex">
      <div className="relative w-full">
        <img
          className="max-h-52 min-w-72 w-full mb-2 rounded-lg object-cover hover:cursor-pointer"
          alt={row.occurrence}
          src={resizedImagePath}
          onClick={handleExpandImage}
        />
        <div className="absolute flex justify-between items-center w-full bottom-2 left-0 px-2 pb-1 bg-black bg-opacity-30 rounded-b-lg">
          <LogInfo row={row} />
          <IconButton size="small" onClick={handleEditLog}>
            <EditOutlined
              fontSize="small"
              sx={{ fill: "white", opacity: "0.7" }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

interface LogCardProps {
  row: CombinedLog;
  breakpoints?: { isXxs?: boolean; isSmallest?: boolean };
  setOpenEdit: (value: boolean) => void;
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
}

function LogInfo({ row }: { row: CombinedLog }) {
  return (
    <div className="flex items-center z-10">
      <div
        className="w-2 h-2 mr-2 rounded-full bg-white bg-opacity-75"
        style={{ backgroundColor: getOccurrenceMarkColor(row.check_status) }}
      ></div>
      <div className="flex flex-col">
        <p className="text-sm font-bold text-white opacity-75">
          {row.occurrence}
        </p>
        <p className="text-xs text-white opacity-75">{row.created_at}</p>
      </div>
    </div>
  );
}
