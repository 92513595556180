import React, { useEffect } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { AnalyticControlsProps } from "interfaces/configureAnalytic";
// Components
import AlertInterval from "./AlertInterval";
import { TimeSchedule } from "./TimeSchedule";
import { AllTimeSchedule } from "./AllTimeSchedule";
import { TargetDetectionNumber } from "./TargetDetectionNumber";
import ExpandableContainer from "components/ExpandableContainer";
import { Alert } from "@mui/material";
import { Tune, Group } from "@mui/icons-material";
import { CarMultiple } from "components/icons/thirdPartyIcons";

dayjs.extend(duration);

export default function AnalyticControls({
  checked,
  analytic,
  setChecked,
  indeterminate,
  allTimeSchedule,
  alertIntervalError,
  setIndeterminate,
  setAlertIntervalError,
  handleChangeAlertInterval,
  handleAddTimeSchedule,
  handleChangeDispatcher,
  handleChangeTargetDetectionNumber,
  handleDeleteTimeSchedule,
  handleChangeAllTimeSchedule,
  handleChangeTimeSchedule,
  timeScheduleError,
  setTimeScheduleError,
}: AnalyticControlsProps) {
  // Handling indeterminate status of checkbox
  useEffect(() => {
    if (
      checked.slice(1, checked.length).some((value) => value === true) &&
      checked.slice(1, checked.length).some((value) => value === false)
    )
      setIndeterminate(true);
    else setIndeterminate(false);
  }, [checked, setIndeterminate]);

  function handleSetChecked(
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) {
    if (id === 0) {
      const newCheckedState = checked.map((value, index) => {
        return Boolean(event.target.checked);
      });
      setChecked(newCheckedState);
    } else {
      const prevState = [...checked];
      prevState[id] = Boolean(event.target.checked);

      setChecked(prevState);
    }
  }

  return (
    <ExpandableContainer
      insideOverflow
      icon={<Tune />}
      error={timeScheduleError}
      title="Configuração do analítico"
    >
      {timeScheduleError && (
        <Alert severity="error">
          Você deve fornecer ao menos um intervalo de horário para ativar o
          analitico.
        </Alert>
      )}
      <AllTimeSchedule
        checked={checked}
        value={allTimeSchedule}
        indeterminate={indeterminate}
        handleSetChecked={handleSetChecked}
        onAddTimeSchedule={handleAddTimeSchedule}
        onDeleteTimeSchedule={handleDeleteTimeSchedule}
        onChangeTimeSchedule={handleChangeAllTimeSchedule}
      />
      <TimeSchedule
        checked={checked}
        timeSchedule={analytic.schedule_time}
        handleSetChecked={handleSetChecked}
        setTimeScheduleError={setTimeScheduleError}
        handleAddTimeSchedule={handleAddTimeSchedule}
        handleDeleteTimeSchedule={handleDeleteTimeSchedule}
        handleChangeTimeSchedule={handleChangeTimeSchedule}
      />
      {analytic.analytic_type === "Evasão" && (
        <TargetDetectionNumber
          label="Número mínimo de pessoas"
          icon={<Group />}
          value={analytic.evasion!.target_detection_number.toString()}
          onChange={handleChangeTargetDetectionNumber}
        />
      )}
      {analytic.analytic_type === "Aglomeração" && (
        <TargetDetectionNumber
          label="Número máximo de pessoas"
          icon={<Group />}
          value={analytic.agglomeration!.target_detection_number.toString()}
          onChange={handleChangeTargetDetectionNumber}
        />
      )}
      {analytic.analytic_type === "Congestionamento" && (
        <TargetDetectionNumber
          icon={<CarMultiple />}
          label="Número máximo de veículos"
          value={analytic.vehicle_agglomeration!.target_detection_number.toString()}
          onChange={handleChangeTargetDetectionNumber}
        />
      )}
      <AlertInterval
        error={alertIntervalError}
        value={analytic.service_sleep_duration}
        onChange={(value) => {
          handleChangeAlertInterval(value);
          setAlertIntervalError(false);
        }}
      />
    </ExpandableContainer>
  );
}
