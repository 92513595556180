import React from "react";
import { DispatcherListData } from "../interfaces/alertSystem";
import dayjs from "dayjs";
import { GridColDef } from "@mui/x-data-grid";
import { getHHmmssFromSeconds } from "./configureAnalytic";
import { IconButton } from "@mui/material";
import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { createDispatcherMiddleware, convertDispatcherListDataToDispatcher } from "./alertSystem";


export function getDispatcherColumns({
  handleDelete, handleEdit, refreshList,
}: {
  handleDelete: (dispatcher: DispatcherListData) => void;
  handleEdit: (id: number) => void;
  refreshList: () => void;
}) {
  const dispatcherColumns: GridColDef<DispatcherListData>[] = [
    {
      field: "name",
      headerName: "Nome",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "alerts",
      headerName: "N° gatilhos",
      valueFormatter(value) {
        return (value as string).length;
      },
    },
    {
      field: "max_cycle_duration",
      headerName: "Duração do fluxo",
      minWidth: 150,
      flex: 1,
      valueFormatter(value) {
        const myDuration = getHHmmssFromSeconds(value);
        return dayjs
          .duration(myDuration)
          .format("H [hora(s)], m [minuto(s)] e s [segundo(s)].");
      },
    },
    {
      field: "actions",
      width: 140,
      headerName: "Ações",
      renderCell(params) {
        if (params.row.name.includes("default"))
          return null;
        return (
          <>
            <IconButton onClick={() => handleEdit(params.row.alert_system_id)}>
              <Edit />
            </IconButton>
            <IconButton
              onClick={async () => {
                await createDispatcherMiddleware(
                  convertDispatcherListDataToDispatcher({
                    ...params.row,
                    name: `${params.row.name} (cópia)`,
                  })
                );
                refreshList();
              }}
            >
              <ContentCopy />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDelete(params.row);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return dispatcherColumns;
}
