export function getAwesomeName(routePath: string) {
  switch (routePath) {
    case "operators":
      return "Usuários";
    case "cameras":
      return "Câmeras";
    case "camera-groups":
      return "Grupos de câmeras";
    case "log":
    case "log/:logType":
      return "Ocorrências";
    case "/log/agglomeration":
      return "Aglomeração";
    case "/log/evasion":
      return "Evasão";
    case "/log/intrusion":
      return "Intrusão";
    case "/log/vehicle-agglomeration":
      return "Congestionamento";
    case "/log/vehicle-evasion":
      return "Ausência de veículo";
    case "/log/vehicle-intrusion":
      return "Detecção de veículo";
    case "/log/animal":
      return "Animal";
    case "dispatchers":
      return "Sistema de alerta";
    case "vms":
      return "Integração VMS";
    case "moni":
      return "Integração MONI";
    case "/log/inactivity":
      return "Inatividade";
    case "/log/panic":
      return "Pânico";
    case "/log/radar":
    case "log-radar":
      return "Radar";
    case "/log/lpr":
    case "log-lpr":
      return "LPR";
    case "/log/permanence":
    case "log-permanence":
      return "Permanência";
    case "forense":
      return "Busca forense";
    case "lpr":
      return "LPR";
    case "io-devices":
      return "Dispositivos I/O";
    case "panic":
      return "Dispositivos pânico";
    case "themes":
      return "Temas";
    case "companies":
      return "Companhias";
    default:
      return "Métricas";
  }
}
