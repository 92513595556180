import { PanicDevice } from "interfaces/panicDevice";
import axiosRoot from "./rootConfig";
import { BackendResponsePagination } from "interfaces/generic";

export async function getPanicDevices() {
  return await axiosRoot
    .get<BackendResponsePagination<PanicDevice>>("/api/face_terminal", {
      params: { page: "1" },
      headers: { paginate: 1000 },
    })
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

export async function createPanicDevice(deviceData: {
  name: string;
  ip: string;
  user: string;
  password: string;
  manufacturer: string;
  model: string;
  remarks: string;
}) {
  return await axiosRoot.post("/api/face_terminal", deviceData, {
    timeout: 15000,
  });
}

export async function getPanicDevice(id: number) {
  return await axiosRoot
    .get(`/api/face_terminal/${id}`)
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

export async function editPanicDevice(
  id: number,
  deviceData: {
    name: string;
    ip: string;
    user: string;
    password: string;
    manufacturer: string;
    model: string;
    remarks: string;
  }
) {
  return await axiosRoot.put(`/api/face_terminal/${id}`, deviceData, {
    timeout: 20000,
  });
}

export async function deletePanicDevice(id: number) {
  return await axiosRoot.delete(`/api/face_terminal/${id}`);
}
