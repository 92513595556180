import React, { useMemo } from "react";
import { Search } from "@mui/icons-material";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { getGroups } from "api/cameraGroup";
import { getSimplifiedCameras } from "api/cameras";
import MultiSelectColors from "components/MultiSelectChip/MultiSelectColors";
import { Controller, UseFormReturn } from "react-hook-form";
import { LprFormSchema } from "./Lpr";
import { CamerasAndGroups } from "interfaces/occurrences";
import {
  CAMERAS_SIMPLIFIED_QUERY_KEY,
  CAMERA_GROUPS_QUERY_KEY,
} from "constants/apiQueryKeys";

export function LprForm({
  form,
  onSearch,
  onClear,
}: {
  form: UseFormReturn<LprFormSchema>;
  onSearch: (data: LprFormSchema) => void;
  onClear: () => void;
}) {
  const { data: cameras, isLoading: isLoadingCameras } = useQuery({
    queryKey: [CAMERAS_SIMPLIFIED_QUERY_KEY],
    queryFn: () => getSimplifiedCameras(),
  });
  const { data: groups, isLoading: isLoadingGroups } = useQuery({
    queryKey: [CAMERA_GROUPS_QUERY_KEY],
    queryFn: () => getGroups(),
  });
  const isLoading = isLoadingCameras || isLoadingGroups;
  const camsAndGroups = useMemo(() => {
    const newCamerasAndGroups: CamerasAndGroups[] = [];
    if (!!cameras) {
      cameras.data.forEach((_camera) =>
        newCamerasAndGroups.push({ ..._camera, category: "Câmeras" })
      );
    }
    if (!!groups) {
      groups.data.forEach((_group) =>
        newCamerasAndGroups.push({ ..._group, category: "Grupos" })
      );
    }
    return newCamerasAndGroups;
  }, [cameras, groups]);

  return (
    <form className="mt-1" onSubmit={form.handleSubmit(onSearch)}>
      <Grid2 container spacing={1}>
        <Grid2 xs={12}>
          <TextField
            size="small"
            fullWidth
            label="Placa"
            placeholder="ABC1D23"
            inputProps={{ ...form.register("plate") }}
          />
        </Grid2>
        <Grid2 md={6} xs={12}>
          <TextField
            size="small"
            select
            fullWidth
            label="Categoria"
            defaultValue="all"
            inputProps={{ ...form.register("platePermissions") }}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="authorized">Permitidas</MenuItem>
            <MenuItem value="unauthorized">Não permitidas</MenuItem>
          </TextField>
        </Grid2>
        <Grid2 md={6} xs={12}>
          <MultiSelectColors size="small" fullWidth label="Cores" form={form} />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <Controller
            control={form.control}
            name="cameras"
            render={({ field }) => (
              <Autocomplete
                disablePortal
                id="cams-and-groups-autocomplete"
                options={camsAndGroups.sort((a, b) =>
                  b.category.localeCompare(a.category)
                )}
                size="small"
                loading={isLoading}
                // value={filterFormData.cameras}
                value={field.value}
                // onChange={(ev, newValue) => handleCamsAndGroupsFilter(newValue)}
                onChange={(ev, newValue) => field.onChange(newValue)}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="camerasIds"
                    label="Câmeras e Grupos"
                  />
                )}
              />
            )}
          />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <Controller
            control={form.control}
            name="timestampBefore"
            render={({ field }) => (
              <DateTimePicker
                label="Antes de"
                value={field.value}
                inputRef={field.ref}
                onChange={(value) => field.onChange(value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <Controller
            control={form.control}
            name="timestampAfter"
            render={({ field }) => (
              <DateTimePicker
                label="Depois de"
                value={field.value}
                inputRef={field.ref}
                onChange={(value) => field.onChange(value)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Button
            type="button"
            variant="outlined"
            color="error"
            onClick={onClear}
          >
            Limpar campos
          </Button>
          <Button
            type="submit"
            variant="contained"
            startIcon={<Search />}
            sx={{
              marginLeft: "10px",
            }}
          >
            Buscar
          </Button>
        </Grid2>
      </Grid2>
    </form>
  );
}
