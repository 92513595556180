import React, { CSSProperties } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme } from "@mui/material";
import { translateColor } from "lib/lprColorsTranslation";
import { Controller, UseFormReturn } from "react-hook-form";
import { LprFormSchema } from "pages/lpr/Lpr";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const colorList = [
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Orange",
  "Purple",
  "Silver",
  "Gold",
  "Pink",
  "Brown",
  "Gray",
  "Black",
  "White",
  "Magenta",
];

function getStyles(name: string, personName: string, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultiSelectColorsProps {
  size?: "small" | "medium";
  style?: CSSProperties;
  label?: string;
  fullWidth?: boolean;
  onChange?: (selectedColors: string[]) => void;
  form: UseFormReturn<LprFormSchema>;
}

export default function MultiSelectColors({
  fullWidth,
  size,
  label,
  style,
  onChange,
  form,
}: MultiSelectColorsProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    onChange?.(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Controller
      control={form.control}
      name="colors"
      render={({ field }) => (
        <FormControl sx={style} size={size} fullWidth={fullWidth}>
          <InputLabel>{label}</InputLabel>
          <Select
            multiple
            value={field.value}
            onChange={handleChange}
            input={<OutlinedInput label="Cores" />}
            MenuProps={MenuProps}
            inputProps={{
              ...field,
            }}
          >
            <MenuItem value="all">Todas</MenuItem>
            {colorList.map((colorName) => (
              <MenuItem key={colorName} value={colorName}>
                {translateColor(colorName)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
