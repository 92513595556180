import React from "react";
import { getAwesomeName } from "lib/sidebar";
import { Notes } from "@mui/icons-material";
import {
  FormControl,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { LogIcon } from "lib/LogIcon";
import {
  allLogs,
  logPathFromAnalyticType,
  SpecificLogUrlPath,
} from "interfaces/occurrences";
import { isPlatform } from "@ionic/react";
import { useQuery } from "@tanstack/react-query";
import { AVAILABLE_ANALYTICS_QUERY_KEY } from "constants/apiQueryKeys";
import { getAvailableAnalytics } from "api/analytics";

export default function SelectLogType({
  logType,
  onSelectLogType,
}: {
  logType: SpecificLogUrlPath;
  onSelectLogType: (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  const { data: availableAnalytics } = useQuery({
    queryKey: [AVAILABLE_ANALYTICS_QUERY_KEY],
    queryFn: () => getAvailableAnalytics(),
  });

  if (isPlatform("mobile"))
    return (
      <FormControl size="small">
        <NativeSelect
          name="cameraStatus"
          input={<OutlinedInput />}
          value={`/log/${logType}`}
          onChange={onSelectLogType as any}
          inputProps={{
            name: "cameraStatus",
          }}
        >
          <option value="/log/all">Todas ocorrências</option>
          {allLogs.map((log) => (
            <option key={log} value={"/log/" + logPathFromAnalyticType(log)}>
              {getAwesomeName("/log/" + logPathFromAnalyticType(log))}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    );

  return (
    <TextField
      select
      value={`/log/${logType}`}
      onChange={onSelectLogType}
      size="small"
      sx={{
        "& .MuiInputBase-input": {
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <MenuItem value="/log/all">
        <Notes fontSize="small" sx={{ fill: "var(--root-color-secondary)" }} />
        <span className="ml-2">Todas ocorrências</span>
      </MenuItem>
      {allLogs.map(
        (log) =>
          availableAnalytics?.includes(log) && (
            <MenuItem key={log} value={"/log/" + logPathFromAnalyticType(log)}>
              <LogIcon
                fontSize="small"
                logType={"/log/" + logPathFromAnalyticType(log)}
              />
              <span className="ml-2 text-base">
                {getAwesomeName("/log/" + logPathFromAnalyticType(log))}
              </span>
            </MenuItem>
          )
      )}
    </TextField>
  );
}
