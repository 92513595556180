// Layouts
import MainLayout from "components/Layouts/MainLayout";
import AuthLayout from "components/Layouts/AuthLayout";
// Routes
import Lpr from "pages/lpr/Lpr";
import Help from "pages/help/Help";
import Auth from "pages/auth/Auth";
import Home from "pages/home/Home";
import Panic from "pages/panic/Panic";
import LogRadar from "pages/logs/LogRadar";
import Forense from "pages/forense/Forense";
import Profile from "pages/profile/Profile";
import Feedback from "pages/feedback/Feedback";
import NotFound from "pages/not-found/NotFound";
import SpecificLog from "pages/logs/SpecificLog";
import Operators from "pages/operators/Operators";
import IODevices from "pages/io-devices/IODevices";
import CreatePanic from "pages/panic/create-panic/CreatePanic";
import LostPassword from "pages/auth/LostPassword/LostPassword";
import MapCheckpoint from "components/Checkpoint/MapCheckpoint";
import ResetPassword from "pages/auth/ResetPassword/ResetPassword";
import EditOperator from "pages/operators/EditOperator/EditOperator";
import NewOperator from "pages/operators/CreateOperator/NewOperator";
import CreateDevice from "pages/io-devices/CreateDevice/CreateDevice";
import CamerasMap from "pages/cameras/map/CamerasMap";
import Dispatchers from "pages/alert-system/Dispatchers";
import CreateDispatcher from "pages/alert-system/create-dispatcher/CreateDispatcher";
import ConfirmEmail from "pages/auth/confirm-email/ConfirmEmail";
import LprPermissions from "pages/lpr/LprBlacklist/LprBlacklist";
// Route loaders
import protectedRouteLoader from "loaders/protectedRouteLoader";
import redirectToAppLoader from "loaders/redirectToAppLoader";
import React from "react";
import { RouteObject } from "react-router-dom";
import ConfirmUpdateEmail from "pages/auth/confirm-update-email/ConfirmUpdateEmail";
import { HEIMDALL_ROUTE } from "interfaces/webRoutes";
import { HeimdallPlatformRoutes } from "interfaces/heimdallRoutes";
import ConfigureAnalyticsPage from "pages/cameras/configure-analytic/ConfigureAnalytics";
import ConfigureAnalyticPage from "pages/cameras/configure-analytic/[analytic-type]/ConfigureAnalyticPage";
import CameraDetailsPage from "pages/cameras/[id]/CameraDetails";

const authRoutes: RouteObject[] = [
  {
    index: true,
    element: <Auth />,
  },
  {
    path: HEIMDALL_ROUTE.LOST_PASSWORD,
    element: <LostPassword />,
  },
  {
    path: HEIMDALL_ROUTE.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: HEIMDALL_ROUTE.CONFIRM_EMAIL,
    element: <ConfirmEmail />,
  },
  {
    path: HEIMDALL_ROUTE.CONFIRM_UPDATE_EMAIL,
    element: <ConfirmUpdateEmail />,
  },
];

const mainRoutes: RouteObject[] = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: HEIMDALL_ROUTE.COMPANIES,
    children: [
      {
        index: true,
        async lazy() {
          let { Companies } = await import("../pages/companies/Companies");
          return {
            element: <Companies />,
          };
        },
      },
      {
        path: HEIMDALL_ROUTE.CREATE_COMPANY,
        async lazy() {
          let { CreateCompany } = await import(
            "../pages/companies/create-company/CreateCompany"
          );
          return {
            element: <CreateCompany />,
          };
        },
      },
      {
        path: HEIMDALL_ROUTE.COMPANY_PLAN,
        async lazy() {
          let { CompanyPlan } = await import(
            "../pages/companies/[id]/plan/CompanyPlan"
          );
          return {
            element: <CompanyPlan />,
          };
        },
      },
    ],
  },
  {
    // TODO: Rename operators to users
    path: HEIMDALL_ROUTE.OPERATORS,
    children: [
      {
        index: true,
        element: <Operators />,
      },
      {
        path: HEIMDALL_ROUTE.CREATE_OPERATOR,
        element: <NewOperator />,
      },
      {
        path: HEIMDALL_ROUTE.EDIT_OPERATOR,
        element: <EditOperator />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.CAMERAS,
    children: [
      {
        index: true,
        async lazy() {
          let { Cameras } = await import("../pages/cameras/Cameras");
          return { element: <Cameras /> };
        },
      },
      {
        path: HEIMDALL_ROUTE.CREATE_CAMERA,
        async lazy() {
          let { AddCamera } = await import(
            "../pages/cameras/AddCamera/AddCamera"
          );
          return { element: <AddCamera /> };
        },
      },
      {
        path: HEIMDALL_ROUTE.CAMERA_DETAILS,
        element: <CameraDetailsPage />,
      },
      {
        path: HEIMDALL_ROUTE.CONFIGURE_ANALYTICS,
        children: [
          {
            index: true,
            element: <ConfigureAnalyticsPage />,
          },
          {
            path: HEIMDALL_ROUTE.CONFIGURE_ANALYTIC,
            element: <ConfigureAnalyticPage />,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.CAMERAS_MAP,
        element: <CamerasMap />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.CAMERA_GROUPS,
    async lazy() {
      let { CameraGroups } = await import(
        "../pages/camera-groups/CameraGroups"
      );
      return { element: <CameraGroups /> };
    },
  },
  {
    path: HEIMDALL_ROUTE.SPECIFIC_LOG,
    element: <SpecificLog />,
  },
  {
    path: HEIMDALL_ROUTE.RADAR_LOG,
    element: <LogRadar />,
  },
  {
    path: HEIMDALL_ROUTE.FORENSE,
    children: [
      {
        index: true,
        element: <Forense />,
      },
      {
        path: HEIMDALL_ROUTE.FORENSE_MAP,
        element: <MapCheckpoint />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.LPR,
    children: [
      {
        index: true,
        element: <Lpr />,
      },
      {
        path: HEIMDALL_ROUTE.LPR_BLACKLIST,
        element: <LprPermissions />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.IO_DEVICES,
    children: [
      {
        index: true,
        element: <IODevices />,
      },
      {
        path: HEIMDALL_ROUTE.CREATE_IO_DEVICE,
        element: <CreateDevice />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.PANIC_DEVICES,
    children: [
      {
        index: true,
        element: <Panic />,
      },
      {
        path: HEIMDALL_ROUTE.CREATE_PANIC_DEVICE,
        element: <CreatePanic />,
      },
    ],
  },
  { path: HEIMDALL_ROUTE.PROFILE, element: <Profile /> },
  { path: HEIMDALL_ROUTE.HELP, element: <Help /> },
  { path: HEIMDALL_ROUTE.FEEDBACK, element: <Feedback /> },
  {
    path: HEIMDALL_ROUTE.DISPATCHERS,
    children: [
      {
        index: true,
        element: <Dispatchers />,
      },
      {
        path: HEIMDALL_ROUTE.CREATE_DISPATCHER,
        element: <CreateDispatcher />,
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.VMS,
    children: [
      {
        index: true,
        async lazy() {
          let { Vms } = await import("../pages/vms/Vms");
          return {
            element: <Vms />,
          };
        },
      },
      {
        path: HEIMDALL_ROUTE.CREATE_VMS,
        async lazy() {
          let { CreateVms } = await import("../pages/vms/create-vms/CreateVms");
          return {
            element: <CreateVms />,
          };
        },
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.MONI,
    children: [
      {
        index: true,
        async lazy() {
          let { Moni } = await import("../pages/moni/moni");
          return {
            element: <Moni />,
          };
        },
      },
      {
        path: HEIMDALL_ROUTE.CREATE_MONI,
        async lazy() {
          let { CreateMoni } = await import(
            "../pages/moni/create-moni/create-moni"
          );
          return {
            element: <CreateMoni />,
          };
        },
      },
    ],
  },
  {
    path: HEIMDALL_ROUTE.THEMES,
    children: [
      {
        index: true,
        async lazy() {
          let { Themes } = await import("../pages/themes/Themes");
          return {
            element: <Themes />,
          };
        },
      },
      {
        path: HEIMDALL_ROUTE.EDIT_THEME,
        async lazy() {
          let { EditTheme } = await import(
            "../pages/themes/edit-theme/EditTheme"
          );
          return {
            element: <EditTheme />,
          };
        },
      },
    ],
  },
];

const monitoringRoutes: RouteObject[] = [
  {
    index: true,
    async lazy() {
      let { MonitorSetup } = await import("../pages/monitor/MonitorSetup");
      return { element: <MonitorSetup /> };
    },
  },
  {
    path: HEIMDALL_ROUTE.DEFAULT_MONITOR,
    async lazy() {
      let { DefaultMonitor } = await import(
        "../pages/monitor/Default/DefaultMonitor"
      );
      return { element: <DefaultMonitor /> };
    },
  },
  {
    path: HEIMDALL_ROUTE.EVENTS_MONITOR,
    async lazy() {
      let { EventsMonitor } = await import(
        "../pages/monitor/Events/EventsMonitor"
      );
      return { element: <EventsMonitor /> };
    },
  },
];

export const webRoutes: RouteObject[] = [
  {
    path: HEIMDALL_ROUTE.AUTH,
    element: <AuthLayout />,
    loader: redirectToAppLoader,
    children: authRoutes,
  },
  {
    path: HEIMDALL_ROUTE.HOME,
    element: <MainLayout />,
    loader: protectedRouteLoader,
    children: mainRoutes,
  },
  {
    path: HEIMDALL_ROUTE.MONITORING,
    async lazy() {
      let { MonitorLayout } = await import(
        "../components/Layouts/MonitorLayout"
      );
      return { element: <MonitorLayout /> };
    },
    loader: protectedRouteLoader,
    children: monitoringRoutes,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const heimdallPlatformRoutes: HeimdallPlatformRoutes = {
  mobile: {
    authRoutes: [
      {
        path: HEIMDALL_ROUTE.AUTH,
        index: true,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.LOST_PASSWORD,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.RESET_PASSWORD,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.CONFIRM_EMAIL,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.CONFIRM_UPDATE_EMAIL,
        index: false,
        enabled: true,
      },
    ],
    mainRoutes: [
      {
        path: HEIMDALL_ROUTE.HOME,
        index: true,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.COMPANIES,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_COMPANY,
            index: false,
            enabled: false,
          },
          { path: HEIMDALL_ROUTE.COMPANY_PLAN, index: false, enabled: false },
        ],
      },
      {
        path: HEIMDALL_ROUTE.OPERATORS,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_OPERATOR,
            index: false,
            enabled: false,
          },
          {
            path: HEIMDALL_ROUTE.EDIT_OPERATOR,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.CAMERAS,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_CAMERA,
            index: false,
            enabled: true,
          },
          {
            path: HEIMDALL_ROUTE.CAMERAS_MAP,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.CAMERA_GROUPS,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.SPECIFIC_LOG,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.RADAR_LOG,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.FORENSE,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.FORENSE_MAP,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.LPR,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.LPR_BLACKLIST,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.IO_DEVICES,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_IO_DEVICE,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.PANIC_DEVICES,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_PANIC_DEVICE,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.PROFILE,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.HELP,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.FEEDBACK,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.DISPATCHERS,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_DISPATCHER,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.VMS,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_VMS,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.MONI,
        index: false,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_MONI,
            index: false,
            enabled: false,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.THEMES,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.EDIT_THEME,
            index: false,
            enabled: true,
          },
        ],
      },
    ],
    monitoringRotues: [
      {
        path: HEIMDALL_ROUTE.MONITORING,
        index: true,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.DEFAULT_MONITOR,
            index: false,
            enabled: false,
          },
          {
            path: HEIMDALL_ROUTE.EVENTS_MONITOR,
            index: false,
            enabled: false,
          },
        ],
      },
    ],
  },
  web: {
    authRoutes: [
      {
        path: HEIMDALL_ROUTE.AUTH,
        index: true,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.LOST_PASSWORD,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.RESET_PASSWORD,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.CONFIRM_EMAIL,
        index: false,
        enabled: false,
      },
      {
        path: HEIMDALL_ROUTE.CONFIRM_UPDATE_EMAIL,
        index: false,
        enabled: false,
      },
    ],
    mainRoutes: [
      {
        path: HEIMDALL_ROUTE.HOME,
        index: true,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.COMPANIES,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_COMPANY,
            index: false,
            enabled: true,
          },
          { path: HEIMDALL_ROUTE.COMPANY_PLAN, index: false, enabled: true },
        ],
      },
      {
        path: HEIMDALL_ROUTE.OPERATORS,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_OPERATOR,
            index: false,
            enabled: true,
          },
          {
            path: HEIMDALL_ROUTE.EDIT_OPERATOR,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.CAMERAS,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_CAMERA,
            index: false,
            enabled: true,
          },
          {
            path: HEIMDALL_ROUTE.CAMERAS_MAP,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.CAMERA_GROUPS,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.SPECIFIC_LOG,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.RADAR_LOG,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.FORENSE,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.FORENSE_MAP,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.LPR,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.LPR_BLACKLIST,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.IO_DEVICES,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_IO_DEVICE,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.PANIC_DEVICES,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_PANIC_DEVICE,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.PROFILE,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.HELP,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.FEEDBACK,
        index: false,
        enabled: true,
      },
      {
        path: HEIMDALL_ROUTE.DISPATCHERS,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_DISPATCHER,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.VMS,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_VMS,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.MONI,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.CREATE_MONI,
            index: false,
            enabled: true,
          },
        ],
      },
      {
        path: HEIMDALL_ROUTE.THEMES,
        index: false,
        enabled: true,
        children: [
          {
            path: HEIMDALL_ROUTE.EDIT_THEME,
            index: false,
            enabled: true,
          },
        ],
      },
    ],
    monitoringRotues: [
      {
        path: HEIMDALL_ROUTE.MONITORING,
        index: true,
        enabled: false,
        children: [
          {
            path: HEIMDALL_ROUTE.DEFAULT_MONITOR,
            index: false,
            enabled: false,
          },
          {
            path: HEIMDALL_ROUTE.EVENTS_MONITOR,
            index: false,
            enabled: false,
          },
        ],
      },
    ],
  },
};
