import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { getCameras } from "api/cameras";
import InfoWindowContent from "./InfoWindowContent";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import MainContainer from "components/MainContainer/MainContainer";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

export default function CamerasMap() {
  const { toast } = useToast();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY!,
  });
  // const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const currentWindow = useRef<google.maps.InfoWindow | null>(null);

  const onLoad = React.useCallback(async function callback(
    map: google.maps.Map
  ) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);

    await getCameras()
      .then((res) => {
        const myCameras = res.data;
        const myCameraMarkers: CameraMarker[] = [];

        myCameras.forEach((camera, index) => {
          if (!camera.camera_address.coordinate) return;
          const reactElement = <InfoWindowContent camera={camera} />;

          // Render the React component into a virtual DOM
          const reactOutput = React.createElement(
            reactElement.type,
            reactElement.props
          );

          // Create a container element
          const container = document.createElement("div");

          // Render the virtual DOM into the container
          ReactDOM.render(reactOutput, container);

          // Use the container's content as content for the InfoWindow
          const infoWindowContent = container;

          const myCameraMarker: CameraMarker = {
            name: camera.name,
            address: camera.camera_address.address!,
            position: {
              lat: parseInt(camera.camera_address.coordinate.latitude),
              lng: parseInt(camera.camera_address.coordinate.longitude),
            },
            infoWindow: new google.maps.InfoWindow({
              ariaLabel: camera.name,
              content: infoWindowContent,
            }),
          };

          myCameraMarkers.push(myCameraMarker);
        });

        setMarkers(map, myCameraMarkers, currentWindow);
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao obter câmeras",
          description: getErrorMessage(err),
        });
      });
  }, [toast]);

  // const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
  //   setMap(null);
  // }, []);

  return (
    <MainContainer title="Mapa de câmeras" returnAction>
      <div className="w-full h-[calc(100vh-var(--header-height)-120px)]">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={Campinas}
            zoom={12}
            onLoad={onLoad}
            // onUnmount={onUnmount}
          ></GoogleMap>
        )}
      </div>
    </MainContainer>
  );
}

interface CameraMarker {
  name: string;
  address: string;
  position: {
    lat: number;
    lng: number;
  };
  infoWindow: google.maps.InfoWindow;
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Campinas = {
  lat: -22.907104,
  lng: -47.06324,
};

function setMarkers(
  map: google.maps.Map,
  markers: CameraMarker[],
  currentWindow: React.MutableRefObject<google.maps.InfoWindow | null>
) {
  const image: google.maps.Icon = {
    url: "/images/mapsMarker.png",
    scaledSize: new google.maps.Size(48, 48),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(0, 5),
  };
  // Shapes define the clickable region of the icon. The type defines an HTML
  // <area> element 'poly' which traces out a polygon as a series of X,Y points.
  // The final coordinate closes the poly by connecting to the first coordinate.
  const shape = {
    coords: [20, 20, 20],
    type: "circle",
  };

  markers.forEach((marker) => {
    const myMarker = new google.maps.Marker({
      position: marker.position,
      map,
      shape: shape,
      title: marker.name,
      zIndex: 2,
      icon: image,
    });

    myMarker.addListener("click", () => {
      if (currentWindow.current) {
        currentWindow.current.close();
      }
      marker.infoWindow.open({
        anchor: myMarker,
        map,
      });
      currentWindow.current = marker.infoWindow;
    });
  });
}
