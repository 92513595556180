import React, { useMemo } from "react";
import { Dayjs } from "dayjs";
// import RefinarBusca from "./RefinarBusca";
// Icons
import Juninho from "@mui/icons-material/Face";
import GloriaMaria from "@mui/icons-material/Face2";
import DanielaLima from "@mui/icons-material/Face3";
import OtoseSan from "@mui/icons-material/Face4";
import Saitama from "@mui/icons-material/Face5";
import HelmetMan from "@mui/icons-material/Face6";
// import SearchIcon from "@mui/icons-material/Search";
import styles from "./SearchControls.module.css";
import { Button } from "@mui/material";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import { ForsenseFaceWithCheck } from "pages/forense/Forense";
import TimeRangePicker from "components/TimeRangePicker/TimeRangePicker";

function getFaceIcon(randInt: number) {
  switch (randInt) {
    case 0:
      return <Juninho style={{ width: "300px", height: "250px" }} />;
    case 1:
      return <GloriaMaria style={{ width: "280px", height: "220px" }} />;
    case 2:
      return <DanielaLima sx={{ fontSize: 225, marginBottom: "15px" }} />;
    case 3:
      return <OtoseSan style={{ width: "300px", height: "250px" }} />;
    case 4:
      return <Saitama style={{ width: "300px", height: "250px" }} />;
    case 5:
      return <HelmetMan style={{ width: "300px", height: "250px" }} />;
    default:
      return null;
  }
}

interface SearchControlsProps {
  selectedImage: File | null;
  setSelectedImage: (files: File | null) => void;
  selectedSlide: string;
  functionDate: (Dayjs | null)[];
  setFunctionDate: (newDates: (Dayjs | null)[]) => void;
  functionTime: (Dayjs | null)[];
  setFunctionTime: (newTimes: (Dayjs | null)[]) => void;
  data: ForsenseFaceWithCheck[];
  faceArrayIDs: number[];
  setFaceArrayIDs: (newFaceIds: number[]) => void;
  faceArrayURLs: string[];
  setFaceArrayURLs: (newFaceUrls: string[]) => void;
  handleChangeSimilarity: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  sendSearch: (refinar?: boolean) => Promise<void>;
  onSelectFace: (idPhoto: number, urlPhoto: string) => void;
}

export default function SearchControls({
  selectedImage,
  setSelectedImage,
  selectedSlide,
  functionDate,
  setFunctionDate,
  functionTime,
  setFunctionTime,
  data,
  faceArrayIDs,
  setFaceArrayIDs,
  faceArrayURLs,
  setFaceArrayURLs,
  handleChangeSimilarity,
  sendSearch,
  onSelectFace,
}: SearchControlsProps) {
  const randInt = useMemo(() => Math.floor(Math.random() * 6), []);

  return (
    <div className={styles.mainSearch}>
      <div className={styles.containerDivider}>
        <div className={styles.divider}>
          <div className={styles.imageControls}>
            {selectedImage ? (
              <>
                <div className={styles.imageBox}>
                  <img
                    alt="face buscada"
                    className={styles.imgStyles}
                    src={URL.createObjectURL(selectedImage)}
                  />
                </div>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  fullWidth
                  onClick={() => setSelectedImage(null)}
                >
                  Remover imagem
                </Button>
              </>
            ) : (
              <>
                <div className={styles.imageBox}>{getFaceIcon(randInt)}</div>
                <Button
                  variant="outlined"
                  component="label"
                  size="small"
                  fullWidth
                >
                  Selecionar imagem
                  <input
                    type="file"
                    hidden
                    onChange={(event) => {
                      // console.log(event.target.files[0]);
                      setSelectedImage(
                        event.target.files ? event.target.files[0] : null
                      );
                    }}
                  />
                </Button>
              </>
            )}
          </div>
          <div className={styles.configs}>
            <label className={styles.labelText}>Similaridade</label>
            <div className={styles.sliderInput}>
              <input
                type="range"
                min={70}
                max={100}
                className={styles.slider}
                value={selectedSlide}
                onChange={handleChangeSimilarity}
              />
              <span className={styles.sliderValue}>{selectedSlide}%</span>
            </div>
            <label className={styles.labelText}>Data</label>
            <DateRangePicker
              defaultValue={{
                startDate: functionDate[0],
                endDate: functionDate[1],
              }}
              onChange={(value) => {
                if (value) {
                  setFunctionDate([value?.startDate, value?.endDate]);
                } else {
                  setFunctionDate([null, null]);
                }
              }}
            />
            {/* <DatePicker.RangePicker
              onChange={(date, strDate) => {
                setFunctionDate([date[0], date[1]]);
              }}
              defaultValue={[functionDate[0], functionDate[1]]}
              locale={locale}
              order={false}
            /> */}
            <label className={styles.labelText}>Intervalo de horário</label>
            <TimeRangePicker
              defaultValue={{
                startTime: functionTime[0],
                endTime: functionTime[1],
              }}
              onChange={(value) => {
                if (value) {
                  setFunctionTime([value?.startTime, value?.endTime]);
                } else {
                  setFunctionTime([null, null]);
                }
              }}
            />
            {/* <TimePicker.RangePicker
              format="HH:mm"
              defaultValue={[functionTime[0], functionTime[1]]}
              onChange={(time, strTime) => {
                setFunctionTime([time[0], time[1]]);
              }}
              order={false}
            /> */}
          </div>
          <Button
            variant="contained"
            size="small"
            style={{ marginBlock: "10px" }}
            onClick={() => {
              sendSearch();
              // if (faceArrayIDs.length > 0) sendSearch(true);
              // else sendSearch();
            }}
          >
            Iniciar busca
            {/* {faceArrayIDs.length > 0 ? "Refinar busca" : "Iniciar busca"} */}
          </Button>
        </div>
        {/* {faceArrayIDs.length === 0 ? null : (
          <RefinarBusca
            faceArrayIDs={faceArrayIDs}
            faceArrayURLs={faceArrayURLs}
            setFaceURLs={setFaceArrayURLs}
            setFaceIDs={setFaceArrayIDs}
            onSelectFace={onSelectFace}
          />
        )} */}
      </div>
    </div>
  );
}
