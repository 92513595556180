import React, { useContext, useEffect, useState } from "react";
// Components
import { Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { AnalyticType } from "interfaces/analytics";
import { useNavigate } from "react-router-dom";
import { HeimdallCamera } from "interfaces/heimdallCamera";
import AnalyticCard from "./AnalyticCard/AnalyticCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Analytic } from "interfaces/analytics";
import GlobalContext from "context/GlobalContext";

interface SelectAnalyticProps {
  setAnalyticType: (analyticType: AnalyticType) => void;
  onActivateAnalytic: () => Promise<void>;
  camera?: HeimdallCamera;
}

export default function SelectAnalytic({
  camera,
  setAnalyticType,
  onActivateAnalytic,
}: SelectAnalyticProps) {
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [peopleAnalytics, setPeopleAnalytics] = useState<Analytic[]>([]);
  const [vehicleAnalytics, setVehicleAnalytics] = useState<Analytic[]>([]);
  const [animalAnalytic, setAnimalAnalytic] = useState<Analytic>();

  function handleNextStep(type: AnalyticType) {
    setAnalyticType(type);
  }

  function handleFinish() {
    setLoading(true);
    navigate("/cameras");
    setLoading(false);
  }

  useEffect(() => {
    if (camera?.analytics) {
      const newPeopleAnalytics: Analytic[] = [];
      const newVehicleAnalytics: Analytic[] = [];
      if (user?.company.plan.permissions.analytic.intrusion.active)
        newPeopleAnalytics.push(camera.analytics[0]); // intrusion
      if (user?.company.plan.permissions.analytic.agglomeration.active)
        newPeopleAnalytics.push(camera.analytics[1]); // agglomeration
      if (user?.company.plan.permissions.analytic.evasion.active)
        newPeopleAnalytics.push(camera.analytics[2]); // evasion
      if (user?.company.plan.permissions.analytic.inactivity.active)
        newPeopleAnalytics.push(camera.analytics[5]); // inactivity
      if (user?.company.plan.permissions.analytic.permanence.active)
        newPeopleAnalytics.push(camera.analytics[10]); // permanence
      if (user?.company.plan.permissions.analytic.vehicle_intrusion.active)
        newVehicleAnalytics.push(camera.analytics[7]); // vehicle intrusion
      if (user?.company.plan.permissions.analytic.vehicle_agglomeration.active)
        newVehicleAnalytics.push(camera.analytics[8]); // vehicle agglomeration
      if (user?.company.plan.permissions.analytic.vehicle_evasion.active)
        newVehicleAnalytics.push(camera.analytics[9]); // vehicle evasion
      if (user?.company.plan.permissions.analytic.lpr.active)
        newVehicleAnalytics.push(camera.analytics[4]); // lpr
      if (user?.company.plan.permissions.analytic.radar.active)
        newVehicleAnalytics.push(camera.analytics[3]); // radar
      setPeopleAnalytics(newPeopleAnalytics);
      setVehicleAnalytics(newVehicleAnalytics);
      if (user?.company.plan.permissions.analytic.animal_intrusion.active)
        setAnimalAnalytic(camera.analytics[6]); // animal
    }
  }, [user, camera?.analytics]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <h2 className="text-xl">Analíticos de pessoas</h2>
        </Grid2>
        {peopleAnalytics.map((analytic, index) => (
          <Grid2 key={index} xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <AnalyticCard
              analytic={analytic}
              onActivateAnalytic={onActivateAnalytic}
              onSelectAnalytic={() => handleNextStep(analytic.analytic_type)}
            />
          </Grid2>
        ))}
        <Grid2 xs={12}>
          <h2 className="text-xl">Analíticos de veículos</h2>
        </Grid2>
        {vehicleAnalytics.map((analytic, index) => (
          <Grid2 key={index} xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <AnalyticCard
              analytic={analytic}
              onActivateAnalytic={onActivateAnalytic}
              onSelectAnalytic={() => handleNextStep(analytic.analytic_type)}
            />
          </Grid2>
        ))}
        <Grid2 xs={12}>
          <h2 className="text-xl">Analíticos de animais</h2>
        </Grid2>
        {animalAnalytic && (
          <Grid2 xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <AnalyticCard
              analytic={animalAnalytic}
              onActivateAnalytic={onActivateAnalytic}
              onSelectAnalytic={() =>
                handleNextStep(animalAnalytic.analytic_type)
              }
            />
          </Grid2>
        )}
      </Grid2>
      <Alert severity="info" sx={{ marginBlock: "10px", width: "100%" }}>
        Finalize o processo de seleção de analítico. Você poderá voltar a
        qualquer momento para editar suas configurações.
      </Alert>
      <LoadingButton
        variant="contained"
        loading={loading}
        fullWidth
        onClick={handleFinish}
      >
        Finalizar
      </LoadingButton>
    </>
  );
}
