import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { DispatcherListData } from "interfaces/alertSystem";
import { getDispatchers } from "api/alertSystem";
import styles from "./AnalyticControls.module.css";
import { AnalyticType } from "interfaces/analytics";
import { useQuery } from "@tanstack/react-query";
import { DISPATCHERS_QUERY_KEY } from "constants/apiQueryKeys";

async function fetchDispatchersAndFilter(analyticType: AnalyticType) {
  return await getDispatchers({
    page: 1,
    pageSize: 10000,
  }).then((res) => {
    if (res) {
      return res.data.filter(
        (_dispatcher) =>
          !_dispatcher.analytic_type ||
          _dispatcher.analytic_type === analyticType
      );
    }
    throw new Error("Error fetching dispatchers");
  });
}

export default function DispatcherSelector({
  initialValue,
  analyticType,
  onSelectDispatcher,
}: {
  initialValue: DispatcherListData;
  analyticType: AnalyticType;
  onSelectDispatcher: (value: number) => void;
}) {
  const { data: dispatchers, isLoading } = useQuery({
    queryKey: [DISPATCHERS_QUERY_KEY],
    queryFn: () => fetchDispatchersAndFilter(analyticType),
  });

  return (
    <>
      <section className={styles.configSection}>
        <Autocomplete
          size="small"
          disablePortal
          loading={isLoading}
          defaultValue={initialValue}
          id="alert-system-autocomplete"
          groupBy={(option) => {
            if (option.name.toLocaleLowerCase().includes("padrão")) {
              return "Padrões";
            } else {
              return "Personalizados";
            }
          }}
          options={
            dispatchers
              ? dispatchers.sort((a, b) => {
                  if (a.name.toLocaleLowerCase().includes("padrão")) return 1;
                  else return -1;
                })
              : []
          }
          isOptionEqualToValue={(option, value) =>
            isLoading || option.alert_system_id === value.alert_system_id
          }
          onChange={(ev, newValue) => {
            if (newValue) onSelectDispatcher(newValue?.alert_system_id);
          }}
          getOptionLabel={(option) =>
            `${option.name} - Fluxo ${option.max_cycle_duration / 60} minutos`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              name="alert-system"
              label="Sistema de alerta"
            />
          )}
        />
      </section>
    </>
  );
}
