import React from "react";
import { Home, HomeOutlined, Notes, NotesOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BottomNavigationButton } from "./BottomNavigationButton";
import { Video } from "lucide-react";

function BottomNavigation() {
  const pathname = window.location.pathname;
  const navigate = useNavigate();
  return (
    <div className="flex justify-around px-2 py-2 border-t sm:hidden">
      <BottomNavigationButton
        label="Início"
        icons={{
          filled: <Home />,
          outlined: <HomeOutlined />,
        }}
        onClick={() => navigate("/")}
        isSelected={pathname === "/"}
      />
      <BottomNavigationButton
        label="Câmeras"
        icons={{
          filled: <Video />,
          outlined: <Video />,
        }}
        onClick={() => navigate("/cameras")}
        isSelected={pathname === "/cameras"}
      />
      <BottomNavigationButton
        label="Ocorrências"
        icons={{
          filled: <Notes />,
          outlined: <NotesOutlined />,
        }}
        onClick={() => navigate("/log/all")}
        isSelected={pathname.includes("/log")}
      />
    </div>
  );
}

export default BottomNavigation;
