import React, { useEffect, useMemo, useState } from "react";
import { getOperator, updatePermissions } from "api/operators";
import { HeimdallOperator } from "interfaces/operator";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

interface GroupCameraInfo {
  name: string;
  id: number;
}

interface PermissionsDialogProps {
  title?: string;
  subtitle?: string;
  open: boolean;
  onClose: () => void;
  operator: HeimdallOperator;
  handleSubmit: () => void;
}

function not(
  a: readonly { name: string; id: number }[],
  b: readonly { name: string; id: number }[]
) {
  return a.filter(
    (value) => value.id !== b.find((bValue) => bValue.id === value.id)?.id
  );
}

function intersection(
  a: readonly { name: string; id: number }[],
  b: readonly { name: string; id: number }[]
) {
  return a.filter(
    (value) => value.id === b.find((bValue) => bValue.id === value.id)?.id
  );
}

export default function PermissionsDialog({
  title,
  subtitle,
  open,
  onClose,
  operator,
  handleSubmit,
}: PermissionsDialogProps) {
  const theme = useTheme();
  const { toast } = useToast();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [typeTab, setTypeTab] = useState(0);
  // Association modal state
  const [checked, setChecked] = useState<readonly GroupCameraInfo[]>([]);
  const [operatorInfosNames, setOperatorInfosNames] = React.useState<
    GroupCameraInfo[]
  >([]);
  // View permission state
  const [left, setLeft] = useState<GroupCameraInfo[]>([]);
  const [right, setRight] = useState<GroupCameraInfo[]>([]);
  const [searchString, setSearchString] = useState("");
  const [moveAllWarning, setMoveAllWarning] = useState(false);
  const [warningText, setWarningText] = useState("");
  const leftChecked = useMemo(
    () => intersection(checked, left),
    [checked, left]
  );
  const rightChecked = useMemo(
    () => intersection(checked, right),
    [checked, right]
  );
  const filteredLeft = useMemo(
    () =>
      left.filter((camera) =>
        camera.name.toLowerCase().includes(searchString.toLowerCase())
      ),
    [left, searchString]
  );
  const filteredRight = useMemo(
    () =>
      right.filter((camera) =>
        camera.name.toLowerCase().includes(searchString.toLowerCase())
      ),
    [right, searchString]
  );

  const handleTypeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTypeTab(newValue);
  };

  async function handleUpdatePermissions() {
    if (!operator) return;
    const permissionArray: [number, boolean][] = [];

    for (let i = 0; i < left.length; i++) {
      permissionArray[i] = [left[i].id, false];
    }

    if (typeTab === 0) {
      const requestData = {
        group_permission: permissionArray,
      };

      await updatePermissions(operator.id, "group", requestData);
    } else if (typeTab === 1) {
      const requestData = {
        camera_permission: permissionArray,
      };

      await updatePermissions(operator.id, "camera", requestData);
    }

    handleSubmit();
  }

  useEffect(() => {
    const fetchOperator = async (id: number, type: string) => {
      await getOperator(id)
        .then((myOperator) => {
          if (type === "grupos") {
            const cam_in_obj = myOperator.cameras_in_group;
            const cam_out_obj = myOperator.cameras_out_group;
            const groupNames = [...cam_in_obj, ...cam_out_obj];

            setLeft(cam_in_obj);
            setRight(cam_out_obj);
            setOperatorInfosNames(groupNames);
          } else {
            const cam_in_obj = myOperator.user_in_camera;
            const cam_out_obj = myOperator.user_out_camera;
            const camNames = [...cam_in_obj, ...cam_out_obj];

            setLeft(cam_in_obj);
            setRight(cam_out_obj);
            setOperatorInfosNames(camNames);
          }
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro na obtencao de usuario",
            description: getErrorMessage(err),
          });
        });
    };

    if (typeTab === 0) {
      operator.id && fetchOperator(operator.id, "grupos");
    } else {
      operator.id && fetchOperator(operator.id, "cameras");
    }
    setChecked([]);
  }, [operator, typeTab, toast]);

  const handleToggle =
    ({ id, name }: GroupCameraInfo) =>
    () => {
      const currentIndex = checked.findIndex((camera) => camera.id === id);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push({ id, name });
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    };

  const moveAllFilteredRight = () => {
    setRight(right.concat(filteredLeft));
    setLeft(not(left, filteredLeft));
    setSearchString("");
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setSearchString("");
  };

  const moveAllFilteredLeft = () => {
    setLeft(left.concat(filteredRight));
    setRight(not(right, filteredRight));
    setSearchString("");
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setSearchString("");
  };

  const customList = (title: React.ReactNode, cameras: GroupCameraInfo[]) => (
    <Card variant="outlined">
      <Typography sx={{ px: 2, py: 1, textAlign: "center" }}>
        {title}
      </Typography>
      <Divider />
      <List
        sx={{
          height: md ? 300 : 370,
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {cameras.map((camera) => {
          const labelId = `transfer-list-all-item-${camera.id}-label`;
          const camName = operatorInfosNames.map((cam) => {
            if (cam.id === camera.id) {
              return cam.name;
            }
            return null;
          });
          return (
            <ListItemButton
              key={camera.id}
              role="listitem"
              onClick={handleToggle(camera)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    checked.findIndex((cam) => cam.id === camera.id) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={camName} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        maxWidth="lg"
        fullScreen={md}
        onClose={onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <p style={{ marginBottom: "10px", fontSize: "14px" }}>{subtitle}</p>
          <TextField
            fullWidth
            size="small"
            value={searchString}
            sx={{ marginBottom: "10px" }}
            placeholder="Buscar câmeras ou grupos"
            onChange={(ev) => setSearchString(ev.target.value)}
            InputProps={{
              startAdornment: <Search sx={{ marginRight: "5px" }} />,
            }}
          />
          <Tabs
            value={typeTab}
            onChange={handleTypeTabs}
            aria-label="Permissions tabs"
            variant="fullWidth"
          >
            <Tab
              label="Permissão por grupos"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            <Tab
              label="Permissão por câmeras"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
          </Tabs>
          <Grid
            sx={{ marginTop: "10px" }}
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={5}>
              {customList("Usuário - " + operator.name, filteredLeft)}
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setWarningText(
                      typeTab === 0
                        ? `Tem certeza que deseja remover acesso ${
                            filteredLeft.length > 1
                              ? "aos " + filteredLeft.length + " grupos?"
                              : "ao grupo?"
                          }`
                        : `Tem certeza que deseja remover todas as ${filteredLeft.length} câmeras do grupo?`
                    );
                    setMoveAllWarning(true);
                  }}
                  disabled={left.length === 0}
                  aria-label="move filtered right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setWarningText(
                      typeTab === 0
                        ? `Tem certeza que deseja permitir acesso ${
                            filteredRight.length > 1
                              ? "aos " + filteredRight.length + " grupos?"
                              : "ao grupo?"
                          }`
                        : `Tem certeza que deseja mover todas as ${filteredRight.length} câmeras para o grupo?`
                    );
                    setMoveAllWarning(true);
                  }}
                  disabled={right.length === 0}
                  aria-label="move filtered left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              {customList(
                typeTab === 0 ? "Grupos cadastrados" : "Câmeras cadastradas",
                filteredRight
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={onClose}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleUpdatePermissions}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={moveAllWarning} onClose={() => setMoveAllWarning(false)}>
        <DialogTitle>{warningText}</DialogTitle>
        <DialogActions>
          <Button color="error" onClick={() => setMoveAllWarning(false)}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (warningText.includes("remover")) moveAllFilteredRight();
              else moveAllFilteredLeft();
              setMoveAllWarning(false);
            }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
