import { BackendResponsePagination } from "interfaces/generic";
import {
  HeimdallCompany,
  companySchema,
  companyWithUserSchema,
} from "interfaces/company";
import axiosRoot, { baseUrl } from "./rootConfig";
import { z } from "zod";

export async function listCompanies() {
  return await axiosRoot
    .get<BackendResponsePagination<HeimdallCompany>>("/api/company")
    .then((res) => res.data);
}

export async function getCompany(id: number) {
  return await axiosRoot
    .get<{ data: HeimdallCompany }>("/api/company/" + id)
    .then((res) => res.data.data);
}

export async function createCompanyWithUser(
  data: Omit<z.infer<typeof companyWithUserSchema>, "state"> & {
    state?: string | null;
  }
) {
  return await axiosRoot
    .post("/api/store_company_user?app_url=" + baseUrl, data)
    .then((res) => res.data);
}

export async function updateCompany(
  id: number,
  data: Omit<z.infer<typeof companySchema>, "state"> & { state?: string | null }
) {
  return await axiosRoot
    .put("/api/company/" + id, data)
    .then((res) => res.data);
}

export async function deleteCompany(id: number) {
  return await axiosRoot.delete("/api/company/" + id).then((res) => res.data);
}
