import React, { FormEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { resetPassword } from "api/user";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { useToast } from "components/ui/use-toast";
import styles from "./resetPassword.module.css";
import { getErrorMessage } from "lib/apiErrorHandler";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [params] = useSearchParams();
  const { toast } = useToast();
  const token = params.get("token");
  const email = params.get("email");

  function isValidPassword() {
    if (!password || password.length < 6) setPasswordError(true);
    if (password.length > 0 && passwordConfirm !== password)
      setPasswordConfirmError(true);

    if (
      !password ||
      password.length < 6 ||
      (password.length > 0 && passwordConfirm !== password)
    )
      return false;

    return true;
  }

  async function handleResetPassword(ev: FormEvent) {
    ev.preventDefault();
    setIsLoading(true);
    if (isValidPassword() && token && email) {
      await resetPassword({
        token,
        email,
        password,
        password_confirmation: passwordConfirm,
      })
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao redefinir senha",
            description: getErrorMessage(err),
          });
        });
    }
    setIsLoading(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {success ? (
          <>
            <h1 style={{ marginBottom: "15px" }}>
              Senha resetada com sucesso!
            </h1>
            <Link to="/auth" style={{ marginBottom: "15px" }}>
              <Button fullWidth variant="outlined">
                Acessar plataforma
              </Button>
            </Link>
          </>
        ) : (
          <>
            <h1 className="mb-4 text-xl">Insira sua nova senha</h1>
            <form className={styles.formContainer} id="resetPasswordForm">
              <PasswordInput
                fullWidth
                id="password"
                name="password"
                value={password}
                label="Nova senha"
                error={passwordError}
                helperText={
                  passwordError ? "A senha deve ter no mínimo 6 caracteres" : ""
                }
                onChange={(ev) => {
                  setPasswordError(false);
                  setPassword(ev.target.value);
                }}
              />
              <PasswordInput
                fullWidth
                label="Confirmar nova senha"
                id="confirm-password"
                name="confirm-password"
                value={passwordConfirm}
                error={passwordConfirmError}
                helperText={
                  passwordConfirmError ? "As senhas devem coincidir" : ""
                }
                onChange={(ev) => {
                  setPasswordConfirmError(false);
                  setPasswordConfirm(ev.target.value);
                }}
              />
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                loading={isLoading}
                onClick={handleResetPassword}
              >
                Trocar senha
              </LoadingButton>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
