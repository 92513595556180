import { getUser } from "api/user";

// This loader is used in the AuthLayout component and in the routes definition

const redirectToAppLoader = async () => {
  let token = localStorage.getItem("token");
  if (!token) token = sessionStorage.getItem("token");
  console.log("Running redirect to app loader");

  if (token) {
    return await getUser()
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return null;
      });
  }

  return null;
};

export default redirectToAppLoader;
