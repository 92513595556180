import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { editPanicDevice, getPanicDevice } from "api/panic";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

interface EditPanicDeviceDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | number;
}

export default function EditPanicDeviceDialog({
  open,
  setOpen,
  id,
}: EditPanicDeviceDialogProps) {
  const { toast } = useToast();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [ip, setIP] = useState("");
  const [ipError, setIPError] = useState(false);
  const [port, setPort] = useState("");
  const [portError, setPortError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      const response = await getPanicDevice(Number(id));

      if (response && response.data) {
        const device = response.data;
        setName(device.name);
        if (!device.user) setUsername("");
        else setUsername(device.user);
        if (!device.password) setPassword("");
        else setPassword(device.password);
        setIP(device.ip);
        setPort(device.port as string);
      }
    }

    if (open && id) {
      getData();

      setIPError(false);
      setPortError(false);
      setNameError(false);
      setUsernameError(false);
      setPasswordError(false);
    }
  }, [open, id]);

  async function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    const requestData = {
      name,
      ip,
      port,
      user: username,
      password,
      manufacturer: "hikvision",
      model: "",
      remarks: "",
    };
    if (!id) return;

    if (!name) setNameError(true);
    if (!ip) setIPError(true);
    if (!username) setUsernameError(true);
    if (!password) setPasswordError(true);
    if (!name || !ip || !username || !password) return;

    setIsLoading(true);

    await editPanicDevice(Number(id), requestData)
      .then(() =>
        toast({
          description: "Dispositivo editado com sucesso",
        })
      )
      .catch((err) =>
        toast({
          variant: "destructive",
          title: "Erro ao editar dispositivo",
          description: getErrorMessage(err),
        })
      );

    setIsLoading(false);
    setOpen(false);
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: "form",
        noValidate: true,
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        },
      }}
    >
      <DialogTitle>Editar dispositivo pânico</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption" style={{ marginBottom: "10px" }}>
          Atualize os campos do dispositivo, caso realmente seja necessário.
        </DialogContentText>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <TextField
            select
            disabled
            size="small"
            defaultValue="hikvision"
            label="Marca"
          >
            <MenuItem value="">Nenhum</MenuItem>
            <MenuItem value="hikvision">Hikvision</MenuItem>
          </TextField>
          <TextField
            label="Nome"
            size="small"
            value={name}
            error={nameError}
            helperText={nameError ? "Você deve fornecer um nome" : ""}
            onChange={(ev) => {
              setNameError(false);
              setName(ev.target.value);
            }}
          />
          <TextField
            label="IP"
            size="small"
            value={ip}
            error={ipError}
            helperText={ipError ? "Você deve fornecer um endereço de IP" : ""}
            onChange={(ev) => {
              setIPError(false);
              setIP(ev.target.value);
            }}
          />
          <TextField
            label="Porta"
            type="number"
            size="small"
            value={port}
            error={portError}
            helperText={portError ? "Você deve fornecer uma porta" : ""}
            onChange={(ev) => {
              setPortError(false);
              setPort(ev.target.value);
            }}
          />
          <TextField
            required
            label="Usuário"
            size="small"
            value={username}
            error={usernameError}
            helperText="Nome de usuário para acesso ao dispositivo"
            onChange={(ev) => {
              setUsernameError(false);
              setUsername(ev.target.value);
            }}
          />
          <PasswordInput
            required
            label="Senha"
            size="small"
            value={password}
            error={passwordError}
            helperText="Senha para acesso ao dispositivo"
            onChange={(
              ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setPasswordError(false);
              setPassword(ev.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          variant="text"
          loading={isLoading}
          onSubmit={handleSubmit}
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
