import { useState, useEffect } from "react";
import { Dayjs } from "dayjs"; // Adjust the import according to your setup
import { FilterFormData } from "interfaces/occurrences";

interface UseFilterMethodsProps {
  initialActiveFilters?: number;
  filterFormData: FilterFormData;
  onFormDataChange: (formData: FilterFormData) => void;
  onClearFilters: () => void;
}

// Hook used in LogsFilter and in CamerasFilter
export default function useFilterMethods({
  filterFormData,
  initialActiveFilters,
  onFormDataChange,
  onClearFilters,
}: UseFilterMethodsProps) {
  const [activeFilters, setActiveFilters] = useState(0);

  useEffect(() => {
    if (initialActiveFilters) setActiveFilters(initialActiveFilters);
  }, [initialActiveFilters]);

  function getTotalActiveFilters() {
    let totalActiveFilters = 0;
    if (filterFormData?.speed) totalActiveFilters += 1;
    if (filterFormData?.status) totalActiveFilters += 1;
    if (filterFormData?.cameras) totalActiveFilters += 1;
    if (filterFormData?.dateAfter) totalActiveFilters += 1;
    if (filterFormData?.dateBefore) totalActiveFilters += 1;
    if (filterFormData?.cameraName) totalActiveFilters += 1;
    if (filterFormData?.licensePlate) totalActiveFilters += 1;
    if (filterFormData?.cameraLocation) totalActiveFilters += 1;
    if (filterFormData?.cameraStatus) totalActiveFilters += 1;
    if (filterFormData?.order) totalActiveFilters += 1;

    setActiveFilters(totalActiveFilters);
    return totalActiveFilters;
  }

  function handleClearFilters() {
    onFormDataChange({
      speed: "",
      status: "",
      cameras: null,
      cameraName: "",
      dateAfter: null,
      dateBefore: null,
      licensePlate: "",
      cameraLocation: "",
      order: "desc",
    });

    setActiveFilters(0);
    onClearFilters();
  }

  function handleFilterFormDataChange(
    type: "dateAfter" | "dateBefore" | "text",
    data:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | Dayjs
      | null
  ) {
    if (type === "text") {
      const event = data as React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onFormDataChange({
        ...filterFormData!,
        [event.target.name]: event.target.value,
      });
    } else if (type === "dateAfter") {
      onFormDataChange({
        ...filterFormData!,
        dateAfter: data as Dayjs | null,
      });
    } else if (type === "dateBefore") {
      onFormDataChange({
        ...filterFormData!,
        dateBefore: data as Dayjs | null,
      });
    }
  }

  return {
    activeFilters,
    setActiveFilters,
    getTotalActiveFilters,
    handleClearFilters,
    handleFilterFormDataChange,
  };
}
