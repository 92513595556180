import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export default function DeleteDialog({
  open,
  onClose,
  onSubmit,
  title,
  message,
  isLoading,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  message?: string;
  isLoading?: boolean;
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        variant="body1"
        id="alert-dialog-title"
        sx={{ fontSize: "20px" }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose} autoFocus>
          Cancelar
        </Button>
        <LoadingButton onClick={onSubmit} loading={isLoading}>
          Sim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
