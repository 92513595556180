import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, IconButton, Menu, useTheme } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import usePusher from "hooks/usePusher";
import { MonitoringMessage } from "interfaces/monitoring";
import { useQuery } from "@tanstack/react-query";
import { getSimplifiedCameras } from "api/cameras";
import { getPanicDevices } from "api/panic";
import { NotificationInfo } from "./NotificationInfo";

export function HeaderNotifications() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: cameras } = useQuery({
    queryKey: ["simplified-cameras"],
    queryFn: () => getSimplifiedCameras(),
  });
  const { data: panicCameras } = useQuery({
    queryKey: ["panic"],
    queryFn: () => getPanicDevices(),
  });
  const [cameraIds, setCameraIds] = useState<number[]>([]);
  const [panicCameraIds, setPanicCameraIds] = useState<number[]>([]);
  const [occurrencesAux, setOccurrencesAux] = useState<MonitoringMessage[]>([]);
  const pusherInfo = useMemo(
    () => ({
      cameras: cameraIds,
      panicCameras: panicCameraIds,
    }),
    [cameraIds, panicCameraIds]
  );
  const { occurrences } = usePusher(pusherInfo);

  useEffect(() => {
    if (!!cameras) setCameraIds(cameras.data.map((camera) => camera.id));
    if (panicCameras)
      setPanicCameraIds(panicCameras.data.map((panicCamera) => panicCamera.id));
  }, [cameras, panicCameras]);

  useEffect(() => {
    if (occurrences.length > 0)
      setOccurrencesAux((prev) => [occurrences[0], ...prev]);
  }, [occurrences]);

  function removeNotification(id: number) {
    const newOccurrencesAux = [...occurrencesAux];
    newOccurrencesAux.splice(id, 1);
    setOccurrencesAux(newOccurrencesAux);
  }

  return (
    <>
      <IconButton onClick={handleClick} color="inherit">
        <Badge badgeContent={occurrencesAux.length} color="error">
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: { style: { maxHeight: "500px", borderRadius: "12px" } },
        }}
      >
        <div className="relative">
          {occurrencesAux.length > 0 && (
            <div className="pb-10">
              {occurrencesAux.map((occurrence, id) => (
                <NotificationInfo
                  key={`${occurrence.occurrence}-${occurrence.id}`}
                  occurrence={occurrence}
                  handleClose={handleClose}
                  handleRemoveNotification={() => removeNotification(id)}
                />
              ))}
            </div>
          )}
          {occurrencesAux.length === 0 ? (
            <div style={{ padding: "10px", width: "440px" }}>
              <p style={{ marginBottom: "5px" }}>
                Nenhuma notificação no momento.
              </p>
              <p>As ocorrências identificadas aparecerão aqui em tempo real.</p>
            </div>
          ) : (
            <div
              style={{
                position: "sticky",
                bottom: "0",
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Button
                fullWidth
                color="error"
                onClick={() => setOccurrencesAux([])}
              >
                Limpar todas
              </Button>
            </div>
          )}
        </div>
      </Menu>
    </>
  );
}
