import { useEffect, useState } from "react";
import styles from "./CardForense.module.css";
import dayjs from "dayjs";
import { LocationOnOutlined } from "@mui/icons-material";
import { baseUrlNoPort } from "api/rootConfig";

export default function CardForense(props) {
  const [checkState, setCheckState] = useState(false);
  useEffect(() => {
    if (props.idsSelected.includes(props.id)) {
      setCheckState(true);
    } else {
      setCheckState(false);
    }
  }, [props.toggleMap, props.idsSelected]);

  return (
    <div className={styles.cardBox}>
      <div className={styles.cardBoxImg}>
        <img
          src={`${baseUrlNoPort}:5600/api/forensic/image/${props.photo}`}
          alt="face"
          style={{ width: "100%", height: "100%", cursor: "pointer" }}
          onClick={() => {
            props.openModal(
              `${baseUrlNoPort}:5600/api/forensic/image/${props.photo}`,
            );
          }}
        />
        <img
          src={`${baseUrlNoPort}:5600/api/forensic/faceimage/${props.faceBox}/${props.photo}`}
          alt="face"
          style={{
            width: "40%",
            height: "30%",
            position: "absolute",
            right: 0,
            top: 0,
            border: "red 2px solid",
          }}
          onClick={() => {
            props.toggleRefineSearch(
              props.id,
              `${baseUrlNoPort}:5600/api/forensic/faceimage/${props.faceBox}/${props.photo}`,
            );
          }}
        />
        <input
          type="checkbox"
          checked={checkState}
          style={{ position: "absolute", right: "2%", top: "24%" }}
          onChange={(value) => {
            props.toggleRefineSearch(
              props.id,
              `${baseUrlNoPort}:5600/api/forensic/faceimage/${props.faceBox}/${props.photo}`,
            );
          }}
        />
      </div>
      <div
        className={styles.description}
        style={{
          backgroundColor:
            props.confidence >= 90 ? "lightgreen" : "rgb(255, 255, 143)",
        }}
      >
        {/* <span>ID: {props.id}</span> */}
        <span>Similaridade: {props.confidence.toFixed(2)}%</span>
        <span>Data: {dayjs(props.date).format("DD/MM/YYYY HH:mm:ss")}</span>
        <span>Local: {props.place}</span>
        <div className={styles.checkBox}>
          <LocationOnOutlined sx={{ marginRight: "5px" }} />
          <input
            type="checkbox"
            onChange={(value) => {
              props.toggleMap(props.checkId);
            }}
          />
        </div>
      </div>
    </div>
  );
}
