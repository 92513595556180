import React from "react";
import DataGridContainer, {
  defaultGridContainerMaxHeight,
} from "components/DataGridContainer/DataGridContainer";
import {
  DataGridPropsWithComplexDefaultValueBeforeProcessing,
  DataGridPropsWithDefaultValues,
} from "@mui/x-data-grid/internals";
import {
  DataGridForcedPropsKey,
  DataGridPropsWithoutDefaultValue,
} from "@mui/x-data-grid/models/props/DataGridProps";
import { CSSProperties } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "components/CustomNoRowsOverlay/CustomNoRowsOverlay";

export default function BorderedDataGrid({
  withContainer,
  containerStyle,
  ...props
}: BorderedDataGridParams) {
  if (withContainer) {
    return (
      <DataGridContainer containerStyle={containerStyle}>
        <DataGrid
          {...props}
          slots={{
            ...props.slots,
            noRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            border: 1,
            // "& .MuiDataGrid-overlay": { minHeight: "150px" },
            // "& .MuiDataGrid-main": { minHeight: "300px" },
            // "& .MuiDataGrid-overlayWrapper": { minHeight: "150px" },
            // "--DataGrid-overlayHeight": "300px",
            maxHeight:
              containerStyle &&
              containerStyle.maxHeight &&
              containerStyle.maxHeight !== "none"
                ? maxHeightFromContainerHeight(containerStyle.maxHeight)
                : maxHeightFromContainerHeight(defaultGridContainerMaxHeight),
            borderColor: "primary.light",
            "& .MuiDataGrid-row": {
              borderBottom: 1,
              borderColor: "primary.light",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: 1,
              borderColor: "primary.light",
            },
            "& .MuiDataGrid-iconSeparator": {
              color: "primary.light",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: 1,
              borderColor: "primary.light",
            },
            "& .MuiDataGrid-overlayWrapperInner": {
              borderBottom: 1,
              borderColor: "primary.light",
            },
          }}
        />
      </DataGridContainer>
    );
  }
  return (
    <DataGrid
      {...props}
      slots={{
        ...props.slots,
        noRowsOverlay: CustomNoRowsOverlay,
        noResultsOverlay: CustomNoRowsOverlay,
      }}
      sx={{
        border: 1,
        borderColor: "primary.light",
        "& .MuiDataGrid-row": {
          borderBottom: 1,
          borderColor: "primary.light",
        },
        "& .MuiDataGrid-columnHeaders": {
          borderBottom: 1,
          borderColor: "primary.light",
        },
        "& .MuiDataGrid-iconSeparator": {
          color: "primary.light",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: 1,
          borderColor: "primary.light",
        },
        "& .MuiDataGrid-overlayWrapperInner": {
          borderBottom: 1,
          borderColor: "primary.light",
        },
      }}
    />
  );
}

export interface BorderedDataGridParams
  extends Omit<
    Partial<DataGridPropsWithDefaultValues> &
      DataGridPropsWithComplexDefaultValueBeforeProcessing &
      DataGridPropsWithoutDefaultValue,
    DataGridForcedPropsKey
  > {
  withContainer?: boolean;
  containerStyle?: CSSProperties;
}

function maxHeightFromContainerHeight(containerHeight: string | number) {
  if (typeof containerHeight === "string") {
    const transformedHeight = parseInt(containerHeight.replace("px", ""));
    return `${transformedHeight - 40}px`;
  } else {
    return `${containerHeight - 40}px`;
  }
}
