export function translateColor(color: string) {
  if (color.toLowerCase() === "black") return "Preto";
  if (color.toLowerCase() === "white") return "Branco";
  if (color.toLowerCase() === "silver") return "Prata";
  if (color.toLowerCase() === "beige") return "Bege";
  if (color.toLowerCase() === "pink") return "Rosa";
  if (color.toLowerCase() === "red") return "Vermelho";
  if (color.toLowerCase() === "green") return "Verde";
  if (color.toLowerCase() === "blue") return "Azul";
  if (color.toLowerCase() === "yellow") return "Amarelo";
  if (color.toLowerCase() === "gray" || color === "grey") return "Cinza";
  if (color.toLowerCase() === "orange") return "Laranja";
  if (color.toLowerCase() === "gold") return "Ouro";
  if (color.toLowerCase() === "brown") return "Marrom";

  return color;
}
