import React from "react";
import { cn } from "../../lib/classNames";
import styles from "./colorTransition.module.css";

interface BottomNavigationButtonProps extends React.ComponentProps<"div"> {
  isSelected?: boolean;
  icons: { filled: React.JSX.Element; outlined?: React.JSX.Element };
  label: string;
  showLabel?: boolean;
}

export function BottomNavigationButton({
  icons,
  showLabel = true,
  label,
  isSelected,
  ...props
}: BottomNavigationButtonProps) {
  const iconColor = isSelected
    ? "var(--root-color-on-primary-container)"
    : "inherit"; // 'primary' for selected, 'inherit' for default color

  // Clone the icon element to add the color prop
  const filledIconWithColor = React.cloneElement(icons.filled, {
    style: { fill: iconColor },
  });
  const outlinedIcon = icons.outlined ?? filledIconWithColor;

  return (
    <div
      {...props}
      className={cn(
        "flex flex-col items-center justify-center cursor-pointer space-y-1",
        props.className,
      )}
    >
      <div
        className={cn(
          "flex items-center justify-center p-1 w-14 rounded-3xl",
          isSelected ? `primary-container ${styles.colorTransition}` : "",
        )}
      >
        {isSelected ? filledIconWithColor : outlinedIcon}
      </div>
      {showLabel && (
        <label className={cn("text-sm", isSelected ? "font-medium" : "")}>
          {label}
        </label>
      )}
    </div>
  );
}
