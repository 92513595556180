import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import AddIcon from "@mui/icons-material/AddCircle";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getDevices } from "api/ioDevices";
import {
  HeimdallDeviceInList,
  HeimdallDeviceTypesPretty,
} from "interfaces/devices";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";

interface IoDevicesListDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAssociate: (
    id: string | number,
    type: HeimdallDeviceTypesPretty
  ) => Promise<void>;
}

export default function IoDevicesListDialog({
  open,
  setOpen,
  handleAssociate,
}: IoDevicesListDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Data grid state
  const [devices, setDevices] = useState<HeimdallDeviceInList[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Fetching initial page data
  useEffect(() => {
    async function getData() {
      const newDevices = await getDevices();

      if (newDevices && newDevices.data) setDevices(newDevices.data);
      setIsLoading(false);
    }

    getData();
  }, []);
  const columns: GridColDef<HeimdallDeviceInList>[] = [
    {
      field: "name",
      headerName: "Nome",
      minWidth: 160,
      flex: 1,
    },
    {
      field: "device",
      headerName: "Tipo",
      minWidth: 120,
    },
    {
      field: "ip",
      headerName: "IP / DDNS",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Ações",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() =>
              handleAssociate(
                String(params.id).split(" ")[0],
                params.row.device
              )
            }
          >
            <AddIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Escolher dispositivo I/O</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption" style={{ marginBottom: "10px" }}>
          Escolha o dispositivo que deseja associar ao analitico.
        </DialogContentText>
        <BorderedDataGrid
          autoHeight
          rows={devices}
          columns={columns}
          getRowId={(row) => row.id + " " + row.name}
          loading={isLoading}
          slots={{ toolbar: GridToolbar }}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            setIsLoading(true);
            setPaginationModel({
              page: model.page,
              pageSize: model.pageSize,
            });
            setIsLoading(false);
          }}
          pageSizeOptions={[5, 10, 15]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          // SEARCH FIELD
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
