import React, { useState, useEffect, useRef } from "react";
import { RegionMonitor } from "interfaces/analytics";
import {
  draw,
  isClose,
  CanvasInfo,
  getEmptyCanvases,
  closePolygonAndDraw,
  getEmptyCanvas,
  drawNormalizedPolygon,
} from "lib/canvas";
import { Alert } from "@mui/material";
import AllRegionCanvas from "./AllRegionCanvas";
import CanvasSkeleton from "./CanvasSkeleton";
import { CanvasError } from "./CanvasError";
import { RegionSelector } from "./RegionSelector";
import { CanvasActions } from "./CanvasActions";
import { RefreshContainer } from "components/cameras-grid/RefreshContainer";

interface PolygonCanvasProps {
  regionError?: boolean;
  regions: RegionMonitor;
  initialValue?: RegionMonitor;
  dimensions: { width: number; height: number };
  cameraFrame: {
    img?: string;
    isLoading: boolean;
    isStale: boolean;
    refetchFrame: () => Promise<void>;
  };
  onAddRegion?: () => void;
  onDeleteRegion?: (index: number) => void;
  onResetRegions?: (newRegions: RegionMonitor) => void;
  onPolygonDraw?: (polygon: [number, number][], index: number) => void;
}

const MultipleRegionCanvas: React.FC<PolygonCanvasProps> = ({
  regions,
  dimensions,
  regionError,
  cameraFrame,
  onAddRegion,
  initialValue,
  onPolygonDraw,
  onResetRegions,
  onDeleteRegion,
}) => {
  const [showAll, setShowAll] = useState(true);
  const verticesRef = useRef<Array<[number, number]>>([]);
  const [canvases, setCanvases] = useState<CanvasInfo[]>(
    getEmptyCanvases(regions.length, dimensions)
  );
  const [currentCanvas, setCurrentCanvas] = useState({
    id: 0,
    canvas: canvases[0],
    completedDrawing: false,
  });
  const currentCanvasIdRef = useRef(0);
  const currentCanvasRef = useRef<{
    id: number;
    canvas: CanvasInfo;
    completedDrawing: boolean;
  } | null>(null);
  const dimensionsRef = useRef(dimensions);

  const addCanvas = () => {
    setCanvases((prev) => {
      const newCanvasInfo = getEmptyCanvas(dimensions);
      return [...prev, newCanvasInfo];
    });
    if (onAddRegion) onAddRegion();
  };

  function handleCurrentCanvasId(id: number) {
    setCurrentCanvas({ id, canvas: canvases[id], completedDrawing: false });
  }

  function handleFinishedDraw() {
    setCurrentCanvas((prev) => ({ ...prev, completedDrawing: true }));
  }

  function handleDeleteCanvas(id: number) {
    // FIXME: Deleting the last region bug
    // If you delete region 1, region 2 is deleted instead
    const newCanvases = [...canvases];
    if (newCanvases.length === 1) return;
    // console.log("Deleting", id);
    // console.log(regions);
    // console.log(newCanvases);
    if (onDeleteRegion) onDeleteRegion(id);
    newCanvases.splice(id, 1);
    // console.log(regions);
    // console.log(newCanvases);
    if (currentCanvas.id >= newCanvases.length) {
      setCurrentCanvas({ ...currentCanvas, id: newCanvases.length - 1 });
    }
    setCanvases(newCanvases);
  }

  // Update the canvases dimensions
  useEffect(() => {
    dimensionsRef.current = dimensions;
    setCanvases((prev) => {
      const updatedCanvases = prev.map((value) => {
        value.dimensions = dimensions;
        return value;
      });
      return updatedCanvases;
    });
    setCurrentCanvas((prev) => ({ ...prev, completedDrawing: false }));
  }, [dimensions]);

  // Handles canvas's context initialization
  useEffect(() => {
    const updatedCanvases = [...canvases];
    let wasUpdated = false;
    canvases.forEach((canvasInfo, idx) => {
      if (!canvasInfo.context) {
        const newCtx = canvasInfo.ref.current?.getContext("2d");
        if (newCtx) {
          newCtx.lineCap = "round";
          newCtx.setLineDash([3, 9]);
          newCtx.fillStyle = canvasInfo.fillStyle;
          newCtx.lineWidth = canvasInfo.lineWidth;
          newCtx.strokeStyle = canvasInfo.lineColor;

          updatedCanvases[idx].context = newCtx;
          wasUpdated = true;
        }
      }
    });
    if (wasUpdated) setCanvases(updatedCanvases);
  }, [canvases]);

  // When new canvases are added, bind click listeners to them
  useEffect(() => {
    function findxy(res: string, e: MouseEvent) {
      if (
        !currentCanvasRef.current ||
        !currentCanvasRef.current.canvas.context ||
        currentCanvasRef.current.completedDrawing
      )
        return;
      setCanvases((prevCanvases) => {
        const updatedCanvases = [...prevCanvases];
        const currentCanvas = updatedCanvases[currentCanvasIdRef.current];

        if (res === "click") {
          if (currentCanvas.drawFlag) {
            currentCanvas.currX = e.offsetX;
            currentCanvas.currY = e.offsetY;
            const newVertex: [number, number] = [
              currentCanvas.currX,
              currentCanvas.currY,
            ];

            if (
              verticesRef.current.length > 2 &&
              isClose(newVertex, verticesRef.current[0])
            ) {
              closePolygonAndDraw(currentCanvas, verticesRef.current);
              if (onPolygonDraw) {
                onPolygonDraw(verticesRef.current, currentCanvasIdRef.current);
              }

              setCurrentCanvas((prev) => ({
                ...prev,
                completedDrawing: true,
              }));
            } else {
              verticesRef.current = [...verticesRef.current, newVertex];
              draw(currentCanvas);
            }
          } else {
            // Only gets executed once
            const firstVertex: [number, number] = [e.offsetX, e.offsetY];
            const newVertices = [firstVertex];
            verticesRef.current = newVertices;
          }

          if (!currentCanvasRef.current?.completedDrawing) {
            // Sets new previous coordinates
            currentCanvas.context!.beginPath();
            currentCanvas.prevX = e.offsetX;
            currentCanvas.prevY = e.offsetY;
            currentCanvas.context!.moveTo(e.offsetX, e.offsetY);
            currentCanvas.context!.fillStyle = currentCanvas.dotColor;
            currentCanvas.context!.arc(
              e.offsetX,
              e.offsetY,
              4,
              0,
              2 * Math.PI,
              false
            );
            currentCanvas.context!.fill();
            currentCanvas.context!.closePath();
            currentCanvas.drawFlag = true;
          }
        }

        currentCanvasRef.current = {
          ...currentCanvasRef.current!,
          canvas: currentCanvas,
        };
        return updatedCanvases;
      });
    }

    const handleClicks = new Map();

    setCanvases((prev) => {
      const updatedCanvases = [...prev];
      updatedCanvases.forEach((canvasInfo) => {
        if (canvasInfo.ref.current) {
          const handleClick = (e: MouseEvent) => findxy("click", e);
          handleClicks.set(canvasInfo.ref.current, handleClick);
          canvasInfo.ref.current.addEventListener("click", handleClick);
        }
      });

      return updatedCanvases;
    });
    // Return cleanup function
    return () => {
      handleClicks.forEach((handleClick, canvasElement) => {
        canvasElement.removeEventListener("click", handleClick);
      });
    };
  }, [canvases.length, onPolygonDraw]);

  // Handles region change
  useEffect(() => {
    if (!currentCanvas.completedDrawing) {
      drawNormalizedPolygon({
        canvas: currentCanvas.canvas,
        polygon: regions[currentCanvas.id],
        onFinishDraw: handleFinishedDraw,
      });
    }

    currentCanvasIdRef.current = currentCanvas.id;
    currentCanvasRef.current = { ...currentCanvas };
  }, [currentCanvas, regions]);

  // Fixes bug when submitting analytic with empty regions
  // This makes the regions array be equal in length to the canvases array,
  // so the user can always draw a region on the canvas
  useEffect(() => {
    if (canvases.length > regions.length) {
      const newCanvases = [...canvases];
      newCanvases.pop();
      setCanvases(newCanvases);
    }
  }, [canvases, regions]);

  function reset() {
    if (onPolygonDraw && initialValue && onResetRegions) {
      onResetRegions(initialValue);
    }
  }

  function loadEmptyValues() {
    const updatedCanvases = [...canvases];
    const updatedCanvas = updatedCanvases[currentCanvas.id];
    updatedCanvas.drawFlag = false;
    updatedCanvas.prevX = 0;
    updatedCanvas.currX = 0;
    updatedCanvas.prevY = 0;
    updatedCanvas.currY = 0;
    setCanvases(updatedCanvases);
    setCurrentCanvas((prev) => ({ ...prev, completedDrawing: false }));
    currentCanvasRef.current = {
      ...currentCanvas,
      completedDrawing: false,
      canvas: updatedCanvases[currentCanvas.id],
    };
  }

  function erase() {
    if (!currentCanvas.canvas.context) return;
    // FIXME: clear canvasWidth and canvasHeight
    currentCanvas.canvas.context.clearRect(0, 0, 1000, 1000);
    currentCanvas.canvas.context.setLineDash([3, 9]);
    loadEmptyValues();
    if (onPolygonDraw) onPolygonDraw([], currentCanvas.id);
  }

  return (
    <>
      <CanvasSkeleton show={cameraFrame.isLoading} dimensions={dimensions} />
      <div
        style={{
          display: cameraFrame.isLoading ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ position: "relative" }}>
          <AllRegionCanvas
            regions={regions}
            dimensions={dimensions}
            show={showAll}
          />
          {regions.map((_, index) => (
            <canvas
              key={index}
              ref={canvases[index].ref}
              width={dimensions.width}
              height={dimensions.height}
              style={{
                position: "absolute",
                zIndex: 2 + index,
                display: currentCanvas.id === index ? "block" : "none",
              }}
            />
          ))}
          <RefreshContainer
            buttonClassname="z-20"
            isRefreshingFrame={cameraFrame.isLoading}
            isStale={cameraFrame.isStale}
            onRefresh={cameraFrame.refetchFrame}
          >
            {cameraFrame.img ? (
              <img
                src={`data:image/jpeg;base64,${cameraFrame.img}`}
                width={dimensions.width}
                height={dimensions.height}
                style={{
                  width: `${dimensions.width}px`,
                  height: `${dimensions.height}px`,
                }}
                alt="Quadro da câmera"
              />
            ) : (
              <CanvasError
                style={{
                  width: `${dimensions.width}px`,
                  height: `${dimensions.height}px`,
                }}
              />
            )}
          </RefreshContainer>
          <RegionSelector
            regions={regions}
            showAll={showAll}
            onAddCanvas={addCanvas}
            setShowAll={setShowAll}
            selected={currentCanvas.id}
            onDeleteCanvas={handleDeleteCanvas}
            setCurrentCanvasId={handleCurrentCanvasId}
          />
          <CanvasActions
            erase={erase}
            reset={reset}
            regionError={regionError}
          />
          {regionError && (
            <Alert severity="error" sx={{ marginTop: "10px" }}>
              Você deve demarcar ao menos uma região para ativar o analítico
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};

export default MultipleRegionCanvas;
