import { Rgba } from "@material/material-color-utilities";
export function getDarkContrastColor(rgba: Rgba): string {
  // Ensure RGBA values are within valid range (0-255)
  const clamp = (value: number) => Math.min(255, Math.max(0, value));

  // Calculate luminance to determine contrast with white text
  const luminance = 0.299 * rgba.r + 0.587 * rgba.g + 0.114 * rgba.b;

  // Adjust darkness based on luminance
  const darknessFactor = luminance > 128 ? 0.7 : 0.4;

  // Calculate darker color
  const darkR = clamp(rgba.r * darknessFactor);
  const darkG = clamp(rgba.g * darknessFactor);
  const darkB = clamp(rgba.b * darknessFactor);

  // Return the darker color as a CSS RGBA string
  return `rgba(${darkR}, ${darkG}, ${darkB}, ${rgba.a})`;
}

export function fromRgbaToString(rgba: Rgba) {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}

export function transformRGBString(rgbString: string): Rgba {
  const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

  if (match) {
    const [, r, g, b] = match.map(Number);
    return { r, g, b, a: 255 };
  }

  throw new Error("Invalid rgb string");
}

export enum OccurenceMarkColor {
  visualized = "#2e7d32",
  accidents = "#ed6c02",
  effective = "#d32f2f",
  false_positive = "#1976d2",
  resident = "#2e7d32",
  vandalism = "#d32f2f",
}
