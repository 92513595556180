import React from "react";
import { Android, Language } from "@mui/icons-material";
import { DeviceInfo } from "@capacitor/device";

export function PlatformIcon({
  devicePlatform,
}: {
  devicePlatform: DeviceInfo["platform"];
}) {
  switch (devicePlatform) {
    case "android":
      return (
        <Android
          className="text-3xl sm:text-4xl"
          color="success"
          fontSize="inherit" />
      );
    case "ios":
      return (
        <svg width="100" height="50" xmlns="http://www.w3.org/2000/svg">
          <text
            x="0"
            y="40"
            font-family="Arial, sans-serif"
            font-size="40"
            fill="black"
          >
            iOS
          </text>
        </svg>
      );
    case "web":
      return <Language className="text-3xl sm:text-4xl" fontSize="inherit" />;
    default:
      return null;
  }
}
