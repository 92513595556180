import { darken, lighten, styled } from "@mui/material/styles";
import LogsMultiViewer from "components/LogsMultiViewer/LogsMultiViewer";
import { getColor } from "lib/monitor";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(LogsMultiViewer)(({ theme }) => ({
  "& .theme--Aglomeração": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
  "& .theme--Intrusão": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  "& .theme--Evasão": {
    backgroundColor: getBackgroundColor(
      theme.palette.action.active,
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.action.active,
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.action.active,
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.action.active,
          theme.palette.mode,
        ),
      },
    },
  },
  "& .theme--Radar": {
    backgroundColor: getBackgroundColor(
      getColor("Radar"),
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        getColor("Radar"),
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        getColor("Radar"),
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          getColor("Radar"),
          theme.palette.mode,
        ),
      },
    },
  },
  "& .theme--DetecçãodeAnimal": {
    backgroundColor: getBackgroundColor(
      getColor("Detecção de Animal"),
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        getColor("Detecção de Animal"),
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        getColor("Detecção de Animal"),
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          getColor("Detecção de Animal"),
          theme.palette.mode,
        ),
      },
    },
  },
  "& .theme--DetecçãodeVeículo": {
    backgroundColor: getBackgroundColor(
      getColor("Detecção de Veículo"),
      theme.palette.mode,
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        getColor("Detecção de Veículo"),
        theme.palette.mode,
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        getColor("Detecção de Veículo"),
        theme.palette.mode,
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          getColor("Detecção de Veículo"),
          theme.palette.mode,
        ),
      },
    },
  },
}));

export default StyledDataGrid;
