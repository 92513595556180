/*----------------------------------------------------------
 * Ocurrences
 * ------------------------------------------------------------- */

import axiosRoot, { baseUrl } from "./rootConfig";
import { BackendResponsePagination } from "interfaces/generic";
import { AnalyticInternalKey, AnalyticType } from "interfaces/analytics";
import { AnalyticLog, LprLog, RadarLog } from "interfaces/analytics";
import { SpecificLogUrlPath } from "interfaces/occurrences";
import { getLogNameFromLogType } from "lib/logs";
import { toast } from "components/ui/use-toast";
import { ExportOptions } from "pages/logs/LogsGridToolbar";

export interface GetLogsParams {
  type?: AnalyticInternalKey | SpecificLogUrlPath;
  page?: number;
  pageSize?: number;
  camerasIds?: string;
  status?: string;
  timestampAfter?: string;
  timestampBefore?: string;
  order?: "asc" | "desc";
}

// TODO: Remove all catch statements, and implement them in every other place the method is called
/**
 * Get general ocurrences or specific ocurrences from the database, with the option to filter the results
 * @param type The type of the ocurrence (e.g. "all": all ocurrences, "agglomeration": only agglomeration)
 * @param page Used for pagination: the page you want to fetch
 * @param pageSize Used for pagination: the size of the page you want to fetch
 * @param camerasIds Used for filtering the results: filter by the Camera ID
 * @param status Used for filtering the results: filter by the status of the ocurrence {"unattended" | "solved" | "false_positive"}
 * @param timestampAfter Used for filtering the results: fetch only results after this date {"YYYY-MM-DD HH:mm"}
 * @param timestampBefore Used for filtering the results: fetch only results before this date {"YYYY-MM-DD HH:mm"}
 * @param order Filter the results based in the order of the `created_at` attribute
 * @returns an object in the form { table: the table data containing the results, or the filtered results, meta: metadata about the results, primarily used for pagination }
 */
export async function getLogs(
  {
    type = SpecificLogUrlPath.all,
    page = 1,
    pageSize = 100000,
    camerasIds,
    status,
    timestampAfter,
    timestampBefore,
    order = "desc",
  }: GetLogsParams = {
    type: SpecificLogUrlPath.all,
    page: 1,
    pageSize: 100000,
    order: "desc",
  }
) {
  // Constructing the request URL for the provided type.
  let occurrenceURL = "";
  if (type === "all") occurrenceURL += "/api/service_log?page=" + page;
  else if (type === "agglomeration")
    occurrenceURL += "/api/agglomeration_log?page=" + page;
  else if (type === "intrusion")
    occurrenceURL += "/api/intrusion_log?page=" + page;
  else if (type === "evasion") occurrenceURL += "/api/evasion_log?page=" + page;
  else if (type === "vehicle_intrusion" || type === "vehicle-intrusion")
    occurrenceURL += "/api/vehicle_intrusion_log?page=" + page;
  else if (type === "vehicle_evasion" || type === "vehicle-evasion")
    occurrenceURL += "/api/vehicle_evasion_log?page=" + page;
  else if (type === "vehicle_agglomeration" || type === "vehicle-agglomeration")
    occurrenceURL += "/api/vehicle_agglomeration_log?page=" + page;
  else if (type === "animal")
    occurrenceURL += "/api/animal_intrusion_log?page=" + page;
  else if (type === "inactivity")
    occurrenceURL += "/api/inactivity_log?page=" + page;
  else if (type === "panic") occurrenceURL += "/api/panic_log?page=" + page;
  else if (type === "permanence")
    occurrenceURL += "/api/permanence_log?page=" + page;
  else if (type === "radar")
    return await getRadarLogs({
      page,
      status,
      pageSize,
      camerasIds,
      timestampAfter,
      timestampBefore,
    });
  else if (type === "lpr") {
    return await getLprLogs({
      page,
      status,
      pageSize,
      camerasIds,
      timestampAfter,
      timestampBefore,
    }).catch((err) => console.log(err));
  } else throw new Error("Tipo de log nao definido");
  // Constructing the request URL for the provided filters.
  if (camerasIds) occurrenceURL += "&filter%5Bcamera_id%5D=" + camerasIds;
  if (status) occurrenceURL += "&filter%5Bcheck_status%5D=" + status;
  if (timestampAfter)
    occurrenceURL += "&filter%5Btimestamp_after%5D=" + timestampAfter;
  if (timestampBefore)
    occurrenceURL += "&filter%5Btimestamp_before%5D=" + timestampBefore;

  return await axiosRoot
    .get<BackendResponsePagination<AnalyticLog>>(occurrenceURL, {
      headers: { paginate: pageSize, OrderDirection: order },
    })
    .then((response) => response.data)
    .catch((err) => console.error("Um erro inesperado ocorreu:", err));
}

export async function exportLogs(data?: ExportOptions, isBrowser?: boolean) {
  toast({
    description: "Seu download começará em breve",
  });

  let requestUrl = "/api/download_logs?page=1";

  if (!!data) {
    requestUrl += "&filter%5Boccurrence%5D=";

    if (!data.type || data.type === "all") requestUrl += "";
    else if (data.type === "vehicle-evasion") {
      requestUrl += getLogNameFromLogType(AnalyticInternalKey.vehicle_evasion);
    } else if (data.type === "vehicle-agglomeration")
      requestUrl += getLogNameFromLogType(
        AnalyticInternalKey.vehicle_agglomeration
      );
    else if (data.type === "vehicle-intrusion")
      requestUrl += getLogNameFromLogType(
        AnalyticInternalKey.vehicle_intrusion
      );
    else requestUrl += getLogNameFromLogType(data.type);

    if (data.cameras) requestUrl += "&filter%5Bcamera_id%5D=" + data.cameras;
    if (data.status) requestUrl += "&filter%5Bcheck_status%5D=" + data.status;
    if (data.after) requestUrl += "&filter%5Btimestamp_after%5D=" + data.after;
    if (data.before)
      requestUrl += "&filter%5Btimestamp_before%5D=" + data.before;
    if (data?.order) requestUrl += "&OrderDirection=" + data.order;
  }

  if (isBrowser) {
    window.open(baseUrl + requestUrl, "_blank");
  } else {
    return await axiosRoot
      .get(requestUrl, {
        responseType: "blob",
        timeout: 35 * 1000, // 35 seconds
        headers: {
          Paginate: Math.pow(10, 12),
          OrderDirection: data?.order || "desc",
          Accept: "text/csv",
        },
      })
      .then((response) => response.data);
  }
}

interface GetRadarLogsParams extends GetLogsParams {
  licensePlate?: string;
  speed?: string;
}

interface GetLprLogsParams extends GetLogsParams {
  licensePlate?: string;
  colors?: string[];
  platesPermission?: "authorized" | "unauthorized" | "all";
}

export async function getLprLogs(
  {
    page = 1,
    pageSize = 10000,
    camerasIds = "",
    status = "",
    timestampAfter = "",
    timestampBefore = "",
    licensePlate = "",
    colors = [],
    platesPermission,
  }: GetLprLogsParams = {
    page: 1,
    pageSize: 10000,
  }
) {
  let requestUrl = "/api/lpr_log?page=" + page;

  // Montando a URL de requisicao para os filtros fornecidos
  if (camerasIds) requestUrl += "&filter%5Bcamera_id%5D=" + camerasIds;
  if (status) requestUrl += "&filter%5Bcheck_status%5D=" + status;
  if (timestampAfter)
    requestUrl += "&filter%5Btimestamp_after%5D=" + timestampAfter;
  if (timestampBefore)
    requestUrl += "&filter%5Btimestamp_before%5D=" + timestampBefore;
  if (licensePlate) requestUrl += "&filter%5Bplate_value%5D=" + licensePlate;
  if (platesPermission === "unauthorized")
    requestUrl += "&filter%5Bplate_forbidden%5D=true";
  if (colors && colors.length > 0 && !colors.includes("all")) {
    requestUrl += "&filter%5Bcar_color%5D=";
    colors.forEach((color, idx) => {
      if (idx < colors.length - 1) requestUrl += `${color},`;
      else requestUrl += color;
    });
  }

  return await axiosRoot
    .get<BackendResponsePagination<LprLog>>(requestUrl, {
      headers: { paginate: pageSize },
    })
    .then((response) => response.data);
}

export async function getRadarLogs({
  page = 1,
  pageSize = 10000,
  camerasIds = "",
  status = "",
  timestampAfter = "",
  timestampBefore = "",
  licensePlate = "",
  speed = "",
}: GetRadarLogsParams) {
  let requestUrl = "/api/radar_log?page=" + page;

  // Montando a URL de requisicao para os filtros fornecidos
  if (camerasIds) requestUrl += "&filter%5Bcamera_id%5D=" + camerasIds;
  if (status) requestUrl += "&filter%5Bcheck_status%5D=" + status;
  if (timestampAfter)
    requestUrl += "&filter%5Btimestamp_after%5D=" + timestampAfter;
  if (timestampBefore)
    requestUrl += "&filter%5Btimestamp_before%5D=" + timestampBefore;
  if (licensePlate) requestUrl += "&filter%5Blicense_plate%5D=" + licensePlate;
  if (speed) requestUrl += "&filter%5Bvelocity%5D=" + speed;

  return await axiosRoot
    .get<BackendResponsePagination<RadarLog>>(requestUrl, {
      headers: { paginate: pageSize },
    })
    .then((response) => response.data)
    .catch((error) =>
      console.error("Erro na obtencao de ocorrencias de radar", error)
    );
}

export async function updateAnalyticLog(
  type: AnalyticInternalKey | AnalyticType,
  data: {
    check_status: string;
    check_description: string;
    velocity?: string | number;
    license_plate?: string;
  },
  id: string | number
) {
  let requestUrl = "";

  if (type === "agglomeration" || type === "Aglomeração")
    requestUrl += "/api/agglomeration_log/" + id;
  else if (type === "animal_intrusion" || type === "Detecção de Animal")
    requestUrl += "/api/animal_intrusion_log/" + id;
  else if (type === "vehicle_intrusion" || type === "Detecção de Veículo")
    requestUrl += "/api/vehicle_intrusion_log/" + id;
  else if (type === "evasion" || type === "Evasão")
    requestUrl += "/api/evasion_log/" + id;
  else if (type === "intrusion" || type === "Intrusão")
    requestUrl += "/api/intrusion_log/" + id;
  else if (type === "vehicle_agglomeration" || type === "Congestionamento")
    requestUrl += "/api/vehicle_agglomeration_log/" + id;
  else if (type === "vehicle_evasion" || type === "Ausência de Veículo")
    requestUrl += "/api/vehicle_evasion_log/" + id;
  else if (type === "Radar") requestUrl += "/api/radar_log/" + id;
  else if (type === "inactivity" || type === "Inatividade") {
    requestUrl += "/api/inactivity_log/" + id;
  } else if (type === "panic" || type === "Pânico")
    requestUrl += "/api/panic_log/" + id;
  else if (type === "permanence" || type === "Permanência")
    requestUrl += "/api/permanence_log/" + id;
  else if (type === "Lpr") requestUrl += "/api/lpr_log/" + id;
  else throw new Error("Tipo de analitico nao definido");

  return await axiosRoot
    .put(requestUrl, data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
