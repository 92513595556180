import { TextField, MenuItem, IconButton, Tooltip } from "@mui/material";
import { BackendResponsePagination } from "interfaces/generic";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import React from "react";

export default function MobilePagination({
  page,
  pageSize,
  data,
  defaultPageSize,
  onPageChange,
  onPageSizeChange,
}: {
  page: number;
  pageSize: number;
  defaultPageSize: number;
  data: BackendResponsePagination<any> | undefined;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}) {
  return (
    <div className="flex items-center space-x-2">
      <TextField
        select
        size="small"
        variant="standard"
        sx={{ minWidth: "45px" }}
        value={pageSize.toString()}
        aria-label="Resultados por página"
        onChange={(ev) => onPageSizeChange(parseInt(ev.target.value))}
      >
        <MenuItem value={defaultPageSize.toString()}>
          {defaultPageSize}
        </MenuItem>
        <MenuItem value="25">25</MenuItem>
        <MenuItem value="50">50</MenuItem>
        <MenuItem value="100">100</MenuItem>
      </TextField>
      <p>
        {1 + pageSize * (page === 0 ? 0 : page)}-
        {Math.min(pageSize * (page + 1), data?.meta.total || 0)} de{" "}
        {data?.meta.total || 0}
      </p>
      <Tooltip title="Página anterior" placement="top">
        <span>
          <IconButton
            onClick={() => onPageChange(page - 1)}
            disabled={page < 1}
          >
            <ChevronLeft />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Próxima página" placement="top">
        <span>
          <IconButton
            onClick={() => onPageChange(page + 1)}
            disabled={!data || page + 1 === data.meta.last_page}
          >
            <ChevronRight />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
