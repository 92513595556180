import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { basePort, baseUrl } from "api/rootConfig";
import PasswordInput from "components/PasswordInput/PasswordInput";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  app_url: z.string(),
  pending_email: z.string().min(1, "Campo obrigatório").email("Email inválido"),
  current_password: z.string().min(1, "Campo obrigatório"),
});

export type NewEmail = z.infer<typeof formSchema>;
const baseUrlObject = new URL(baseUrl);

export default function ChangeEmailDialog({
  open,
  isSubmitting,
  onClose,
  onSubmit,
}: {
  open: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (data: NewEmail) => Promise<void>;
}) {
  const form = useForm<NewEmail>({
    defaultValues: {
      app_url: baseUrlObject.hostname + (basePort ? `:${basePort}` : ""),
      current_password: "",
      pending_email: "",
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (ev: React.FormEvent<HTMLFormElement>) =>
          form.handleSubmit(async (data) => {
            ev.preventDefault();
            await onSubmit(data);
            form.reset();
          }),
      }}
    >
      <DialogTitle>Alteração de email</DialogTitle>
      <DialogContent className="flex flex-col space-y-3">
        <TextField
          label="Novo email"
          size="small"
          margin="dense"
          inputProps={{ ...form.register("pending_email") }}
          error={!!form.formState.errors.pending_email}
          helperText={form.formState.errors.pending_email?.message}
        />
        <PasswordInput
          size="small"
          label="Senha atual"
          inputProps={{ ...form.register("current_password") }}
          error={!!form.formState.errors.current_password}
          helperText={form.formState.errors.current_password?.message}
        />
      </DialogContent>
      <DialogActions>
        <Button type="button" color="error" onClick={onClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={form.handleSubmit(async (data) => {
            await onSubmit(data);
            form.reset();
          })}
        >
          Alterar email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
