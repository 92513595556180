import { useMediaQuery, useTheme } from "@mui/material";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import React, { CSSProperties, useMemo } from "react";

export const defaultGridContainerMaxHeight = 530;

export default function DataGridContainer({
  containerStyle,
  children,
}: {
  children: React.ReactNode;
  containerStyle?: CSSProperties;
}) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const { isClassic } = useHeimdallTheme();

  const defaultContainerStyle: CSSProperties = useMemo(() => {
    return {
      padding: sm ? "8px" : "20px",
      height: `${defaultGridContainerMaxHeight}px`,
      borderRadius: "12px",
      backgroundColor: isClassic
        ? "var(--root-color-primary-container)"
        : "var(--root-color-surface)",
      boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)",
    };
  }, [isClassic, sm]);

  return (
    <div
      style={
        { ...defaultContainerStyle, ...containerStyle } || defaultContainerStyle
      }
    >
      {children}
    </div>
  );
}
