import { MonitoringMessage } from "interfaces/monitoring";
import { isPlatform } from "@ionic/react";

export function notify(
  message = "Você habilitou o recebimento de notificações",
) {
  if (isPlatform("mobile")) return;
  if (!("Notification" in window) && !isPlatform("mobile")) {
    alert("Navegador não suporta notificações");
  } else if (Notification.permission === "granted") {
    new Notification(message);
  } else if (Notification.permission === "default") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        new Notification(message);
      }
    });
  }
}

export function occurrenceNotificationMessage(occurrence: MonitoringMessage) {
  return `${occurrence.occurrence} na câmera ${occurrence.camera.camera_name}`;
}
