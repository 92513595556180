import { Dayjs } from "dayjs";
import { HeimdallCamera, HeimdallCameraGroup } from "./heimdallCamera";
import { GetLogsParams } from "api/logs";
import { AnalyticType } from "./analytics";

export enum SpecificLogUrlPath {
  all = "all",
  agglomeration = "agglomeration",
  evasion = "evasion",
  inactivity = "inactivity",
  intrusion = "intrusion",
  permanence = "permanence",
  vehicle_agglomeration = "vehicle-agglomeration",
  vehicle_evasion = "vehicle-evasion",
  vehicle_intrusion = "vehicle-intrusion",
  lpr = "lpr",
  radar = "radar",
  panic = "panic",
  animal = "animal",
}

export const allLogs: readonly AnalyticType[] = [
  "Aglomeração",
  "Intrusão",
  "Evasão",
  "Inatividade",
  "Permanência",
  "Detecção de Animal",
  "Detecção de Veículo",
  "Congestionamento",
  "Ausência de Veículo",
  "Lpr",
  "Radar",
  "Pânico",
] as const;

const logsPaths = [
  "/log/agglomeration",
  "/log/intrusion",
  "/log/evasion",
  "/log/inactivity",
  "/log/permanence",
  "/log/animal",
  "/log/panic",
  "/log/vehicle-evasion",
  "/log/vehicle-intrusion",
  "/log/vehicle-agglomeration",
  "/log/radar",
  "/log/lpr",
] as const;

export function logPathFromAnalyticType(
  analyticType: AnalyticType
): SpecificLogUrlPath {
  switch (analyticType) {
    case "Intrus\u00E3o":
      return SpecificLogUrlPath.intrusion;
    case "Aglomera\u00E7\u00E3o":
      return SpecificLogUrlPath.agglomeration;
    case "Evas\u00E3o":
      return SpecificLogUrlPath.evasion;
    case "Inatividade":
      return SpecificLogUrlPath.inactivity;
    case "Radar":
      return SpecificLogUrlPath.radar;
    case "Detec\u00E7\u00E3o de Animal":
      return SpecificLogUrlPath.animal;
    case "Detec\u00E7\u00E3o de Ve\u00EDculo":
      return SpecificLogUrlPath.vehicle_intrusion;
    case "Aus\u00EAncia de Ve\u00EDculo":
      return SpecificLogUrlPath.vehicle_evasion;
    case "Congestionamento":
      return SpecificLogUrlPath.vehicle_agglomeration;
    case "P\u00E2nico":
      return SpecificLogUrlPath.panic;
    case "Lpr":
      return SpecificLogUrlPath.lpr;
    case "Perman\u00EAncia":
      return SpecificLogUrlPath.permanence;
    default:
      throw new Error(`Tipo de analítico ${analyticType} não definido`);
  }
}

export type CamerasAndGroups =
  | (Pick<HeimdallCamera, "id" | "name"> & {
      category: "Câmeras" | "Grupos";
    })
  | (HeimdallCameraGroup & {
      category: "Câmeras" | "Grupos";
    });

export interface FilterFormData {
  // Occurrence
  cameras?: CamerasAndGroups | null;
  status?: OccurrenceMark | "";
  dateAfter?: Dayjs | null;
  dateBefore?: Dayjs | null;
  order?: GetLogsParams["order"];
  // Radar
  licensePlate?: string;
  speed?: string;
  // Camera
  cameraName?: string;
  cameraLocation?: string;
  cameraStatus?: HeimdallCamera["status"] | "";
}

export type OccurrenceMark =
  | "visualized"
  | "false_positive"
  | "resident"
  | "effective"
  | "accidents"
  | "vandalism"
  | "whatsapp"
  | "headless"
  | "unattended";
