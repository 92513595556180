import React, { useEffect, useMemo, useState } from "react";
import {
  DispatcherFormError,
  canCreateDispatcher,
  convertDispatcherListDataToDispatcher,
  createDispatcherMiddleware,
  getEmptyDispatcher,
  getEmptyTrigger,
  getInitialErrorMessages,
} from "lib/alertSystem";
import {
  Dispatcher,
  DispatcherReceiverInfo,
  DispatcherReceiverTypes,
  NewDispatcherReceiver,
} from "interfaces/alertSystem";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AnalyticType } from "interfaces/analytics";
import { getDispatcher } from "api/alertSystem";
import { TimeField } from "@mui/x-date-pickers";
import { Alert, Button, MenuItem, TextField } from "@mui/material";
import MainContainer from "components/MainContainer/MainContainer";
import TriggerTransition from "components/dispatchers/TriggerTransition";
import DispatcherTrigger from "components/dispatchers/DispatcherTrigger";
import { Add, Save } from "@mui/icons-material";
import { Dayjs } from "dayjs";
import { getAvailableAnalytics } from "api/analytics";
import { AVAILABLE_ANALYTICS_QUERY_KEY } from "constants/apiQueryKeys";

export default function CreateDispatcher() {
  const params = useParams();
  const isEdit = useMemo(() => !!params.id, [params.id]);
  const navigate = useNavigate();
  const [dispatcher, setDispatcher] =
    useState<Dispatcher>(getEmptyDispatcher());
  const [errorMessages, setErrorMessages] = useState(getInitialErrorMessages());
  const { data: analyticTypes } = useQuery({
    queryKey: [AVAILABLE_ANALYTICS_QUERY_KEY],
    queryFn: () => getDispatcherAnalyticTypes(),
  });

  // Clears error messages when user changes a field
  useEffect(() => {
    setErrorMessages(getInitialErrorMessages());
  }, [dispatcher]);

  // Loads prev dispatcher info, for edit only
  useEffect(() => {
    async function fetchDispatcher() {
      const futureDispatcher = await getDispatcher(parseInt(params.id!));

      if (futureDispatcher) {
        const myFormattedDispatcher =
          convertDispatcherListDataToDispatcher(futureDispatcher);

        // console.log("MY formatted dispatcher,", myFormattedDispatcher);
        setDispatcher(myFormattedDispatcher);
      }
    }

    if (isEdit) fetchDispatcher();
  }, [isEdit, params.id]);

  function handleDispatcher(
    type: "duration" | "name" | "analyticType",
    payload: Dayjs | string | null
  ) {
    const newDispatcher = { ...dispatcher };

    if (type === "duration") {
      newDispatcher.duration = payload as Dayjs | null;
    } else if (type === "name") {
      newDispatcher.name = payload as string;
    } else if (type === "analyticType") {
      newDispatcher.analyticType = payload as AnalyticType | "";
    }

    setDispatcher(newDispatcher);
  }

  function handleTriggerTime(value: Dayjs | null, triggerIndex: number) {
    const newDispatcher = { ...dispatcher };
    newDispatcher.triggers[triggerIndex].analyticTriggerTime = value;
    setDispatcher(newDispatcher);
  }

  function handleTransition(
    type: "interval" | "maxNumber" | "usePermanence" | "useMaxNumber",
    triggerIndex: number,
    payload: Dayjs | number | boolean | null
  ) {
    const newDispatcher = { ...dispatcher };
    if (type === "interval") {
      newDispatcher.triggers[triggerIndex].transition.permanenceInterval =
        payload as Dayjs | null;
    } else if (type === "maxNumber") {
      newDispatcher.triggers[triggerIndex].transition.maxAlerts =
        payload as number;
    } else if (type === "useMaxNumber") {
      newDispatcher.triggers[triggerIndex].transition.useMaxAlerts =
        payload as boolean;
    } else if (type === "usePermanence") {
      newDispatcher.triggers[triggerIndex].transition.usePermanenceInterval =
        payload as boolean;
    }
    setDispatcher(newDispatcher);
  }

  function handleReceiver({
    info,
    type,
    triggerIndex,
    receiverIndex,
  }: {
    info?: DispatcherReceiverInfo;
    type: DispatcherReceiverTypes;
    triggerIndex: number;
    receiverIndex: number;
  }) {
    const newDispatcher = { ...dispatcher };
    if (type === "whatsapp") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          isVideo: info ? info.isVideo : false,
          number: info ? info.number : "",
        },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "whatsappGroup") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          isVideo: info ? info.isVideo : false,
          groupId: info ? info.groupId : "",
        },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "telegram") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          isVideo: info ? info.isVideo : false,
          id: info ? info.id.toString() : "",
        },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "email") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          address: info ? info.email : "",
          name: info ? info.emailName : "",
          id: -1,
        },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "user") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: { id: info ? info.id! : -1, name: info ? info.name : "Todos" },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "gear") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: null,
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "moni") {
      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          moniIntegrationId: info ? info.moniIntegrationId! : -1,
          clientId: info ? info.clientId! : "",
          companyId: info ? info.companyId! : "",
          partitionId: info ? info.partitionId! : "",
        },
      };
      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else if (type === "webhook") {
      const newReceiverInfo: any = { ...info };
      delete newReceiverInfo["id"];
      delete newReceiverInfo["name"];
      delete newReceiverInfo["number"];
      delete newReceiverInfo["groupId"];
      delete newReceiverInfo["isVideo"];
      delete newReceiverInfo["email"];
      delete newReceiverInfo["emailName"];
      delete newReceiverInfo["moniIntegrationId"];
      delete newReceiverInfo["clientId"];
      delete newReceiverInfo["partitionId"];
      delete newReceiverInfo["sectorId"];
      delete newReceiverInfo["companyId"];

      const newReceiver: NewDispatcherReceiver = {
        type,
        info: {
          ip: info ? info.ip : "",
          port: info ? info.port : "",
          body: info ? info.body : {},
          headers: info ? info.headers : {},
          endpoint: info ? info.endpoint : "",
          protocol: info ? info.protocol : "http",
        },
      };

      if (!info?.protocol) {
        // @ts-expect-error
        delete newReceiver.info.protocol;
      }

      newDispatcher.triggers[triggerIndex].receivers[receiverIndex] =
        newReceiver;
      setDispatcher(newDispatcher);
    } else {
      throw new Error("Receiver type not defined");
    }
  }

  function addReceiver(triggerIndex: number) {
    const newDispatcher = { ...dispatcher };
    newDispatcher.triggers[triggerIndex].receivers.push({
      type: "whatsapp",
      info: {
        isVideo: false,
        number: "",
      },
    });
    setDispatcher(newDispatcher);
  }

  function removeReceiver(triggerIndex: number, receiverIndex: number) {
    const newDispatcher = { ...dispatcher };
    newDispatcher.triggers[triggerIndex].receivers.splice(receiverIndex, 1);
    setDispatcher(newDispatcher);
  }

  function addTrigger() {
    const newDispatcher = { ...dispatcher };
    newDispatcher.triggers.push(getEmptyTrigger());
    setDispatcher(newDispatcher);
  }

  function removeTrigger(triggerIndex: number) {
    const newDispatcher = { ...dispatcher };
    newDispatcher.triggers.splice(triggerIndex, 1);
    setDispatcher(newDispatcher);
  }

  function handleDispatcherFormError(
    error: DispatcherFormError,
    triggerId: number
  ) {
    const newErrorMessages = [...errorMessages];
    // Assuming you want to display "Field cannot be empty" for each error
    newErrorMessages[triggerId][error] = "Campo inválido";
    setErrorMessages(newErrorMessages);
  }

  async function handleCreateDispatcher() {
    const dispatcherError = canCreateDispatcher(dispatcher);
    const hasNoError = dispatcherError.error === DispatcherFormError.noErrors;
    if (isEdit) {
      if (
        hasNoError &&
        (await createDispatcherMiddleware(dispatcher, parseInt(params.id!)))
      ) {
        return navigate("/dispatchers");
      }
      return handleDispatcherFormError(
        dispatcherError.error,
        dispatcherError.triggerIndex
      );
    }
    if (hasNoError && (await createDispatcherMiddleware(dispatcher))) {
      return navigate("/dispatchers");
    }
    handleDispatcherFormError(
      dispatcherError.error,
      dispatcherError.triggerIndex
    );
  }

  return (
    <MainContainer
      title={
        isEdit ? "Sistema de Alerta - Edição" : "Sistema de Alerta - Cadastro"
      }
      returnAction
    >
      <TextField
        select
        margin="dense"
        name="dispatcher-analytic-type"
        size="small"
        label="Tipo de analítico"
        value={dispatcher.analyticType}
        onChange={(ev) => handleDispatcher("analyticType", ev.target.value)}
      >
        {analyticTypes?.map((analyticType) => (
          <MenuItem key={analyticType} value={analyticType}>
            {analyticType}
          </MenuItem>
        ))}
      </TextField>
      {errorMessages[0][DispatcherFormError.type] && (
        <Alert severity="error" style={{ marginTop: "5px" }}>
          {errorMessages[0][DispatcherFormError.type]}
        </Alert>
      )}
      <TextField
        name="name"
        size="small"
        margin="dense"
        label="Nome"
        value={dispatcher.name}
        onChange={(ev) => handleDispatcher("name", ev.target.value)}
      />
      {errorMessages[0][DispatcherFormError.name] && (
        <Alert severity="error" style={{ marginTop: "5px" }}>
          {errorMessages[0][DispatcherFormError.name]}
        </Alert>
      )}
      <TimeField
        size="small"
        margin="dense"
        name="duration"
        format="HH:mm:ss"
        label="Duração do fluxo"
        value={dispatcher.duration}
        onChange={(value) => handleDispatcher("duration", value)}
        inputProps={{
          error: errorMessages[0][DispatcherFormError.maxCycleDuration] === "",
        }}
      />
      {errorMessages[0][DispatcherFormError.maxCycleDuration] && (
        <Alert severity="error" style={{ marginTop: "5px" }}>
          {errorMessages[0][DispatcherFormError.maxCycleDuration]}
        </Alert>
      )}
      <h2 className="text-xl my-2">Fluxo</h2>
      {dispatcher.triggers.map((trigger, triggerIndex, triggers) => (
        <React.Fragment key={triggerIndex}>
          <DispatcherTrigger
            triggers={triggers}
            removeTrigger={removeTrigger}
            triggerIndex={triggerIndex}
            dispatcher={dispatcher}
            trigger={trigger}
            handleTriggerTime={handleTriggerTime}
            errorMessages={errorMessages}
            removeReceiver={removeReceiver}
            addReceiver={addReceiver}
            handleReceiver={handleReceiver}
          />
          {triggerIndex !== triggers.length - 1 && (
            <TriggerTransition
              trigger={trigger}
              triggerIndex={triggerIndex}
              errorMessages={errorMessages}
              handleTransition={handleTransition}
            />
          )}
          {triggerIndex === triggers.length - 1 && (
            <>
              {dispatcher.analyticType !== "Lpr" && (
                <Button
                  startIcon={<Add />}
                  onClick={addTrigger}
                  style={{ marginBottom: "10px" }}
                >
                  Adicionar gatilho
                </Button>
              )}
              <Button
                variant="contained"
                startIcon={<Save />}
                onClick={handleCreateDispatcher}
              >
                {isEdit ? "Salvar" : "Criar sistema de alerta"}
              </Button>
            </>
          )}
        </React.Fragment>
      ))}
    </MainContainer>
  );
}

// Excludes Panic Analytic Type
async function getDispatcherAnalyticTypes() {
  return await getAvailableAnalytics().then((res) => {
    return res.filter((value) => value !== "Pânico");
  });
}
