import React from "react";
import { DeleteOutline, EditOutlined, LockOutlined } from "@mui/icons-material";
import { Avatar, Chip, Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { HeimdallOperator } from "interfaces/operator";
import { translateRole } from "lib/operators";
import { NavigateFunction } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";

export function getOperatorsColumns({
  navigate,
  setOpen,
  setOperator,
  setOpenPermissions,
}: {
  navigate: NavigateFunction;
  setOpen: (value: boolean) => void;
  setOperator: (value: HeimdallOperator) => void;
  setOpenPermissions: (value: boolean) => void;
}): GridColDef<HeimdallOperator>[] {
  return [
    {
      field: "userInfo",
      headerName: "Usuário",
      minWidth: 220,
      flex: 1,
      renderCell(params) {
        return (
          <div className="flex items-center space-x-3 h-full">
            <Avatar sx={{ width: 32, height: 32, bgcolor: deepPurple[500] }}>
              {params.row.name[0].toUpperCase()}
            </Avatar>
            <div className="flex flex-col justify-center">
              <Typography variant="subtitle2">{params.row.name}</Typography>
              <Typography variant="caption">{params.row.email}</Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "username",
      headerName: "Nome de usuário",
      minWidth: 150,
    },
    {
      field: "role",
      headerName: "Função",
      minWidth: 130,
      valueFormatter(value) {
        return translateRole(value);
      },
      renderCell(params) {
        return (
          <Chip
            label={params.formattedValue}
            color={
              params.value === "super-admin"
                ? "warning"
                : params.value === "manager"
                ? "primary"
                : "default"
            }
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Criado em",
      minWidth: 130,
      valueFormatter(value) {
        if (value)
          return dayjs(value, "DD/MM/YYYY HH:mm:ss").format("DD/MM/YYYY");
      },
    },
    {
      field: "last_access",
      headerName: "Último acesso em",
      valueFormatter(value) {
        if (!value) return "Nunca acessou";
        return dayjs(value, "YYYY-MM-DD HH:mm:ss").format(
          "DD/MM/YYYY HH:mm:ss"
        );
      },
      minWidth: 180,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      type: "actions",
      filterable: false,
      sortable: false,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditOutlined />}
          onClick={() => {
            navigate("edit-operator", { state: { id: params.id } });
          }}
          label="Editar operador"
          showInMenu
        />,
        <GridActionsCellItem
          disabled={params.row.role !== "collaborator"}
          icon={<LockOutlined />}
          onClick={() => {
            setOperator(params.row);
            setOpenPermissions(true);
          }}
          label="Gerenciar permissões"
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteOutline color="error" />}
          onClick={() => {
            setOperator(params.row);
            setOpen(true);
          }}
          label="Remover operador"
          showInMenu
        />,
      ],
    },
  ];
}
