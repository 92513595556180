import React from "react";
import { Typography } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { HeimdallDeviceInList, HeimdallDeviceTypes } from "interfaces/devices";
import {
  DeleteOutline,
  EditOutlined,
  ScienceOutlined,
} from "@mui/icons-material";

interface GetDevicesColumnsParams {
  setOpen: (value: boolean) => void;
  setOpenTest: (value: boolean) => void;
  setOpenEdit: (value: boolean) => void;
  setId: (value: number | string) => void;
  setDevice: (value: HeimdallDeviceInList) => void;
  setDeviceType: (value: HeimdallDeviceTypes) => void;
}

export function getDevicesColumns({
  setId,
  setOpen,
  setOpenTest,
  setOpenEdit,
  setDeviceType,
  setDevice,
}: GetDevicesColumnsParams): GridColDef<HeimdallDeviceInList>[] {
  return [
    {
      field: "name",
      headerName: "Nome",
      minWidth: 200,
    },
    {
      field: "type_model",
      headerName: "Tipo/Modelo",
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-around">
            <Typography variant="subtitle2">
              {params.row.device ? params.row.device : "---"}
            </Typography>
            <Typography variant="caption">
              {params.row.manufacturer !== "Unknown"
                ? params.row.manufacturer
                : "---"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "ip_port",
      headerName: "IP/Porta",
      minWidth: 150,
      flex: 1,
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-around">
            <Typography variant="subtitle2">{params.row.ip}</Typography>
            <Typography variant="caption">{params.row.port}</Typography>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          showInMenu
          icon={<ScienceOutlined />}
          label="Testar dispositivo"
          onClick={(ev) => {
            ev.stopPropagation();
            setId(String(params.id).split(" ")[0]);
            if (params.row.device === "Controladora") setDeviceType("commbox");
            else setDeviceType("horn_speaker");
            setOpenTest(true);
          }}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<EditOutlined />}
          label="Editar dispositivo"
          onClick={(ev) => {
            ev.stopPropagation();
            setId(String(params.id).split(" ")[0]);
            if (params.row.device === "Controladora") {
              setDeviceType("commbox");
            } else setDeviceType("horn_speaker");
            setOpenEdit(true);
          }}
        />,
        <GridActionsCellItem
          showInMenu
          icon={<DeleteOutline color="error" />}
          label="Remover dispositivo"
          onClick={(ev) => {
            ev.stopPropagation();
            setDevice(params.row);
            if (params.row.device === "Controladora") setDeviceType("commbox");
            else setDeviceType("horn_speaker");
            setId(String(params.id).split(" ")[0]);
            setOpen(true);
          }}
        />,
      ],
    },
  ];
}
