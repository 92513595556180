import React from "react";
import { MultipleStop } from "@mui/icons-material";
import { TimeField } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { DispatcherTrigger } from "interfaces/alertSystem";
import { DispatcherFormError } from "lib/alertSystem";
import {
  FormControlLabel,
  Switch,
  TextField,
  Alert,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";

export default function TriggerTransition({
  errorMessages,
  handleTransition,
  trigger,
  triggerIndex,
}: {
  trigger: DispatcherTrigger;
  handleTransition: (
    type: "interval" | "maxNumber" | "usePermanence" | "useMaxNumber",
    triggerIndex: number,
    payload: Dayjs | number | boolean | null
  ) => void;
  triggerIndex: number;
  errorMessages: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
  }[];
}) {
  return (
    <Card
      sx={{
        marginBottom: "10px",
        backgroundColor: "var(--root-color-surface)",
      }}
    >
      <CardHeader
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <MultipleStop />
            <h3 className="ml-2 text-xl">Transição</h3>
            {/* <Repeat /> */}
          </div>
        }
      />
      <CardContent style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "15px",
          }}
        >
          <FormControlLabel
            sx={{ marginLeft: "0px" }}
            control={
              <Switch
                name="usePermanenceInterval"
                checked={trigger.transition.usePermanenceInterval}
                onChange={(ev) =>
                  handleTransition(
                    "usePermanence",
                    triggerIndex,
                    ev.target.checked
                  )
                }
              />
            }
            labelPlacement="start"
            label="Próxima ocorrência antes de"
          />
          {trigger.transition.usePermanenceInterval && (
            <TimeField
              fullWidth
              size="small"
              format="mm:ss"
              label="Próxima ocorrência antes de (mm:ss)"
              value={trigger.transition.permanenceInterval}
              disabled={!trigger.transition.usePermanenceInterval}
              helperText={`Esse valor deve ser estritamente maior ou igual que 01:00 ${
                trigger.analyticTriggerTime &&
                trigger.analyticTriggerTime.isValid()
                  ? trigger.analyticTriggerTime.format(
                      "[e ao mesmo tempo ]mm:ss"
                    )
                  : ""
              }`}
              onChange={(value) =>
                handleTransition("interval", triggerIndex, value)
              }
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            sx={{ marginLeft: "0px" }}
            control={
              <Switch
                name="useMaxAlerts"
                checked={trigger.transition.useMaxAlerts}
                onChange={(ev) =>
                  handleTransition(
                    "useMaxNumber",
                    triggerIndex,
                    ev.target.checked
                  )
                }
              />
            }
            labelPlacement="start"
            label="Número de ocorrências maior que"
          />
          {trigger.transition.useMaxAlerts && (
            <TextField
              fullWidth
              type="number"
              size="small"
              label="Número de ocorrências maior que"
              value={trigger.transition.maxAlerts}
              disabled={!trigger.transition.useMaxAlerts}
              onChange={(ev) =>
                handleTransition(
                  "maxNumber",
                  triggerIndex,
                  parseInt(ev.target.value)
                )
              }
            />
          )}
        </div>
        {errorMessages[triggerIndex][DispatcherFormError.noTransition] ? (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            Pelo menos uma regra de transição deve ser utilizada
          </Alert>
        ) : errorMessages[triggerIndex][
            DispatcherFormError.transitionHasNull
          ] ? (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            O campo "Número máximo de incidência" foi preenchido incorretamente
          </Alert>
        ) : errorMessages[triggerIndex][
            DispatcherFormError.permanenceIntervalRule
          ] ? (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            O campo "Tempo mínimo entre alertas" foi preenchido incorretamente
          </Alert>
        ) : null}
      </CardContent>
    </Card>
  );
}
