import { HeimdallUser } from "interfaces/generic";
import { createContext } from "react";

interface ContextProps {
  user: HeimdallUser | null;
  setUser: (value: HeimdallUser | null) => void;
  baseURL: string;

  logout: () => Promise<void>;
}

const initialContext: ContextProps = {
  user: null,
  setUser: () => {},
  baseURL: "",
  logout: async () => {},
};

const GlobalContext = createContext(initialContext);

export default GlobalContext;
