import React from "react";

import { RadarLog } from "interfaces/analytics";
import { MonitoringMessage } from "interfaces/monitoring";
import EditRadarLogModal from "./EditRadarLogModal";
import EditLprLogModal from "./EditLprLogModal";
import EditGenericLogModal from "./EditGenericLogModal";

interface EditLogModalProps {
  title?: string;
  location?: string;
  log: RadarLog | MonitoringMessage;
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export default function EditLogModal({
  log,
  open,
  title,
  location,
  onClose,
  onSubmit,
}: EditLogModalProps) {
  if (log.occurrence === "Radar")
    return (
      <EditRadarLogModal
        log={log}
        open={open}
        title={title}
        reload={onSubmit}
        location={location}
        onClose={onClose}
      />
    );
  else if (log.occurrence === "Lpr")
    return (
      <EditLprLogModal
        log={log}
        open={open}
        title={title}
        reload={onSubmit}
        location={location}
        onClose={onClose}
      />
    );
  else
    return (
      <EditGenericLogModal
        log={log}
        open={open}
        title={title}
        onSubmit={onSubmit}
        location={location}
        onClose={onClose}
      />
    );
}
