import React from "react";
import { Dayjs } from "dayjs";
import { translateDayToPortuguese } from "lib/configureAnalytic";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  useTheme,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { ScheduleTimeForm, TimeRangeForm } from "interfaces/heimdallCamera";
import TimeRangePicker from "components/TimeRangePicker/TimeRangePicker";

interface TimeScheduleProps {
  checked: boolean[];
  timeSchedule: ScheduleTimeForm;
  setTimeScheduleError: (value: boolean) => void;
  handleDeleteTimeSchedule: (index: number, dayOfWeek: string) => void;
  handleAddTimeSchedule: (dayOfWeek: string) => void;
  handleSetChecked: (
    ev: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleChangeTimeSchedule: (
    dayOfWeek: string,
    index: number,
    timeRange: [Dayjs | null, Dayjs | null]
  ) => void;
}

export function TimeSchedule({
  checked,
  timeSchedule,
  handleSetChecked,
  setTimeScheduleError,
  handleAddTimeSchedule,
  handleChangeTimeSchedule,
  handleDeleteTimeSchedule,
}: TimeScheduleProps) {
  const theme = useTheme();

  return (
    <>
      {Object.entries(timeSchedule).map(([dayOfWeek, timeRanges], index) => (
        <div
          key={dayOfWeek}
          className="flex flex-col sm:flex-row py-2"
          style={{
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <FormControlLabel
            label={translateDayToPortuguese(dayOfWeek)}
            sx={{ width: "100px" }}
            control={
              <Checkbox
                checked={checked[index + 1]}
                onChange={(ev) => handleSetChecked(ev, index + 1)}
              />
            }
          />
          <TimeRangeList
            checked={checked}
            timeRangeIdx={index}
            dayOfWeek={dayOfWeek}
            timeRanges={timeRanges}
            setTimeScheduleError={setTimeScheduleError}
            handleAddTimeSchedule={handleAddTimeSchedule}
            handleChangeTimeSchedule={handleChangeTimeSchedule}
            handleDeleteTimeSchedule={handleDeleteTimeSchedule}
          />
        </div>
      ))}
    </>
  );
}

function TimeRangeList({
  checked,
  dayOfWeek,
  timeRanges,
  timeRangeIdx,
  setTimeScheduleError,
  handleAddTimeSchedule,
  handleChangeTimeSchedule,
  handleDeleteTimeSchedule,
}: {
  timeRanges: TimeRangeForm[];
  timeRangeIdx: number;
  checked: boolean[];
  dayOfWeek: string;
  setTimeScheduleError: (value: boolean) => void;
  handleDeleteTimeSchedule: (index: number, dayOfWeek: string) => void;
  handleAddTimeSchedule: (dayOfWeek: string) => void;
  handleChangeTimeSchedule: (
    dayOfWeek: string,
    index: number,
    timeRange: [Dayjs | null, Dayjs | null]
  ) => void;
}) {
  return (
    <div className="flex flex-1 flex-col justify-end">
      {timeRanges.map((timeRange, idx) => {
        return (
          <div key={idx} className="flex flex-1">
            <TimeRangePicker
              disabled={!checked[timeRangeIdx + 1]}
              value={{
                startTime: timeRange.initTime,
                endTime: timeRange.stopTime,
              }}
              // fieldsWidth={isXs ? "90px" : ""}
              // second arrow function solves internal onChange bug
              onChange={(value) => {
                setTimeScheduleError(false);
                handleChangeTimeSchedule(
                  dayOfWeek,
                  idx,
                  value ? [value.startTime, value.endTime] : [null, null]
                );
              }}
            />
            {/* {isPlatform("mobile") && !isPlatform("mobileweb") ? (
              <TimeRangePicker />
            ) : (
              <RangePicker
                format="HH:mm"
                placeholder={["HH:mm início", "HH:mm fim"]}
                disabled={!checked[timeRangeIdx + 1]}
                order={false}
                value={[timeRange.initTime, timeRange.stopTime]}
                onChange={(values) => {
                  setTimeScheduleError(false);
                  handleChangeTimeSchedule(
                    dayOfWeek,
                    idx,
                    values
                      ? (values as [Dayjs | null, Dayjs | null])
                      : [null, null]
                  );
                }}
              />
            )} */}
            {idx === 0 ? (
              <IconButton
                disabled={!checked[timeRangeIdx + 1]}
                onClick={() => handleAddTimeSchedule(dayOfWeek)}
              >
                <Add />
              </IconButton>
            ) : (
              <IconButton
                disabled={!checked[timeRangeIdx + 1]}
                onClick={() => handleDeleteTimeSchedule(idx, dayOfWeek)}
              >
                <Remove
                  color={!checked[timeRangeIdx + 1] ? "disabled" : "error"}
                />
              </IconButton>
            )}
          </div>
        );
      })}
    </div>
  );
}
