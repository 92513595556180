import * as React from "react";

function HeimdallShieldMinimal(props: React.ComponentProps<"svg">) {
  return (
    <svg
      width="35.894318mm"
      height="37.186409mm"
      viewBox="0 0 35.894318 37.186409"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M170 492h46v53h-46z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="b">
          <path d="M197.191 544.645c-15.617.285-24.722-6-26.308-19.875 25.316-.153 39.012-12.043 44.082-32.723 1.605 21.496-2.801 39.906-17.774 52.598" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="c">
          <path d="M0 0h613v793H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="d">
          <path d="M138 505h38v58h-38z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="e">
          <path d="M138.855 520.219c7.7-13.59 17.743-18.235 30.493-12.543-12.743 21.875-9.469 39.715 5.757 54.594-19.324-9.547-32.933-22.704-36.25-42.051" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="f">
          <path d="M0 0h613v793H0z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="g">
          <path d="M134 477h61v37h-61z" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="h">
          <path d="M189.09 480.445c8.074 13.371 7.199 24.403-4.012 32.727-12.82-21.824-29.976-27.719-50.41-21.738 17.797-12.164 35.934-17.582 54.422-10.989" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="i">
          <path d="M0 0h613v793H0z" />
        </clipPath>
      </defs>
      <g fill="none" stroke="#000" strokeDasharray="none" strokeOpacity={1}>
        <g
          clipPath="url(#a)"
          transform="translate(-327.458 -105.986) matrix(.43569 0 0 .43569 269.388 -102.103)"
          strokeWidth={2.83689}
        >
          <g clipPath="url(#b)">
            <g
              clipPath="url(#c)"
              fill="none"
              stroke="#000"
              strokeWidth={2.83689}
              strokeDasharray="none"
              strokeOpacity={1}
            >
              <path
                d="M197.191 544.645c-15.617.285-24.722-6-26.308-19.875 25.316-.153 39.012-12.043 44.082-32.723 1.605 21.496-2.801 39.906-17.774 52.598"
                fill="none"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={2.83689}
                strokeDasharray="none"
                strokeOpacity={1}
              />
            </g>
          </g>
        </g>
        <path
          d="M363.044 112.276c-2.408-2.078-5.264-3.404-8.761-3.649 3.966 8.34.457 14.3-10.444 17.906 11.019-.067 16.992-5.264 19.205-14.257"
          fillOpacity={1}
          fillRule="evenodd"
          strokeWidth={0.636}
          transform="translate(-327.458 -105.986)"
        />
        <g
          clipPath="url(#d)"
          transform="translate(-327.458 -105.986) matrix(.43569 0 0 .43569 269.388 -102.103)"
          strokeWidth={2.83689}
        >
          <g clipPath="url(#e)">
            <g
              clipPath="url(#f)"
              fill="none"
              stroke="#000"
              strokeWidth={2.83689}
              strokeDasharray="none"
              strokeOpacity={1}
            >
              <path
                d="M138.855 520.219c7.7-13.59 17.743-18.235 30.493-12.543-12.743 21.875-9.469 39.715 5.757 54.594-19.324-9.547-32.933-22.704-36.25-42.051"
                fill="none"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={2.83689}
                strokeDasharray="none"
                strokeOpacity={1}
              />
            </g>
          </g>
        </g>
        <path
          d="M345.679 142.871c3.016-1.017 5.61-2.801 7.6-5.688-9.2-.827-12.547-6.879-10.108-18.098-5.545 9.52-4.109 17.307 2.508 23.786"
          fillOpacity={1}
          fillRule="evenodd"
          strokeWidth={0.636}
          transform="translate(-327.458 -105.986)"
        />
        <g
          clipPath="url(#g)"
          transform="translate(-327.458 -105.986) matrix(.43569 0 0 .43569 269.388 -102.103)"
          strokeWidth={2.83689}
        >
          <g clipPath="url(#h)">
            <g
              clipPath="url(#i)"
              fill="none"
              stroke="#000"
              strokeWidth={2.83689}
              strokeDasharray="none"
              strokeOpacity={1}
            >
              <path
                d="M189.09 480.445c8.074 13.371 7.199 24.403-4.012 32.727-12.82-21.824-29.976-27.719-50.41-21.738 17.797-12.164 35.934-17.582 54.422-10.989"
                fill="none"
                fillRule="evenodd"
                stroke="#000"
                strokeWidth={2.83689}
                strokeDasharray="none"
                strokeOpacity={1}
              />
            </g>
          </g>
        </g>
        <path
          d="M328.061 112.009c-.59 3.128-.306 6.263 1.236 9.411 5.228-7.613 12.143-7.565 20.727.06-5.58-9.499-13.072-12.063-21.963-9.471"
          fillOpacity={1}
          fillRule="evenodd"
          strokeWidth={0.636}
          transform="translate(-327.458 -105.986)"
        />
      </g>
    </svg>
  );
}

export default HeimdallShieldMinimal;
