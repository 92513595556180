import React, { useEffect, useState } from "react";
import {
  HeimdallDeviceInList,
  HeimdallDeviceTypes,
  HeimdallDeviceTypesPretty,
} from "interfaces/devices";
import { Badge, Button, MenuItem, TextField } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { getDevices, deleteDevice, editDevice } from "api/ioDevices";
import { useNavigate } from "react-router-dom";
import EditDeviceDialog from "components/Modals/EditDeviceDialog";
import TestDeviceDialog from "components/Modals/TestDeviceDialog";
import MainContainer from "components/MainContainer/MainContainer";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";
import { Add, FilterList, Search } from "@mui/icons-material";
import ExpandableContainer from "components/ExpandableContainer";
import { getDevicesColumns } from "./getDevicesColumns";
import DeleteDialog from "components/Modals/DeleteDialog";
import { getDeviceTypeFromDevicePrettyType } from "lib/devices";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

export default function IODevices() {
  const { toast } = useToast();
  // Data grid state
  const [devices, setDevices] = useState<HeimdallDeviceInList[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  // Modal state
  const [device, setDevice] = useState<HeimdallDeviceInList>();
  const [id, setId] = useState<string | number>();
  const [deviceType, setDeviceType] = useState<HeimdallDeviceTypes>();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const navigate = useNavigate();
  // Filter state
  const [nameFilter, setNameFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState<HeimdallDeviceTypesPretty | "">(
    ""
  );
  // const [modelFilter, setModelFilter] = useState<HeimdallDeviceModels | "">("");
  const [activeFilters, setActiveFilters] = useState(0);

  // Fetching initial page data
  useEffect(() => {
    async function getData() {
      const newDevices = await getDevices();

      if (newDevices && newDevices.data) setDevices(newDevices.data);

      setIsLoading(false);
    }

    getData();
  }, []);

  function getActiveFilters() {
    let totalActiveFilters = 0;

    if (nameFilter) totalActiveFilters += 1;
    if (typeFilter) totalActiveFilters += 1;
    // if (modelFilter) totalActiveFilters += 1;

    return totalActiveFilters;
  }

  async function filter() {
    setIsLoading(true);

    const newDevices = await getDevices({
      name: nameFilter,
      type: typeFilter ? typeFilter : undefined,
      // model: modelFilter ? modelFilter : undefined,
    });

    if (newDevices) {
      setDevices(newDevices.data);
      setActiveFilters(getActiveFilters());
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }

    setIsLoading(false);
  }

  async function clearFilters() {
    setIsLoading(true);

    const newDevices = await getDevices();

    if (newDevices) {
      setTypeFilter("");
      // setModelFilter("");
      setNameFilter("");
      setActiveFilters(0);
      setDevices(newDevices.data);
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    }

    setIsLoading(false);
  }

  async function handleEditDevice(
    deviceType: HeimdallDeviceTypes,
    id: string | number,
    data: {
      name: string;
      ip: string;
      port: number | string;
      user: string;
      password: string;
    }
  ) {
    await editDevice(deviceType, id, data)
      .then(async () => {
        setIsLoading(true);

        const newDevices = await getDevices({
          name: nameFilter,
          type: typeFilter ? typeFilter : undefined,
          // model: modelFilter ? modelFilter : undefined,
        });

        if (newDevices && newDevices.data) setDevices(newDevices.data);

        setIsLoading(false);
        toast({
          description: "Dispositivo editado com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao editar dispositivo",
          description: getErrorMessage(err),
        });
      });
  }

  async function handleDelete() {
    if (device) {
      const response = await deleteDevice(
        getDeviceTypeFromDevicePrettyType(device.device),
        device.id
      );

      if (response) {
        toast({
          description: "Dispositivo removido com sucesso",
        });
        setIsLoading(true);
        const devices = await getDevices({
          name: nameFilter,
          // model: modelFilter ? modelFilter : undefined,
          type: typeFilter ? typeFilter : undefined,
        });
        if (devices && devices.data) setDevices(devices.data);
        setIsLoading(false);
      } else {
        toast({
          variant: "destructive",
          title: "Erro ao remover dispositivo",
          description: "Um erro inesperado ocorreu",
        });
      }

      setOpen(false);
    }
  }

  return (
    <>
      <MainContainer
        title="Dispositivos I/O"
        responsiveActions={[
          {
            title: "Novo dispositivo",
            icon: <Add />,
            action: () => navigate("create-device"),
          },
        ]}
      >
        <ExpandableContainer
          title="Filtros avançados"
          icon={
            <>
              {activeFilters > 0 ? (
                <Badge
                  color="secondary"
                  badgeContent={activeFilters}
                  style={{ position: "absolute", top: "0px", left: "20px" }}
                />
              ) : null}
              <FilterList color="primary" />
            </>
          }
        >
          <div style={{ display: "flex" }}>
            <TextField
              size="small"
              label="Nome"
              margin="dense"
              value={nameFilter}
              sx={{ flex: 1, marginRight: "10px" }}
              onChange={(ev) => setNameFilter(ev.target.value)}
            />
            <TextField
              select
              size="small"
              label="Tipo"
              margin="dense"
              value={typeFilter}
              sx={{ flex: 1 }}
              onChange={(ev) =>
                setTypeFilter(ev.target.value as HeimdallDeviceTypesPretty | "")
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Controladora">Controladora</MenuItem>
              <MenuItem value="Alto Falante">Alto falante</MenuItem>
            </TextField>
            {/* <TextField
              select
              size="small"
              label="Modelo"
              margin="dense"
              value={modelFilter}
              sx={{ flex: 1 }}
              onChange={(ev) =>
                setModelFilter(ev.target.value as HeimdallDeviceModels | "")
              }
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Commbox">Commbox</MenuItem>
              <MenuItem value="Niobox">Niobox</MenuItem>
              <MenuItem value="Khomp">Alto falante</MenuItem>
            </TextField> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              color="error"
              onClick={clearFilters}
              style={{ marginRight: "10px" }}
            >
              Limpar filtros
            </Button>
            <Button variant="outlined" startIcon={<Search />} onClick={filter}>
              Buscar
            </Button>
          </div>
        </ExpandableContainer>
        <BorderedDataGrid
          withContainer
          rows={devices}
          columns={getDevicesColumns({
            setId,
            setOpen,
            setDevice,
            setOpenTest,
            setOpenEdit,
            setDeviceType,
          })}
          getRowId={(row) => row.id + " " + row.name}
          loading={isLoading}
          slots={{ toolbar: GridToolbar }}
          paginationModel={paginationModel}
          disableRowSelectionOnClick
          onPaginationModelChange={(model) => {
            setIsLoading(true);
            setPaginationModel({
              page: model.page,
              pageSize: model.pageSize,
            });
            setIsLoading(false);
          }}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </MainContainer>
      <DeleteDialog
        open={open}
        onSubmit={handleDelete}
        onClose={() => setOpen(false)}
        title={`Tem certeza que deseja remover o dispositivo ${device?.name}?`}
      />
      <EditDeviceDialog
        id={id}
        open={openEdit}
        deviceType={deviceType}
        onSubmit={handleEditDevice}
        setOpen={setOpenEdit}
      />
      <TestDeviceDialog
        id={id}
        open={openTest}
        setOpen={setOpenTest}
        deviceType={deviceType}
      />
    </>
  );
}
