import React from "react";
import {
  Dispatcher,
  DispatcherReceiverInfo,
  DispatcherReceiverTypes,
  DispatcherTrigger as DispatcherTriggerI,
} from "interfaces/alertSystem";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { cn } from "lib/classNames";
import { DispatcherFormError } from "lib/alertSystem";
import { Delete, NewReleases } from "@mui/icons-material";
import { TimeField } from "@mui/x-date-pickers";
import DispatcherReceiver from "components/dispatchers/dispatcherReceiver/DispatcherReceiver";
import { Dayjs } from "dayjs";

export default function DispatcherTrigger({
  trigger,
  triggers,
  dispatcher,
  triggerIndex,
  errorMessages,
  handleTriggerTime,
  handleReceiver,
  removeReceiver,
  removeTrigger,
  addReceiver,
}: {
  trigger: DispatcherTriggerI;
  triggers: DispatcherTriggerI[];
  dispatcher: Dispatcher;
  triggerIndex: number;
  errorMessages: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
    9: string;
  }[];
  handleReceiver: ({
    info,
    type,
    triggerIndex,
    receiverIndex,
  }: {
    info?: DispatcherReceiverInfo;
    type: DispatcherReceiverTypes;
    triggerIndex: number;
    receiverIndex: number;
  }) => void;
  addReceiver: (triggerIndex: number) => void;
  removeTrigger: (triggerIndex: number) => void;
  removeReceiver: (triggerIndex: number, receiverIndex: number) => void;
  handleTriggerTime: (value: Dayjs | null, triggerIndex: number) => void;
}) {
  return (
    <Card
      sx={{
        marginBottom: "10px",
        backgroundColor: "var(--root-color-surface)",
      }}
    >
      <CardHeader
        action={
          triggers.length > 1 && (
            <IconButton onClick={() => removeTrigger(triggerIndex)}>
              <Delete color="error" />
            </IconButton>
          )
        }
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <NewReleases />
            <h3 className="ml-2 text-xl">Gatilho {triggerIndex + 1}</h3>
          </div>
        }
      />
      <CardContent>
        {dispatcher.analyticType !== "Intrusão" && (
          <>
            <TimeField
              fullWidth
              size="small"
              format="mm:ss"
              value={trigger.analyticTriggerTime}
              onChange={(value) => handleTriggerTime(value, triggerIndex)}
              label="Tempo para acionamento do analítico"
            />
            {errorMessages[triggerIndex][
              DispatcherFormError.analyticTriggerTime
            ] && (
              <Alert severity="error" style={{ marginTop: "5px" }}>
                {
                  errorMessages[triggerIndex][
                    DispatcherFormError.analyticTriggerTime
                  ]
                }
              </Alert>
            )}
          </>
        )}
        <h3
          className={cn(
            dispatcher.analyticType === "Intrusão" ? "mb-4" : "my-4"
          )}
        >
          Destinatários
        </h3>
        {trigger.receivers.map((receiver, receiverIndex, receivers) => (
          <DispatcherReceiver
            key={receiverIndex}
            value={receiver}
            onRemove={
              receiverIndex === 0
                ? undefined
                : () => removeReceiver(triggerIndex, receiverIndex)
            }
            onAdd={
              receiverIndex === receivers.length - 1
                ? () => addReceiver(triggerIndex)
                : undefined
            }
            onChange={(type, info) =>
              handleReceiver({
                type,
                info,
                triggerIndex,
                receiverIndex,
              })
            }
          />
        ))}
        {errorMessages[triggerIndex][
          DispatcherFormError.noMoniIntegrationId
        ] && (
          <Alert severity="error" style={{ marginTop: "5px" }}>
            O campo 'Integração moni' deve ser preenchido caso queira utilizar
            um destinatário do tipo moni
          </Alert>
        )}
        {errorMessages[triggerIndex][DispatcherFormError.emptyReceivers] && (
          <Alert severity="error" style={{ marginTop: "5px" }}>
            Erro no preenchimento de destinatários
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}
