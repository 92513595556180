import React, { useEffect, useMemo } from "react";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { IonFab, IonFabButton, IonFabList } from "@ionic/react";
import { Add, ArrowBack } from "@mui/icons-material";
import styles from "./MainContainer.module.css";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

type MainContainerAction = {
  title: string;
  icon: JSX.Element;
  isSecondary?: boolean;
  action: () => void;
};

interface MainContainerProps {
  title?: string | React.ReactNode;
  actions?: React.JSX.Element;
  responsiveActions?: MainContainerAction[];
  style?: React.CSSProperties;
  children?: React.ReactNode;
  returnAction?: boolean | React.JSX.Element;
  className?: string;
}

export default function MainContainer({
  children,
  actions,
  title,
  className,
  style,
  returnAction,
  responsiveActions,
}: MainContainerProps) {
  const navigate = useNavigate();
  const theme = useHeimdallTheme();
  const isSm = useMediaQuery("(max-width: 640px)");
  const showResponsiveActions = useMemo(
    () => responsiveActions && responsiveActions.length > 0 && isSm,
    [responsiveActions, isSm]
  );

  useEffect(() => {
    if (!!title && typeof title === "string") {
      document.title = title + " | Analíticos IA";
    }
  }, [title]);

  return (
    <div
      id="main-container"
      className={`${styles.container} ${
        theme.isDark ? "surface-variant" : "surface"
      } ${className || ""}`}
      style={style}
    >
      {title && (
        <header className="sticky top-0 z-20 flex justify-between bg-inherit border-b sm:border-none sm:pb-2">
          <div className="flex items-center space-x-2 flex-1">
            {!!returnAction ? (
              typeof returnAction === "boolean" ? (
                <IconButton color="secondary" onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              ) : (
                returnAction
              )
            ) : null}
            {typeof title === "string" ? (
              <h1 className="text-lg sm:text-xl max-w-xl line-clamp-2">
                {title}
              </h1>
            ) : (
              title
            )}
          </div>
          {!!actions && !isSm && <div>{actions}</div>}
          {!!responsiveActions && !isSm && (
            <div className="inline-flex space-x-2">
              {responsiveActions.map((action, idx) => (
                <Button
                  key={idx}
                  color="secondary"
                  startIcon={action.icon}
                  onClick={action.action}
                  variant={action.isSecondary ? "outlined" : "contained"}
                >
                  {action.title}
                </Button>
              ))}
            </div>
          )}
        </header>
      )}
      <div id="main-container-scroll-area" className={styles.scrollArea}>
        <section className={styles.content}>{children}</section>
      </div>
      {showResponsiveActions && responsiveActions!.length === 1 && (
        <IonFab vertical="bottom" horizontal="end">
          <IonFabButton
            className="size-12"
            onClick={responsiveActions![0].action}
          >
            <Add />
          </IonFabButton>
        </IonFab>
      )}
      {showResponsiveActions && responsiveActions!.length > 1 && (
        <IonFab vertical="bottom" horizontal="end">
          <IonFabButton className="size-12">
            <Add />
          </IonFabButton>
          <IonFabList side="top">
            {responsiveActions!.map((action, idx) => (
              <IonFabButton key={idx} onClick={action.action}>
                {action.icon}
              </IonFabButton>
            ))}
          </IonFabList>
        </IonFab>
      )}
    </div>
  );
}
