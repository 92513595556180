import React from "react";

interface NavLinkProps extends React.ComponentProps<"div"> {
  name?: string;
  img: React.ReactNode;
  hideText?: boolean;
}

function NavLink({
  name,
  img,
  hideText,
  ...props
}: NavLinkProps) {
  return (
    <div className="flex space-x-3 items-center" {...props}>
        {img}
        {name && !hideText && (
          <h5 style={{ marginLeft: "8px", fontSize: "16px" }}>{name}</h5>
        )}
    </div>
  );
}

export default NavLink;
