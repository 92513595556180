import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import styles from "./DrawCanvas.module.css";

export function RegionSelector({
  regions,
  showAll,
  selected,
  setShowAll,
  onAddCanvas,
  onDeleteCanvas,
  setCurrentCanvasId,
}: {
  regions: [number, number][][];
  selected: number;
  showAll: boolean;
  onAddCanvas: () => void;
  setShowAll: (value: boolean) => void;
  onDeleteCanvas: (value: number) => void;
  setCurrentCanvasId: (value: number) => void;
}) {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    index: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            index,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  };

  const handleDeleteCanvas = () => {
    onDeleteCanvas(contextMenu!.index);
    setContextMenu(null);
  };

  return (
    <section className={styles.regionSelect}>
      <Button
        className={styles.showAllButton}
        variant={showAll ? "outlined" : "text"}
        onClick={() => setShowAll(true)}
      >
        Todas
      </Button>
      {regions.map((_, index) => (
        <Tooltip
          key={index}
          placement="top"
          title="Clique com o botão direito para abrir o menu"
          enterDelay={1000}
        >
          <Button
            className={styles.regionButton}
            onContextMenu={(ev) => handleContextMenu(ev, index)}
            variant={!showAll && selected === index ? "outlined" : "text"}
            onClick={() => {
              setCurrentCanvasId(index);
              setShowAll(false);
            }}
          >
            R{index + 1}
          </Button>
        </Tooltip>
      ))}
      <IconButton color="primary" onClick={onAddCanvas}>
        <Add />
      </IconButton>
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleDeleteCanvas}>Remover</MenuItem>
      </Menu>
    </section>
  );
}
