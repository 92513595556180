import { BackendResponsePagination } from "interfaces/generic";
import { AnalyticType } from "interfaces/analytics";
import axiosRoot from "./rootConfig";
import { DispatcherListData } from "interfaces/alertSystem";

export async function createDispatcher(data: FormData) {
  return await axiosRoot
    .post("/api/alert_system", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
}

export async function getDispatcher(id: number) {
  return await axiosRoot
    .get<{ data: DispatcherListData }>("/api/alert_system/" + id)
    .then((response) => response.data.data)
    .catch((error) => console.error(error));
}

export async function putDispatcher(data: FormData, id: number) {
  return await axiosRoot
    .post("/api/alert_system/" + id + "?_method=PUT", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
}

export async function deleteDispatcher(id: number) {
  return await axiosRoot.delete("/api/alert_system/" + id);
}

export const getDispatchers = async ({
  page = 1,
  pageSize = 10,
  name,
  includeDefault = true,
}: {
  page?: number;
  pageSize?: number;
  name?: string;
  includeDefault?: boolean;
}): Promise<BackendResponsePagination<DispatcherListData> | void> => {
  let requestUrl = "/api/alert_system?page=" + page;

  if (!includeDefault) requestUrl += "&filter%5Bis_default%5D=false";
  if (name) requestUrl += "&filter%5Bname%5D=" + name;

  return await axiosRoot
    .get<BackendResponsePagination<DispatcherListData>>(requestUrl, {
      headers: {
        paginate: pageSize,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => console.error(error));
};

export async function putAlertAssociation(
  associationId: number,
  data: {
    alert_system_id: number;
  }
) {
  return await axiosRoot
    .put("/api/alert_system_analytic/" + associationId, data, {
      timeout: 20000,
    })
    .then((response) => response.data);
}
