import React, { useContext, useMemo } from "react";
import { getAwesomeName } from "lib/sidebar";
import { Link } from "react-router-dom";
import NavLink from "../NavLink/NavLink";
import { excludedSidebarPaths } from "routes";
import GlobalContext from "context/GlobalContext";
import { isPlatform } from "@ionic/react";
// Icons
import { Notes } from "@mui/icons-material";
import { getMatchingIcon } from "../getMatchingIcon";
// Stylesheet
import styles from "./SidebarForDrawer.module.css";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";

export default function SidebarForDrawer({
  closeDrawer,
}: {
  closeDrawer: () => void;
}) {
  const { user } = useContext(GlobalContext);
  const { images } = useHeimdallTheme();
  const mainRoutes = useMemo(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      return user?.company.plan.platform_routes.mobile.mainRoutes;
    }
    return user?.company.plan.platform_routes.web.mainRoutes;
  }, [user]);

  return (
    <div className="primary-container h-full w-64 py-3">
      {/* <MainActionButton /> */}
      <div className={styles.navContainer}>
        <img
          src={images.logoDark}
          className="mb-5"
          width="170px"
          height="auto"
          alt="logo compainha"
        />
        <ul className={styles.navMenu}>
          {mainRoutes?.map((mainRoute, idx) => {
            if (
              // @ts-expect-error Type string will always be a valid path, because everything comes from the enum HEIMDALL_ROUTE
              !excludedSidebarPaths(user?.role).includes(mainRoute.path) &&
              mainRoute.enabled
            ) {
              if (mainRoute.path === "log/:logType") {
                return (
                  <li key="logType" className={styles.navLink}>
                    <Link to="/log/all" onClick={closeDrawer}>
                      <NavLink
                        name="Ocorrências"
                        img={
                          <Notes
                            width={24}
                            height={24}
                            className={styles.navLinkIcon}
                          />
                        }
                      />
                    </Link>
                  </li>
                );
              }
              return (
                <li key={idx} className={styles.navLink}>
                  <Link to={mainRoute.path} onClick={closeDrawer}>
                    <NavLink
                      name={getAwesomeName(mainRoute.path)}
                      img={getMatchingIcon(mainRoute.path, true)}
                    />
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
      <p className={styles.zionsWatermark}>
        {/* <ZionsLogo width={22} style={{ marginRight: "5px" }} /> */}
        <a
          href="https://zionstech.com.br"
          style={{ color: "inherit" }}
          rel="noreferrer"
          target="_blank"
        >
          powered by zions vision
        </a>
      </p>
    </div>
  );
}
