import React, { useState, useContext } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import GlobalContext from "context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import styles from "./createPanic.module.css";
import { createPanicDevice } from "api/panic";
import MainContainer from "components/MainContainer/MainContainer";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

export default function CreatePanic() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [deviceType, setDeviceType] = useState<"hikvision" | "">("hikvision");
  const [deviceTypeError, setDeviceTypeError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [ip, setIP] = useState("");
  const [ipError, setIPError] = useState(false);
  // const [port, setPort] = useState("");
  // const [portError, setPortError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(GlobalContext);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  async function handleCreateDevice(ev: React.SyntheticEvent) {
    ev.preventDefault(); // prevents default behavior of form submitting
    if (!user) return;

    if (!deviceType) setDeviceTypeError(true);
    if (!name) setNameError(true);
    // if (!model) setModelError(true);
    if (!ip) setIPError(true);
    if (deviceType === "hikvision" && !username) setUsernameError(true);
    if (deviceType === "hikvision" && !password) setPasswordError(true);
    if (
      !deviceType ||
      !name ||
      //   !model ||
      !ip ||
      (deviceType === "hikvision" && !username) ||
      (deviceType === "hikvision" && !password)
    )
      return;

    const requestData = {
      name,
      ip,
      // port,
      user: username,
      password,
      manufacturer: deviceType,
      model: "DS-2CD3056G2-IS",
      remarks: "",
    };

    setLoading(true);

    await createPanicDevice(requestData)
      .then(() => navigate("/panic"))
      .catch((err) =>
        toast({
          variant: "destructive",
          title: "Erro na criação de dispositivo",
          description: getErrorMessage(err),
        })
      );
    setLoading(false);
  }

  return (
    <MainContainer title="Novo Dispositivo de Pânico" returnAction>
      <p
        style={{
          fontSize: "12px",
          color: "#9FA0A5",
          marginBottom: "20px",
        }}
      >
        Crie um novo dispositivo de pânico para vincular ao analítico
        configurado. O dispositivo deve ser ligado a uma placa controladora
        homologada na plataforma. Insira os dados nos campos, conforme o
        dispositivo.
      </p>
      <form className={styles.form} onSubmit={(ev) => handleCreateDevice(ev)}>
        <TextField
          required
          select
          size="small"
          value={deviceType}
          error={deviceTypeError}
          label="Modelo"
          helperText={
            deviceTypeError
              ? "Você deve selecionar um modelo de dispositivo"
              : ""
          }
          onChange={(ev) => {
            setDeviceTypeError(false);
            if (ev.target.value === "" || ev.target.value === "hikvision")
              setDeviceType(ev.target.value);
          }}
        >
          <MenuItem value="hikvision">Hikvision</MenuItem>
        </TextField>
        <TextField
          required
          label="Nome"
          size="small"
          value={name}
          error={nameError}
          helperText={nameError ? "Você deve fornecer um nome" : ""}
          onChange={(ev) => {
            setName(ev.target.value);
            setNameError(false);
          }}
        />
        <TextField
          required
          label="IP/DDNS"
          value={ip}
          size="small"
          error={ipError}
          helperText={ipError ? "Você deve fornecer um endereço de IP" : ""}
          onChange={(ev) => {
            setIP(ev.target.value);
            setIPError(false);
          }}
        />
        {/* <TextField
            label="Porta"
            value={port}
            size="small"
            error={portError}
            // helperText={portError ? "Você deve fornecer uma porta" : ""}
            onChange={(ev) => {
              setPort(ev.target.value);
              setPortError(false);
            }}
          /> */}
        <TextField
          required
          error={usernameError}
          helperText="Nome de usuário para acesso ao dispositivo"
          onChange={(ev) => {
            setUsername(ev.target.value);
            setUsernameError(false);
          }}
          label="Usuário"
          value={username}
          size="small"
        />
        <TextField
          required
          error={passwordError}
          value={password}
          helperText="Senha para acesso ao dispositivo"
          onChange={(ev) => {
            setPassword(ev.target.value);
            setPasswordError(false);
          }}
          label="Senha"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={!showPassword ? "password" : "text"}
          size="small"
        />
        <LoadingButton
          variant="contained"
          type="submit"
          loading={loading}
          onClick={handleCreateDevice}
        >
          Criar dispositivo
        </LoadingButton>
      </form>
    </MainContainer>
  );
}
