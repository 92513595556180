import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowForward } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { z } from "zod";
import { updateBaseUrl } from "api/rootConfig";
import { domainFromCompanyName } from "api/auth";
import { useToast } from "components/ui/use-toast";
import { LoadingButton } from "@mui/lab";

const formSchema = z.object({
  companyName: z.string().min(1, "Campo obrigatório"),
});

type CompanyAuthSchema = z.infer<typeof formSchema>;

export default function MobileCompanyForm({
  onSuccess,
}: {
  onSuccess: () => void;
}) {
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyAuthSchema>({
    defaultValues: {
      companyName: "",
    },
    resolver: zodResolver(formSchema),
  });
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(data: CompanyAuthSchema) {
    setIsLoading(true);
    await domainFromCompanyName(data.companyName)
      .then((res) => {
        updateBaseUrl(`https://${res.data.domain}`);
        onSuccess();
      })
      .catch(() => {
        toast({
          variant: "destructive",
          description: "Um erro inesperado ocorreu",
        });
      });
    setIsLoading(false);
  }

  return (
    <form className="flex flex-col space-y-3" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Nome da organização"
        error={!!errors.companyName}
        helperText={errors.companyName?.message}
        inputProps={{
          ...register("companyName"),
        }}
      />
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isLoading}
        startIcon={<ArrowForward />}
      >
        Continuar
      </LoadingButton>
    </form>
  );
}
