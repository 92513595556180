import { ArrowForward } from "@mui/icons-material";
import { TimePickerProps } from "@mui/lab";
import { TimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

type TimeRangeValue = {
  startTime: Dayjs | null;
  endTime: Dayjs | null;
};

interface TimeRangePickerProps
  extends Omit<TimePickerProps<Dayjs>, "value" | "onChange" | "defaultValue"> {
  value?: TimeRangeValue;
  defaultValue?: TimeRangeValue;
  onChange?: (value: TimeRangeValue | null) => void;
  fieldsWidth?: string;
}

export default function TimeRangePicker({
  value,
  defaultValue,
  fieldsWidth,
  onChange,
  ...props
}: TimeRangePickerProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [internalStartTime, setInternalStartTime] = useState<Dayjs | null>(
    value?.startTime || null
  );
  const [internalEndTime, setInternalEndTime] = useState<Dayjs | null>(
    value?.endTime || null
  );

  useEffect(() => {
    if (!firstLoad) {
      if (!!internalStartTime && !!internalEndTime) {
        onChange?.({ startTime: internalStartTime, endTime: internalEndTime });
      } else {
        onChange?.(null);
      }
    }
  }, [internalEndTime, internalStartTime]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <div className="flex items-center space-x-2">
      <TimePicker
        {...props}
        label="Início"
        value={value?.startTime}
        defaultValue={defaultValue?.startTime}
        onChange={(value) => {
          setInternalStartTime(value);
        }}
        slotProps={{
          textField: {
            size: "small",
            margin: "dense",
            sx: { width: fieldsWidth },
          },
        }}
      />
      <ArrowForward fontSize="small" />
      <TimePicker
        {...props}
        label="Fim"
        value={value?.endTime}
        defaultValue={defaultValue?.endTime}
        onChange={(value) => {
          setInternalEndTime(value);
        }}
        slotProps={{
          textField: {
            size: "small",
            margin: "dense",
            sx: { width: fieldsWidth },
          },
        }}
      />
    </div>
  );
}
