import { argbFromHex } from "@material/material-color-utilities";
import { ThemeState } from "interfaces/heimdallTheme";
import { createHeimdallMuiTheme } from "styles/muiTheme";
import { argbSchemeToRgbaScheme, themeFromSourceColors } from "lib/themeUtils";
import { PaletteOptions, ThemeOptions } from "@mui/material";

const _defaultSourceColor = "#0098DA";
const _defaultSecondaryColor = "#0c0f63";

export function getDefaultHeimdallThemeState(): ThemeState {
  const defaultMd3Theme = getDefaultMd3Theme();
  const lightScheme = argbSchemeToRgbaScheme(defaultMd3Theme.schemes.light);
  const darkScheme = argbSchemeToRgbaScheme(defaultMd3Theme.schemes.dark);
  return {
    headerLogoMode: "dark",
    isClassic: false,
    isCustom: false,
    isDark: false,
    md3Schemes: { light: lightScheme, dark: darkScheme },
    md3Theme: defaultMd3Theme,
    muiTheme: getDefaultMuiTheme(),
  };
}

const getDefaultMd3Theme = () => {
  const theme = themeFromSourceColors(
    argbFromHex(_defaultSourceColor),
    argbFromHex(_defaultSecondaryColor),
    null
  );
  return theme;
};

const getDefaultMuiTheme = () => {
  const myMuiTheme = createHeimdallMuiTheme(defaultHeimdallMuiLightPalette);
  return myMuiTheme;
};

export const defaultHeimdallMuiOverrides = (
  rootContainer?: HTMLDivElement
): Partial<ThemeOptions> => ({
  typography: {
    fontFamily: "Roboto",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        //   disableRipple: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        root: {
          // borderRadius: '60px !important',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootContainer,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootContainer,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootContainer,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootContainer,
      },
    },
  },
});

export const defaultHeimdallMuiLightPalette: PaletteOptions = {
  mode: "light",
  onPrimary: {
    main: "#fff",
    dark: "#fff",
    light: "#fff",
  },
  primary: {
    main: "#006492",
    light: "#007eb6",
    dark: "#005880",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#5156a9",
    light: "#6a6fc4",
    dark: "#454a9c",
    contrastText: "#ffffff",
  },
  error: {
    main: "#ba1a1a",
    light: "#de3730",
    dark: "#a80710",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb851",
    dark: "#b2741a",
    contrastText: "rgba(0,0,0,0.87)",
  },
  background: {
    default: "#fcfcff",
    paper: "#fcfcff",
  },
  text: {
    primary: "rgba(0,0,0,0.87)",
    secondary: "rgba(0,0,0,0.75)",
    disabled: "rgba(0,0,0,0.45)",
  },
  divider: "rgba(0,0,0,0.4)",
};

export const defaultHeimdallMuiDarkPalette: PaletteOptions = {
  mode: "dark",
  onPrimary: {
    main: "#fff",
    dark: "#fff",
    light: "#fff",
    contrastText: "#000",
  },
  primary: {
    main: "#8bceff",
    light: "#c9e6ff",
    dark: "#41b4f8",
    contrastText: "#00344e",
  },
  secondary: {
    main: "#5156a9",
    light: "#6a6fc4",
    dark: "#454a9c",
    contrastText: "#ffffff",
  },
  background: {
    default: "#121212",
    paper: "#121212",
  },
  text: {
    primary: "#ffffff",
    secondary: "rgba(255,255,255,0.7)",
    disabled: "rgba(255,255,255,0.5)",
  },
  error: {
    main: "#f44336",
    light: "#f6685e",
    dark: "#aa2e25",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb851",
    dark: "#b2741a",
    contrastText: "rgba(0,0,0,0.87)",
  },
  divider: "rgba(255,255,255,0.12)",
};
