import React from "react";
import { Button } from "@mui/material";
import { Clear, Restore } from "@mui/icons-material";
import styles from "./DrawCanvas.module.css";

export function CanvasActions({
  reset,
  erase,
  regionError,
}: {
  erase: () => void;
  reset: () => void;
  regionError?: boolean;
}) {
  return (
    <section className={styles.actions}>
      <Button
        disabled
        variant="outlined"
        startIcon={<Restore />}
        onClick={reset}
      >
        Resetar
      </Button>
      <Button
        variant="outlined"
        color="error"
        onClick={erase}
        startIcon={<Clear />}
      >
        Limpar
      </Button>
    </section>
  );
}
