import { HeimdallOperatorRoles } from "interfaces/operator";
import { HEIMDALL_ROUTE } from "interfaces/webRoutes";
import { webRoutes } from "lib/heimdallRoutes";

const heimdallRoutes = webRoutes;

export default heimdallRoutes;
export const excludedSidebarPaths = (role?: HeimdallOperatorRoles) => {
  // Paths that do not show on Sidebar for every user
  const commonPaths = [
    HEIMDALL_ROUTE.HELP,
    HEIMDALL_ROUTE.PROFILE,
    HEIMDALL_ROUTE.FEEDBACK,
  ];

  switch (role) {
    case "manager":
      return [...commonPaths, HEIMDALL_ROUTE.THEMES, HEIMDALL_ROUTE.COMPANIES];
    case "collaborator":
      return [
        ...commonPaths,
        HEIMDALL_ROUTE.THEMES,
        HEIMDALL_ROUTE.COMPANIES,
        HEIMDALL_ROUTE.FORENSE,
        HEIMDALL_ROUTE.OPERATORS,
        HEIMDALL_ROUTE.IO_DEVICES,
        HEIMDALL_ROUTE.PANIC_DEVICES,
        HEIMDALL_ROUTE.DISPATCHERS,
        HEIMDALL_ROUTE.VMS,
        HEIMDALL_ROUTE.MONI,
      ];
    case "super-admin":
      return commonPaths;
    default:
      return [
        ...commonPaths,
        HEIMDALL_ROUTE.THEMES,
        HEIMDALL_ROUTE.COMPANIES,
        HEIMDALL_ROUTE.FORENSE,
        HEIMDALL_ROUTE.OPERATORS,
      ];
  }
};
