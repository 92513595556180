import {
  HeimdallDevice,
  HeimdallDeviceInList,
  HeimdallDeviceModels,
  HeimdallDeviceTypes,
  HeimdallDeviceTypesPretty,
} from "interfaces/devices";
import axiosRoot from "./rootConfig";
import { BackendResponsePagination } from "interfaces/generic";
import { Analytic } from "interfaces/analytics";

/**
 * Creates a new I/O device
 * @param {"commbox" | "horn"} type Type of device to be created
 * @param {object} data The data needed to create a new device (name, ip, port, etc)
 * @returns void
 */
export async function createDevice(type: HeimdallDeviceTypes, data: any) {
  let requestURL = "";

  if (type === "commbox" || type === "niobox") requestURL += "/api/commbox";
  else if (type === "horn_speaker") requestURL += "/api/horn_speaker";

  return await axiosRoot
    .post(requestURL, data)
    .then((response) => {
      if (response.status === 200) return response.data;
      throw new Error(response.data);
    })
    .catch((err) => console.error(err));
}

interface GetDevicesParams {
  page?: number;
  pageSize?: number;
  name?: string;
  type?: HeimdallDeviceTypesPretty;
  model?: HeimdallDeviceModels;
}

export async function getDevices(
  { page = 1, pageSize = 100000, name, type, model }: GetDevicesParams = {
    page: 1,
    pageSize: 100000,
  }
) {
  let requestUrl = "/api/list_devices?page=" + page;

  if (name) requestUrl += "&filter%5Bname%5D=" + name;
  if (type) requestUrl += "&filter%5Bdevice%5D=" + type;
  if (model) requestUrl += "&filter%5Bmanufacturer%5D=" + model;

  return await axiosRoot
    .get<BackendResponsePagination<HeimdallDeviceInList>>(requestUrl, {
      headers: { paginate: pageSize },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function getDevice(
  type: HeimdallDeviceTypes,
  id: number | string
) {
  let requestUrl = "";
  if (type === "commbox") requestUrl += "/api/commbox/" + id;
  else if (type === "horn_speaker") requestUrl += "/api/horn_speaker/" + id;

  return await axiosRoot
    .get<{ data: HeimdallDevice }>(requestUrl)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function getLinkedDevicesByAnalytic(id: number | string) {
  return await axiosRoot
    .get<{ data: Analytic }>("/api/analytic/" + id)
    .then(function (response) {
      const linkedDevices: Analytic["devices_analytics"] = [];
      response.data.data.devices_analytics.forEach((devices) => {
        linkedDevices.push({ ...devices });
      });
      return linkedDevices;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function editDevice(
  type: HeimdallDeviceTypes,
  id: number | string,
  data: Partial<HeimdallDevice>
) {
  let requestUrl = "";
  if (type === "commbox") requestUrl += "/api/commbox/" + id;
  else if (type === "horn_speaker") requestUrl += "/api/horn_speaker/" + id;

  return await axiosRoot
    .put(requestUrl, data)
    .then((res) => res.data)
}

export async function deleteDevice(
  type: HeimdallDeviceTypes,
  id: number | string
) {
  let requestURL = "";

  if (type === "commbox") requestURL += "/api/commbox/";
  else if (type === "horn_speaker") requestURL += "/api/horn_speaker/";

  requestURL += id;

  return await axiosRoot
    .delete(requestURL)
    .then((response) => response.data)
    .catch((err) => console.error(err));
}

/*----------------------------------------------------------
 * Link I/O Devices
 * ------------------------------------------------------------- */
export async function linkHornSpeaker(
  analyticID: number | string,
  deviceID: number | string,
  audio: number,
  replayCount: number
) {
  const requestData = {
    analytic_id: analyticID,
    horn_speaker_id: deviceID,
    audio_id: audio,
    nbr_times: replayCount,
  };

  return await axiosRoot.post("/api/horn_speaker_analytic", requestData);
}

export async function linkCommbox(
  analyticID: number | string,
  deviceID: number | string,
  address: number,
  time: number
) {
  const requestData = {
    analytic_id: analyticID,
    commbox_id: deviceID,
    address,
    time,
  };

  return await axiosRoot.post("/api/commbox_analytic", requestData);
}

export async function deleteLinkedDevice(
  id: number,
  deviceType: HeimdallDeviceTypes
) {
  let requestURL = "";

  if (deviceType === "horn_speaker")
    requestURL += "/api/horn_speaker_analytic/" + id;
  else if (deviceType === "commbox")
    requestURL += "/api/commbox_analytic/" + id;

  return await axiosRoot
    .delete(requestURL)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
