import React, { useCallback, useContext, useEffect, useState } from "react";
import { baseUrl } from "api/rootConfig";
import { Button, Modal, Paper } from "@mui/material";
import { RadarLog } from "interfaces/analytics";
import { MonitoringMessage } from "interfaces/monitoring";
import AllRegionCanvas from "components/AddCamera/ConfigureAnalytic/DrawCanvas/AllRegionCanvas";
import styles from "./ExpandImageModal.module.css";
import { Download } from "@mui/icons-material";
import { downloadImage } from "lib/themeUtils";
import dayjs from "dayjs";
import GlobalContext from "context/GlobalContext";

export default function ExpandImageModal({
  log,
  title,
  location,
  open,
  setOpen,
  radarInfo,
}: ExpandImageModalProps) {
  const { user } = useContext(GlobalContext);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 750,
    height: 450,
  });
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);

  const onRefChange = useCallback((node: HTMLCanvasElement) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
      setCtx(null);
    } else {
      // DOM node referenced by ref has changed and exists
      setCtx(node.getContext("2d"));
    }
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      const newSize = getWindowSize();
      if (newSize.innerWidth < 800) {
        setCanvasDimensions({
          width: newSize.innerWidth - 40,
          height: (newSize.innerWidth - 40) * 0.6,
        });
      }
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    function writeTextAtCoordinates(
      canvasContext: CanvasRenderingContext2D,
      x: number,
      y: number
    ) {
      // Set the font properties
      canvasContext.font = "16px Roboto";
      // Set the text color
      canvasContext.fillStyle = "yellow";
      // Define the text and the X, Y coordinates where the text should be written
      const precision = ((log.simi_lst[0] as number) * 100).toFixed(2);
      const text = `${precision}%`;
      // Use the fillText method to draw the text on the canvas
      canvasContext.fillText(text, x, y);
    }
    function handleBoundingBox() {
      if (!log.bbox_lst || typeof log.bbox_lst === "string" || !ctx) return;
      // ctx.strokeStyle = "#ba1a1a";
      ctx.strokeStyle = "#fcba03";
      ctx.lineWidth = 2;
      //@ts-ignore
      log.bbox_lst.forEach((bbox: number[]) => {
        const initialPointNormalized = [bbox[0], bbox[1]];
        const [widthNormalized, heightNormalized] = [bbox[2], bbox[3]];
        const initialPoint = [
          initialPointNormalized[0] * canvasDimensions.width,
          initialPointNormalized[1] * canvasDimensions.height,
        ];
        const [width, height] = [
          widthNormalized * canvasDimensions.width,
          heightNormalized * canvasDimensions.height,
        ];
        let xOffset = -10;
        let yOffset = -5;
        let writeXCoordinate = initialPoint[0];
        let writeYCoordinate = initialPoint[1];
        if (isNormalizedPointInLeftHalf(initialPointNormalized[0])) {
          writeXCoordinate += width;
          xOffset = -15;
        }
        if (isNormalizedPointInUpperHalf(initialPointNormalized[1])) {
          writeYCoordinate += height;
          yOffset = 15;
        }
        if (user && user.role === "super-admin") {
          writeTextAtCoordinates(
            ctx,
            writeXCoordinate + xOffset,
            writeYCoordinate + yOffset
          );
        }
        // Starting point
        ctx.moveTo(initialPoint[0], initialPoint[1]);
        // Coordinates for the lines
        ctx.lineTo(initialPoint[0] + width, initialPoint[1]);
        ctx.lineTo(initialPoint[0] + width, initialPoint[1] + height);
        ctx.lineTo(initialPoint[0], initialPoint[1] + height);
        ctx.lineTo(initialPoint[0], initialPoint[1]);
        // Drawing line
      });

      ctx.stroke();
    }

    if (open) {
      handleBoundingBox();
    }
  }, [open, ctx, user, canvasDimensions, log]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Paper className={styles.modal}>
        <h3 style={{ marginBottom: "2px" }}>{title}</h3>
        {location && (
          <h3 style={{ marginBottom: radarInfo ? "2px" : "10px" }}>
            Local: {location}
          </h3>
        )}
        {radarInfo && <h3 style={{ marginBottom: "10px" }}>{radarInfo}</h3>}
        <div className={styles.imageContainer} style={{ position: "relative" }}>
          <AllRegionCanvas
            regions={log.region_dic}
            dimensions={canvasDimensions}
            show
            isInactivityTEMP={log.occurrence === "Inatividade"}
          />
          <canvas
            ref={onRefChange}
            id="bboxCanvas"
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{
              position: "absolute",
              zIndex: "2",
            }}
          />
          <img
            src={baseUrl + log.url}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{
              width: `${canvasDimensions.width}px`,
              height: `${canvasDimensions.height}px`,
            }}
            alt="Ocorrência"
          />
        </div>
        {log.check_description && (
          <section className={styles.description}>
            <h2>Descrição</h2>
            <p style={{ marginTop: "5px" }}>{log.check_description}</p>
          </section>
        )}
        <div className={styles.modalActions}>
          <Button
            startIcon={<Download />}
            onClick={() =>
              downloadImage(
                baseUrl + log.url,
                `${log.occurrence}_${dayjs(
                  log.created_at,
                  "DD/MM/YYYY HH:mm:ss"
                ).format("DD-MM-YYYY_HH-mm-ss")}`
              )
            }
          >
            Baixar imagem
          </Button>
          <Button variant="text" color="error" onClick={() => setOpen(false)}>
            Fechar
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

interface ExpandImageModalProps {
  title?: string;
  location?: string;
  log: RadarLog | MonitoringMessage;
  radarInfo?: string;
  imgSrc?: string;
  bbox?: "[]" | number[][];
  description?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function isNormalizedPointInUpperHalf(y: number) {
  return y <= 0.5 ? true : false;
}
function isNormalizedPointInLeftHalf(x: number) {
  return x <= 0.5 ? true : false;
}

function isPointInUpperHalf(y: number, canvasHeight: number) {
  if (y <= canvasHeight / 2) {
    return true;
  }
  return false;
}
function isPointInLeftHalf(x: number, canvasWidth: number) {
  if (x <= canvasWidth / 2) {
    return true;
  }
  return false;
}
