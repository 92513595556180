import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalContext from "context/GlobalContext";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
// Components
import { HeaderNotifications } from "./HeaderNotifications";
import { Avatar, Button, Drawer, Popover, useMediaQuery } from "@mui/material";
import SidebarForDrawer from "components/Sidebar/DrawerSidebar/SidebarForDrawer";
// Icons
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { ColorLens, Menu, SupportAgent } from "@mui/icons-material";
// Styles
import styles from "./Header.module.css";
import { deepPurple } from "@mui/material/colors";

const HEADER_HEIGHT = 52; // Adjust according to your header height

export default function Header() {
  const isSm = useMediaQuery("(max-width: 600px)");

  // if (isSm)
  //   return (
  //     <HideOnScroll
  //       scrollTarget={
  //         document.getElementById("main-container-scroll-area") || undefined
  //       }
  //     >
  //       <HeaderContent />
  //     </HideOnScroll>
  //   );

  return <HeaderContent />;
}

function HeaderContent() {
  const isSm = useMediaQuery("(max-width: 600px)");
  const { headerLogoMode, images } = useHeimdallTheme();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { user, logout } = useContext(GlobalContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollTarget = useCallback(() => {
    return document.getElementById("main-container-scroll-area") || undefined;
  }, [location.pathname]);

  // console.log(scrollTarget());

  useEffect(() => {
    document.documentElement.style.removeProperty("--header-height");
    setShow(true);
  }, [location.pathname]);

  useEffect(() => {
    const controlNavbar = () => {
      if (scrollTarget() && scrollTarget()!.scrollTop > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
        document.documentElement.style.setProperty("--header-height", "0px");
      } else {
        console.log("Scrolling up");
        setShow(true);
        document.documentElement.style.removeProperty("--header-height");
      }

      // remember current page location to use in the next move
      setLastScrollY(scrollTarget()!.scrollTop);
    };

    if (isSm && !!scrollTarget()) scrollTarget()!.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      document
        .getElementById("main-container-scroll-area")
        ?.removeEventListener("scroll", controlNavbar);
      scrollTarget()?.removeEventListener("scroll", controlNavbar);
    };
  }, [isSm, scrollTarget, lastScrollY]);

  return (
    <div
      className={`${styles.container} primary-container ${show ? "flex" : "hidden"}`}
    >
      <button
        onClick={() => setOpenDrawer(true)}
        className="border-none bg-transparent flex"
      >
        {isSm ? (
          <Menu />
        ) : headerLogoMode === "light" ? (
          <img
            height={40}
            className="h-10"
            alt="logo companhia"
            src={images.logoLight}
          />
        ) : (
          <img
            height={40}
            className="h-10"
            alt="logo companhia"
            src={images.logoDark}
          />
        )}
      </button>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "var(--root-color-primary-container)",
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <SidebarForDrawer closeDrawer={() => setOpenDrawer(false)} />
      </Drawer>
      <div className={`${styles.actions} on-primary-container-text`}>
        <div className={styles.profile} onClick={handleClick}>
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: deepPurple[500],
              marginLeft: "10px",
            }}
          >
            {user?.username[0].toUpperCase()}
          </Avatar>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ul className={styles.options}>
            <li>
              <Button
                variant="text"
                size="large"
                fullWidth
                onClick={() => {
                  handleClose();
                  navigate("/profile");
                }}
                style={{ justifyContent: "flex-start" }}
                color="inherit"
                startIcon={<AccountCircleOutlinedIcon />}
              >
                {user?.username}
              </Button>
            </li>
            {user?.role === "super-admin" && (
              <li>
                <Button
                  variant="text"
                  size="large"
                  color="inherit"
                  fullWidth
                  style={{ justifyContent: "flex-start" }}
                  onClick={() => {
                    handleClose();
                    navigate("/themes");
                  }}
                  startIcon={<ColorLens />}
                >
                  Temas
                </Button>
              </li>
            )}
            <li>
              <Button
                fullWidth
                size="large"
                variant="text"
                color="inherit"
                style={{ justifyContent: "flex-start" }}
                onClick={logout}
                startIcon={<LogoutIcon />}
              >
                Sair
              </Button>
            </li>
            <hr />
            <li>
              <Button
                color="inherit"
                variant="text"
                size="large"
                fullWidth
                onClick={() => {
                  handleClose();
                  navigate("/help");
                }}
                style={{ justifyContent: "flex-start" }}
                startIcon={<SupportAgent />}
              >
                Suporte
              </Button>
            </li>
            {/* <li>
              <Button
              color="inherit"
              variant="text"
              size="large"
              fullWidth
              onClick={() => {
                handleClose();
                navigate("/feedback");
                }}
                style={{ justifyContent: "flex-start" }}
                startIcon={<FeedbackOutlinedIcon />}
                >
                Enviar feedback
                </Button>
                </li> */}
          </ul>
        </Popover>
        <HeaderNotifications />
        {/* {!theme.isCustom && (
          <IconButton onClick={handleTheme} color="inherit">
          {theme.isDark ? (
            <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
              )}
              </IconButton>
              )} */}
      </div>
    </div>
  );
}

const debounce = (func: Function, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
