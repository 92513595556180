import React, { useContext, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import GlobalContext from "context/GlobalContext";
import Header from "components/Header/Header";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { StatusBar } from "@capacitor/status-bar";
import { argbFromRgba, hexFromArgb } from "@material/material-color-utilities";
import { isPlatform } from "@ionic/react";
import { HeimdallUser } from "interfaces/generic";
import BottomNavigation from "../BottomNavigation/BottomNavigation";

export default function MainLayout() {
  const { md3Schemes } = useHeimdallTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { user, setUser } = useContext(GlobalContext);

  const storedUser = useLoaderData() as HeimdallUser | null;

  // Mobile status bar color the same as header color
  useEffect(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      StatusBar.setBackgroundColor({
        color: hexFromArgb(argbFromRgba(md3Schemes.light.primaryContainer)),
      });
    }
  }, [md3Schemes]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) token = sessionStorage.getItem("token");

    if (!user && storedUser && token) {
      setUser(storedUser);
    } else if (!user && storedUser && !token) {
      navigate("/auth");
    }
  }, [user, setUser, storedUser, navigate]);

  if (!user && !storedUser) return <Navigate to="/auth" />;

  return (
    <>
      <Header />
      <main
        className={
          location.pathname.match(/monitor(\/[w-]*)?/)
            ? "appContainerDASH"
            : "appContainer"
        }
      >
        <Sidebar />
        <Outlet />
      </main>
      <BottomNavigation />
    </>
  );
}
