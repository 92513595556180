import React, { useContext, useEffect, useMemo, useState } from "react";
// Components
import SignInForm from "components/Auth/SignInForm";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { useSpring, animated, useSpringRef, useChain } from "@react-spring/web";
import { isPlatform } from "@ionic/react";
// Styles
import styles from "./auth.module.css";
import HeimdallShieldMinimal from "assets/icons/heimdallShieldMinimal";
import MobileCompanyForm from "components/Auth/MobileCompanyForm/MobileCompanyForm";
import GlobalContext from "context/GlobalContext";
import axiosRoot from "api/rootConfig";
import { correctLogoUrlFromUrl } from "lib/themeUtils";
import { HeimdallTheme } from "interfaces/heimdallTheme";
import { getUser } from "api/user";
import { HeimdallAuth } from "interfaces/generic";
import { addListeners } from "lib/pushNotifications";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

const AnimatedHeimdallShield = animated(HeimdallShieldMinimal);

if (isPlatform("mobile") && !isPlatform("mobileweb")) {
  console.log("Adding listeners");
  addListeners();
}

export default function Login() {
  const { toast } = useToast();
  const [tabValue] = useState(0);
  const { setUser } = useContext(GlobalContext);
  const { images, loadHeimdallBackendTheme } = useHeimdallTheme();
  const hasTheme = useMemo(() => !!localStorage.getItem("defaultTheme"), []);
  const [companyExists, setCompanyExists] = useState(
    !isPlatform("mobile") || isPlatform("mobileweb")
  );
  // Animations
  const [whiteLabelLogo, setWhiteLabelLogo] = useState<string | null>(null);

  const springApi = useSpringRef();
  const [a1Springs, a1] = useSpring(() => ({
    ref: springApi,
    // config: config.stiff,
    from: { rotate: 0 },
  }));

  const [a3Springs, a3] = useSpring(() => ({
    from: { scale: 1 },
  }));

  const spring2 = useSpringRef();
  const [a2Springs, a2] = useSpring(() => ({
    ref: spring2,
    // config: config.stiff,
    from: { opacity: 0, scale: 0 },
  }));

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain([springApi, spring2]);
  // const [springs, api] = useSpring(() => ({
  //   from: { rotate: 0, opacity: 1 },
  // }));
  const [animationEndend, setAnimationEndend] = useState(false);
  const animationDuration = 3000; // 3 seconds

  const playFirstAnimation = () => {
    a1.start({
      from: {
        rotate: 0,
      },
      to: {
        rotate: 360,
      },
    });
  };

  const playSecondAnimation = () => {
    a2.start({
      from: { opacity: 0, scale: 0 },
      to: {
        opacity: 1,
        scale: 1,
      },
    });
  };

  const playThirdAnimation = () => {
    a3.start({ from: { scale: 1 }, to: { scale: 0 } });
  };

  function handleRotationAnimation(): Promise<void> {
    return new Promise<void>((resolve) => {
      playFirstAnimation();

      setTimeout(() => {
        playThirdAnimation();
      }, 1000);

      setTimeout(() => {
        setAnimationEndend(true);
        playSecondAnimation();
      }, 2000);

      // Set a timeout to resolve the promise after the animation duration
      setTimeout(() => {
        // Resolve the promise
        resolve();
      }, animationDuration);
    });
  }

  useEffect(() => {
    document.title = "Autenticação | Analíticos IA";
  }, []);

  function handleThemeFirstLoad(backendTheme: HeimdallTheme) {
    localStorage.setItem("defaultTheme", JSON.stringify(backendTheme));
    loadHeimdallBackendTheme(backendTheme.state, {
      logoLight: correctLogoUrlFromUrl(backendTheme.logo_light),
      logoDark: correctLogoUrlFromUrl(backendTheme.logo_dark),
      favicon: correctLogoUrlFromUrl(backendTheme.favicon),
    });
  }

  async function onSignIn(user: HeimdallAuth) {
    localStorage.setItem("token", user.token);
    // Overriding current axios instance haeders with new token
    axiosRoot.defaults.headers["Authorization"] = "Bearer " + user.token;
    // Handles playing login animation and theme load
    const storedTheme = !!localStorage.getItem("defaultTheme");
    if (!storedTheme) {
      setWhiteLabelLogo(correctLogoUrlFromUrl(user.user_theme.logo_dark));
      await handleRotationAnimation();
    }
    handleThemeFirstLoad(user.user_theme);
    // TODO: falar pro Alan retornar todas informacoes do user_profile
    // na rota de login
    await getUser()
      .then((completeUserData) => {
        // Updating context
        setUser(completeUserData);
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          description: getErrorMessage(err),
        });
      });
  }

  // const handleChangeTab = (event, newValue) => {
  //   setTabValue(newValue);
  //   console.log(newValue);
  // };

  if (!companyExists) {
    return (
      <div className={styles.container}>
        <HeimdallShieldMinimal height={56} />
        <div className="max-w-md w-full mt-3 p-5 rounded-xl">
          <h1 className="text-2xl mb-8 text-center">Bem vindo(a)</h1>
          <MobileCompanyForm onSuccess={() => setCompanyExists(true)} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className="h-20">
        {hasTheme ? (
          <img src={images.logoDark} width={170} alt="logo" />
        ) : (
          <div className={`${!!whiteLabelLogo ? "h-0" : ""}`}>
            <AnimatedHeimdallShield
              id="animated-logo"
              width={55}
              height={56}
              style={{ ...a1Springs, ...a3Springs }}
            />
          </div>
        )}
        <animated.img
          id="white-label-logo"
          // src={theme.isDark ? theme.logos.light : theme.logos.dark}
          src={whiteLabelLogo || ""}
          alt="logo"
          width={140}
          style={{
            display: animationEndend ? "inherit" : "none",
            ...a2Springs,
          }}
        />
      </div>
      <div className={styles.logBox}>
        {/* <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label="Entrar" />
          <Tab label="Registrar-se" />
        </Tabs> */}
        <h2 className="mb-8 text-2xl">Bem vindo(a)</h2>
        {tabValue === 0 ? <SignInForm onSignIn={onSignIn} /> : null}
        <p className="text-sm text-gray-400 text-center">
          Ao entrar você concorda com nossos{" "}
          <span style={{ color: "#3060AA" }}>Termos de Uso</span> e nossa{" "}
          <a
            href="https://zionstech.com.br/politica-de-privacidade/"
            style={{ color: "#3060AA" }}
            rel="noreferrer"
            target="_blank"
          >
            Política de Privacidade
          </a>
        </p>
        <p className="flex items-center text-gray-400 text-xs mt-20">
          {/* <ZionsLogo width={22} style={{ marginRight: "10px" }} /> */}
          <a
            href="https://zionstech.com.br"
            style={{ color: "inherit" }}
            rel="noreferrer"
            target="_blank"
          >
            powered by zions vision
          </a>
        </p>
      </div>
    </div>
  );
}
