import React, { useState } from "react";
import { HeimdallOperatorRoles } from "interfaces/operator";
import { Badge, Button, MenuItem, TextField } from "@mui/material";
import { FilterList, Search } from "@mui/icons-material";
import ExpandableContainer from "components/ExpandableContainer";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

export function OperatorsFilter({
  name,
  role,
  dateAfter,
  dateBefore,
  setRole,
  setName,
  setDateAfter,
  setDateBefore,
  clearFilters,
  filter,
}: {
  name: string;
  role: string;
  dateBefore: Dayjs | null;
  dateAfter: Dayjs | null;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setRole: React.Dispatch<React.SetStateAction<"" | HeimdallOperatorRoles>>;
  setDateAfter: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  setDateBefore: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  filter: () => void;
  clearFilters: () => Promise<void>;
}) {
  const [activeFilters, setActiveFilters] = useState(0);

  function getActiveFilters() {
    let totalActiveFilters = 0;
    if (name) totalActiveFilters += 1;
    if (role) totalActiveFilters += 1;
    if (dateAfter) totalActiveFilters += 1;
    if (dateBefore) totalActiveFilters += 1;

    return totalActiveFilters;
  }

  function handleFilter() {
    setActiveFilters(getActiveFilters());
    filter();
  }

  function handleClearFilters() {
    setActiveFilters(0);
    clearFilters();
  }

  return (
    <ExpandableContainer
      title="Filtros avançados"
      icon={
        <>
          {activeFilters > 0 ? (
            <Badge
              badgeContent={activeFilters}
              style={{ position: "absolute", top: "0px", left: "25px" }}
              color="secondary"
            />
          ) : null}
          <FilterList color="primary" />
        </>
      }
    >
      <form className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <TextField
          size="small"
          label="Nome"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
        <TextField
          select
          size="small"
          value={role}
          label="Função"
          onChange={(ev) => setRole(ev.target.value as HeimdallOperatorRoles)}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="manager">Administrador</MenuItem>
          <MenuItem value="collaborator">Operador</MenuItem>
        </TextField>
        <DateTimePicker
          value={dateAfter}
          onChange={(value) => setDateAfter(value)}
          slotProps={{
            textField: {
              size: "small",
              label: "Último acesso depois de",
              fullWidth: true,
            },
          }}
        />
        <DateTimePicker
          value={dateBefore}
          onChange={(value) => setDateBefore(value)}
          slotProps={{
            textField: {
              size: "small",
              label: "Último acesso antes de",
              fullWidth: true,
            },
          }}
        />
        <div className="flex justify-end sm:col-span-2">
          <Button
            color="error"
            style={{ marginRight: "10px" }}
            onClick={handleClearFilters}
          >
            Limpar filtros
          </Button>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Search />}
            onClick={handleFilter}
          >
            Buscar
          </Button>
        </div>
      </form>
    </ExpandableContainer>
  );
}
