import { useEffect, useRef, useState } from "react";
import { getInitialHeight, getInitialWidth } from "lib/canvas";

function debounce<T extends (...args: any[]) => void>(fn: T, ms: number) {
  let timer: NodeJS.Timeout | null;

  return (...args: Parameters<T>): void => {
    clearTimeout(timer as NodeJS.Timeout);
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, ms);
  };
}

export default function useCanvasDimensions() {
  const [windowSize] = useState(getWindowSize());
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: getInitialWidth(windowSize.innerWidth),
    height: getInitialHeight(windowSize.innerWidth),
  });
  const previousIntervalRef = useRef(
    getCurrentInterval(getWindowSize().innerWidth)
  );

  useEffect(() => {
    function handleWindowResize() {
      const newSize = getWindowSize();
      const currentInterval = getCurrentInterval(newSize.innerWidth);
      if (
        currentInterval !== previousIntervalRef.current ||
        previousIntervalRef.current === "small" ||
        previousIntervalRef.current === "medium"
      ) {
        if (currentInterval === "small") {
          const newWidth = newSize.innerWidth - 25;
          const newHeight = newWidth * 0.6;
          setCanvasDimensions({
            width: newWidth,
            height: newHeight,
          });
        } else if (currentInterval === "medium") {
          const newWidth = newSize.innerWidth - 125;
          const newHeight = newWidth * 0.6;
          setCanvasDimensions({
            width: newWidth,
            height: newHeight,
          });
        } else if (currentInterval === "large") {
          setCanvasDimensions({
            width: 450,
            height: 280,
          });
        } else if (currentInterval === "x-large") {
          setCanvasDimensions({
            width: 600,
            height: 380,
          });
        } else {
          setCanvasDimensions({
            width: 800,
            height: 420,
          });
        }
        previousIntervalRef.current = currentInterval;
      }
    }

    const debouncedHandleResize = debounce(handleWindowResize, 450);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);
  return canvasDimensions;
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function getCurrentInterval(width: number) {
  if (width < 601) {
    return "small";
  } else if (width < 1100) {
    return "medium";
  } else if (width < 1475) {
    return "large";
  } else if (width < 1675) {
    return "x-large";
  } else {
    return "2x-large";
  }
}
