import React from "react";
import { TextField } from "@mui/material";

import styles from "./AnalyticControls.module.css";

export function TargetDetectionNumber({
  icon,
  value,
  label,
  onChange,
}: {
  icon: React.ReactNode;
  value: string;
  label: string;
  onChange: (value: string) => void;
}) {
  return (
    <section className={styles.configSection}>
      <header className={styles.sectionHeader}>
        {icon}
        <h3>{label}</h3>
      </header>
      <TextField
        label={label}
        type="number"
        value={value}
        size="small"
        fullWidth
        inputProps={{
          min: "1",
          max: "120",
        }}
        onChange={(ev) => onChange(ev.target.value)}
      />
    </section>
  );
}
