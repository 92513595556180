import axiosRoot from "./rootConfig";
import { BackendResponsePagination } from "interfaces/generic";
import { DashboardMetrics } from "interfaces/dashboard";

export async function getDashboardData() {
  return await axiosRoot
    .get<BackendResponsePagination<DashboardMetrics>>("/api/dashboard", {
      headers: {
        paginate: 10000,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      console.error(err);
    });
}

export async function getGraphData(
  interval: number,
  initDate = "2023-04-26+01:00",
  endDate = "2023-04-26+23:00",
) {
  console.log(
    `http://192.168.88.201:5150/api/counter?interval=${interval}&start=${initDate}&end=${endDate}`,
  );
  return await fetch(
    `http://192.168.88.201:5150/api/counter?interval=${interval}&start=${initDate}&end=${endDate}`,
  )
    .then((res) => res.json())
    .catch((err) => console.error("Erro obtendo dados do grafico de area"));
}
