import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import GlobalContext from "context/GlobalContext";
import { testCommbox, testHornSpeaker } from "api/flaskApiMethods";
import { linkCommbox, linkHornSpeaker } from "api/ioDevices";
import { LoadingButton } from "@mui/lab";
import { HeimdallDevice, HeimdallDeviceTypes } from "interfaces/devices";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

interface AssociateDeviceDialogProps {
  device?: HeimdallDevice;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deviceType?: HeimdallDeviceTypes;
  analyticID: number;
  handleSubmit: () => Promise<void>;
}

export default function AssociateDeviceDialog({
  device,
  open,
  setOpen,
  deviceType,
  analyticID,
  handleSubmit,
}: AssociateDeviceDialogProps) {
  const { toast } = useToast();
  const [audio, setAudio] = useState("1");
  const [output, setOutput] = useState("1");
  const [time, setTime] = useState("1");
  const [isTesting, setIsTesting] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const { user } = useContext(GlobalContext);

  async function linkDevice() {
    if (user && device) {
      setIsLinking(true);
      // Linking device
      if (deviceType === "commbox") {
        await linkCommbox(analyticID, device.id, Number(output), Number(time))
          .then(() => {
            setIsLinking(false);
            handleSubmit();
            toast({
              description: "Dispositivo associado com sucesso",
            });
          })
          .catch((err) => {
            toast({
              variant: "destructive",
              title: "Erro ao associar dispositivo",
              description: getErrorMessage(err),
            });
            setIsLinking(false);
            handleSubmit();
          });
      } else if (deviceType === "horn_speaker") {
        await linkHornSpeaker(
          analyticID,
          device.id,
          Number(audio),
          Number(time)
        )
          .then(() => {
            setIsLinking(false);
            handleSubmit();
            toast({
              description: "Dispositivo associado com sucesso",
            });
          })
          .catch((err) => {
            toast({
              variant: "destructive",
              title: "Erro ao associar dispositivo",
              description: getErrorMessage(err),
            });
            setIsLinking(false);
            handleSubmit();
          });
      }
    }
  }

  async function handleTest() {
    if (user && device) {
      setIsTesting(true);
      // const device = await getDevice(user.token, deviceType, device.id);
      if (!device) {
        setIsTesting(false);
        return;
      }
      if (deviceType === "horn_speaker") {
        const response = await testHornSpeaker(
          device.ip,
          device.port,
          audio,
          time,
          device.password!
        );
        if (!response)
          toast({
            variant: "destructive",
            description: "Erro de comunicação com dispositivo",
          });
      } else {
        const response = await testCommbox(
          device.ip,
          device.port,
          output,
          time
        );
        if (!response)
          toast({
            variant: "destructive",
            description: "Erro de comunicação com dispositivo",
          });
      }

      setIsTesting(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          linkDevice();
        },
      }}
    >
      <DialogTitle>Associação de dispositivo I/O - {device?.name}</DialogTitle>
      <DialogContent>
        {deviceType === "horn_speaker" ? (
          <>
            <TextField
              fullWidth
              margin="dense"
              label="Número de repetições"
              type="number"
              size="small"
              value={time}
              inputProps={{ min: "1", max: "5" }}
              onChange={(ev) => {
                if (!ev.target.value) setTime("");
                else if (Number(ev.target.value) <= 0) setTime("1");
                else if (Number(ev.target.value) <= 5) setTime(ev.target.value);
                else if (Number(ev.target.value) > 5) setTime("5");
                else setTime("");
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Número do áudio"
              type="number"
              size="small"
              value={audio}
              inputProps={{ min: "1", max: "4" }}
              onChange={(ev) => {
                if (!ev.target.value) setAudio("");
                else if (Number(ev.target.value) <= 0) setAudio("1");
                else if (Number(ev.target.value) <= 5)
                  setAudio(ev.target.value);
                else if (Number(ev.target.value) > 5) setAudio("5");
                else setAudio("");
              }}
            />
          </>
        ) : (
          <>
            <TextField
              fullWidth
              margin="dense"
              label="Relé"
              type="number"
              size="small"
              value={output}
              inputProps={{ min: "1" }}
              onChange={(ev) => {
                if (!ev.target.value) setOutput("");
                else if (Number(ev.target.value) <= 0) setOutput("1");
                else if (Number(ev.target.value) > 0)
                  setOutput(ev.target.value);
                else setOutput("");
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              label="Duração (segundos)"
              type="number"
              size="small"
              value={time}
              inputProps={{ min: "1" }}
              onChange={(ev) => {
                setTime(ev.target.value);
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <LoadingButton
          variant="text"
          color="secondary"
          loading={isTesting}
          onClick={handleTest}
        >
          Testar
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="text"
          loading={isLinking}
          onSubmit={linkDevice}
        >
          Associar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
