import axiosRoot, { baseUrl } from "./rootConfig";
import { HeimdallUser } from "interfaces/generic";
import { NewEmail } from "components/Modals/ChangeEmailDialog";
import { NewPassword } from "components/Modals/ChangePasswordDialog";
import { DeviceInfo } from "@capacitor/device";

export async function getUser() {
  return await axiosRoot
    .get<HeimdallUser>("/api/user_profile")
    .then(function (response) {
      return response.data;
    });
}

export async function sendNewPasswordRequest(email: string) {
  return await axiosRoot
    .post("/api/forgot?app_url=" + baseUrl, { email })
    .then(function (response) {
      return response;
    })
    .catch((error) => error);
}

export async function resetPassword(data: {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
}) {
  return await axiosRoot.post("/api/reset", data).then(function (response) {
    return response;
  });
}

export async function changePassword(userId: number, data: NewPassword) {
  return await axiosRoot
    .put("/api/user_company_change_password/" + userId, data)
    .then((res) => res.data);
}

export async function changeEmail(userId: number, data: NewEmail) {
  return await axiosRoot
    .put("/api/user_company_change_email/" + userId, data)
    .then((res) => res.data);
}

export async function addFirebaseDeviceMessagingToken(
  userId: number,
  data: {
    device_id: string;
    device_token: string;
    device_info: DeviceInfo;
  }
) {
  const requestData = new FormData();

  requestData.append("device_id", data.device_id);
  requestData.append("device_token", data.device_token);
  requestData.append("device_info", JSON.stringify(data.device_info));

  return await axiosRoot
    .post("/api/user/store_device_token/" + userId, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
}

export async function removeConnectedDevice(
  userId: number,
  device_token: string
) {
  return await axiosRoot
    .delete(
      `/api/user/remove_device_token/${userId}?device_token=${device_token}`
    )
    .then((res) => res.data);
}
