import React from "react";
import { Dayjs } from "dayjs";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  useTheme,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import TimeRangePicker from "components/TimeRangePicker/TimeRangePicker";

export function AllTimeSchedule({
  value,
  checked,
  indeterminate,
  handleSetChecked,
  onAddTimeSchedule,
  onChangeTimeSchedule,
  onDeleteTimeSchedule,
}: {
  value: { initTime: Dayjs | null; stopTime: Dayjs | null }[];
  checked: boolean[];
  indeterminate: boolean;
  onChangeTimeSchedule: (
    index: number,
    timeRange: [Dayjs | null, Dayjs | null]
  ) => void;
  onAddTimeSchedule: (dayOfWeek: string, allFields?: boolean) => void;
  onDeleteTimeSchedule: (
    index: number,
    dayOfWeek: string,
    allFields?: boolean
  ) => void;
  handleSetChecked: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
}) {
  const theme = useTheme();

  return (
    <div
      className="flex flex-col sm:flex-row py-2"
      style={{
        marginTop: "5px",
        borderBottom: `1px solid ${theme.palette.divider}`,
      }}
    >
      <FormControlLabel
        label="Todo dia"
        sx={{ minWidth: "105px", marginRight: "10px" }}
        control={
          <Checkbox
            checked={checked
              .slice(1, checked.length)
              .every((value) => value === true)}
            indeterminate={indeterminate}
            onChange={(ev) => handleSetChecked(ev, 0)}
          />
        }
      />
      <div className="flex flex-col">
        {value.map((timeRange, index) => (
          <div key={index} className="flex flex-1">
            <TimeRangePicker
              value={{
                startTime: timeRange.initTime,
                endTime: timeRange.stopTime,
              }}
              // second arrow function solves internal onChange bug
              onChange={(_value) => {
                // FIXME: if (setTimeScheduleError) setTimeScheduleError(false);
                onChangeTimeSchedule(
                  index,
                  _value ? [_value.startTime, _value.endTime] : [null, null]
                );
              }}
              disabled={!checked[0]}
            />
            {index === 0 ? (
              <IconButton onClick={() => onAddTimeSchedule("monday", true)}>
                <Add />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => onDeleteTimeSchedule(index, "monday", true)}
              >
                <Remove
                  color={
                    checked
                      .slice(1, checked.length)
                      .every((value) => value === false)
                      ? "disabled"
                      : "error"
                  }
                />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
