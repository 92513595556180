interface HeimdallBackendError {
  errors: {
    [key: string]: string;
  };
}

interface HeimdallBackendError2 {
  message: string;
  exception: string;
  file: string;
  line: number;
  trace: {
    file: string;
    line: number;
    function: string;
    class: string;
    type: string;
  }[];
}

interface HeimdallBackendError3 {
  errors: string;
}

function isHeimdallBackendError(
  myObject: any
): myObject is HeimdallBackendError {
  return !!myObject.errors;
}

function isHeimdallBackendError3(
  myObject: any
): myObject is HeimdallBackendError3 {
  return !!myObject.errors && typeof myObject.errors === "string";
}

export function getErrorMessage(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errorData = error.response.data as
      | HeimdallBackendError
      | HeimdallBackendError2;

    if (isHeimdallBackendError3(errorData)) {
      return errorData.errors;
    }
    if (isHeimdallBackendError(errorData)) {
      return Object.values(errorData.errors)[0];
    } else {
      return errorData.message;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    return "Um erro inesperado ocorreu.";
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log("Error", error.message);
    return error.message as string;
  }
  //   console.log(error.config);
}
