import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AssociateDevices from "components/AddCamera/ConfigureAnalytic/IO_Devices/AssociateDevices";
import { PanicLinkedAnalytic } from "interfaces/panicDevice";
import React from "react";

export default function PanicAssociate({
  open,
  title,
  analyticID,
  panicLinkedDevices,
  onClose,
}: {
  open: boolean;
  title?: string;
  analyticID: number;
  panicLinkedDevices?: PanicLinkedAnalytic[];
  onClose: () => void;
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <AssociateDevices
          analyticID={analyticID}
          panicLinkedDevices={panicLinkedDevices}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose}>Fechar</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
