import { ArrowForward } from "@mui/icons-material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

type DateRangeValue = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

interface DateRangePickerProps
  extends Omit<DatePickerProps<Dayjs>, "value" | "onChange" | "defaultValue"> {
  value?: DateRangeValue;
  defaultValue?: DateRangeValue;
  onChange?: (value: DateRangeValue | null) => void;
  fieldsWidth?: string;
}

export default function DateRangePicker({
  value,
  fieldsWidth,
  defaultValue,
  onChange,
  ...props
}: DateRangePickerProps) {
  const [firstLoad, setFirstLoad] = useState(true);
  const [internalStartDate, setInternalStartDate] = useState<Dayjs | null>(
    value?.startDate || null
  );
  const [internalEndDate, setInternalEndDate] = useState<Dayjs | null>(
    value?.endDate || null
  );

  useEffect(() => {
    if (!firstLoad) {
      if (!!internalStartDate && !!internalEndDate) {
        onChange?.({ startDate: internalStartDate, endDate: internalEndDate });
      } else {
        onChange?.(null);
      }
    }
  }, [internalEndDate, internalStartDate]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <div className="flex items-center space-x-2">
      <DatePicker
        {...props}
        label="Início"
        value={value?.startDate}
        defaultValue={defaultValue?.startDate}
        onChange={(value) => {
          setInternalStartDate(value);
        }}
        slotProps={{
          textField: {
            size: "small",
            margin: "dense",
            sx: { width: fieldsWidth },
          },
        }}
      />
      <ArrowForward fontSize="small" />
      <DatePicker
        {...props}
        label="Fim"
        value={value?.endDate}
        defaultValue={defaultValue?.endDate}
        onChange={(value) => {
          setInternalEndDate(value);
        }}
        slotProps={{
          textField: {
            size: "small",
            margin: "dense",
            sx: { width: fieldsWidth },
          },
        }}
      />
    </div>
  );
}
