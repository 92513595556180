import React, { useContext, useMemo } from "react";
import { getAwesomeName } from "lib/sidebar";
import GlobalContext from "context/GlobalContext";
import { Link } from "react-router-dom";
import NavLink from "./NavLink/NavLink";
import MainActionButton from "./MainActionButton/MainActionButton";
// Icons
import { Notes } from "@mui/icons-material";
// Stylesheet
import styles from "./Sidebar.module.css";
import { useMediaQuery, useTheme } from "@mui/material";
import { excludedSidebarPaths } from "routes";
import { getMatchingIcon } from "./getMatchingIcon";
import { isPlatform } from "@ionic/react";

export default function Sidebar() {
  const theme = useTheme();
  const { user } = useContext(GlobalContext);
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const mainRoutes = useMemo(() => {
    if (isPlatform("mobile") && !isPlatform("mobileweb")) {
      return user?.company.plan.platform_routes.mobile.mainRoutes;
    }
    return user?.company.plan.platform_routes.web.mainRoutes;
  }, [user]);

  return (
    <div className={styles.container}>
      <MainActionButton />
      <div className={styles.navContainer}>
        <ul className={styles.navMenu}>
          {mainRoutes?.map((mainRoute, idx) => {
            if (
              // @ts-expect-error Type string will always be a valid path, because everything comes from the enum HEIMDALL_ROUTE
              !excludedSidebarPaths(user?.role).includes(mainRoute.path) &&
              mainRoute.enabled
            ) {
              if (mainRoute.path === "log/:logType") {
                return (
                  <li key="logType" className={styles.navLink}>
                    <Link to="/log/all">
                      <NavLink
                        hideText={!isLg}
                        name="Ocorrências"
                        img={
                          <Notes
                            width={24}
                            height={24}
                            className={styles.navLinkIcon}
                          />
                        }
                      />
                    </Link>
                  </li>
                );
              }
              return (
                <li key={idx} className={styles.navLink}>
                  <Link to={mainRoute.path}>
                    <NavLink
                      hideText={!isLg}
                      name={getAwesomeName(mainRoute.path)}
                      img={getMatchingIcon(mainRoute.path)}
                    />
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
      <p className={styles.zionsWatermark}>
        {/* <ZionsLogo width={22} style={{ marginRight: "5px" }} /> */}
        <a
          href="https://zionstech.com.br"
          style={{ color: "inherit" }}
          rel="noreferrer"
          target="_blank"
        >
          powered by zions vision
        </a>
      </p>
    </div>
  );
}
