import React from "react";
import { VideocamOff } from "@mui/icons-material";

export function CanvasError(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "75px",
        height: "40px",
        border: "1px solid black",
        ...props.style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {!props.style || !props.style.width ? (
          <VideocamOff fontSize="small" />
        ) : (
          <>
            <VideocamOff fontSize="large" />
            <span>Erro ao obter</span>
            <span>imagem da câmera</span>
          </>
        )}
      </div>
    </div>
  );
}
