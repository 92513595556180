import React, { CSSProperties, useState } from "react";
import { ArrowDropDown, ArrowDropUp, Close } from "@mui/icons-material";
import styles from "./ExpandableContainer.module.css";
import { useHeimdallTheme } from "context/HeimdallThemeProvider";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

export interface ExpandableContainerProps {
  isSm?: boolean;
  title: string;
  error?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  insideOverflow?: boolean;
  smDialogControls?: {
    open?: boolean;
    onClose?: () => void;
  };
}

export default function ExpandableContainer({
  isSm,
  icon,
  error,
  style,
  title,
  children,
  insideOverflow,
  smDialogControls,
}: ExpandableContainerProps) {
  const { isClassic } = useHeimdallTheme();
  const [expanded, setExpanded] = useState(false);

  if (isSm) {
    if (!smDialogControls) {
      throw new Error(
        `Dialog controls must be provided if "isSm" flag is true`
      );
    }
    return (
      <Dialog
        open={smDialogControls.open!}
        onClose={smDialogControls.onClose!}
        fullWidth
      >
        <DialogTitle className="pb-1 text-lg flex items-center justify-between">
          <span>{title}</span>
          <IconButton color="inherit" onClick={smDialogControls.onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-2">{children}</DialogContent>
      </Dialog>
    );
  }

  return (
    <div
      className={`${styles.container} ${
        isClassic ? "primary-container on-primary-container-text" : "surface"
      }`}
      style={
        error
          ? { border: "2px solid rgb(201, 77, 77)", ...style }
          : { boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.2)", ...style }
      }
    >
      <header className={styles.header} onClick={() => setExpanded(!expanded)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {icon}
          <h2 className="ml-3 text-xl">{title}</h2>
        </div>

        {expanded ? (
          <ArrowDropUp style={{ width: "32px", height: "32px" }} />
        ) : (
          <ArrowDropDown style={{ width: "32px", height: "32px" }} />
        )}
      </header>
      <div
        className={`${styles.formContainer} ${
          expanded ? styles.expanded : styles.hidden
        }`}
        style={insideOverflow ? { overflow: "auto" } : {}}
      >
        {children}
      </div>
    </div>
  );
}
