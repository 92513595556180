import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  AnalyticInternalKey,
  AnalyticLog,
  CombinedLog,
  LprLog,
  RadarLog,
} from "interfaces/analytics";
import { Button, Chip, IconButton, Typography } from "@mui/material";
import {
  getOccurrenceMarkColor,
  getTranslation,
} from "./occurrenceMarksTranslation";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { baseUrl } from "api/rootConfig";
import { AnalyticType } from "interfaces/analytics";
import { SpecificLogUrlPath } from "interfaces/occurrences";
import { getResizedImagePath } from "./resizedImage";

interface GetColumnsParameters {
  (
    setSelectedOccurrence: React.Dispatch<
      React.SetStateAction<AnalyticLog | undefined>
    >,
    setModalPhoto: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenOccurrence: React.Dispatch<React.SetStateAction<boolean>>,
    options: ColumnOptions
  ): GridColDef[];
}

interface GetRadarColumnsParameters {
  (
    setSelectedOccurrence: React.Dispatch<
      React.SetStateAction<RadarLog | undefined>
    >,
    setModalPhoto: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenOccurrence: React.Dispatch<React.SetStateAction<boolean>>,
    handlePDF: (row: RadarLog) => void
  ): GridColDef[];
}

interface ColumnOptions {
  includeOccurrence?: boolean;
  isRadar?: boolean;
  isLpr?: boolean;
}

export function getLogNameFromLogType(
  logType: AnalyticInternalKey | SpecificLogUrlPath
): AnalyticType | "Ocorrências" {
  switch (logType) {
    case "all":
      return "Ocorrências";
    case "agglomeration":
      return "Aglomeração";
    case "animal_intrusion":
    case "animal":
      return "Detecção de Animal";
    case "evasion":
      return "Evasão";
    case "intrusion":
      return "Intrusão";
    case "radar":
      return "Radar";
    case "vehicle_intrusion":
    case "vehicle-intrusion":
      return "Detecção de Veículo";
    case "vehicle_evasion":
    case "vehicle-evasion":
      return "Ausência de Veículo";
    case "vehicle_agglomeration":
    case "vehicle-agglomeration":
      return "Congestionamento";
    case "inactivity":
      return "Inatividade";
    case "lpr":
      return "Lpr";
    case "permanence":
      return "Permanência";
    case "panic":
      return "Pânico";
    default:
      throw new Error(`Tipo de analítico "${logType}" não definido`);
  }
}

export function getLogTypeFromLogName(
  logName: AnalyticType
): AnalyticInternalKey {
  switch (logName) {
    case "Intrus\u00E3o":
      return AnalyticInternalKey.intrusion;
    case "Aglomera\u00E7\u00E3o":
      return AnalyticInternalKey.agglomeration;
    case "Evas\u00E3o":
      return AnalyticInternalKey.evasion;
    case "Inatividade":
      return AnalyticInternalKey.inactivity;
    case "Radar":
      return AnalyticInternalKey.radar;
    case "Detec\u00E7\u00E3o de Animal":
      return AnalyticInternalKey.animal_intrusion;
    case "Detec\u00E7\u00E3o de Ve\u00EDculo":
      return AnalyticInternalKey.vehicle_intrusion;
    case "Aus\u00EAncia de Ve\u00EDculo":
      return AnalyticInternalKey.vehicle_evasion;
    case "Congestionamento":
      return AnalyticInternalKey.vehicle_evasion;
    case "P\u00E2nico":
      return AnalyticInternalKey.panic;
    case "Lpr":
      return AnalyticInternalKey.lpr;
    case "Perman\u00EAncia":
      return AnalyticInternalKey.permanence;
    default:
      throw new Error("Nome do analítico não definido");
  }
}

export function convertLogsToCsv(logs: AnalyticLog[]) {
  const header = [
    "Data - Horário",
    "Nome da câmera",
    "Endereço",
    "Ocorrência",
    "Foto",
    "Status",
    "Checado após",
    "Checado por",
  ];
  const csvRows = [header.join(",")];

  logs.forEach((log) => {
    // Ensure names containing commas are wrapped in double quotes
    const nameField = log.camera.camera_name.includes(",")
      ? `"${log.camera.camera_name}"`
      : log.camera.camera_name;

    const cameraAddress = log.camera.camera_address.address;
    const locationField = cameraAddress
      ? cameraAddress.includes(",")
        ? `"${cameraAddress}"`
        : cameraAddress
      : "";

    const row = [
      log.created_at,
      nameField,
      locationField,
      log.occurrence,
      baseUrl + log.url,
      getTranslation(log.check_status),
      log.check_timestamp,
      log.check_user,
    ];
    csvRows.push(row.join(","));
  });

  return csvRows.join("\n");
}

export const getColumns: GetColumnsParameters = function (
  setSelectedOccurrence,
  setModalPhoto,
  setOpenOccurrence,
  options
) {
  const columns: GridColDef<CombinedLog>[] = [
    {
      field: "camera",
      headerName: "Câmera",
      minWidth: 250,
      flex: 1,
      valueGetter(value, row) {
        return row;
      },
      valueFormatter(value, row) {
        return `${row.camera.camera_name}\n${row.camera.camera_address.address}\n${row.url}`;
      },
      renderCell(params) {
        return (
          <div style={{ display: "flex" }}>
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                setSelectedOccurrence(params.row);
                setModalPhoto(true);
              }}
            >
              <img
                src={`${baseUrl}${getResizedImagePath(params.value.url, 70)}`}
                alt="Imagem da ocorrência"
                width={70}
                className="rounded-sm max-h-10 object-cover"
              />
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2">
                {params.value.camera.camera_name}
              </Typography>
              <Typography variant="caption">
                {params.value.camera.camera_address.address}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Horário",
      minWidth: 100,
      renderCell(params) {
        const date = dayjs(params.value, "DD/MM/YYYY HH:mm:ss");
        return (
          <div className="flex flex-col justify-center h-full">
            <Typography variant="subtitle2">
              {date.format("HH:mm:ss")}
            </Typography>
            <Typography variant="caption">
              {date.format("DD MMM, YYYY")}
            </Typography>
          </div>
        );
      },
    },
  ];

  if (options.includeOccurrence) {
    columns.push({
      field: "occurrence",
      headerName: "Ocorrência",
      minWidth: 180,
    });
    columns.push({
      field: "details",
      headerName: "Detalhes",
      minWidth: 150,
    });
  } else if (options.isRadar) {
    columns.push({
      field: "details",
      headerName: "Detalhes",
      minWidth: 160,
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {(params.row as RadarLog).velocity} km/h
            </Typography>
            <Typography variant="caption">
              {(params.row as RadarLog).license_plate}
            </Typography>
          </div>
        );
      },
    });
  } else if (options.isLpr) {
    columns.push({
      field: "details",
      headerName: "Detalhes",
      minWidth: 160,
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {(params.row as LprLog)?.plate_value}
            </Typography>
            <Typography variant="caption">
              {(params.row as LprLog)?.car_color?.[0]}
            </Typography>
          </div>
        );
      },
    });
  }

  columns.push(
    {
      field: "check_status",
      headerName: "Status",
      valueFormatter: (value) => {
        if (value) return getTranslation(value);
      },
      renderCell(params) {
        return (
          <Chip
            label={params.formattedValue}
            sx={
              getOccurrenceMarkColor(params.value) && {
                backgroundColor: getOccurrenceMarkColor(params.value),
                color: "white",
              }
            }
          />
        );
      },
      minWidth: 130,
    },
    {
      field: "check",
      headerName: "Checado por",
      minWidth: 180,
      flex: 1,
      valueGetter(value, row) {
        return row;
      },
      valueFormatter(value) {
        return `${(value as any).check_user || "---"}\n${
          (value as any).check_timestamp || "---"
        }`;
      },
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {params.value.check_user || "---"}
            </Typography>
            <Typography variant="caption">
              {params.value.check_timestamp || "---"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      disableExport: true,
      width: 60,
      renderCell(params) {
        return (
          <IconButton
            onClick={(ev) => {
              ev.stopPropagation();
              setSelectedOccurrence(params.row);
              setOpenOccurrence(true);
            }}
          >
            <EditIcon color="action" />
          </IconButton>
        );
      },
    }
  );

  return columns;
};

export const getPanicColumns: GetColumnsParameters = function (
  setSelectedOccurrence,
  setModalPhoto,
  setOpenOccurrence
) {
  const columns: GridColDef<AnalyticLog>[] = [
    {
      field: "Câmera",
      minWidth: 250,
      renderCell(params) {
        return (
          <div style={{ display: "flex" }}>
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                setSelectedOccurrence(params.row);
                setModalPhoto(true);
              }}
            >
              <img
                width={70}
                src={`${baseUrl}${getResizedImagePath(params.row.url, 70)}`}
                alt="Imagem da ocorrência"
                className="rounded-sm max-h-10 object-cover"
              />
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2">
                {params.row.camera.camera_name}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Horário",
      minWidth: 180,
      renderCell(params) {
        const date = dayjs(params.row.created_at, "DD/MM/YYYY HH:mm:ss");
        return (
          <div className="flex flex-col justify-center h-full">
            <Typography variant="subtitle2">
              {date.format("HH:mm:ss")}
            </Typography>
            <Typography variant="caption">
              {date.format("DD MMM, YYYY")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Pessoa identificada",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "check_status",
      headerName: "Status",
      valueFormatter: (value) => {
        if (value) return getTranslation(value);
      },
      renderCell(params) {
        return (
          <Chip
            label={params.formattedValue}
            sx={
              getOccurrenceMarkColor(params.value) && {
                backgroundColor: getOccurrenceMarkColor(params.value),
                color: "white",
              }
            }
          />
        );
      },
      minWidth: 130,
    },
    {
      field: "check",
      headerName: "Checado por",
      minWidth: 180,
      flex: 1,
      valueGetter(value, row) {
        return row;
      },
      valueFormatter(value) {
        return `${(value as any).check_user || "---"}\n${
          (value as any).check_timestamp || "---"
        }`;
      },
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {params.value.check_user || "---"}
            </Typography>
            <Typography variant="caption">
              {params.value.check_timestamp || "---"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <IconButton
          onClick={(ev) => {
            ev.stopPropagation();
            setSelectedOccurrence(params.row);
            setOpenOccurrence(true);
          }}
        >
          <EditIcon color="action" />
        </IconButton>
      ),
    },
  ];

  return columns;
};

export const getRadarColumns: GetRadarColumnsParameters = function (
  setSelectedOccurrence,
  setModalPhoto,
  setOpenOccurrence,
  handlePDF
) {
  const columns: GridColDef<RadarLog>[] = [
    {
      field: "Camera",
      headerName: "Câmera",
      minWidth: 200,
      flex: 1,
      valueGetter(value, row) {
        return row;
      },
      valueFormatter(value, row) {
        return `${row.camera.camera_name}\n${row.camera.camera_address.address}\n${row.url}`;
      },
      renderCell(params) {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={(ev) => {
                ev.stopPropagation();
                setSelectedOccurrence(params.row);
                setModalPhoto(true);
              }}
              style={{ border: "none", cursor: "pointer" }}
            >
              <img
                width={70}
                alt="Imagem do local"
                style={{ borderRadius: "2px" }}
                className="rounded-sm max-h-10 object-cover"
                src={`${baseUrl}${getResizedImagePath(params.value.url, 70)}`}
              />
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2">
                {params.value.camera.camera_name}
              </Typography>
              <Typography variant="caption">
                {params.value.camera.camera_address.address}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Horário",
      minWidth: 165,
      renderCell(params) {
        const date = dayjs(params.value, "DD/MM/YYYY HH:mm:ss");
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {date.format("HH:mm:ss")}
            </Typography>
            <Typography variant="caption">
              {date.format("DD MMM, YYYY")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "license_plate",
      headerName: "Placa",
    },
    {
      field: "velocity",
      headerName: "Velocidade",
      align: "center",
    },
    {
      field: "check_status",
      headerName: "Status",
      valueFormatter: (value) => {
        if (value) return getTranslation(value);
      },
      renderCell(params) {
        return (
          <Chip
            label={params.formattedValue}
            sx={
              getOccurrenceMarkColor(params.value) && {
                backgroundColor: getOccurrenceMarkColor(params.value),
                color: "white",
              }
            }
          />
        );
      },
    },
    {
      field: "check",
      headerName: "Checado por",
      minWidth: 180,
      flex: 1,
      valueGetter(value, row) {
        return row;
      },
      valueFormatter(value) {
        return `${(value as RadarLog).check_user || "---"}\n${
          (value as RadarLog).check_timestamp || "---"
        }`;
      },
      renderCell(params) {
        return (
          <div className="flex flex-col h-full justify-center">
            <Typography variant="subtitle2">
              {params.value.check_user || "---"}
            </Typography>
            <Typography variant="caption">
              {params.value.check_timestamp || "---"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      align: "center",
      headerAlign: "center",
      disableExport: true,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={(ev) => {
              ev.stopPropagation();
              setSelectedOccurrence(params.row);
              setOpenOccurrence(true);
            }}
          >
            <EditIcon color="action" />
          </IconButton>

          <IconButton
            onClick={(ev) => {
              ev.stopPropagation();
              handlePDF(params.row);
            }}
          >
            <PictureAsPdfIcon style={{ fill: "#f24446" }} />
          </IconButton>
        </>
      ),
    },
  ];

  return columns;
};
