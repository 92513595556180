import React, { useContext, useEffect, useState } from "react";
import { deletePanicDevice, getPanicDevices } from "api/panic";
import { IconButton } from "@mui/material";
import { GridColDef, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import GlobalContext from "context/GlobalContext";

import { useNavigate } from "react-router-dom";
import EditPanicDeviceDialog from "components/Modals/EditPanicDeviceDialog";
import { PanicDevice, PanicLinkedAnalytic } from "interfaces/panicDevice";
import DeleteDialog from "components/Modals/DeleteDialog";
import PanicAssociate from "components/Modals/PanicAssociate";
import MainContainer from "components/MainContainer/MainContainer";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";
import { Add } from "@mui/icons-material";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";

export default function Panic() {
  const { toast } = useToast();
  // Data grid state
  const [devices, setDevices] = useState<PanicDevice[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  // Modal state
  const [id, setID] = useState<string | number>();
  const [panicDevice, setPanicDevice] = useState<PanicDevice>();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAssociate, setOpenAssociate] = useState(false);
  const [analyticID, setAnalyticID] = useState(0);
  const [panicLinkedDevices, setPanicLinkedDevices] = useState<
    PanicLinkedAnalytic[]
  >([]);
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Fetching initial page data
  useEffect(() => {
    async function getData() {
      if (user) {
        const newDevices = await getPanicDevices();

        if (newDevices && newDevices.data) setDevices(newDevices.data);

        setIsLoading(false);
      }
    }

    getData();
  }, [user]);

  useEffect(() => {
    async function getData() {
      if (user) {
        setIsLoading(true);

        const newDevices = await getPanicDevices();
        if (newDevices && newDevices.data) setDevices(newDevices.data);

        setIsLoading(false);
      }
    }

    if (!openEdit) getData();
  }, [openEdit, user]);

  async function handleDelete() {
    setIsLoading(true);

    if (panicDevice) {
      await deletePanicDevice(panicDevice.id)
        .then(async () => {
          toast({
            description: "Dispositivo removido com sucesso",
          });

          const devices = await getPanicDevices();
          if (devices && devices.data) setDevices(devices.data);
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Erro ao remover dispositivo",
            description: getErrorMessage(err),
          });
        });
      setOpen(false);
    }
    setIsLoading(false);
  }

  const columns: GridColDef<PanicDevice>[] = [
    {
      field: "name",
      headerName: "Nome",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "model",
      headerName: "Modelo",
      minWidth: 150,
      valueFormatter(value) {
        if (!value) return "N/D";
        return value;
      },
    },
    {
      field: "ip",
      headerName: "IP / DDNS",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Ações",
      align: "center",
      headerAlign: "center",
      width: 150,
      sortable: false,
      filterable: false,
      // flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                setID(params.id);
                setOpenEdit(true);
              }}
            >
              <EditIcon color="action" />
            </IconButton>
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                setID(params.id);
                setAnalyticID(params.row.analytics[0].id);
                setPanicLinkedDevices(
                  params.row.analytics[0].devices_analytics
                );
                setOpenAssociate(true);
                // if (params.row.device === "Controladora")
                //   setDeviceType("commbox");
                // else setDeviceType("horn");
                // setOpenTest(true);
              }}
            >
              <SettingsIcon color="action" />
            </IconButton>
            <IconButton
              onClick={(ev) => {
                ev.stopPropagation();
                setPanicDevice(params.row);
                setOpen(true);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <MainContainer
        title="Dispositivos de Pânico"
        responsiveActions={[
          {
            title: "Novo dispositivo",
            icon: <Add />,
            action: () => navigate("create-panic"),
          },
        ]}
      >
        <BorderedDataGrid
          withContainer
          rows={devices}
          columns={columns}
          loading={isLoading}
          slots={{ toolbar: GridToolbar }}
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            setIsLoading(true);
            setPaginationModel({
              page: model.page,
              pageSize: model.pageSize,
            });
            setIsLoading(false);
          }}
          pageSizeOptions={[10, 15, 25, 50, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </MainContainer>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={handleDelete}
        title={`Deseja mesmo remover o dispositivo ${panicDevice?.name}?`}
        message="Essa ação não poderá ser revertida"
        isLoading={isLoading}
      />
      <EditPanicDeviceDialog open={openEdit} setOpen={setOpenEdit} id={id} />
      <PanicAssociate
        open={openAssociate}
        onClose={() => setOpenAssociate(false)}
        analyticID={analyticID}
        panicLinkedDevices={panicLinkedDevices}
        title="Associação de dispositivo pânico à dispositivo I/O"
      />
    </>
  );
}
