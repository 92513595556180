import React, { useMemo } from "react";
import {
  Autocomplete,
  Badge,
  Button,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getGroups } from "api/cameraGroup";
import dayjs from "dayjs";
import { FilterList, Search } from "@mui/icons-material";
import { CamerasAndGroups, FilterFormData } from "interfaces/occurrences";
import { getSimplifiedCameras } from "api/cameras";
import ExpandableContainer from "components/ExpandableContainer";
import { useQuery } from "@tanstack/react-query";
import {
  CAMERAS_SIMPLIFIED_QUERY_KEY,
  CAMERA_GROUPS_QUERY_KEY,
} from "constants/apiQueryKeys";
import useFilterMethods from "hooks/useFilterMethods";

interface LogsFilterProps {
  smDialogControls?: {
    open: boolean;
    onClose: () => void;
  };
  isSm?: boolean;
  isRadar?: boolean;
  initialActiveFilters?: number;
  filterFormData: FilterFormData;
  onFormDataChange: (newData: FilterFormData) => void;
  filter: (formData?: FilterFormData) => void;
  setLicensePlate?: (value: string) => void;
  setSpeed?: (value: number) => void;
  clearFilters: () => void;
}

export default function LogsFilter({
  isSm,
  filter,
  isRadar,
  clearFilters,
  filterFormData,
  onFormDataChange,
  smDialogControls,
  initialActiveFilters,
}: LogsFilterProps) {
  const { data: cameras, isLoading: isLoadingCameras } = useQuery({
    queryKey: [CAMERAS_SIMPLIFIED_QUERY_KEY],
    queryFn: () => getSimplifiedCameras(),
  });
  const { data: groups, isLoading: isLoadingGroups } = useQuery({
    queryKey: [CAMERA_GROUPS_QUERY_KEY],
    queryFn: () => getGroups(),
  });
  const camsAndGroups = useMemo(() => {
    const newCamerasAndGroups: CamerasAndGroups[] = [];
    if (!!cameras) {
      cameras.data.forEach((_camera) =>
        newCamerasAndGroups.push({ ..._camera, category: "Câmeras" })
      );
    }
    if (!!groups) {
      groups.data.forEach((_group) =>
        newCamerasAndGroups.push({ ..._group, category: "Grupos" })
      );
    }
    return newCamerasAndGroups;
  }, [cameras, groups]);
  const {
    activeFilters,
    handleClearFilters,
    getTotalActiveFilters,
    handleFilterFormDataChange,
  } = useFilterMethods({
    filterFormData,
    initialActiveFilters,
    onClearFilters: clearFilters,
    onFormDataChange,
  });

  function handleCamsAndGroupsFilter(value?: CamerasAndGroups | null) {
    onFormDataChange({
      ...filterFormData!,
      cameras: value,
    });
  }

  if (isSm && !smDialogControls) {
    throw new Error(`Dialog controls must be provided if "isSm" flag is true`);
  }

  return (
    <ExpandableContainer
      isSm={isSm}
      smDialogControls={{
        open: smDialogControls?.open,
        onClose: smDialogControls?.onClose,
      }}
      title="Filtros avançados"
      icon={
        <>
          {activeFilters > 0 && (
            <Badge
              color="secondary"
              className="absolute top-0 left-5"
              badgeContent={activeFilters}
            />
          )}
          <FilterList color="primary" />
        </>
      }
    >
      <form
        className="grid grid-cols-1 sm:grid-cols-2 gap-2"
        onSubmit={(ev) => {
          ev.preventDefault();
          filter();
          getTotalActiveFilters();
        }}
      >
        <Autocomplete
          disablePortal
          id="cams-and-groups-autocomplete"
          options={camsAndGroups.sort((a, b) =>
            b.category.localeCompare(a.category)
          )}
          size="small"
          loading={isLoadingCameras || isLoadingGroups}
          value={filterFormData.cameras}
          onChange={(ev, newValue) => handleCamsAndGroupsFilter(newValue)}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} name="camerasIds" label="Câmeras e Grupos" />
          )}
        />
        <TextField
          select
          size="small"
          name="status"
          label="Status"
          value={filterFormData!.status}
          onChange={(ev) => handleFilterFormDataChange("text", ev)}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="unattended">Não tratado</MenuItem>
          <MenuItem value="visualized">Visto</MenuItem>
          <MenuItem value="false_positive">Falso positivo</MenuItem>
          <MenuItem value="resident">Morador</MenuItem>
          <MenuItem value="effective">Efetivo</MenuItem>
          <MenuItem value="accidents">Acidente</MenuItem>
          <MenuItem value="vandalism">Vandalismo</MenuItem>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="headless">Sem destinatário</MenuItem>
        </TextField>
        <DateTimePicker
          slotProps={{ textField: { size: "small", label: "Depois de" } }}
          value={filterFormData!.dateAfter}
          onChange={(date) => {
            if (date) {
              handleFilterFormDataChange(
                "dateAfter",
                dayjs(date, "DD-MM-YYYY HH:mm")
              );
            } else handleFilterFormDataChange("dateAfter", null);
          }}
        />
        <DateTimePicker
          slotProps={{ textField: { size: "small", label: "Antes de" } }}
          value={filterFormData!.dateBefore}
          onChange={(date) => {
            if (date) {
              handleFilterFormDataChange(
                "dateBefore",
                dayjs(date, "DD-MM-YYYY HH:mm")
              );
            } else handleFilterFormDataChange("dateBefore", null);
          }}
        />
        <TextField
          select
          size="small"
          name="order"
          label="Ordenação"
          value={filterFormData!.order}
          onChange={(ev) => handleFilterFormDataChange("text", ev)}
        >
          <MenuItem value="desc">Mais recente {`-->`} Mais antigo</MenuItem>
          <MenuItem value="asc">Mais antigo {`-->`} Mais recente</MenuItem>
        </TextField>
        {isRadar && (
          <>
            <TextField
              size="small"
              label="Placa"
              name="licensePlate"
              value={filterFormData?.licensePlate}
              onChange={(ev) => handleFilterFormDataChange("text", ev)}
            />
            <TextField
              size="small"
              name="speed"
              label="Velocidade"
              value={filterFormData?.speed}
              onChange={(ev) => handleFilterFormDataChange("text", ev)}
            />
          </>
        )}
        <section className="flex flex-1 justify-end sm:col-span-2">
          <Button
            onClick={handleClearFilters}
            style={{ marginRight: "10px" }}
            color="error"
          >
            Limpar filtros
          </Button>
          <Button
            type="submit"
            variant="outlined"
            startIcon={<Search />}
            onSubmit={(ev) => {
              ev.preventDefault();
              filter();
              getTotalActiveFilters();
            }}
          >
            Buscar
          </Button>
        </section>
      </form>
    </ExpandableContainer>
  );
}
