import axios from "axios";
import { toast } from "components/ui/use-toast";
import { externalSetUser } from "context/GlobalContextProvider";
import { cleanUp } from "lib/logoutUtils";
import { isPlatform } from "@ionic/react";

function getBaseUrl() {
  let baseUrl;
  const isDev = process.env.NODE_ENV === "development";

  if (isDev) {
    // development
    baseUrl = process.env.REACT_APP_DEV_API_URL;
  } else if (!isDev && isPlatform("mobile") && !isPlatform("mobileweb")) {
    // mobile in production
    const mobileBaseUrl = localStorage.getItem("mobileBaseUrl");
    if (mobileBaseUrl) {
      baseUrl = mobileBaseUrl;
    } else {
      // This might be redundant
      baseUrl = "https://heimdallcloud.com.br";
    }
  } else if (!isDev) {
    // browser in production
    baseUrl = window.location.origin;
  }

  if (!baseUrl)
    throw new Error(
      "Erro na definição do endereço da API. Cheque as variáveis de ambiente"
    );

  return baseUrl;
}

export const baseUrl = getBaseUrl();
const urlAux = new URL(baseUrl);
export const basePort = urlAux.port;
export const baseUrlNoPort = `${urlAux.protocol}//${urlAux.hostname}`;

let token: string | null = null;
token = localStorage.getItem("token");

// if (!token) throw new Error("Falha no login");

const axiosRoot = axios.create({
  baseURL: baseUrl,
  timeout: 8500,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
});

export function updateBaseUrl(newBaseUrl: string) {
  localStorage.setItem("mobileBaseUrl", newBaseUrl);
  axiosRoot.defaults.baseURL = newBaseUrl;
}

axiosRoot.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response) {
      const tokenExpired = err.response.data.message === "Token has expired";
      const tokenBlacklisted =
        err.response.data.message === "The token has been blacklisted";
      if (tokenExpired || tokenBlacklisted) {
        toast({
          variant: "destructive",
          description: err.response.data.message,
        });
        cleanUp();
        externalSetUser(null);
      }
    }
    return Promise.reject(err);
  }
);

export default axiosRoot;
