import { BackendResponsePagination } from "interfaces/generic";
import axiosRoot from "./rootConfig";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";

export async function getGroups(page = 1, pageSize = 10000) {
  return await axiosRoot
    .get<BackendResponsePagination<HeimdallCameraGroup>>(
      "/api/camera_group?page=" + page,
      {
        headers: {
          paginate: pageSize,
        },
      }
    )
    .then(function (response) {
      return response.data;
    });
}

export async function createGroup(name: string) {
  return await axiosRoot
    .post("/api/camera_group", { name })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export async function deleteGroup(id: number) {
  return await axiosRoot.delete("/api/camera_group/" + id);
}

export async function putGroup(
  id: number,
  data: { name?: string; cameras_in_group?: number[] }
) {
  return await axiosRoot.put("/api/camera_group/" + id, data);
}
