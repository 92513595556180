import { RemoveCircle } from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
import {
  DispatcherReceiver,
  isTelegramReceiver,
  isUserReceiver,
  isWhatsAppGroupReceiver,
  isWhatsAppReceiver,
  TelegramReceiver,
  UserReceiver,
  WhatsAppGroupReceiver,
} from "interfaces/newAlertSystem";
import React, { useMemo } from "react";
import { ReceiverType } from "./ConfigureAnalytic";
import { ReceiverIcon } from "./ReceiverIcon";

export function SimpleReceiver({
  receiver,
  onRemove,
}: {
  receiver: DispatcherReceiver;
  onRemove: (receiver: DispatcherReceiver) => void;
}) {
  const telegramPlaceholder = "ID";
  const whatsappPlaceholder = "ID do grupo";
  const userPlaceholder = "Selecionar usuário";

  const type = useMemo(() => {
    if (isWhatsAppGroupReceiver(receiver)) {
      return ReceiverType.whatsapp;
    }
    if (isTelegramReceiver(receiver)) {
      return ReceiverType.telegram;
    }
    if (isUserReceiver(receiver)) {
      return ReceiverType.user;
    }
    return null;
  }, [receiver]);

  const value = useMemo(() => {
    if (isWhatsAppGroupReceiver(receiver)) {
      return receiver.groupId;
    }
    if (isTelegramReceiver(receiver)) {
      return receiver.id;
    }
    if (isUserReceiver(receiver)) {
      return receiver.name;
    }
  }, [receiver]);

  const placeholder = useMemo(() => {
    if (isWhatsAppGroupReceiver(receiver)) {
      return whatsappPlaceholder;
    }
    if (isTelegramReceiver(receiver)) {
      return telegramPlaceholder;
    }
    if (isUserReceiver(receiver)) {
      return userPlaceholder;
    }
  }, [receiver]);

  return (
    <div className="flex items-center justify-between space-x-1">
      <TextField
        disabled
        fullWidth
        size="small"
        value={value}
        placeholder={placeholder}
        InputProps={{
          style: { borderRadius: "20px" },
          startAdornment: <ReceiverIcon type={type} />,
        }}
      />
      <IconButton color="error" onClick={() => onRemove(receiver)}>
        <RemoveCircle fontSize="large" />
      </IconButton>
    </div>
  );
}
