export function translateRole(role: string) {
  switch (role) {
    case "collaborator":
      return "Operador";
    case "manager":
      return "Administrador";
    case "super-admin":
      return "Super usuário";
    default:
      return "---";
  }
}

export function isValidEmail(email: string) {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function isValidOperator(
  name: string,
  username: string,
  email: string,
  password: string,
  confirm: string,
  isEditing?: boolean
) {
  if (isEditing) {
    if (
      !name ||
      !username ||
      username.length < 4 ||
      username.length > 15 ||
      !email ||
      !isValidEmail(email)
    )
      return false;
  } else {
    if (
      !name ||
      !username ||
      username.length < 4 ||
      username.length > 15 ||
      !email ||
      !isValidEmail(email) ||
      !password ||
      password.length < 6 ||
      (password && !confirm) ||
      (password && confirm !== password)
    )
      return false;
  }

  return true;
}
