import { redirect } from "react-router-dom";
import { getUser } from "api/user";

const protectedRouteLoader = async () => {
  let token = localStorage.getItem("token");
  if (!token) token = sessionStorage.getItem("token");

  console.log("Running protected route loader");
  if (token) {
    return await getUser()
      .then((user) => {
        return user;
      })
      .catch((err) => {
        return redirect("/auth");
      });
  }

  return redirect("/auth");
};

export default protectedRouteLoader;
