import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { basePort, baseUrl } from "api/rootConfig";
import PasswordInput from "components/PasswordInput/PasswordInput";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z
  .object({
    app_url: z.string(),
    password: z.string().min(1, "Campo obrigatório"),
    current_password: z.string().min(1, "Campo obrigatório"),
    password_confirmation: z.string().min(1, "Campo obrigatório"),
  })
  .refine(
    (arg) => {
      return arg.password === arg.password_confirmation;
    },
    { message: "As senhas devem coincidir", path: ["password_confirmation"] }
  );

export type NewPassword = z.infer<typeof formSchema>;

const baseUrlObject = new URL(baseUrl);

export default function ChangePasswordDialog({
  open,
  isSubmitting,
  onClose,
  onSubmit,
}: {
  open: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (data: NewPassword) => Promise<void>;
}) {
  const form = useForm<NewPassword>({
    defaultValues: {
      app_url: baseUrlObject.hostname + (basePort ? `:${basePort}` : ""),
      password_confirmation: "",
      current_password: "",
      password: "",
    },
    resolver: zodResolver(formSchema),
  });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (ev: React.FormEvent<HTMLFormElement>) =>
          form.handleSubmit((data) => {
            ev.preventDefault();
            onSubmit(data);
            form.reset();
          }),
      }}
    >
      <DialogTitle>Alteração de senha</DialogTitle>
      <DialogContent>
        <div className="flex flex-col space-y-3 pt-2">
          <PasswordInput
            size="small"
            label="Senha atual"
            inputProps={{ ...form.register("current_password") }}
            error={!!form.formState.errors.current_password}
            helperText={form.formState.errors.current_password?.message}
          />
          <PasswordInput
            size="small"
            label="Nova senha"
            inputProps={{ ...form.register("password") }}
            error={!!form.formState.errors.password}
            helperText={form.formState.errors.password?.message}
          />
          <PasswordInput
            size="small"
            label="Confirmar senha"
            inputProps={{ ...form.register("password_confirmation") }}
            error={!!form.formState.errors.password_confirmation}
            helperText={form.formState.errors.password_confirmation?.message}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button type="button" color="error" onClick={onClose}>
          Cancelar
        </Button>
        <LoadingButton
          loading={isSubmitting}
          onClick={form.handleSubmit(async (data) => {
            await onSubmit(data);
            form.reset();
          })}
        >
          Alterar senha
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
