import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {  getDevice } from "api/ioDevices";
import { LoadingButton } from "@mui/lab";
import PasswordInput from "components/PasswordInput/PasswordInput";
import { HeimdallDeviceTypes } from "interfaces/devices";

interface EditDeviceDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | number;
  deviceType?: HeimdallDeviceTypes;
  onSubmit: (deviceType: HeimdallDeviceTypes, id: string | number, data: {
    name: string;
    ip: string;
    port: number |string;
    user: string;
    password: string;
  }) => Promise<void>
}

export default function EditDeviceDialog({
  open,
  id,
  deviceType,
  onSubmit,
  setOpen,
}: EditDeviceDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [ip, setIP] = useState("");
  const [ipError, setIPError] = useState(false);
  const [port, setPort] = useState("");
  const [portError, setPortError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      let device;

      if (id && deviceType) device = await getDevice(deviceType, id);

      if (device) {
        setName(device.name);
        if (!device.user) setUsername("");
        else setUsername(device.user);
        if (!device.password) setPassword("");
        else setPassword(device.password);
        setIP(device.ip);
        setPort(device.port as string);
      }
    }

    if (open) {
      getData();

      setIPError(false);
      setPortError(false);
      setNameError(false);
      setUsernameError(false);
      setPasswordError(false);
    }
  }, [id, open, deviceType]);

  async function handleSubmit(ev: FormEvent) {
    ev.preventDefault();
    const requestData = {
      name,
      ip,
      port,
      user: username,
      password,
    };
    if (!id || !deviceType) return;

    if (!name) setNameError(true);
    if (!ip) setIPError(true);
    if (!port) setPortError(true);
    if (deviceType === "horn_speaker" && !username) setUsernameError(true);
    if (deviceType === "horn_speaker" && !password) setPasswordError(true);
    if (
      !deviceType ||
      !name ||
      !ip ||
      (deviceType === "horn_speaker" && !username) ||
      (deviceType === "horn_speaker" && !password) ||
      !port
    )
      return;

    setIsLoading(true);
    await onSubmit(deviceType, id, requestData);
    setIsLoading(false);
    setOpen(false);
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      fullScreen={fullScreen}
      onClose={() => setOpen(false)}
      PaperProps={{
        component: "form",
        noValidate: true,
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          handleSubmit(event);
        },
      }}
    >
      <DialogTitle>Edição de dispositivo I/O</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption">
          Atualize os campos do dispositivo, caso realmente seja necessário.
        </DialogContentText>
        <div className="my-3 grid grid-cols-1 sm:grid-cols-2 gap-3">
          <TextField
            select
            disabled
            size="small"
            value={deviceType}
            label="Tipo de dipositivo"
          >
            <MenuItem value="">Nenhum</MenuItem>
            <MenuItem value="commbox">Commbox</MenuItem>
            <MenuItem value="horn_speaker">Corneta</MenuItem>
          </TextField>
          <TextField
            label="Nome"
            size="small"
            value={name}
            error={nameError}
            helperText={nameError ? "Você deve fornecer um nome" : ""}
            onChange={(ev) => {
              setNameError(false);
              setName(ev.target.value);
            }}
          />

          <TextField
            label="IP"
            size="small"
            value={ip}
            error={ipError}
            helperText={ipError ? "Você deve fornecer um endereço de IP" : ""}
            onChange={(ev) => {
              setIPError(false);
              setIP(ev.target.value);
            }}
          />
          <TextField
            label="Porta"
            type="number"
            size="small"
            value={port}
            error={portError}
            helperText={portError ? "Você deve fornecer uma porta" : ""}
            onChange={(ev) => {
              setPortError(false);
              setPort(ev.target.value);
            }}
          />

          <TextField
            label="Usuário"
            size="small"
            value={username}
            error={usernameError}
            required={deviceType === "horn_speaker"}
            helperText="Nome de usuário para acesso ao dispositivo"
            onChange={(ev) => {
              setUsernameError(false);
              setUsername(ev.target.value);
            }}
          />
          <PasswordInput
            label="Senha"
            size="small"
            value={password}
            error={passwordError}
            required={deviceType === "horn_speaker"}
            helperText="Senha para acesso ao dispositivo"
            onChange={(
              ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setPasswordError(false);
              setPassword(ev.target.value);
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <LoadingButton
          type="submit"
          variant="text"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Salvar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
