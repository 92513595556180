import { drawAllRegions } from "lib/canvas";
import { RegionDescription, RegionMonitor } from "interfaces/analytics";
import React, { useEffect, useRef } from "react";

export default function AllRegionCanvas({
  regions,
  show,
  isInactivityTEMP,
  dimensions,
}: {
  regions: RegionDescription | RegionMonitor | undefined;
  show: boolean;
  isInactivityTEMP?: boolean;
  dimensions: { width: number; height: number };
}) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const context = canvasRef.current?.getContext("2d");

  useEffect(() => {
    if (regions && context && show && !isInactivityTEMP)
      drawAllRegions(regions, context, dimensions);
  }, [regions, context, show, dimensions, isInactivityTEMP]);

  return (
    <canvas
      ref={canvasRef}
      width={dimensions.width}
      height={dimensions.height}
      style={{
        zIndex: 2,
        position: "absolute",
        display: show ? "block" : "none",
      }}
    />
  );
}
