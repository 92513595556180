import React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PetsIcon from "@mui/icons-material/Pets";
import GroupsIcon from "@mui/icons-material/Groups";
import SpeedIcon from "@mui/icons-material/Speed";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import WarningIcon from "@mui/icons-material/Warning";
import { AirlineSeatReclineExtra } from "@mui/icons-material";
import {
  CarArrowRight,
  CarCrash,
  CarMultiple,
  CarSearch,
  StandedPerson,
} from "components/icons/thirdPartyIcons";
import { cn } from "lib/classNames";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SpecificLogUrlPath } from "interfaces/occurrences";

interface DashboardCardProps {
  value: number | string;
  title: string;
}

export default function DashboardCard({ value, title }: DashboardCardProps) {
  const navigate = useNavigate();
  function navigateToSpecificLog() {
    const specificLog = logPathFromTitle(title);
    if (!!specificLog) {
      navigate("/log/" + specificLog);
    }
  }
  return (
    <ButtonBase className="w-full rounded-lg" onClick={navigateToSpecificLog}>
      <div className="flex flex-col justify-around p-3 w-full min-w-40 rounded-lg shadow-md secondary-container on-secondary-container-text">
        <header className="flex justify-between items-center">
          <span
            className={cn(
              "text-start",
              title === "Congestionamento" ? "text-base" : "text-lg"
            )}
          >
            {title}
          </span>
          <CardIcon type={title} />
        </header>
        <div className="flex items-baseline">
          <span className="text-xl sm:text-2xl font-bold">{value}</span>
          {!title.includes("Câmeras") && (
            <span className="text-base ml-2">ocorrências</span>
          )}
        </div>
        {/* <Button variant="outlined" color="secondary" className="max-w-32">Ver detalhes</Button> */}
        {/* <span className={styles.legend}>Último mês</span> */}
      </div>
    </ButtonBase>
  );
}

function logPathFromTitle(title: string): SpecificLogUrlPath | void {
  switch (title) {
    case "Aglomeração":
      return SpecificLogUrlPath.agglomeration;
    case "Detecção de animais":
      return SpecificLogUrlPath.animal;
    case "Placas reconhecidas":
      return SpecificLogUrlPath.lpr;
    case "Intrusão de veículos":
      return SpecificLogUrlPath.vehicle_intrusion;
    case "Ausência de veículos":
      return SpecificLogUrlPath.vehicle_evasion;
    case "Congestionamento":
      return SpecificLogUrlPath.vehicle_agglomeration;
    case "Evasão":
      return SpecificLogUrlPath.evasion;
    case "Intrusão":
      return SpecificLogUrlPath.intrusion;
    case "Permanência":
      return SpecificLogUrlPath.permanence;
    case "Inatividade":
      return SpecificLogUrlPath.inactivity;
    case "Infrações de radar":
      return SpecificLogUrlPath.radar;
    case "Total":
      return SpecificLogUrlPath.all;
    default:
      return;
  }
}
function CardIcon({ type }: { type: string }) {
  switch (type) {
    case "Aglomeração":
      return <GroupsIcon />;
    case "Câmeras ativas":
      return <VideocamIcon />;
    case "Câmeras inativas":
      return <VideocamOffIcon />;
    case "Detecção de animais":
      return <PetsIcon />;
    case "Placas reconhecidas":
      return <CarSearch fill="var(--root-color-on-secondary-container)" />;
    case "Intrusão de veículos":
      return <CarArrowRight fill="var(--root-color-on-secondary-container)" />;
    case "Ausência de veículos":
      return <CarCrash fill="var(--root-color-on-secondary-container)" />;
    case "Congestionamento":
      return <CarMultiple fill="var(--root-color-on-secondary-container)" />;
    case "Evasão":
      return <DirectionsRunIcon style={{ transform: "scale(-1,1)" }} />;
    case "Intrusão":
      return <DirectionsRunIcon />;
    case "Permanência":
      return (
        <StandedPerson
          className="size-6"
          fill="var(--root-color-on-secondary-container)"
        />
      );
    case "Inatividade":
      return <AirlineSeatReclineExtra />;
    case "Infrações de radar":
      return <SpeedIcon />;
    case "Total":
      return <WarningIcon />;
    default:
      return null;
  }
}
