import { BackendResponsePagination } from "interfaces/generic";
import axiosRoot from "./rootConfig";
import { MoniIntegration } from "interfaces/moni";

export async function createMoniIntegration(data: FormData) {
  return await axiosRoot.post("/api/monitoring", data).then((res) => res.data);
}

export async function updateMoniIntegration(id: number, data: FormData) {
  return await axiosRoot
    .post("/api/monitoring/" + id + "?_method=PUT", data)
    .then((res) => res.data);
}

export async function deleteMoniIntegration(id: number) {
  return await axiosRoot
    .delete("/api/monitoring/" + id)
    .then((res) => res.data);
}

export async function getMoniIntegration(id: number) {
  return await axiosRoot
    .get<{ data: MoniIntegration }>("/api/monitoring/" + id)
    .then((res) => res.data);
}

export async function getMoniIntegrations(
  {
    page = 1,
    pageSize = 1000000,
  }: {
    page: number;
    pageSize: number;
  } = { page: 1, pageSize: 1000000 }
) {
  return await axiosRoot
    .get<BackendResponsePagination<MoniIntegration>>(
      "/api/monitoring?page=" + page,
      {
        headers: {
          paginate: pageSize,
        },
      }
    )
    .then((res) => res.data);
}
