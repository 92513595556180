import React, { useMemo } from "react";
import { CombinedLog, LprLog, RadarLog } from "interfaces/analytics";
import { LogCard } from "./LogCard";
import { Skeleton, useMediaQuery } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface LogsCardViewerProps {
  rows: CombinedLog[];
  isLoading?: boolean;
  setSelectedOccurrence: ((log: RadarLog) => void) | ((log: LprLog) => void);
  setOpenExpanded: (value: boolean) => void;
  setOpenEdit: (value: boolean) => void;
}

const mapArray = [1, 2, 3, 4, 5, 6];

function Row({
  data,
  index,
  style,
  breakpoints,
}: ListChildComponentProps<Omit<LogsCardViewerProps, "isLoading">> & {
  breakpoints?: {
    isSmallest?: boolean;
    isXxs?: boolean;
  };
}) {
  const currentRow = data.rows[index];

  return (
    <div style={{ ...style, marginBottom: "12px" }}>
      <LogCard
        breakpoints={breakpoints}
        row={currentRow}
        key={currentRow.id + currentRow.occurrence}
        setOpenEdit={data.setOpenEdit}
        setOpenExpanded={data.setOpenExpanded}
        setSelectedOccurrence={data.setSelectedOccurrence}
      />
    </div>
  );
}

export function LogsCardViewer({
  rows,
  isLoading,
  setOpenEdit,
  setOpenExpanded,
  setSelectedOccurrence,
}: LogsCardViewerProps) {
  const isXxs = useMediaQuery("(max-width: 450px)");
  const isSmallest = useMediaQuery("(max-width: 350px)");
  const height = window.innerHeight - 120;
  const listItemContext = useMemo(() => {
    return {
      rows,
      setOpenEdit,
      setOpenExpanded,
      setSelectedOccurrence,
    };
  }, [rows, setOpenEdit, setOpenExpanded, setSelectedOccurrence]);

  return (
    <div className="flex flex-col space-y-3">
      {isLoading ? (
        mapArray.map((value) => (
          <Skeleton key={value} variant="rounded" width="100%" height={205} />
        ))
      ) : (
        <FixedSizeList
          height={height}
          width="100%"
          itemSize={220}
          itemData={listItemContext}
          itemCount={rows.length}
          overscanCount={5}
        >
          {(props) => <Row {...props} breakpoints={{ isXxs, isSmallest }} />}
        </FixedSizeList>
      )}
    </div>
  );
}
