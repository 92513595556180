import { CallMissed } from "@mui/icons-material";
import MainContainer from "components/MainContainer/MainContainer";
import React from "react";

export default function Help() {
  return (
    <MainContainer title="Suporte">
      <br />
      <h3>Atendimento nos dias úteis, das 08:30 às 12:00, e das 13:00 às 18:00</h3>
      <br />
      <h3 style={{ marginBottom: "10px" }}>ENDEREÇO</h3>
      <h3>Rua André Gonçalves, 130, Campinas - SP</h3>
      <h3 style={{ marginBlock: "10px" }}>Telefone</h3>
      <h3>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/+5519935000999?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20do%20suporte%21"
        >
          (19) 93500-0999
        </a>
      </h3>
      <h3 style={{ marginBlock: "10px" }}>EMAIL</h3>
      <h3>
        <a href="mailto:zions@zionstech.com?subject=Suporte">
          zions@zionstech.com
        </a>
      </h3>
      <h2 style={{ marginTop: "30px" }}>
        Para mais informações, visite nosso{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.zionstech.com.br/"
          style={{ display: "inline-flex", alignItems: "flex-end" }}
        >
          <span className="primary-text">website</span>
          <CallMissed color="primary" style={{ transform: "scale(-1, 1)" }} />
        </a>
      </h2>
    </MainContainer>
  );
}
