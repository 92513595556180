import { ArrowBack, CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { confirmEmail } from "api/auth";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// TODO: fazer redirecionamento automatico para a tela de login, e adicionar texto avisando
// sobre esse redirecionamento

export default function ConfirmEmail() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const params = useSearchParams();
  const userId = params[0].get("id");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function handleConfirmEmail() {
      if (!userId) throw new Error("ID de usuário não definido");
      await confirmEmail(parseInt(userId))
        .then(() => {
          toast({
            description: "Email confirmado com sucesso",
          });
          setSuccess(true);
        })
        .catch((err) => {
          setErrorMessage(getErrorMessage(err));
          toast({
            variant: "destructive",
            description: getErrorMessage(err),
          });
        });
    }
    handleConfirmEmail();
  }, [toast, userId]);

  return (
    <div className="flex h-screen items-center justify-center p-2">
      <div className="flex-1 max-w-md shadow-lg p-3">
        <h4 className="text-xl mb-5 text-center">Confirmação de email</h4>
        {success && (
          <div className="text-center">
            <div>Email confirmado com sucesso!</div>
            <CheckCircle color="success" fontSize="large" />
          </div>
        )}
        {errorMessage !== "" && (
          <div className="text-center">{errorMessage}</div>
        )}
        <div className="m-auto text-center">
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            className="mt-5 text-center"
            onClick={() => navigate("/auth")}
          >
            Acessar plataforma
          </Button>
        </div>
      </div>
    </div>
  );
}
