import { ArrowBack, CheckCircle, HighlightOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { confirmUpdateEmail } from "api/auth";
import { getErrorMessage } from "lib/apiErrorHandler";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// TODO: fazer redirecionamento automatico para a tela de login, e adicionar texto avisando
// sobre esse redirecionamento

export default function ConfirmUpdateEmail() {
  const navigate = useNavigate();
  const params = useSearchParams();
  const userId = params[0].get("id");
  const oldEmail = params[0].get("old-email");
  const newEmail = params[0].get("new-email");
  const token = params[0].get("token-email");
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    async function handleConfirmUpdateEmail() {
      if (!userId || !oldEmail || !newEmail || !token) {
        setErrorMessage("Dados insuficientes");
        return;
      }

      await confirmUpdateEmail({
        userId: parseInt(userId),
        oldEmail,
        newEmail,
        token,
      })
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          setErrorMessage(getErrorMessage(err));
        });
    }
    handleConfirmUpdateEmail();
  }, [newEmail, oldEmail, token, userId]);

  return (
    <div className="h-dvh flex justify-center items-center p-2">
      <div className="flex flex-1 flex-col items-center max-w-md min-h-24 shadow-lg p-3 space-y-3">
        <h4 className="text-xl">Confirmação de email</h4>
        {success && (
          <>
            <CheckCircle fontSize="large" color="success" />
            <p>Seu email foi alterado com sucesso</p>
          </>
        )}
        {!!errorMessage && (
          <>
            <HighlightOff fontSize="large" color="error" />
            <p>{errorMessage}</p>
          </>
        )}
        <div className="m-auto text-center">
          <Button
            variant="outlined"
            className="mt-3 text-center"
            onClick={() => navigate("/auth")}
            startIcon={<ArrowBack />}
          >
            Acessar plataforma
          </Button>
        </div>
      </div>
    </div>
  );
}
