import { CorePalette } from "@material/material-color-utilities";

export class AuxScheme {
  private readonly props;
  get primary(): number {
    return this.props.primary;
  }
  get onPrimary(): number {
    return this.props.onPrimary;
  }
  get primaryContainer(): number {
    return this.props.primaryContainer;
  }
  get onPrimaryContainer(): number {
    return this.props.onPrimaryContainer;
  }
  get secondary(): number {
    return this.props.secondary;
  }
  get onSecondary(): number {
    return this.props.onSecondary;
  }
  get secondaryContainer(): number {
    return this.props.secondaryContainer;
  }
  get onSecondaryContainer(): number {
    return this.props.onSecondaryContainer;
  }
  get tertiary(): number {
    return this.props.tertiary;
  }
  get onTertiary(): number {
    return this.props.onTertiary;
  }
  get tertiaryContainer(): number {
    return this.props.tertiaryContainer;
  }
  get onTertiaryContainer(): number {
    return this.props.onTertiaryContainer;
  }
  get error(): number {
    return this.props.error;
  }
  get onError(): number {
    return this.props.onError;
  }
  get errorContainer(): number {
    return this.props.errorContainer;
  }
  get onErrorContainer(): number {
    return this.props.onErrorContainer;
  }
  get background(): number {
    return this.props.background;
  }
  get onBackground(): number {
    return this.props.onBackground;
  }
  get surface(): number {
    return this.props.surface;
  }
  get onSurface(): number {
    return this.props.onSurface;
  }
  get surfaceVariant(): number {
    return this.props.surfaceVariant;
  }
  get onSurfaceVariant(): number {
    return this.props.onSurfaceVariant;
  }
  get outline(): number {
    return this.props.outline;
  }
  get outlineVariant(): number {
    return this.props.outlineVariant;
  }
  get shadow(): number {
    return this.props.shadow;
  }
  get scrim(): number {
    return this.props.scrim;
  }
  get inverseSurface(): number {
    return this.props.inverseSurface;
  }
  get inverseOnSurface(): number {
    return this.props.inverseOnSurface;
  }
  get inversePrimary(): number {
    return this.props.inversePrimary;
  }
  /**
   * @param argb ARGB representation of a color.
   * @return Light Material color scheme, based on the color's hue.
   */
  static light(argb: number): AuxScheme {
    return AuxScheme.lightFromCorePalette(CorePalette.of(argb));
  }
  /**
   * @param argb ARGB representation of a color.
   * @return Dark Material color scheme, based on the color's hue.
   */
  static dark(argb: number): AuxScheme {
    return AuxScheme.darkFromCorePalette(CorePalette.of(argb));
  }
  /**
   * @param argb ARGB representation of a color.
   * @return Light Material content color scheme, based on the color's hue.
   */
  static lightContent(argb: number): AuxScheme {
    return AuxScheme.lightFromCorePalette(CorePalette.contentOf(argb));
  }
  /**
   * @param argb ARGB representation of a color.
   * @return Dark Material content color scheme, based on the color's hue.
   */
  static darkContent(argb: number): AuxScheme {
    return AuxScheme.darkFromCorePalette(CorePalette.contentOf(argb));
  }
  /**
   * Light scheme from core palette
   */
  static lightFromCorePalette(core: CorePalette): AuxScheme {
    return new AuxScheme({
      primary: core.a1.tone(40),
      onPrimary: core.a1.tone(100),
      primaryContainer: core.a1.tone(90),
      onPrimaryContainer: core.a1.tone(10),
      secondary: core.a2.tone(40),
      onSecondary: core.a2.tone(100),
      secondaryContainer: core.a2.tone(90),
      onSecondaryContainer: core.a2.tone(10),
      tertiary: core.a3.tone(40),
      onTertiary: core.a3.tone(100),
      tertiaryContainer: core.a3.tone(90),
      onTertiaryContainer: core.a3.tone(10),
      error: core.error.tone(40),
      onError: core.error.tone(100),
      errorContainer: core.error.tone(90),
      onErrorContainer: core.error.tone(10),
      background: core.n1.tone(99),
      onBackground: core.n1.tone(10),
      surface: core.n1.tone(99),
      onSurface: core.n1.tone(10),
      surfaceVariant: core.n2.tone(90),
      onSurfaceVariant: core.n2.tone(30),
      outline: core.n2.tone(50),
      outlineVariant: core.n2.tone(80),
      shadow: core.n1.tone(0),
      scrim: core.n1.tone(0),
      inverseSurface: core.n1.tone(20),
      inverseOnSurface: core.n1.tone(95),
      inversePrimary: core.a1.tone(80),
    });
  }
  /**
   * Dark scheme from core palette
   */
  static darkFromCorePalette(core: CorePalette): AuxScheme {
    return new AuxScheme({
      primary: core.a1.tone(80),
      onPrimary: core.a1.tone(20),
      primaryContainer: core.a1.tone(30),
      onPrimaryContainer: core.a1.tone(90),
      secondary: core.a2.tone(80),
      onSecondary: core.a2.tone(20),
      secondaryContainer: core.a2.tone(30),
      onSecondaryContainer: core.a2.tone(90),
      tertiary: core.a3.tone(80),
      onTertiary: core.a3.tone(20),
      tertiaryContainer: core.a3.tone(30),
      onTertiaryContainer: core.a3.tone(90),
      error: core.error.tone(80),
      onError: core.error.tone(20),
      errorContainer: core.error.tone(30),
      onErrorContainer: core.error.tone(80),
      background: core.n1.tone(10),
      onBackground: core.n1.tone(90),
      surface: core.n1.tone(10),
      onSurface: core.n1.tone(90),
      surfaceVariant: core.n2.tone(30),
      onSurfaceVariant: core.n2.tone(80),
      outline: core.n2.tone(60),
      outlineVariant: core.n2.tone(30),
      shadow: core.n1.tone(0),
      scrim: core.n1.tone(0),
      inverseSurface: core.n1.tone(90),
      inverseOnSurface: core.n1.tone(20),
      inversePrimary: core.a1.tone(40),
    });
  }
  constructor(props: {
    primary: number;
    onPrimary: number;
    primaryContainer: number;
    onPrimaryContainer: number;
    secondary: number;
    onSecondary: number;
    secondaryContainer: number;
    onSecondaryContainer: number;
    tertiary: number;
    onTertiary: number;
    tertiaryContainer: number;
    onTertiaryContainer: number;
    error: number;
    onError: number;
    errorContainer: number;
    onErrorContainer: number;
    background: number;
    onBackground: number;
    surface: number;
    onSurface: number;
    surfaceVariant: number;
    onSurfaceVariant: number;
    outline: number;
    outlineVariant: number;
    shadow: number;
    scrim: number;
    inverseSurface: number;
    inverseOnSurface: number;
    inversePrimary: number;
  }) {
    this.props = props;
  }
  toJSON() {
    return { ...this.props };
  }
}
