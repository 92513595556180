import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { testCommbox, testHornSpeaker } from "api/flaskApiMethods";
import { getDevice } from "api/ioDevices";
import { LoadingButton } from "@mui/lab";
import { HeimdallDeviceTypes } from "interfaces/devices";
import { useToast } from "components/ui/use-toast";

interface TestDeviceDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | number;
  deviceType?: HeimdallDeviceTypes;
}

export default function TestDeviceDialog({
  open,
  setOpen,
  id,
  deviceType,
}: TestDeviceDialogProps) {
  const { toast } = useToast();
  const [audio, setAudio] = useState("1");
  const [output, setOutput] = useState("1");
  const [replayCount, setReplayCount] = useState("1");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAudio("1");
    setOutput("1");
    setReplayCount("1");
  }, [open]);

  async function handleSubmit() {
    if (id && deviceType) {
      setIsLoading(true);
      const device = await getDevice(deviceType, id);
      if (!device) {
        setIsLoading(false);
        return;
      }
      if (deviceType === "horn_speaker") {
        const response = await testHornSpeaker(
          device.ip,
          device.port,
          audio,
          replayCount,
          device.password!
        );
        if (!response)
          toast({
            variant: "destructive",
            description: "Erro na comunicação com o dispositivo",
          });
      } else {
        const response = await testCommbox(
          device.ip,
          device.port,
          output,
          replayCount
        );
        if (!response)
          toast({
            variant: "destructive",
            description: "Erro na comunicação com o dispositivo",
          });
      }

      setIsLoading(false);
    }
  }

  return (
    <Dialog fullWidth open={open} maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>Testar dispositivo I/O</DialogTitle>
      <DialogContent>
        <DialogContentText variant="caption" sx={{ marginBottom: "10px" }}>
          Preencha os campos e clique em testar.
        </DialogContentText>
        <form>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <TextField
              fullWidth
              label="Tipo de dipositivo"
              select
              disabled
              size="small"
              value={deviceType}
            >
              <MenuItem value="">Nenhum</MenuItem>
              <MenuItem value="commbox">Commbox</MenuItem>
              <MenuItem value="horn">Corneta</MenuItem>
            </TextField>
            {deviceType === "horn_speaker" ? (
              <TextField
                fullWidth
                label="Áudio"
                size="small"
                value={audio}
                inputProps={{ min: "1", max: "4", type: "number" }}
                onChange={(ev) => {
                  if (!ev.target.value) setAudio("");
                  else if (Number(ev.target.value) <= 0) setAudio("1");
                  else if (Number(ev.target.value) <= 5)
                    setAudio(ev.target.value);
                  else if (Number(ev.target.value) > 5) setAudio("5");
                  else setAudio("");
                }}
              />
            ) : (
              <TextField
                fullWidth
                label="Saida"
                type="number"
                size="small"
                value={output}
                inputProps={{ min: "1" }}
                onChange={(ev) => {
                  if (!ev.target.value) setOutput("");
                  else if (Number(ev.target.value) <= 0) setOutput("1");
                  else if (Number(ev.target.value) > 0)
                    setOutput(ev.target.value);
                  else setOutput("");
                }}
              />
            )}
            {deviceType === "horn_speaker" ? (
              <TextField
                label="Número de repetições"
                type="number"
                size="small"
                value={replayCount}
                inputProps={{ min: "1", max: "5" }}
                onChange={(ev) => {
                  if (!ev.target.value) setReplayCount("");
                  else if (Number(ev.target.value) <= 0) setReplayCount("1");
                  else if (Number(ev.target.value) <= 5)
                    setReplayCount(ev.target.value);
                  else if (Number(ev.target.value) > 5) setReplayCount("5");
                  else setReplayCount("");
                }}
              />
            ) : (
              <TextField
                label="Duracao (segundos)"
                type="number"
                size="small"
                value={replayCount}
                inputProps={{ min: "1" }}
                onChange={(ev) => {
                  setReplayCount(ev.target.value);
                }}
              />
            )}
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={() => setOpen(false)}>
          Fechar
        </Button>
        <LoadingButton
          variant="text"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Testar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
