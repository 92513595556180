import { useQuery } from "@tanstack/react-query";
import { getCamera } from "api/cameras";
import { getCameraFrame } from "api/flaskApiMethods";
import ConfigureAnalytic from "components/AddCamera/ConfigureAnalytic/ConfigureAnalytic";
import MainContainer from "components/MainContainer/MainContainer";
import { CAMERA_BY_ID_QUERY_KEY } from "constants/apiQueryKeys";
import { AnalyticType } from "interfaces/analytics";
import {
  convertAnalyticToAnalyticForm,
  getMatchingAnalytic,
} from "lib/addCamera";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export default function ConfigureAnalyticPage() {
  const params = useParams();
  const { data: camera } = useQuery({
    queryKey: [CAMERA_BY_ID_QUERY_KEY(params.cameraId!)],
    queryFn: () => getCamera(params.cameraId!),
  });
  const {
    isStale,
    data: cameraFrame,
    isFetching: isLoadingFrame,
    refetch: refetchFrame,
  } = useQuery({
    staleTime: 5 * 60 * 1000,
    enabled: !!camera,
    queryKey: ["camera-frame", camera?.rtsp],
    queryFn: () => getCameraFrame(camera!.rtsp),
  });
  const analytic = useMemo(() => {
    if (!!camera) {
      return convertAnalyticToAnalyticForm(
        getMatchingAnalytic(camera, params.analyticType! as AnalyticType)
      );
    }
    return null;
  }, [camera, params]);
  const title = useMemo(() => {
    return (
      <div>
        <h2 className="text-lg sm:text-xl max-w-xl line-clamp-1">
          {`${params.analyticType} - ${camera?.name}`}
        </h2>
        <h2 className="max-w-xl">{camera?.camera_address.address}</h2>
      </div>
    );
  }, [camera, params]);

  return (
    <MainContainer returnAction title={title}>
      {!!analytic && (
        <ConfigureAnalytic
          cameraFrame={{
            img: cameraFrame ? cameraFrame.img : "",
            isLoading: isLoadingFrame,
            refetchFrame: async () => {
              if (isStale) await refetchFrame();
            },
            isStale,
          }}
          // updateCamera={updateCamera}
          updateCamera={async () => {}}
          cameraAnalytic={analytic}
        />
      )}
    </MainContainer>
  );
}
