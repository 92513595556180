import { PlayArrow } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { getCameraFrame } from "api/flaskApiMethods";
import { baseUrlNoPort } from "api/rootConfig";
import { HeimdallCamera } from "interfaces/heimdallCamera";
import React, { useEffect, useState } from "react";

export default function InfoWindowContent({
  camera,
}: {
  camera: HeimdallCamera;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [cameraFrame, setCameraFrame] = useState<string>();
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    async function fetchCameraFrame() {
      await getCameraFrame(camera.rtsp)
        .then((res) => {
          setCameraFrame(res.img);
        })
        .catch((err) => console.error(err));

      setIsLoading(false);
    }

    fetchCameraFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h2>{camera.name}</h2>
      <h4>{camera.camera_address.address}</h4>
      <h4 style={{ marginBottom: "5px" }}>
        Latitude: {camera.camera_address.coordinate?.latitude} | Longitude:{" "}
        {camera.camera_address.coordinate?.longitude}
      </h4>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            marginBlock: "10px",
            width: "400px",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : showVideo ? (
        <iframe
          allowFullScreen
          title="Camera video"
          src={baseUrlNoPort + camera.video_media_mtx}
          className="w-full h-40 md:h-96"
        ></iframe>
      ) : (
        // <LiveStream
        //   videoSrc={camera.video}
        //   isRtsp={camera.rtsp.includes("rtsp")}
        //   style={{ maxWidth: "400px", marginBlock: "10px" }}
        // />
        <>
          <div style={{ position: "relative" }}>
            <img
              src={`data:image/jpeg;base64,${cameraFrame}`}
              alt={`Frame da camera ${camera.name}`}
              width="400px"
              height="215px"
            />
            <IconButton
              size="large"
              style={{
                position: "absolute",
                top: "90px",
                left: "170px",
                zIndex: 20,
              }}
              onClick={() => setShowVideo(true)}
            >
              <PlayArrow style={{ fill: "white" }} />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}
