import React, { useState, useEffect } from "react";
import { getLogs } from "api/logs";
import { useNavigate, useParams } from "react-router-dom";
import { getPanicColumns, getColumns } from "lib/logs";
import { AnalyticLog, CombinedLog, RadarLog } from "interfaces/analytics";
import { FilterFormData, SpecificLogUrlPath } from "interfaces/occurrences";
// Components
import StyledDataGrid from "./StyledDataGrid";
import LogsGridToolbar from "./LogsGridToolbar";
import { GridPaginationModel, useGridApiRef } from "@mui/x-data-grid";
import ExpandImageModal from "components/Modals/ExpandImageModal";
import LogsFilter from "components/logs-filter/LogsFilter";
import MainContainer from "components/MainContainer/MainContainer";
import LogsMultiViewer from "components/LogsMultiViewer/LogsMultiViewer";
import DataGridContainer from "components/DataGridContainer/DataGridContainer";
import SelectLogType from "./SelectLogType";
import EditLogModal from "components/Modals/EditLogModal";
import CustomNoRowsOverlay from "components/CustomNoRowsOverlay/CustomNoRowsOverlay";
import { IconButton, useMediaQuery } from "@mui/material";
import { BackendResponsePagination } from "interfaces/generic";
import { Search } from "@mui/icons-material";
import { HeimdallCameraGroup } from "interfaces/heimdallCamera";

const initialFilterData: Partial<FilterFormData> = {
  cameras: null,
  status: "",
  dateAfter: null,
  dateBefore: null,
  order: "desc",
};

export default function SpecificLog() {
  const navigate = useNavigate();
  const params = useParams();
  const gridApiRef = useGridApiRef();
  const logType = params.logType as SpecificLogUrlPath;
  const isSm = useMediaQuery("(max-width: 600px)");
  const [openFilter, setOpenFilter] = useState(false);
  // Modal state
  const [photoModal, setModalPhoto] = useState(false);
  const [selectedOccurrence, setSelectedOccurrence] = useState<AnalyticLog>();
  const [openEdit, setOpenEdit] = useState(false);
  // Grid state
  const [isLoading, setIsLoading] = useState(false);
  const [logData, setLogData] =
    useState<BackendResponsePagination<CombinedLog>>();
  const [totalPages, setTotalPages] = useState(0);
  const [filterData, setFilterData] =
    useState<FilterFormData>(initialFilterData);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(totalPages || 0);

  useEffect(() => {
    async function fetchLogs() {
      setIsLoading(true);
      const logs = await getLogs({
        type: logType,
        page: 1,
        pageSize: 15,
      });

      if (logs) {
        setLogData(logs);
        setTotalPages(logs.meta.total);
      }
      setIsLoading(false);
    }
    fetchLogs();
  }, [logType]);

  const filterProps = {
    filter,
    clearFilters,
    filterFormData: filterData,
    onFormDataChange: handleFilterChange,
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalPages !== undefined ? totalPages : prevRowCountState
    );
  }, [totalPages, setRowCountState]);

  function handleFilterChange(filterFormData: FilterFormData) {
    setFilterData(filterFormData);
  }

  function handleChangeLogType(
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const logPath = ev.target.value;
    clearFilters(true);
    navigate(logPath);
  }

  async function clearFilters(avoidRefetch?: boolean) {
    if (avoidRefetch) {
      setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
      setFilterData(initialFilterData);
      return;
    }
    setIsLoading(true);
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });

    const data = await getLogs({
      type: logType,
      page: 1,
      pageSize: paginationModel.pageSize,
    });

    if (data) {
      setFilterData(initialFilterData);
      setTotalPages(data.meta.total);
      setLogData(data);
    }
    setIsLoading(false);
  }

  async function filter() {
    setIsLoading(true);
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    const data = await getLogs({
      type: logType,
      page: 1,
      pageSize: paginationModel.pageSize,
      camerasIds: (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
        ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
            .map((camera) => camera.id)
            .join(",")
        : filterData.cameras?.id.toString(),
      status: filterData.status,
      timestampAfter: filterData.dateAfter?.format("YYYY-MM-DD[%20]HH[%3A]mm"),
      timestampBefore: filterData.dateBefore?.format(
        "YYYY-MM-DD[%20]HH[%3A]mm"
      ),
      order: filterData.order,
    });

    if (data) {
      setTotalPages(data.meta.total);
      setLogData(data);
    }
    // console.log("Dados filtrados:", data);
    setIsLoading(false);
  }

  async function onPaginationModelChange(model: GridPaginationModel) {
    setIsLoading(true);
    // console.log(model, "Mudei o paginationModel");

    const newData = await getLogs({
      type: logType,
      page: model.page + 1,
      pageSize: model.pageSize,
      camerasIds: (filterData.cameras as HeimdallCameraGroup)?.cameras_in_group
        ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
            .map((camera) => camera.id)
            .join(",")
        : filterData.cameras?.id.toString(),
      status: filterData.status,
      timestampAfter: filterData.dateAfter?.format("YYYY-MM-DD[%20]HH[%3A]mm"),
      timestampBefore: filterData.dateBefore?.format(
        "YYYY-MM-DD[%20]HH[%3A]mm"
      ),
    });

    if (newData) {
      setLogData(newData);

      setPaginationModel({
        page: model.page,
        pageSize: model.pageSize,
      });
    }

    setIsLoading(false);
  }

  return (
    <MainContainer
      title={
        <div className="flex w-full justify-between items-center pb-2">
          <SelectLogType
            logType={logType}
            onSelectLogType={handleChangeLogType}
          />
          <IconButton className="sm:hidden" color="inherit" onClick={() => setOpenFilter(true)}>
            <Search />
          </IconButton>
        </div>
      }
    >
      <LogsFilter
        isSm={isSm}
        smDialogControls={{
          open: openFilter,
          onClose() {
            setOpenFilter(false);
          },
        }}
        {...filterProps}
      />
      {logType === "all" ? (
        isSm ? (
          <StyledDataGrid
            apiRef={gridApiRef}
            data={logData}
            rows={logData?.data || []}
            loading={isLoading}
            paginationMode="server"
            rowCount={rowCountState}
            disableRowSelectionOnClick
            setOpenEdit={setOpenEdit}
            setOpenExpanded={setModalPhoto}
            setSelectedOccurrence={setSelectedOccurrence}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
              toolbar: () =>
                LogsGridToolbar({
                  before: filterData.dateBefore?.format(
                    "YYYY-MM-DD[%20]HH[%3A]mm"
                  ),
                  cameras: (filterData.cameras as HeimdallCameraGroup)
                    ?.cameras_in_group
                    ? (
                        filterData.cameras as HeimdallCameraGroup
                      ).cameras_in_group
                        .map((camera) => camera.id)
                        .join(",")
                    : filterData.cameras?.id.toString(),
                  after: filterData.dateAfter?.format(
                    "YYYY-MM-DD[%20]HH[%3A]mm"
                  ),
                  status: filterData.status,
                  type: logType,
                }),
            }}
            columns={getColumns(
              setSelectedOccurrence,
              setModalPhoto,
              setOpenEdit,
              { includeOccurrence: true }
            )}
            paginationModel={paginationModel}
            pageSizeOptions={[15, 25, 50, 100]}
            getRowId={(row) => row.id + " " + row.occurrence}
            onPaginationModelChange={onPaginationModelChange}
            getRowClassName={(params) =>
              `theme--${params.row.occurrence.replace(/\s+/g, "")}`
            }
          />
        ) : (
          <DataGridContainer
            containerStyle={{
              height:
                logData && logData.data.length > 0
                  ? `${53 * paginationModel.pageSize + 190}px`
                  : "450px",
            }}
          >
            <StyledDataGrid
              apiRef={gridApiRef}
              data={logData}
              rows={logData?.data || []}
              loading={isLoading}
              paginationMode="server"
              rowCount={rowCountState}
              disableRowSelectionOnClick
              setOpenEdit={setOpenEdit}
              setOpenExpanded={setModalPhoto}
              setSelectedOccurrence={setSelectedOccurrence}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
                toolbar: () =>
                  LogsGridToolbar({
                    before: filterData.dateBefore?.format(
                      "YYYY-MM-DD[%20]HH[%3A]mm"
                    ),
                    cameras: (filterData.cameras as HeimdallCameraGroup)
                      ?.cameras_in_group
                      ? (
                          filterData.cameras as HeimdallCameraGroup
                        ).cameras_in_group
                          .map((camera) => camera.id)
                          .join(",")
                      : filterData.cameras?.id.toString(),
                    after: filterData.dateAfter?.format(
                      "YYYY-MM-DD[%20]HH[%3A]mm"
                    ),
                    status: filterData.status,
                    type: logType,
                    order: filterData.order,
                  }),
              }}
              columns={getColumns(
                setSelectedOccurrence,
                setModalPhoto,
                setOpenEdit,
                { includeOccurrence: true }
              )}
              paginationModel={paginationModel}
              pageSizeOptions={[15, 25, 50, 100]}
              getRowId={(row) => row.id + " " + row.occurrence}
              onPaginationModelChange={onPaginationModelChange}
              getRowClassName={(params) =>
                `theme--${params.row.occurrence.replace(/\s+/g, "")}`
              }
            />
          </DataGridContainer>
        )
      ) : (
        <LogsMultiViewer
          withContainer
          apiRef={gridApiRef}
          data={logData}
          rows={logData?.data || []}
          loading={isLoading}
          paginationMode="server"
          rowCount={rowCountState}
          disableRowSelectionOnClick
          setOpenEdit={setOpenEdit}
          setOpenExpanded={setModalPhoto}
          setSelectedOccurrence={setSelectedOccurrence}
          slots={{
            toolbar: () =>
              LogsGridToolbar({
                before: filterData.dateBefore?.format(
                  "YYYY-MM-DD[%20]HH[%3A]mm"
                ),
                cameras: (filterData.cameras as HeimdallCameraGroup)
                  ?.cameras_in_group
                  ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
                      .map((camera) => camera.id)
                      .join(",")
                  : filterData.cameras?.id.toString(),
                after: filterData.dateAfter?.format("YYYY-MM-DD[%20]HH[%3A]mm"),
                status: filterData.status,
                type: logType,
              }),
          }}
          columns={
            logType === "panic"
              ? getPanicColumns(
                  setSelectedOccurrence,
                  setModalPhoto,
                  setOpenEdit,
                  {}
                )
              : getColumns(setSelectedOccurrence, setModalPhoto, setOpenEdit, {
                  includeOccurrence: false,
                  isRadar: logType === "radar",
                  isLpr: logType === "lpr",
                })
          }
          paginationModel={paginationModel}
          pageSizeOptions={[15, 25, 50, 100]}
          getRowId={(row) => row.id + " " + row.occurrence}
          onPaginationModelChange={onPaginationModelChange}
        />
      )}
      {selectedOccurrence && (
        <>
          <ExpandImageModal
            open={photoModal}
            setOpen={setModalPhoto}
            log={selectedOccurrence as RadarLog}
            location={selectedOccurrence.camera.camera_address.address}
            title={`${selectedOccurrence.camera.camera_name}, às ${selectedOccurrence.created_at}`}
          />
          <EditLogModal
            open={openEdit}
            log={selectedOccurrence as RadarLog}
            onClose={() => setOpenEdit(false)}
            onSubmit={async () => {
              setIsLoading(true);
              const newOccurrences = await getLogs({
                type: logType,
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
                camerasIds: (filterData.cameras as HeimdallCameraGroup)
                  ?.cameras_in_group
                  ? (filterData.cameras as HeimdallCameraGroup).cameras_in_group
                      .map((camera) => camera.id)
                      .join(",")
                  : filterData.cameras?.id.toString(),
                status: filterData.status,
                timestampAfter: filterData.dateAfter?.format(
                  "YYYY-MM-DD[%20]HH[%3A]mm"
                ),
                timestampBefore: filterData.dateBefore?.format(
                  "YYYY-MM-DD[%20]HH[%3A]mm"
                ),
              });

              if (newOccurrences) {
                setLogData(newOccurrences);
                setTotalPages(newOccurrences.meta.total);
              }

              setIsLoading(false);
              setOpenEdit(false);
            }}
            location={`Local: ${selectedOccurrence?.camera.camera_address.address}`}
            title={`${selectedOccurrence?.camera.camera_name}, às ${selectedOccurrence?.created_at}`}
          />
        </>
      )}
    </MainContainer>
  );
}
