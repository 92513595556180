import { OccurrenceMark } from "interfaces/occurrences";
import { ScheduleTime, ScheduleTimeForm } from "./heimdallCamera";
import { PanicLinkedAnalytic } from "./panicDevice";
import { DispatcherListData } from "./alertSystem";
import { Dayjs } from "dayjs";

export type RegionMonitor = [number, number][][];
export interface RegionDescription {
  [region: string]: [number, number][];
}

// Type guard for RegionMonitor
export function isRegionMonitor(obj: any): obj is RegionMonitor {
  if (Array.isArray(obj)) {
    // Check the structure of the array to match RegionMonitor
    return obj.every(
      (layer) =>
        Array.isArray(layer) &&
        layer.every((coord) => Array.isArray(coord) && coord.length === 2)
    );
  }
  return false;
}

// Type guard for RegionDescription
export function isRegionDescription(obj: any): obj is RegionDescription {
  if (typeof obj === "object" && obj !== null) {
    // Check if each property is a string-keyed array of [number, number]
    return Object.keys(obj).every((key) => {
      const value = obj[key];
      return (
        typeof key === "string" &&
        Array.isArray(value) &&
        value.every((coord) => Array.isArray(coord) && coord.length === 2)
      );
    });
  }
  return false;
}

type AlertSystemInfo = {
  whatsapp?: {
    name: string;
    number: string;
    status: number;
    is_group: boolean;
    is_video: boolean;
  }[];
};

export interface AnalyticLog {
  id: number;
  occurrence: AnalyticType;
  rule: number;
  region_dic: RegionDescription | RegionMonitor | undefined;
  bbox_lst: "[]" | number[][];
  simi_lst: number[];
  class_lst: any;
  recipient_users: { id: number; name: string }[];
  alert_system_info: AlertSystemInfo | null;
  alert_system_recipient_users: AlertSystemInfo | null;
  alert_system_recipient_user_mail: AlertSystemInfo | null;
  alert_system_recipient_user_whatsapp: AlertSystemInfo | null;
  url: string;
  check_status: OccurrenceMark;
  check_user_id: number;
  check_user: string;
  check_description: string;
  check_timestamp: string;
  created_at: string;
  video: string;
  camera_id: number;
  camera: {
    id: number;
    camera_name: string;
    ip: string;
    rtsp: string;
    remarks: string;
    camera_address: {
      id: number;
      cep: string;
      address: string;
      district: null | string;
      city: null | string;
      state: null | string;
      description: null | string;
      coordinate: string;
    };
  };
}

export interface RadarLog extends AnalyticLog {
  occurrence: "Radar";
  license_plate: string;
  velocity: number;
  region_dic: undefined;
}

export interface LprLog extends AnalyticLog {
  car_color: string[];
  car_color_confs: number[];
  car_confidence: number;
  car_coords: [number, number, number, number];
  plate_coords: [number, number, number, number];
  plate_digits_confs: number[];
  plate_digits_coords: [number, number, number, number][];
  plate_value: string;
  plate_value_debug: string;
}

export type CombinedLog = AnalyticLog | RadarLog | LprLog;

export function isAnalyticLog(log: CombinedLog): log is AnalyticLog {
  return "type" in log;
}

export function isRadarLog(log: CombinedLog): log is RadarLog {
  return (
    "occurrence" in log &&
    "license_plate" in log &&
    "velocity" in log &&
    !("region_dic" in log)
  );
}

export function isLprLog(log: CombinedLog): log is LprLog {
  return (
    "car_color" in log &&
    "car_color_confs" in log &&
    "car_confidence" in log &&
    "car_coords" in log &&
    "plate_coords" in log &&
    "plate_digits_confs" in log &&
    "plate_digits_coords" in log &&
    "plate_value" in log &&
    "plate_value_debug" in log
  );
}

export interface Analytic {
  id: number;
  camera: {
    id: number;
    camera_name: string;
    ip: string;
    rtsp: string;
    remarks: string;
    camara_address: any;
  };
  activated: boolean;
  pause: boolean;
  duration_time: string;
  expiration_time: string;
  similarity: number;
  region_monitor: RegionMonitor;
  schedule_time: ScheduleTime | string | null; // JSON.stringify
  service_sleep_duration: number | string;
  analytic_type: AnalyticType;
  machine_id: number;
  created_at: string;
  devices_analytics: PanicLinkedAnalytic[];
  alerts_analytics: DispatcherListData[];
  userMutedAnalytic: {
    id: number;
    user_id: number;
    name: string;
    username: string;
    email: string;
  }[];
  intrusion: {
    id: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  } | null;
  agglomeration: {
    id: number;
    target_detection_number: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  } | null;
  evasion: {
    id: number;
    limit_time: string;
    target_detection_number: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  } | null;
  inactivity: {
    id: number;
    limit_time: string;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    movement_threshold: number;
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  } | null;
  radar: {
    id: number;
    max_velocity: number;
    deleted_at: string | null;
  } | null;
  lpr: {
    id: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    black_list: null | any[];
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  };
  animal_intrusion: {
    id: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    target: string;
    deleted_at: string | null;
  } | null;
  vehicle_intrusion: {
    id: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    target: string;
    deleted_at: string | null;
  } | null;
  vehicle_agglomeration: {
    id: number;
    target_detection_number: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    target: string;
    deleted_at: string | null;
  } | null;
  vehicle_evasion: {
    id: number;
    limit_time: string;
    target_detection_number: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    target: string;
    deleted_at: string | null;
  } | null;
  permanence: {
    id: number;
    bbox_min_size: number;
    bbox_max_size: number;
    iou_max_val: number;
    violation_queue_size: number;
    violation_max_num: number;
    deleted_at: string | null;
  } | null;
}

export interface AnalyticForm
  extends Omit<Analytic, "schedule_time" | "service_sleep_duration"> {
  schedule_time: ScheduleTimeForm;
  service_sleep_duration: Dayjs | null;
  // analyticType: AnalyticType;
  // machineId: number;
  // createdAt: string;
  // devicesAnalytics: PanicLinkedAnalytic[];
  integration_params: CreateAnalytic["integration_params"];
}

export interface CreateAnalytic extends Analytic {
  target_detection_number?: number;
  limit_time?: string;
  movement_threshold?: number | string;
  integration_params: null | {
    moni: {
      moniIntegrationId: number;
      sectorId: string;
    }[];
    gear: {}[];
  };
}

export enum AnalyticInternalKey {
  agglomeration = "agglomeration",
  evasion = "evasion",
  inactivity = "inactivity",
  intrusion = "intrusion",
  permanence = "permanence",
  vehicle_agglomeration = "vehicle_agglomeration",
  vehicle_evasion = "vehicle_evasion",
  vehicle_intrusion = "vehicle_intrusion",
  lpr = "lpr",
  radar = "radar",
  panic = "panic",
  animal_intrusion = "animal_intrusion",
}

export type AnalyticType =
  | "Intrusão"
  | "Aglomeração"
  | "Evasão"
  | "Inatividade"
  | "Radar"
  | "Detecção de Animal"
  | "Detecção de Veículo"
  | "Ausência de Veículo"
  | "Congestionamento"
  | "Pânico"
  | "Lpr"
  | "Permanência";
