import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getOperators } from "api/operators";
import { HeimdallOperator, HeimdallOperatorRoles } from "interfaces/operator";
import { deleteOperator } from "api/operators";
import { Dayjs } from "dayjs";
import { useToast } from "components/ui/use-toast";
import { getErrorMessage } from "lib/apiErrorHandler";
// Components
import { GridPaginationModel, GridToolbar } from "@mui/x-data-grid";
import PermissionsDialog from "components/Operator/PermissionsDialog";
import MainContainer from "components/MainContainer/MainContainer";
import BorderedDataGrid from "components/BorderedDataGrid/BorderedDataGrid";
import { OperatorsFilter } from "../../components/Operator/OperatorsFilter";
import { getOperatorsColumns } from "./operatorsColumns";
import DeleteDialog from "components/Modals/DeleteDialog";
// Icons
import { Add } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { OPERATORS_QUERY_KEY } from "constants/apiQueryKeys";

export default function Operators() {
  const { toast } = useToast();
  const navigate = useNavigate();
  // Grid data state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  // Modal state
  const [open, setOpen] = useState(false);
  const [openPermissions, setOpenPermissions] = useState(false);
  const [operator, setOperator] = useState<HeimdallOperator | null>(null);
  // Filter state
  const [name, setName] = useState("");
  const [role, setRole] = useState<HeimdallOperatorRoles | "">("");
  const [dateBefore, setDateBefore] = useState<Dayjs | null>(null);
  const [dateAfter, setDateAfter] = useState<Dayjs | null>(null);
  const {
    data: operators,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [OPERATORS_QUERY_KEY],
    queryFn: () =>
      getOperators({
        page: paginationModel.page,
        // pageSize: paginationModel.pageSize, // No page size for client side pagination
        name,
        role,
        dateAfter: dateAfter?.format("YYYY-MM-DD HH:mm:ss"),
        dateBefore: dateBefore?.format("YYYY-MM-DD HH:mm:ss"),
      }),
  });

  async function handleDelete() {
    if (!operator) return;
    await deleteOperator(operator.id)
      .then(async (res) => {
        refetch();
        toast({
          description: "Usuário removido com sucesso",
        });
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          title: "Erro ao remover usuário",
          description: getErrorMessage(err),
        });
      });

    setOpen(false);
  }

  async function onPaginationModelChange(model: GridPaginationModel) {
    setPaginationModel({
      page: model.page,
      pageSize: model.pageSize,
    });
  }

  async function handleUpdatePermissions() {
    await refetch();
    setOpenPermissions(false);
  }

  async function clearFilters() {
    setName("");
    setRole("");
    setDateAfter(null);
    setDateBefore(null);
    setPaginationModel({ page: 0, pageSize: paginationModel.pageSize });
    setTimeout(() => {
      refetch();
    }, 300);
  }

  return (
    <MainContainer
      title="Usuários"
      responsiveActions={[
        {
          title: "Novo usuário",
          icon: <Add />,
          action: () => navigate("create-operator"),
        },
      ]}
    >
      <OperatorsFilter
        name={name}
        role={role}
        dateAfter={dateAfter}
        dateBefore={dateBefore}
        setRole={setRole}
        setName={setName}
        setDateAfter={setDateAfter}
        setDateBefore={setDateBefore}
        clearFilters={clearFilters}
        filter={() => {
          refetch();
        }}
      />
      <BorderedDataGrid
        withContainer
        rows={operators?.data || []}
        columns={getOperatorsColumns({
          navigate,
          setOpen,
          setOperator,
          setOpenPermissions,
        })}
        loading={isLoading || isFetching}
        slots={{ toolbar: GridToolbar }}
        paginationModel={paginationModel}
        pageSizeOptions={[10, 15, 25, 50, 100]}
        onPaginationModelChange={(model) => onPaginationModelChange(model)}
        disableRowSelectionOnClick
        // SEARCH FIELD
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      <DeleteDialog
        open={open}
        onSubmit={handleDelete}
        onClose={() => setOpen(false)}
        title={`Tem certeza que deseja remover o usuário ${operator?.name}?`}
      />
      {operator && (
        <PermissionsDialog
          open={openPermissions}
          onClose={() => {
            setOperator(null);
            setOpenPermissions(false);
          }}
          operator={operator}
          handleSubmit={handleUpdatePermissions}
          title={`Gerenciamento de visualização de câmeras - Usuário ${operator.name}`}
          subtitle="Habilite os usuários a editar e visualizar câmeras e grupos, fornecendo as permissões necessárias."
        />
      )}
    </MainContainer>
  );
}
