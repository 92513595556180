import { Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import MainContainer from "components/MainContainer/MainContainer";
import React from "react";

export default function Feedback() {
  return (
    <MainContainer title="Feedback">
      <p>Críticas, sugestões ou elogios? Escreva seu feedback abaixo!</p>
      <TextField
        multiline
        minRows={4}
        style={{ marginBlock: "15px" }}
        placeholder="Nos conte o que você acha do heimdall..."
      />
      <LoadingButton variant="contained" startIcon={<Send />}>
        Enviar
      </LoadingButton>
    </MainContainer>
  );
}
