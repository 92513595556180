import { useQuery } from "@tanstack/react-query";
import { getCamera } from "api/cameras";
import SelectAnalytic from "components/AddCamera/SelectAnalytic/SelectAnalytic";
import MainContainer from "components/MainContainer/MainContainer";
import { CAMERA_BY_ID_QUERY_KEY } from "constants/apiQueryKeys";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigureAnalytic from "components/AddCamera/ConfigureAnalytic/ConfigureAnalytic";
import { AnalyticType } from "interfaces/analytics";
import {
  convertAnalyticToAnalyticForm,
  getMatchingAnalytic,
} from "lib/addCamera";
import { getCameraFrame } from "api/flaskApiMethods";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

export default function ConfigureAnalyticsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: camera, refetch: refetchCamera } = useQuery({
    queryKey: [CAMERA_BY_ID_QUERY_KEY(params.cameraId!)],
    queryFn: () => getCamera(params.cameraId!),
  });
  const {
    isStale,
    data: cameraFrame,
    isFetching: isLoadingFrame,
    refetch: refetchFrame,
  } = useQuery({
    staleTime: 5 * 60 * 1000,
    enabled: !!camera,
    queryKey: ["camera-frame", camera?.rtsp],
    queryFn: () => getCameraFrame(camera!.rtsp),
  });
  const [analyticType, setAnalyticType] = useState<AnalyticType | null>(null);
  const title = useMemo(() => {
    return (
      <div>
        <h2 className="text-lg sm:text-xl max-w-xl line-clamp-1">
          {`${
            analyticType ? analyticType : "Configurar analíticos"
          } - ${camera?.name}`}
        </h2>
        <h2 className="max-w-xl">{camera?.camera_address.address}</h2>
      </div>
    );
  }, [camera, analyticType]);

  function handleReturn() {
    if (!!analyticType) {
      setAnalyticType(null);
      return;
    }

    navigate(-1);
  }

  return (
    <MainContainer
      title={title}
      returnAction={
        <IconButton color="secondary" onClick={handleReturn}>
          <ArrowBack />
        </IconButton>
      }
    >
      {!analyticType ? (
        <SelectAnalytic
          camera={camera}
          setAnalyticType={setAnalyticType}
          onActivateAnalytic={async () => {
            await refetchCamera();
          }}
        />
      ) : (
        camera && (
          <ConfigureAnalytic
            cameraFrame={{
              img: cameraFrame ? cameraFrame.img : "",
              isLoading: isLoadingFrame,
              refetchFrame: async () => {
                if (isStale) await refetchFrame();
              },
              isStale,
            }}
            updateCamera={() => refetchCamera()}
            cameraAnalytic={convertAnalyticToAnalyticForm(
              getMatchingAnalytic(camera, analyticType),
              camera.integration_params
            )}
          />
        )
      )}
    </MainContainer>
  );
}
