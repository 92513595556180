import { BackendResponsePagination } from "interfaces/generic";
import axiosRoot from "./rootConfig";
import { LprBlacklist } from "interfaces/lpr";

export async function getLprBlacklists(page = 1) {
  return await axiosRoot
    .get<BackendResponsePagination<LprBlacklist>>(
      "/api/blacklist_lpr?page=" + page,
      {
        headers: {
          paginate: 1000000,
        },
      }
    )
    .then((res) => res.data);
}

export async function createBlacklistedPlate(data: FormData) {
  return await axiosRoot
    .post("/api/blacklist_lpr", data)
    .then((res) => res.data);
}

export async function putBlacklistedPlate(id: number, data: FormData) {
  return await axiosRoot
    .post("/api/blacklist_lpr/" + id + "?_method=PUT", data)
    .then((res) => res.data);
}

export async function deleteBlacklistedPlate(id: number) {
  return await axiosRoot
    .delete("/api/blacklist_lpr/" + id)
    .then((res) => res.data);
}
