import { Delete } from "@mui/icons-material";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  createBlacklistedPlate,
  deleteBlacklistedPlate,
  getLprBlacklists,
} from "api/lpr";
import MainContainer from "components/MainContainer/MainContainer";
import DeleteDialog from "components/Modals/DeleteDialog";
import { useToast } from "components/ui/use-toast";
import { LprBlacklist } from "interfaces/lpr";
import { getErrorMessage } from "lib/apiErrorHandler";
import React, { useState } from "react";

export default function LprPermissions() {
  const { toast } = useToast();
  const [licensePlate, setLicensePlate] = useState("");
  const [selectedBlacklist, setSelectedBlacklist] =
    useState<LprBlacklist | null>(null);
  const { data: blacklists, refetch } = useQuery({
    queryKey: ["lpr-blacklists"],
    queryFn: () => getLprBlacklists(),
  });

  async function addToBlacklist(_licensePlate: string) {
    if (_licensePlate.length === 0) {
      toast({
        variant: "destructive",
        description: "Campo placa é obrigatório",
      });
      return;
    }
    const formData = new FormData();
    formData.append("plate_value_blacklist", _licensePlate);
    await createBlacklistedPlate(formData)
      .then(() => {
        toast({
          description: "Placa adicionada com sucesso",
        });
        refetch();
        setLicensePlate("");
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          description: getErrorMessage(err),
        });
      });
  }

  async function handleRemovePlate(_blacklist: LprBlacklist) {
    await removeFromBlacklist(_blacklist.id);
    setSelectedBlacklist(null);
  }

  async function removeFromBlacklist(id: number) {
    await deleteBlacklistedPlate(id)
      .then(() => {
        toast({
          description: "Placa removida com sucesso",
        });
        refetch();
      })
      .catch((err) => {
        toast({
          variant: "destructive",
          description: getErrorMessage(err),
        });
      });
  }

  return (
    <MainContainer title="LPR - Gerenciamento de permissões" returnAction>
      <h1 className="text-xl my-2">Placas não permitidas</h1>
      <form
        className="flex items-center"
        onSubmit={(ev) => {
          ev.preventDefault();
          addToBlacklist(licensePlate);
        }}
      >
        <TextField
          size="small"
          label="Placa"
          margin="dense"
          value={licensePlate}
          onChange={(ev) => setLicensePlate(ev.target.value.toUpperCase())}
          style={{ flex: 1, maxWidth: "500px" }}
        />
        <Button
          type="submit"
          variant="contained"
          style={{ marginLeft: "10px" }}
        >
          Inserir
        </Button>
      </form>
      <List style={{ maxWidth: "500px" }}>
        {blacklists?.data.map((_blacklist) => (
          <React.Fragment key={_blacklist.id}>
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  color="error"
                  aria-label="delete"
                  onClick={() => setSelectedBlacklist(_blacklist)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <span>{_blacklist.plate_value_blacklist}</span>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <DeleteDialog
        open={!!selectedBlacklist}
        onClose={() => setSelectedBlacklist(null)}
        onSubmit={() => handleRemovePlate(selectedBlacklist!)}
        title={`Tem certeza que deseja remover a placa ${selectedBlacklist?.plate_value_blacklist}?`}
      />
    </MainContainer>
  );
}
