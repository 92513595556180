import { AnalyticType } from "interfaces/analytics";

export function getColor(occurrenceType: AnalyticType) {
  switch (occurrenceType) {
    case "Aglomeração":
      // console.log(theme.palette.getContrastText("rgb(237, 177, 32)"));
      return "rgb(217, 83, 25)";
    // if (theme.palette.mode === "light") return "#EDB120";
    // else return "#D49D1E";
    case "Evasão":
      return "rgb(237, 177, 32)";
    case "Intrusão":
    case "Inatividade":
      return "rgb(162, 20, 47)";
    // if (theme.palette.mode === "light") return "#C348DB";
    // else return "#7E2F8E";
    case "Radar":
      return "rgb(0, 114, 189)";
    case "Detecção de Animal":
      return "rgb(119,172,48)";
    case "Detecção de Veículo":
      return "rgb(77,190,238)";
    case "Pânico":
      return "rgb(162, 20, 47)";
    default:
      return "rgb(77,190,238)";
  }
}

export function getCardDescription(occurrenceType: AnalyticType) {
  switch (occurrenceType) {
    case "Aglomeração":
      // FIXME:
      return "Número máximo de pessoas na região excedido";
    case "Evasão":
      return "Número mínimo de pessoas na região não satisfeito";
    case "Intrusão":
      return "Pessoa em perímetro não autorizado";
    case "Radar":
      return "Excesso de velocidade";
    case "Detecção de Animal":
      return "Animal em perímetro não autorizado";
    case "Detecção de Veículo":
      return "Veículo em perímetro não autorizado";
    case "Congestionamento":
      return "Número máximo de veículos na região excedido";
    case "Ausência de Veículo":
      return "Veículo não fora identificado ao longo do intervalo definido";
    case "Inatividade":
      return "Detectado ausência de movimento por um longo período";
    case "Pânico":
      return "Gesto de pânico detectado";
    default:
      return "";
  }
}
