import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import CardForense from "../../components/Checkpoint/CardForense";
import SearchControls from "../../components/Checkpoint/SearchControls";
import ExpandImageModal from "components/Modals/ExpandImageModal";
import { CircularProgress } from "@mui/material";
import MainContainer from "components/MainContainer/MainContainer";
import { searchFace } from "api/flaskApiMethods";
import styles from "./ForenseSearch.module.css";
import { ForenseFace } from "interfaces/forense";

export interface ForsenseFaceWithCheck extends ForenseFace {
  selected: boolean;
  checkId: number;
}

export default function Forense() {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedSlide, setSelectedSlide] = useState("90");
  const [functionTime, setFunctionTime] = useState<(Dayjs | null)[]>([
    dayjs().subtract(1, "days").startOf("day"),
    dayjs().subtract(1, "days").endOf("day"),
  ]);
  const [functionDate, setFunctionDate] = useState<(Dayjs | null)[]>([
    dayjs().add(-3, "days"),
    dayjs(),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ForsenseFaceWithCheck[]>([]);
  const [modalOn, setModalOn] = useState(false);
  const [urlToModal, setUrlToModal] = useState("");
  const [faceArrayIDs, setFaceArrayIDs] = useState<number[]>([]); //Vetor com os ids de faces selecionadas
  const [faceArrayURLs, setFaceArrayURLs] = useState<string[]>([]); //Vetor com os fotos de faces selecionadas
  const navigate = useNavigate();

  const onSelectChange = (index: number) => {
    let arr = data;
    arr[index].selected = data[index].selected ? false : true;
    console.log(arr);
    setData(arr);
  };

  const sendSearch = async (refinar?: boolean) => {
    if (!selectedImage) return;
    setIsLoading(true);

    const form = new FormData();
    form.append("confidence", selectedSlide.toString());
    form.append("file", selectedImage);
    if (functionDate[0])
      form.append("date_start", functionDate[0].format("YYYY-MM-DD"));
    if (functionDate[1])
      form.append("date_end", functionDate[1].format("YYYY-MM-DD"));
    if (functionTime[0])
      form.append("time_start", functionTime[0].format("HH:mm"));
    if (functionTime[1])
      form.append("time_end", functionTime[1].format("HH:mm"));
    if (refinar) {
      for (let index = 0; index < faceArrayIDs.length; index++) {
        form.append("id", faceArrayIDs[index].toString());
      }
    } else {
      setFaceArrayIDs([]);
      setFaceArrayURLs([]);
    }

    const futureFaces = await searchFace(form);

    if (futureFaces) {
      setData(
        futureFaces.map((face, index) => ({
          ...face,
          selected: false,
          checkId: index,
        }))
      );
    } else {
      setData([]);
    }

    setIsLoading(false);

    // const options = {
    //   method: "POST",
    //   url: "http://" + socketURL + ":5600/api/forensic/search",
    //   headers: { "Content-Type": "multipart/form-data" },
    //   data: form,
    // };

    // axios
    //   .request(options)
    //   .then(function (response) {
    //     console.log(response.data);
    //     const transformData = response.data.map((face, index) => {
    //       return {
    //         id: face.id,
    //         confidence: Math.floor(face.confidence),
    //         place:
    //           face.camera.length > 18
    //             ? face.camera.substring(0, 16) + "..."
    //             : face.camera,
    //         date: face.date.substring(0, 19),
    //         file: face.file,
    //         faceBox: face.bbox.replaceAll(" ", ""),
    //         x: face.x * 800,
    //         y: face.y * 600,
    //         selected: false,
    //         checkId: index,
    //       };
    //     });
    //     console.log(transformData);
    //     setData(transformData);
    //     setPoints(transformData);
    //     setIsLoading(false);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //     setIsLoading(false);
    //   });
  };

  const handleChangeSimilarity = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedSlide(event.target.value);
  };

  const onSelectFace = (idPhoto: number, urlPhoto: string) => {
    const arrayIDs = [...faceArrayIDs];
    const arrayURLs = [...faceArrayURLs];

    const index = arrayIDs.indexOf(idPhoto);
    if (index > -1) {
      arrayIDs.splice(index, 1);
      arrayURLs.splice(index, 1);
      console.log(arrayIDs);
      console.log(arrayURLs);
    } else {
      arrayIDs.push(idPhoto);
      arrayURLs.push(urlPhoto);

      console.log(arrayIDs);
      console.log(arrayURLs);
    }

    setFaceArrayIDs(arrayIDs);
    setFaceArrayURLs(arrayURLs);
  };

  const searchControlsProps = {
    selectedImage,
    setSelectedImage,
    selectedSlide,
    functionDate,
    setFunctionDate,
    functionTime,
    setFunctionTime,
    data,
    setData,
    faceArrayIDs,
    setFaceArrayIDs,
    faceArrayURLs,
    setFaceArrayURLs,
    handleChangeSimilarity,
    sendSearch,
    onSelectFace,
  };

  const openModal = (url: string) => {
    setModalOn(true);
    setUrlToModal(url);
  };

  return (
    <MainContainer title="Busca forense">
      <SearchControls {...searchControlsProps} />
      {data.length > 0 && !isLoading ? (
        <div className={styles.cardsContainer}>
          <div className={styles.cardsActions}>
            <div>
              <span style={{ fontSize: "22px" }}>Resultados da Busca</span>
              <button
                className={styles.seeMap}
                onClick={() => {
                  navigate("/checkpoint/map");
                }}
              >
                VER MAPA
              </button>
            </div>
            <span
              style={{
                display: "flex",
                justifySelf: "flex-end",
                color: "#353535",
              }}
            >
              Mostrando resultados: {data.length} de {data.length}
            </span>
          </div>
          <div className={styles.mainCards}>
            {data.map((face) => (
              <CardForense
                id={face.id}
                faceBox={face.bbox}
                key={face.id}
                place={face.camera}
                confidence={face.confidence}
                photo={face.file}
                idsSelected={faceArrayIDs}
                date={face.date}
                checkId={face.checkId}
                toggleMap={onSelectChange}
                toggleRefineSearch={onSelectFace}
                openModal={openModal}
              />
            ))}
          </div>
        </div>
      ) : isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.cardsContainer}>
          <span style={{ marginBottom: "20px", fontSize: "22px" }}>
            Resultados da Busca
          </span>
          <p>
            Nenhum registro encontrado para essa busca, selecione outra imagem e
            faça a busca novamente.
          </p>
        </div>
      )}
      {/* {modalOn && (
        <ExpandImageModal
          open={modalOn}
          imgSrc={urlToModal}
          setOpen={setModalOn}
          onClose={() => {
            setModalOn(false);
            setUrlToModal("");
          }}
        />
      )} */}
    </MainContainer>
  );
}
