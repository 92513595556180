import React, { useState } from "react";
import { createDevice } from "api/ioDevices";
import { getCommboxInfo } from "api/flaskApiMethods";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { MenuItem, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import styles from "./CreateDevice.module.css";
import MainContainer from "components/MainContainer/MainContainer";
import { HeimdallDeviceTypes } from "interfaces/devices";
import { useToast } from "components/ui/use-toast";

export default function CreateDevice() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [deviceType, setDeviceType] = useState<HeimdallDeviceTypes | "">("");
  const [deviceTypeError, setDeviceTypeError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [model, setModel] = useState("");
  const [modelError, setModelError] = useState(false);
  const [ip, setIP] = useState("");
  const [ipError, setIPError] = useState(false);
  const [port, setPort] = useState("");
  const [portError, setPortError] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  async function handleModel() {
    if (deviceType === "horn_speaker" || !ip || !port) return;

    const response = await getCommboxInfo(ip, port, deviceType === "niobox");

    if (response) {
      setModel(response.model);
    }
  }

  async function handleCreateDevice(ev: React.SyntheticEvent) {
    ev.preventDefault(); // prevents default behavior of form submitting

    if (!deviceType) setDeviceTypeError(true);
    if (!name) setNameError(true);
    // if (!model) setModelError(true);
    if (!ip) setIPError(true);
    if (!port) setPortError(true);
    if (deviceType === "horn_speaker" && !username) setUsernameError(true);
    if (deviceType === "horn_speaker" && !password) setPasswordError(true);
    if (
      !deviceType ||
      !name ||
      //   !model ||
      !ip ||
      (deviceType === "horn_speaker" && !username) ||
      (deviceType === "horn_speaker" && !password) ||
      !port
    )
      return;

    const requestData: any = {
      name,
      ip,
      port,
      user: username,
      password,
    };

    if (deviceType === "commbox") {
      requestData.manufacturer = "Commbox";
    } else if (deviceType === "niobox") {
      requestData.manufacturer = "Niobox";
    } else if (deviceType === "horn_speaker") {
      requestData.manufacturer = "Khomp";
    }

    setLoading(true);

    const response = await createDevice(
      deviceType,
      JSON.stringify(requestData)
    );

    if (response) {
      navigate("/io-devices");
    } else {
      toast({
        variant: "destructive",
        title: "Erro ao criar dispositivo",
        description: "Um erro inesperado ocorreu",
      });
    }

    setLoading(false);
  }

  return (
    <MainContainer title="Novo Dispositivo I/O" returnAction>
      <p className="mb-5 text-sm text-gray-400">
        Crie um novo dispositivo I/O para vincular ao analítico configurado. O
        dispositivo de saída deve ser ligado a uma placa controladora homologada
        na plataforma. Insira os dados nos campos, conforme o dispositivo.
      </p>
      <form className={styles.form} onSubmit={(ev) => handleCreateDevice(ev)}>
        <TextField
          required
          select
          size="small"
          value={deviceType}
          error={deviceTypeError}
          label="Tipo de dispositivo"
          helperText={
            deviceTypeError ? "Você deve selecionar um tipo de dispositivo" : ""
          }
          onChange={(ev) => {
            setDeviceTypeError(false);
            setDeviceType(ev.target.value as HeimdallDeviceTypes);
          }}
        >
          <MenuItem value="">Indefinido</MenuItem>
          <MenuItem value="niobox">Niobox</MenuItem>
          <MenuItem value="commbox">Commbox</MenuItem>
          <MenuItem value="horn_speaker">Horn Speaker</MenuItem>
        </TextField>
        <TextField
          required
          label="Nome"
          size="small"
          value={name}
          error={nameError}
          helperText={nameError ? "Você deve fornecer um nome" : ""}
          onChange={(ev) => {
            setName(ev.target.value);
            setNameError(false);
          }}
        />
        <TextField
          required
          label="IP"
          value={ip}
          size="small"
          error={ipError}
          onBlur={handleModel}
          helperText={ipError ? "Você deve fornecer um endereço de IP" : ""}
          onChange={(ev) => {
            setIP(ev.target.value);
            setIPError(false);
          }}
        />
        <TextField
          required
          label="Porta"
          value={port}
          size="small"
          error={portError}
          onBlur={handleModel}
          helperText={portError ? "Você deve fornecer uma porta" : ""}
          onChange={(ev) => {
            setPort(ev.target.value);
            setPortError(false);
          }}
        />
        {deviceType === "commbox" || deviceType === "niobox" ? (
          <TextField
            required
            disabled
            size="small"
            label="Modelo"
            value={model}
            error={modelError}
            helperText="Este campo é preenchido automaticamente"
            onChange={(ev) => {
              setModel(ev.target.value);
              setModelError(false);
            }}
          />
        ) : null}
        <TextField
          required={deviceType === "horn_speaker"}
          error={usernameError}
          helperText="Nome de usuário para acesso ao dispositivo"
          onChange={(ev) => {
            setUsername(ev.target.value);
            setUsernameError(false);
          }}
          label="Usuário"
          value={username}
          size="small"
        />
        <TextField
          required={deviceType === "horn_speaker"}
          error={passwordError}
          value={password}
          helperText="Senha para acesso ao dispositivo"
          onChange={(ev) => {
            setPassword(ev.target.value);
            setPasswordError(false);
          }}
          label="Senha"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={!showPassword ? "password" : "text"}
          size="small"
        />
        <LoadingButton
          variant="contained"
          type="submit"
          loading={loading}
          onClick={handleCreateDevice}
        >
          Criar dispositivo
        </LoadingButton>
      </form>
    </MainContainer>
  );
}
