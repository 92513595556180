import {
  CorePalette,
  CustomColor,
  Scheme,
  TonalPalette,
  Theme as Md3Theme,
  rgbaFromArgb,
  argbFromRgba,
  customColor,
  applyTheme,
} from "@material/material-color-utilities";
import { AuxScheme } from "../styles/md3AuxScheme";
import {
  HeimdallThemeBackend,
  HeimdallThemeScheme,
  ThemeState,
} from "interfaces/heimdallTheme";
import { baseUrl } from "api/rootConfig";
import { lighten } from "@mui/material";
import { fromRgbaToString } from "styles/colors";
import { createHeimdallMuiTheme } from "styles/muiTheme";
import {
  defaultHeimdallMuiDarkPalette,
  defaultHeimdallMuiLightPalette,
} from "./themeDefaults";

export const argbSchemeToRgbaScheme = (argbScheme: Scheme | AuxScheme) => {
  const oldScheme = argbScheme.toJSON();
  const rgbaScheme: any = {};
  Object.entries(oldScheme).forEach(([key, value]) => {
    rgbaScheme[key] = rgbaFromArgb(value);
  });
  return rgbaScheme as HeimdallThemeScheme;
};

export const rgbaSchemeToArgbScheme = (rgbaScheme: HeimdallThemeScheme) => {
  console.log("old:", rgbaScheme);
  const newScheme: any = Object.fromEntries(
    Object.entries(rgbaScheme).map(([key, value]) => [key, argbFromRgba(value)])
  );
  console.log("new:", newScheme);
  // @ts-expect-error
  const argbScheme: Scheme = new AuxScheme(newScheme);
  return argbScheme;
};

export function themeFromSourceColors(
  primary: number,
  secondary: number | null,
  tertiary: number | null,
  customColors: CustomColor[] = []
) {
  const palette = CorePalette.of(primary);
  if (secondary != null) palette.a2 = TonalPalette.fromInt(secondary);
  if (tertiary != null) palette.a3 = TonalPalette.fromInt(tertiary);
  const generatedTheme: Md3Theme = {
    source: primary,
    schemes: {
      light: Scheme.lightFromCorePalette(palette),
      dark: Scheme.darkFromCorePalette(palette),
    },
    palettes: {
      primary: palette.a1,
      secondary: palette.a2,
      tertiary: palette.a3,
      neutral: palette.n1,
      neutralVariant: palette.n2,
      error: palette.error,
    },
    customColors: customColors.map((c) => customColor(primary, c)),
  };
  return generatedTheme;
}

/**
 * @deprecated this method only opens the image in a new URL
 * @param imageUrl the URL to the image
 * @param filename the filename
 */
export function downloadImageOld(imageUrl: string, filename: string) {
  // Create a link element
  const link = document.createElement("a");
  link.href = imageUrl;
  link.download = filename;
  link.target = "_blank";
  link.rel = "noreferrer";

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
}

export function downloadImage(imageUrl: string, filename: string) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", imageUrl, true);
  xhr.responseType = "blob";

  xhr.onload = function () {
    if (xhr.status === 200) {
      const blob = xhr.response;
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }
  };

  xhr.send();
}

function downloadJSON(jsonData: any, filename: string) {
  // Create a Blob object representing the data as a JSON string
  const blob = new Blob([JSON.stringify(jsonData)], {
    type: "application/json",
  });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);

  // Revoke the temporary URL to free up memory
  URL.revokeObjectURL(url);
}

export function correctLogoUrlFromUrl(logoUrl: string) {
  let prevUrl: URL | null = null;
  try {
    prevUrl = new URL(logoUrl);
    // Here, logoUrl comes with origin
    return baseUrl + prevUrl.pathname;
  } catch (error) {
    // Here, logoUrl comes without origin
    return baseUrl + logoUrl;
  }
}

export const muiPaletteFromHeimdallScheme = (
  heimdallScheme: HeimdallThemeScheme,
  rootContainer: HTMLDivElement,
  darkPalette?: boolean
) => {
  const newMuiTheme = darkPalette
    ? createHeimdallMuiTheme(
        {
          ...defaultHeimdallMuiDarkPalette,
          primary: {
            main: lighten(fromRgbaToString(heimdallScheme.primary), 0.3),
          },
          secondary: { main: fromRgbaToString(heimdallScheme.secondary) },
        },
        rootContainer
      )
    : createHeimdallMuiTheme(
        {
          ...defaultHeimdallMuiLightPalette,
          primary: { main: fromRgbaToString(heimdallScheme.primary) },
          secondary: { main: fromRgbaToString(heimdallScheme.secondary) },
        },
        rootContainer
      );

  return newMuiTheme;
};

export const applyMd3Theme = (
  theme: ThemeState["md3Theme"],
  rootContainer: HTMLDivElement
) => {
  applyTheme(theme, { brightnessSuffix: true, target: rootContainer });
};

export function applyFavicon(url: string) {
  let link = document.querySelector(
    "link[rel~='icon']"
  ) as HTMLLinkElement | null;
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = url;
}

export function removeMuiModalProps(muiTheme: ThemeState["muiTheme"]) {
  delete muiTheme.components?.MuiPopover;
  delete muiTheme.components?.MuiPopper;
  delete muiTheme.components?.MuiDialog;
  delete muiTheme.components?.MuiModal;
}

export function getMuiThemeForExport(muiTheme: ThemeState["muiTheme"]) {
  const auxTheme: Partial<ThemeState["muiTheme"]> = { ...muiTheme };
  delete auxTheme.shadows;
  delete auxTheme.components?.MuiPopover;
  delete auxTheme.components?.MuiPopper;
  delete auxTheme.components?.MuiDialog;
  delete auxTheme.components?.MuiModal;
  // @ts-expect-error
  delete auxTheme.components?.MuiLocalizationProvider;
  delete auxTheme.shape;
  delete auxTheme.zIndex;
  delete auxTheme.typography;
  delete auxTheme.transitions;
  delete auxTheme.breakpoints;
  delete auxTheme.unstable_sxConfig;

  return auxTheme;
}

export function addMuiModalProps(
  muiTheme: ThemeState["muiTheme"],
  rootContainer: HTMLDivElement
) {
  muiTheme.components!.MuiPopover = {
    defaultProps: {
      container: rootContainer,
    },
  };
  muiTheme.components!.MuiPopper = {
    defaultProps: {
      container: rootContainer,
    },
  };
  muiTheme.components!.MuiDialog = {
    defaultProps: {
      container: rootContainer,
    },
  };
  muiTheme.components!.MuiModal = {
    defaultProps: {
      container: rootContainer,
    },
  };
}

export function handleDownloadTheme(
  theme: HeimdallThemeBackend,
  rootContainer: HTMLDivElement
) {
  // removeMuiModalProps(theme.state.muiTheme);
  const auxMuiTheme = getMuiThemeForExport(theme.state.muiTheme);

  const themeState: ThemeState = {
    headerLogoMode: theme.state.headerLogoMode,
    isClassic: theme.state.isClassic,
    isDark: theme.state.isDark,
    isCustom: theme.state.isCustom,
    md3Schemes: theme.state.md3Schemes,
    md3Theme: theme.state.md3Theme,
    // @ts-expect-error
    muiTheme: auxMuiTheme,
  };

  downloadJSON(themeState, `tema_heimdall_${theme.company.name}`);
}
