import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import InputMask from "react-input-mask";

export type MaskedInputProps = React.ComponentProps<typeof InputMask> & TextFieldProps;

export function PhoneInput(props: MaskedInputProps) {
  return (
    <InputMask mask={props.mask} value={props.value} onChange={props.onChange}>
      {/* @ts-expect-error} */}
      {(inputProps) => (
        <TextField
          label={props.label}
          size={props.size}
          error={props.error}
          fullWidth={props.fullWidth}
          helperText={props.helperText}
          inputProps={{
            ...inputProps,
            ...props.inputProps,
            type: "tel",
            disableUnderline: true,
          }}
        />
      )}
    </InputMask>
  );
}
